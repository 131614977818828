import React from 'react'
import {
    cloud,
    initiatives,
    computing_history,
    hybrid,
    analyze,
    native,
    provider,
    google,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar11 from '../../componets/sidebar/Sidebar11';
const CloudComputing = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar11 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                Cloud Computing
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${cloud})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[33%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                                What is Cloud Computing?
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                The global search volume for cloud computing is over 300,000! Cloud computing is the most commonly used generic term today. However, this is a relatively recent phenomenon. Dell tried to trademark the term “cloud computing” in 2008, and no one opposed it.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                Dell applied for the trademark in context with:
                            </p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>"Custom manufacture of computer hardware for use in data centres and mega-scale computing environments for others” and "design of computer hardware for use in data centres and mega-scale computing environments".</p>
                            </div>
                        </div>
                        <p className="text-[27px] font-sans	font-light mt-5">The US Patent and Trademark Office sent a preliminary notice that Dell could own the term. However, USPTO withdrew the permission notice within a month.</p>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>Registration is refused because the applied-for mark merely describes a feature and characteristic of the applicant's services.</p>
                            </div>
                        </div>
                        <div className="mt-5">
                            <img src={initiatives} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is Cloud Computing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Cloud is a metaphor for the internet. Cloud computing is the on-demand availability of computing resources such as computation and file storage anywhere and anytime. The user does not have to manage the resources actively, and new resources can be added at any time, making it easy to scale operations.
                            </p>
                            <p className="text-[27px] font-sans font-bold mt-5">
                                Cloud computing is nothing but renting out someone else’s computer to run business operations.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">Large-scale enterprises that require large clouds have data and functions distributed across multiple locations, with each of these locations representing a data center.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                History of Cloud Computing
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Cloud computing is not modern technology. It has continued to evolve since the early 1950s. In 1955, John McCarthy created a time-sharing concept that enabled users to use an expensive mainframe simultaneously. McCarthy’s theory of mainframe timesharing is said to have had a significant impact on the development of the internet.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                With the evolution of technology, the concept of cloud computing took a giant leap in the mid-1960s, when an American computer scientist Joseph Carl Robnett Licklider, described a theory of interconnected computing systems. This idea gave birth to the predecessor of the internet: ARPANET (Advanced Research Projects Agency Network).
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">Licklider’s contribution is considered to be the most valuable in the creation of cloud computation; thus, he is believed to be the father of cloud computing.</p>
                        </div>
                        <div className="mt-5">
                            <img src={computing_history} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Primary Characteristics of Cloud Computing
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The primary characteristics of cloud computing include:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Pay-as-you-go Basis:</strong>customers only pay for resources that they use, which, in turn, reduces the overall cloud expenditure</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Device, Time, and Location Independent: </strong>Users can access the cloud-based applications from anywhere, any time, and from any device</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Scalability:</strong>Businesses can scale their operations by requesting more resources whenever required. This makes it easy to expand operations without much delay.</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">On-the-Go Updates: </strong>The cloud vendor handles the management and the updates. For a vendor, this poses an added responsibility to meet the changing needs of the customers.</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Cloud Computing: Use Cases
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Cloud computing is beneficial for:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Developing applications</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Storing, recovering, and backing up data</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Delivering software to the customers</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Streaming services such as audio and video</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Testing applications</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Running data analytics services</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Advantages of Cloud Computing</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>The rate at which data is exploding has pushed digital businesses to embrace cloud computing as the platform for innovation. Businesses across all industries are leveraging cloud solutions for various use cases such as digital security, disaster recovery, big data analytics, and data backup.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>37.3% of organizations believe ‘digital security is the biggest challenge that they face on the road to digital transformation.</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-light mt-5'>Today, cloud solutions help businesses navigate the challenges of the digital era, enabling them to quickly respond to a complex and fast-paced business landscape. Adopting the cloud allows businesses to run more effectively, serve customers better, and boost their revenue. In fact, companies that invest in cloud, big data, and mobility see 53% faster revenue growth than their competitors.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>Apart from helping businesses transform, differentiate, and gain a competitive advantage, the benefits of cloud computing are:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Cost Optimization</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Scalability</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Security</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Efficiency</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Automatic Upgrades</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Disaster Recovery</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Types of Cloud Deployment: Public vs Private vs Hybrid Cloud
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">A single type of cloud computing service is not fit for every business requirement. Different cloud deployment models and services have evolved with time to offer the right solution for every business need. Thus, before migrating to the cloud, it is essential to determine the types of cloud deployment: Public Cloud, Private Cloud, or Hybrid Cloud.</p>
                        </div>
                        <div className="mt-5">
                            <img src={hybrid} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Public Cloud</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Public clouds are operated and owned by third-party providers. In the case of a public cloud, the services offered by cloud providers are over a network that is open for public use. This implies that an organization shares the same hardware and network devices with other companies of the same cloud service provider. Microsoft Azure is an example of a public cloud.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Private Cloud</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A private cloud is a cloud deployment model that a single organization exclusively uses. In the case of a private cloud, the services and infrastructure are maintained on a private network, either located physically at the organization’s on-site data center or hosted by third-party service providers.</p>
                        </div>                     
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Types of Cloud Computing Service Models: IaaS vs SaaS vs PaaS
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">IaaS (Infrastructure as a Service), PaaS (Platform as a Service), and SaaS (Software as a Service) are the types of cloud computing services that help businesses transform their digital experience while, in turn, reducing the infrastructural costs. All of these cloud services differ primarily in what they offer to the end-user. Each of these cloud services has its benefits depending upon the business and functional requirements.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Here is a breakdown of the different cloud services (Iaas vs PaaS vs SaaS):</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Software as a Service (SaaS)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>SaaS/software as a service</span> refers to cloud-based applications, often developed by a <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>SaaS development company</span>, that are hosted online by a company. SaaS products are typically made available for purchase on a pay-as-you-go pricing model.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>According to <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>Gartner</span>, the end-user spending forecast for the Software as a service (SaaS) model will be around $171,915 million in 2022.</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-light mt-5'>Adopting a SaaS Business model provides many benefits over traditional on-premise software installations. Some of them are as follows:</p>
\                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Speed of Innovation</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Reduced Marketing Efforts</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Faster Time to Market</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Efficiency</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Reduced Churn Rates</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The Anytime, Anywhere Access</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Platform as a Service (PaaS)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>PaaS/ platform as a service refers to a cloud-based platform service that offers a robust framework for developers to build, test, and manage custom applications. Unlike SaaS, PaaS does not provide software over the Internet. Instead, it offers a platform to businesses where the software is built.<span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'> Google App Engine</span> and OpenShift are common PaaS examples.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>According to <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>Gartner</span>, the end-user spending forecast for the Software as a service (SaaS) model will be around $171,915 million in 2022.</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Infrastructure as a Service (IaaS)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>IaaS/ infrastructure as a service refers to a cloud-based infrastructure service that offers virtual data centers to businesses, which help them build and manage their operating systems, servers, data storage, and network infrastructure. The common IaaS examples are Microsoft Azure and Amazon Web Services (AWS).</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>According to <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>Gartner</span>, the end-user spending forecast for the Software as a service (SaaS) model will be around $171,915 million in 2022.</p>
                            </div>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            How to Build and Implement Enterprise Cloud Strategy?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">An enterprise cloud strategy is defined as a well-thought-out strategy for implementing the cloud at the organizational level.</p>
                            <p className="text-[27px] font-sans font-light mt-5">A 5-step plan for implementing an enterprise cloud strategy includes:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Form a Team or Outsource</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The cloud team is <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>headed by the CTO</span> and usually comprises IT and networking team members. Alternatively, a business can consider hiring a cloud consultant company that can help create a fool-proof enterprise cloud implementation roadmap.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Analyze Existing Applications</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The second step is to decide which applications stay on-premise and which applications need to move to the cloud. For instance, mission-critical applications need to be maintained on-premise, while applications that are not mission-critical or have gone obsolete need to be scrapped.</p>
                        </div>
                        <div className="mt-5">
                            <img src={analyze} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Build a Hybrid or a Multi-Cloud Strategy</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The next step is to build a hybrid or multi-cloud strategy. What cloud services does the business want to invest in? What cloud vendor would they choose? What are the business and functional requirements?</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Answers to all these questions help in forming a robust, hybrid, or multi-cloud strategy.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">Conclusion</h5>
                            <p className="text-[27px] font-sans font-light mt-5">Cloud computing is growing massively. 61% of organizations plan to upscale cloud usage and make it a top initiative for driving growth. With that in mind, every business should implement a smart cloud strategy that fulfills their business and functional requirements.</p>
                            <p className="text-[27px] font-sans font-light mt-5">This write-up covers what cloud computing is and everything about its related concepts. Before deploying the cloud, it becomes essential to understand the ins and outs of this growing <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>digital transformation trend</span>.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={native} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>CLOUD SERVICES,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    A Guide To Cloud-Native Application Development</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={google} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>CLOUD SERVICES,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    What is the Difference Between AWS, Azure, and Google Cloud? </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={provider} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>CLOUD SERVICES,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    How to Choose the Right Cloud Service Provider? </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CloudComputing