import React from "react";
import {
  product_development,
  Digital_analytics1,
  digital_analytics2,
  digital_analytics_chart2,
  digi_anlytics,
  clients_quotes,
  Mario_maruffi,
  digital_analytics11,
  digi_anlytics_img3,
  ebook_img,
  Top_Data,
  Data_Visualization,
  Comparison_between,
  Key_Principles,
  a_ds,
  cta_new,
  the_lean_product,
  hub_img1,
  hub_img2,
  process_diagram,
  hub_img4,
  hub_img5,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar5 from "../../componets/sidebar/Sidebar5";

const ProductDevelopment = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="sm:w-[400px] sm:h-[500px] mt-[68px] sm:fixed overflow-auto max-sm:w-[100%]">
          <Sidebar5 />
        </div>
        <div className="sm:ml-[420px]">
          <ul className="flex pt-[85px] max-sm:hidden">
            <li>
              <Link to={""} className="font-medium text-[18px]">
                Home
              </Link>
            </li>
            <li className="mt-0.5 ml-3">
              <div>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </div>
            </li>
            <li className="ml-3">
              <Link to={""} className="font-medium text-[18px]">
                Home Knowledge Hub
              </Link>
            </li>
            <li className="mt-0.5 ml-3">
              <div>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </div>
            </li>
            <li className="ml-3">
              <Link to={""} className="font-medium text-[18px]">
                Product Development
              </Link>
            </li>
          </ul>
          <div
            style={{ backgroundImage: `url(${product_development})` }}
            className="w-full sm:h-[400px] max-sm:h-[200px] bg-center bg-cover mt-5"
          >
            <div className="relative sm:left-[53px] top-[80px] sm:w-[32%]">
              <h5 className="font-extrabold sm:text-[41px] text-white max-sm:text-[20px] max-sm:text-center">
                Product development
              </h5>
            </div>
          </div>
          <div className="sm:px-10 mt-5">
            <p className="sm:text-[27px] font-sans	font-light max-sm:text-[20px] max-sm:text-center">
              To continuously drive innovation in the age of digital business,
              leaders must expect digital disruptions and prepare their
              organization to make a paradigm shift towards digital product
              development, all without compromising time to market.
            </p>
            <p className="sm:text-[27px]  ui-sans-serif font-light mt-5 max-sm:text-[20px] max-sm:text-center">
              This guide helps you understand the evolving definition of product
              development, its importance, process, and other key concepts. We
              also share the exclusive product development trends based on the
              worldwide survey of more than a hundred industry leaders.
            </p>
          </div>
          <div className="mt-5">
            <div>
              <img src={Digital_analytics1} />
            </div>
            <div className="sm:px-10">
              <div className="border-l-8 border-sky-400 relative top-[-100px]">
                <p className="px-2 pt-36 font-black sm:text-[58px] max-sm:text-[20px] max-sm:text-center">
                  What is Product Development?
                </p>
              </div>
              <div className="sm:px-4">
                <div>
                  <p className="sm:text-[27px] font-sans	font-light max-sm:text-[20px] max-sm:text-center">
                    Product development refers to transforming a planned product
                    idea into a meaningful offering, keeping the target customer
                    in mind. It typically employs design thinking, DevSecOps,
                    and{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623] max-sm:text-[20px] max-sm:text-center">
                      Agile development methodologies
                    </span>{" "}
                    to introduce a product to the market at speed and scale
                    while regularly testing and iterating as per the customer
                    feedback.
                  </p>
                  <p className="sm:text-[27px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    The foundational factors for digital product development
                    methodology are Speed and CX. It should align all aspects of
                    the product development process under a strategic vision to
                    Discover, Define, Design & Build, and Deliver the results
                    through continuous improvement.
                  </p>
                </div>
                <div className="mt-5">
                  <img src={a_ds} />
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What are the Stages of Product Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    An effective digital product development process simplifies
                    the approach and helps organizations release a digital
                    product in a structured manner. The following are the key
                    seven stages in the new product development process:
                  </p>
                  <ul className="list-disc ml-5">
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Idea Generation
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Idea Screening
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Concept Development & Testing
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Market Strategy/Business Analysis
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Product Development
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Market Testing
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Market Entry/Commercialization
                    </li>
                  </ul>
                </div>
                <div
                  style={{ backgroundImage: `url(${ebook_img})` }}
                  className="w-full h-[350px] bg-center bg-cover mt-5"
                >
                  <div className="relative left-[53px] top-[50px] w-[56%]">
                    <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                      Would You Like to Learn More about New Product
                      Development?
                    </h5>
                    <Link to={""}>
                      <button
                        type="button"
                        className="px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold h-16 mt-3 sm:w-[270px]"
                      >
                        Explore Our blog
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is the difference between Product Development &
                    Software Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Software and products are different. As we begin, "Product"
                    is the higher-order thing, and software is a component
                    order.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    A product is something that is built and sold to clients.
                    Product development involves understanding your clients'
                    requirements and technology constraints to design and
                    develop a product with the best possible feature set while
                    minimizing your costs.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Developing software requires an excellent understanding of
                    the process of building software systems to make them more
                    efficient and defect-free. It also requires a good vision of
                    the system's entire life cycle to plan its future evolution
                    and shape it to the benefit of your organization.
                  </p>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    Why is it Vital to Release Software to Production Multiple
                    Times a Day?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Rapidsofts'{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      Agile Product Development 2020 report
                    </span>{" "}
                    highlights that 73.6% of the organizations cannot release
                    additional product features within one week. This is one
                    reason 69.3% of the businesses reported that 10-30% of their
                    products cannot meet customer needs.
                  </p>
                </div>
                <div className="bg-[#FFF9EC] mt-5">
                  <div className="sm:px-24 max-sm:px-3 py-4">
                    <img src={clients_quotes} />
                    <h5 className="sm:text-[25px] font-extralight mt-7 max-sm:text-[20px] max-sm:text-center">
                      You want your build cycle happening almost instantly, so
                      your developers aren’t context switching. The insight and
                      observability we have into the monitoring process might
                      allow us to be able to say we could speed up a release
                      cycle…
                    </h5>
                    <div className="flex mt-5">
                      <div>
                        <img src={Mario_maruffi} />
                      </div>
                      <div className="ml-6">
                        <h5 className="sm:text-[21px] font-bold mt-3.5 max-sm:text-[20px] max-sm:text-center">
                          Ted Nelson
                        </h5>
                        <span className="text-[16px] font-medium max-sm:text-[20px] max-sm:text-center">
                          American pioneer of information technology
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    Product Development Trends 2021
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Rapidsofts surveyed 100 digital champions from different
                    industries to understand the key product development trends
                    that will help manage headwinds effectively and drive the
                    digital product development process in 2021. The following
                    are the six{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623] max-sm:text-[20px] max-sm:text-center">
                      product development trends for 2021
                    </span>
                    .
                  </p>
                  <ul className="list-disc ml-5">
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Agile Product Development Drives Performance
                    </li>
                    <li className="text-[27px] font-sans font-light mt-4">
                      <span className="font-bold border-b-[2px] border-[#F13623] max-sm:text-[20px] max-sm:text-center">
                        DevSecOps
                      </span>{" "}
                      is a Must-Have
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Continuous Customer Satisfaction Improvement and Faster
                      Product Delivery
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Stability harmonizes with Agility
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                      Product-Market Fit and Undefined Process are the Biggest
                      Roadblocks
                    </li>
                    <li className="sm:text-[27px] font-sans font-light mt-4">
                      <span className="font-bold border-b-[2px] border-[#F13623] max-sm:text-[20px] max-sm:text-center">
                        COVID-19
                      </span>{" "}
                      is an Instrumental Factor in Propelling Organizations to
                      Adopt Agile Product Development Methodology
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <img src={cta_new} />
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is the Importance of Product-Market Fit?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 v">
                    Product-market fit implies{" "}
                    <spna className="font-bold border-b-[2px] border-[#F13623] max-sm:text-[20px] max-sm:text-center">
                      building a right product
                    </spna>{" "}
                    that matches the customer’s needs, i.e., defining the
                    products’ must-have features and building the product around
                    those features in order to solve the user's problem.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Customers drive the market. Businesses cater to their needs
                    and deliver products based on their preference. But
                    different demographics have different requirements. It is
                    essential to clearly understand your intended demographic
                    and design your product around their demand, achieving a
                    perfect product-market fit.
                  </p>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    Steps to Achieve Product-Market Fit
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Most businesses struggle to understand their target
                    customers and their needs, which is one reason why most of
                    the products fail in the market. A product-market fit
                    pyramid, introduced by Dan Olsen, mentions six stages that
                    help achieve a perfect product-market fit.
                  </p>
                </div>
                <div className="mt-5">
                  <img src={the_lean_product} />
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is Web Product Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      Web development
                    </span>{" "}
                    refers to the work that happens behind the scenes to build
                    and maintain a website, ensuring that it delivers a seamless
                    user experience.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    The digital world is growing rapidly — over{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      1.8 B web applications and 200 million active websites
                    </span>{" "}
                    to date. With thousands of new websites getting added to the
                    world wide web each day, competition is getting intensified,
                    and savvy businesses are looking ahead of innovative ways to
                    adapt to this ever-changing web development world, about
                    which we have discussed in this section.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: `url(${hub_img1})` }}
                  className="w-full h-[350px] bg-center bg-cover mt-5"
                >
                  <div className="relative sm:left-[53px] top-[50px] sm:w-[56%] max-sm:text-[20px] max-sm:text-center">
                    <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                      Would You Like to Learn More about New Product
                      Development?
                    </h5>
                    <Link to={""}>
                      <button
                        type="button"
                        className="px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 sm:w-[310px] max-sm:text-[10px]"
                      >
                        Explore the Collection
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is Mobile App Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    The mobile app development industry is passing through one
                    of the most competitive phases. It has become vital for
                    businesses to lay down the mobile app development strategy
                    that includes choosing an apt platform, selecting the right
                    framework, and getting acquainted with the latest{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      mobile app development trends
                    </span>
                    .
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    In this section, we discuss the importance of mobile app
                    development in a customer-centric era and tips, techniques,
                    trends, challenges, and strategies involved.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: `url(${hub_img2})` }}
                  className="w-full h-[350px] bg-center bg-cover mt-5"
                >
                  <div className="relative sm:left-[53px] top-[50px] sm:w-[56%]">
                    <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                      Would You Like to Learn More about New Product
                      Development?
                    </h5>
                    <Link to={""}>
                      <button
                        type="button"
                        className="px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 sm:w-[310px] max-sm:text-[10px]"
                      >
                        Explore the Collection
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is Agile Product Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    The sluggishness and inadequacy of traditional{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      software development methodologies
                    </span>{" "}
                    — waterfall, iterative, spiral — to steer the{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      digital transformation
                    </span>{" "}
                    objectives have pushed businesses to adopt innovative ways
                    to deliver customer excellence. And one such innovative way
                    is Agile product development that helps deliver customer
                    value today and build a scalable product for tomorrow.
                  </p>
                </div>
                <div className="mt-5">
                  <img src={process_diagram} />
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is MVP Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Talking to budding entrepreneurs about launching a business,
                    most of them have massive dreams of starting a big,
                    successful company that will be the next Facebook or
                    Instagram, or Amazon. Maybe it will be, maybe not. The best
                    way to test a product's worth without a constant outflow of
                    money or time is{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      Minimum Viable Product
                    </span>{" "}
                    (MVP) Development, which we discuss in this section.
                  </p>
                </div>
                <div className="mt-5 ">
                  <iframe
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/fTixT5x7Igo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is Cloud-based Product Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Cloud technology has rapidly entered the business world. And
                    those who restrain themselves in incorporating it are at the
                    highest risk to undergo dire consequences of Digital
                    Darwinism — evolve or become extinct. This section talks
                    about Cloud technology, its different types, and benefits at
                    length.
                  </p>
                  <div className="sm:px-32 mt-5">
                    <div className="px-16 py-8 border-l-8 border-yellow-400">
                      <h5 className="sm:text-[31px] font-extralight max-sm:text-[20px] max-sm:text-center">
                        More than $1.3 trillion in IT spending will be affected
                        by the shift to the cloud by 2022.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is SaaS Application Development?
                  </h5>
                  <p className="text-[27px] font-sans font-light mt-4">
                    <span className="font-bold border-b-[2px] border-[#F13623] ">
                      Software as a service (SaaS)
                    </span>{" "}
                    is a software delivery framework owned, delivered, and
                    managed remotely by one or more providers. The provider
                    licenses the software, which is centrally hosted, on a
                    subscription basis.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    According to{" "}
                    <sapn className="font-bold border-b-[2px] border-[#F13623]">
                      Rapidsofts' Digital Transformation 2020 Survey
                    </sapn>
                    , close to half of the business leaders plan to reach their
                    digital transformation goals with SaaS transformation.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: `url(${hub_img4})` }}
                  className="w-full h-[350px] bg-center bg-cover mt-5"
                >
                  <div className="relative sm:left-[53px] top-[50px] sm:w-[56%]">
                    <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                      Would You Like to Learn More about Cloud Computing?
                    </h5>
                    <Link to={""}>
                      <button
                        type="button"
                        className="px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 sm:w-[310px] max-sm:text-[10px]"
                      >
                        Explore the Collection
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    What is the Importance of Quality Assurance (QA) in
                    Successful Product Development?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Quality assurance is a practice of identifying, monitoring,
                    and maintaining the{" "}
                    <span className="font-bold border-b-[2px] border-[#F13623]">
                      software development processes
                    </span>{" "}
                    used in digital product development to ensure its sound
                    quality: improving the digital product's performance,
                    security, and compatibility. In this section, we discuss
                    Software Testing, its importance, and types.
                  </p>
                </div>
                <div
                  style={{ backgroundImage: `url(${hub_img5})` }}
                  className="w-full sm:h-[350px] bg-center bg-cover mt-5"
                >
                  <div className="relative sm:left-[53px] top-[50px] sm:w-[56%]">
                    <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                      Would You Like to Learn More about Quality Assurance (QA)?
                    </h5>
                    <Link to={""}>
                      <button
                        type="button"
                        className="px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 sm:w-[310px] max-sm:text-[10px]"
                      >
                        Explore the Collection
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">
                    Why is Software Outsourcing a Good Business Strategy?
                  </h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Entrepreneurs and startups need to find the best price for
                    every aspect of running a business. With lean staffing,
                    outsourcing can be a crucial and cost-efficient component of
                    the business workflow. When comparing digital agencies for
                    outsourcing solutions, looking at firms in other countries
                    may be worthwhile.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    Most of the companies (59%) surveyed in the Deloitte Global
                    Outsourcing Survey revealed that 'Cost' is the most critical
                    parameter that pushed them to choose offshore outsourcing{" "}
                    <sapn className="font-bold border-b-[2px] border-[#F13623]">
                      product development company
                    </sapn>
                    . Budget is indeed essential, but it is also vital to
                    understand and mitigate offshore outsourcing risks.
                  </p>
                </div>
                <div className="mt-5">
                  <h5 className="font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center">Conclusion</h5>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    To survive in today's evolving product development era, it
                    is vital to adopt a continuous quality strategy amalgamated
                    with Agile and DevOps techniques that enable faster and
                    improved delivery of software products with minimum errors
                    than traditional product development methodology.
                  </p>
                  <p className="sm:text-[27px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                    The page covers product development and its significance in
                    creating continual improvement, breaking down silos, and
                    enhancing collaboration. These key parameters define an
                    ideal product development environment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 className="font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center">Featured Blogs</h5>
        <div className="sm:flex gap-5">
          <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
            <img src={Key_Principles} />
            <div className="px-4">
              <div className="flex mt-3">
                <p className="px-2 bg-[#F9D94E]">BLOG</p>
                <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
              </div>
              <div className="mt-4">
                <div className="flex">
                  <Link
                    to={""}
                    className="text-[#F13623] tracking-widest text-[12px]"
                  >
                    Data Analytics & Insights
                  </Link>
                  <Link
                    to={""}
                    className="text-[#F13623] ml-2 tracking-widest text-[12px]"
                  >
                    Data Visualization
                  </Link>
                </div>
                <div className="mt-4">
                  <Link
                    to={""}
                    className="text-[21px] hover:text-[#F13623] font-bold"
                  >
                    5 Key Principles of a Good Dashboard Design{" "}
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                >
                  Read Blog
                </button>
              </div>
            </div>
          </div>
          <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
            <img src={Data_Visualization} />
            <div className="px-4">
              <div className="flex mt-3">
                <p className="px-2 bg-[#F9D94E]">BLOG</p>
                <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
              </div>
              <div className="mt-4">
                <div className="flex">
                  <Link
                    to={""}
                    className="text-[#F13623] tracking-widest text-[12px]"
                  >
                    Data Analytics & Insights
                  </Link>
                  <Link
                    to={""}
                    className="text-[#F13623] ml-2 tracking-widest text-[12px]"
                  >
                    Data Visualization
                  </Link>
                </div>
                <div className="mt-4">
                  <Link
                    to={""}
                    className=" text-[21px] hover:text-[#F13623] font-bold"
                  >
                    Top 7 Data Visualization Trends & Tools for the Years to
                    Come{" "}
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                >
                  Read Blog
                </button>
              </div>
            </div>
          </div>
          <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
            <img src={Comparison_between} />
            <div className="px-4">
              <div className="flex mt-3">
                <p className="px-2 bg-[#F9D94E]">BLOG</p>
                <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
              </div>
              <div className="mt-4">
                <div>
                  <Link
                    to={""}
                    className="text-[#F13623] tracking-widest text-[12px]"
                  >
                    Hadoop Vs Spark — Choosing the Right Big Data Framework
                  </Link>
                </div>
                <div className="mt-4">
                  <Link
                    to={""}
                    className=" text-[21px] hover:text-[#F13623] font-bold"
                  >
                    Hadoop Vs Spark — Choosing the Right Big Data Framework{" "}
                  </Link>
                </div>
                <button
                  type="button"
                  className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                >
                  Read Blog
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDevelopment;
