import React from 'react'
import { Link } from "react-router-dom";
import { lab_img1, black_imageicon1, black_imageicon162, black_imageicon1666, black_imageicon1602, black_imageicon1603, ai_icon, ai_icon1, blockchain_icon, Hiring, Google_Cloud, Content_Management, software_vendor, and_thats } from '../../assets';


const ResearchDevelopment = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[32px]'>
                            The Best Research and Development Lab on the Web
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Want to keep up with the industry and capitalize on every shift? Look no more as we are offering the best R&D services to help you out.
                        </p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-32 h-10 text-[12px] font-extrabold mt-9'>Know more</button></Link>
                    </div>
                    <div className='w-[45%] mt-5'>
                        <img src={lab_img1} />
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>
                            Conducting Top-Notch R&D to Help Your Business
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            The business sector is a highly fluctuating one, and to survive the harsh settings, it is pivotal for businesses to rapidly evolve their approach towards the market. Technology plays a key role in the functioning of any market, but capitalizing on every tech turn is more essential to maintain an uptight stature in the industry. Our carefully prepared Research and Development helps businesses devise the best strategies and keep up with the latest technologies and algorithms. Avail RevInfotech today and ensure the continuous growth of your venture. Get ahead of every market shift with our help and reach the top.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4'>
                        <h5 className='font-bold text-[25px]'>More service</h5>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5]'>
                            <Link to={""}><img src={black_imageicon1603} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal"> Technology Consulting</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon1602} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Internet of Thing</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon1} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Automation Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon162} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Platform Modernization</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon1666} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Software AG</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 mt-3 bg-yellow-300'>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal ">See More Industries..</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#093D77] w-full h-40 mt-5'>
                <div className='container mx-auto px-44'>
                    <h5 className='text-center font-extrabold text-[33px] text-white pt-14'>Steering Latest Market Tech to Better Suit Your Venture</h5>
                </div>
            </div>
            <div className='container mx-auto'>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Proficiency in Fields Like</h5>
                <div className='flex flex-row gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={ai_icon} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>ARTIFICIAL INTELLIGENCE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Integrate AI within your proceedings and automate trivial yet crucial activities for your business. Save time and money with effective artificial intelligence implementation.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse '>
                        <div className='px-10 py-11'>
                            <img src={ai_icon1} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>MACHINE LEARNING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Build comprehensive solutions specifically designed for your business. With our help, you can create the ultimate platform to help your brand without any hitch.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={blockchain_icon} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>BLOCKCHAIN</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Ride the next leading service from the front and get big on it. Offer the best digital assets exchange services with the help of the leaders of the industry.</p>
                        </div>
                    </div>
                </div>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Get Extensive Solutions and Avail Esteemed Benefits</h5>
                <div className='flex flex-row flex-wrap gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>BEST BUSINESS STRATEGIES</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Study the market and devise the best strategies to help out your business. Our efficient research and development ensure your business is well-equipped with the latest market terms. With our strategies, you can never stay behind the cult.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>IDEAL SOFTWARE SOLUTIONS</h5>
                            <p className='font-light text-center text-[22px] mt-3'>With our help, you can build the best software solutions for your consumers. Leave the hassles of studying the market to us and sit back while we garner every piece of data for you. Accumulate the information and create the best software.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>REDUCED COSTS</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Conducting thorough research and development can consume a considerable amount of capital. Outsourcing the operations helps you save up hefty funds and you can utilize the capital on additional vital activities.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>SPECIALIZATION</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Hiring permanent research and development experts can also incur heavy funds for you. Choosing the R&D specialists like RevInfotech ensures that you are receiving the best services. Avail of the benefits of specialization with us.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>INCREASED MARKET KNOWLEDGE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Hire our services and forget the limitations of standard software development. Our experts ensure that you get an adequate chance to make modifications throughout the advancement. Even your sudden changes won’t affect the product’s quality.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[30px]'>
                            How Effective R&D Changes Things for You
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            A business can only go so far without a thought-out plan. Conducting market research and developing effective strategies accordingly is vital for an organization’s growth. When you conduct thorough R&D, your business is equipped with in-depth industry knowledge, which in turn can be translated to consumers. Not only can you garner a loyal user base but you can also get increased revenues. Develop the best R&D with the experts and get big on the web.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4 bg-[#F6F6F6]'>
                        <h5 className='text-[20px]'>
                            Why Choosing Rapidsofts for R&D Services is Ideal?
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Every sound organization is aware of the importance of effective research and development practices in a competitive scenario. Our R&D experts help your business gather an apt amount of consumer data and knowledge. With years of knowledge and experience, we can perform the function within a limited time. After collecting the data, we then study and offer the most essential bits to you. With our help, you can easily read the market shifts and deliver how your consumer demands.</p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9'>Help My Business</button></Link>
                    </div>
                </div>
                <h5 className='text-[34px] font-extrabold text-center pt-20'>Insights</h5>
                <div className='flex flex-row gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Content_Management} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>March 3, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Keeping Up With Content Management Systems in 2023: A Complete Guide
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Hiring} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>March 2, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Making the Old New: Hiring Fresh
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5 '>
                        <img src={Google_Cloud} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 28, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[30px] px-4 font-semibold leading-9 mb-12'>
                                Everything you need to know about Google Cloud SQL
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResearchDevelopment