import React from 'react'
import {
    mvp,
    xyour,
    ythumbnail,
    zux,
    clients_quotes,
    Eris_Ries,
    viable_product,
    viable_minimum,
    xmvp,
    typess,
    xEric_Ries,
    zinfinity,
    xgraph,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar15 from '../../componets/sidebar/Sidebar15'

const MinimumViableProduct = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar15 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                MVP Development
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${mvp})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[30%]">
                            <h5 className="font-extrabold text-[41px]">
                                The Ultimate Guide to MVP Development
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                You have probably read it repeatedly. Yet here are the numbers again - 90% of Startups fail, about 10% fail in the first year, and about 42% fail because of “no market need” or the lack of product-market fit.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">Beyond the silos of your business domains exists a dynamic world of assumptions, trials, errors, tests, and delivery. Undoubtedly, the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>waterfall methodology</span> of linear, start-to-end product development delivers results. Predicting market needs and developing a product at perfection levels in a complex digital era could be a losing game. When market dynamics are unpredictable, it is the speed of execution rather than perfection that will help. Instead, make a product “good enough,” deliver it, take market feedback, incorporate the learning, improve upon the product and repeat the process.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">This cycle of delivering, testing, and incorporating incremental iterations makes up for the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>agile methodology</span> in product development which fits better in the complex, ever-changing market scenario around us. Find errors, minimize them, mitigate risks and deliver fast - “fail faster, learn faster.”</p>
                            <p className="text-[27px] font-sans	font-light mt-4">We owe the concept of failing fast to learn faster to Eric Reis, who initiated this in his book ‘The Lean Startup.’ He removed the stigma around the word “failure” and put forth the logic that iterations made after market learning increase the probability of long-term success. In doing so, you also cut down on or avoid the sunk cost effect i:e, the tendency to keep investing in something just because failure seems unacceptable.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is an MVP?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The term ‘minimum viable product’ was initiated by SyncDev CEO Frank Robinson in 2001 but later popularized by Eric Ries in his book The Lean Startup in 2011.</p>
                            <p className="text-[27px] font-sans font-light mt-5">An essential link in the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>product development cycle</span>, the MVP is the beacon light to evaluate whether a product development idea should be pursued. It is a basic version, the initial workable or “saleable” version of a new idea or concept.</p>
                            <p className="text-[27px] font-sans font-light mt-5">The method is to introduce a product in the market with just the basic features, the lowest risk possible, and the minimum time to develop - yet enough features to catch the customer's attention. Once the feedback is taken, the developers will either add new features, give it a different twist, or develop a new product.</p>
                        </div>
                        <div className='bg-[#FFF9EC] mt-5'>
                            <div className='px-24 py-4'>
                                <img src={clients_quotes} />
                                <h5 className='text-[25px] font-extralight mt-7'>The minimum viable product is that version of a new product which allows a team to collect the maximum amount of validated learning about customers with the least effort.</h5>
                                <div className='flex mt-5'>
                                    <div>
                                        <img src={Eris_Ries} />
                                    </div>
                                    <div className='ml-6'>
                                        <h5 className='text-[21px] font-bold mt-3.5'>Eris Ries</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                The concept of a minimum viable product
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The MVP goes beyond answering “can this product be built?” and answers the question “SHOULD this product be built?”</p>
                            <p className="text-[27px] font-sans font-light mt-5">The MVP does not work just on the functionality aspect.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={viable_product} />
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans font-light">The MVP seeks to answer questions such that by the time the product is launched, it will already have a customer base. All feedback can then be used to review and revisit the product premise, the strategy for development, and the engine used for the growth. The underlying assumption is that all product development has to be about building a sustainable business.</p>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>Is the MVP only for startups?</span> An MVP can be for enterprises, too, when they want to test or validate a new feature or concept. Or they could use the MVP to test a new product idea.</p>
                            <p className="text-[27px] font-sans font-light mt-5">An MVP:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Should perform core functions</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Must have Basic features</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Can possess a simple design</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Has to have an efficient turnaround time</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Be deliverable fast enough</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Should save time and money by attaining fast feedback</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={viable_minimum} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Purpose of building an MVP
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The MVP is a way of building a basic version and releasing it to the public at a minimum cost. The feedback taken from the audience is then used to make the necessary changes or improvements.</p>
                            <p className="text-[27px] font-sans font-light mt-5">A minimum viable product serves the purpose of -</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Getting down the time to market for new product releases</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Delivering the product to your potential customers</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Testing the market before building a complete product</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Collecting usable data on consumer behavior patterns so that iterations can be worked out</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Validate the product ideas with data from real life</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Create and enhance a pre-launch user base</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={xmvp} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Types of MVP
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">We know now that the MVP is a scaled-down version of the final near-perfect product businesses would like to deliver into the market. Depending on the need of a business organization, there can be different types of MVPs available in the market.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Classification into various types can overlap. Rather than restrict ourselves to defining the types under just one or another terminology, it would do us well to understand the logic behind classifying under different names.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Broadly we could have low-fidelity and high-fidelity categories of MVP. Low-fidelity MVP is a basic, primitive concept that perhaps doesn’t need very sophisticated or complicated ways of validation. It might not present any product as such and, therefore, would not promise 100% direction for future development. That could become possible with a high-fidelity MVP that is more advanced and resource-consuming.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Low-fidelity MVP
                            </h5>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>It is essential, requiring simple or no software development</li>
                                <li className='text-[24px] font-sans font-light mt-4'>It is aimed at understanding the customer's problem and finding a solution</li>
                                <li className='text-[24px] font-sans font-light mt-4'>It spots customer challenges, gauges the demand for a particular solution, and then finds the solution</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Examples could be landing pages or “fake doors,” email campaigns, or other marketing campaigns</li>
                            </ul>
                            <p className='text-[24px] font-sans font-bold mt-4'>Examples-</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-light text-[27px]'><span className='font-bold text-[27px]'>1. ‘Fake Door’ </span>MVP, or the audience building MVP, is a low-fidelity MVP that assesses the interest in a product without the actual implementation. Ever come across this? You go to a website offering exclusive premium tips to lose weight, click on the CTA that says “get the eBook,” and the subsequent page says ‘under construction.’ You’re a part of the list that the business will collect about the number of people interested in the product. A little risky, but it does help in gauging the potential of a future idea, feature, or product without spending or entering into the validation process of the technical viability.</p>
                            <p className='font-light text-[27px] mt-5'><span className='font-bold text-[27px]'>2. Landing Page</span>MVP helps get feedback on the customer base for future features and content additions. In this low-fidelity case, there is no need to state the product is coming in the future or under construction. You connect with the target audience, get an insight into the USP of your product and collect information about the potential audience. While sometimes it may be difficult to understand why a particular customer did not follow up on the CTA, this is still a relatively inexpensive and easy-to-deploy method.</p>
                            <p className='font-light text-[27px] mt-5'><span className='font-bold text-[27px]'>3. Email Campaign </span>MVP works well when you have existing email data of customers and you want to target a specific audience to check if a new idea interests them. Detailed analytics and insights can help determine how many customers responded to you.</p>
                            <p className='font-light text-[27px] mt-5'><span className='font-bold text-[27px]'>4. Marketing/No-product MVP</span>is a low-fidelity MVP that uses promotional campaigns, social media, influencers, etc., to test an idea on a broader audience. This can be helpful for testing on a wider audience. This would work well to test ideas and explore the market. No-product MVP also works well with no-tech or low-tech development. Examples could be advertising campaigns or even wireframes and demos.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={typess} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                5 Ms: MVP Product Development Benefits
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">When product development revolves around developing the MVP, it operates on a methodology of delivering good-to-use, functional products fastest and with the most negligible risks. Speed becomes supreme. Providing a new product that delights your customers demands a transformation — eliminating waste and embracing processes that could accelerate product delivery.</p>
                            <p className="text-[27px] font-sans font-light mt-5">In that process, some benefits accrue for the business:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>More Focus on Building the Core</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Rather than developing a feature-heavy product, offering the core features helps businesses verify their product concept — whether it resonates with their target audience.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Meteoric (Speedy) Product Development</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Speedy means designing, building, and releasing the basic application quickly, iterating fastly, and validating along the way.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Market Validation</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>MVP software development is about testing and analyzing what works and does not. An MVP aims to get acquainted with the market demand and sell that product to customers they will love.</p>
                        </div>
                        <div className='bg-[#FFF9EC] mt-5'>
                            <div className='px-24 py-4'>
                                <img src={clients_quotes} />
                                <h5 className='text-[25px] font-extralight mt-7'>What if we found ourselves building something that nobody wanted? In that case what did it matter if we did it on time and on budget?</h5>
                                <div className='flex mt-5'>
                                    <div>
                                        <img src={xEric_Ries} />
                                    </div>
                                    <div className='ml-6'>
                                        <h5 className='text-[21px] font-bold mt-3.5'>Eris Ries</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Minimizing the Product Development Cost</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>An MVP approach helps avoid mediocre end-user satisfaction, schedule slippage, and cost overruns by keeping you from spending your entire production budget on all the features immediately.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                How to Build an MVP in S.I.M.P.L.E. Steps?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The MVP development process is a top-down, iterative, and test-driven approach that focuses on the customer at every stage of the MVP process. The purpose of a minimum viable product is a quick release, quick iteration, and continuous validation to make the final product launch easier and more successful at the later stage.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Understanding the MVP development process and its steps are vital to its and the product’s success. The following are the necessary S.I.M.P.L.E. steps to build an MVP.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Start with Market Research</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>I have an idea, bingo. Alas, not all ideas are worth bringing to the market — may be your vision does not fit the customers' and market's needs. To evaluate, it is vital to set up market research and conduct surveys to gain more insights before embarking upon an MVP <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Development process</span>.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Ideate on Value Addition.</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>You cannot sell an MVP of an air conditioner in Antarctica. No matter how good your idea is, it will fail if you cannot answer the following questions:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>What value does your view add to its target user base?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>How could your idea benefit them?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>What will make them buy your idea?</li>
                            </ul>
                        </div>
                        <div className='bg-[#FFF9EC] mt-5'>
                            <div className='px-24 py-4'>
                                <img src={clients_quotes} />
                                <h5 className='text-[25px] font-extralight mt-7'>Beautiful product development in an ugly market segment simply makes no sense.</h5>
                                <div className='flex mt-5'>
                                    <div>
                                        <img src={xEric_Ries} />
                                    </div>
                                    <div className='ml-6'>
                                        <h5 className='text-[21px] font-bold mt-3.5'>Dan Adams</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Map Out User Flow</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Building a car without referring to its visual design is almost impossible. Impatiently jumping to the MVP development process without highlighting the design and user flow leads to a ‘Failed MVP.’ Keep the future product and target audience in mind to design a user flow that is convenient for users.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight'>"Remember: Design Thinking + Lean UX + Agile = Successful MVP".</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Launch MVP</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Once you have gained insights into the market needs and have shortlisted the features based on the MoSCoW approach to MVP, start building your MVP. While the ‘launch & build’ process of an MVP, remember that although it is not a final product, it should be made with top-most quality that fulfills your customer’s needs.</p>
                        </div>
                        <div className='bg-[#FFF9EC] mt-5'>
                            <div className='px-24 py-4'>
                                <img src={clients_quotes} />
                                <h5 className='text-[25px] font-extralight mt-7'>Customer needs may vary, but their bias for quality never does.</h5>
                                <div className='flex mt-5'>
                                    <div>
                                        <img src={xEric_Ries} />
                                    </div>
                                    <div className='ml-6'>
                                        <h5 className='text-[21px] font-bold mt-3.5'>J. Willard Marriott</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>5. <span className='ml-3'>Exercise ‘B.M.L.’ — Build, Measure, Learn</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Build-Measure-Learn is the fundamental methodology that Reis talks about in ‘The Lean StartUp.’ Based on a scientific approach, the B-M-L course stresses discovering the problem you need to solve, building a minimum viable product (MVP), and starting the learning process after measuring the launch product's impact. The Build-Measure-Learn (B.M.L.) is an essential step in building an MVP. It deals with measuring the acceptance of the built MVP and its enhancement as needed. Based on the customer need hypothesis, keep iterating the product in relatively small increments.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={zinfinity} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                How Much does an MVP Cost?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Undoubtedly, an MVP costs much less than the entire product. Still, it’s imperative to consider and analyze the MVP cost before starting the MVP development process. Whether you are <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>developing a mobile app</span> or a website, the MVP cost of development varies, depending on different factors, like the idea, design, features, technology stack, and time taken to <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>build an MVP</span>.</p>
                        </div>
                        <div className='mt-5 w-[70%] mx-auto'>
                            <img src={xgraph} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Initial Budget to Build an MVP</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>You can follow different approaches to build an MVP for your business — hiring a <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>developing a mobile app</span> or a website, the MVP cost of development varies, depending on different factors, like the idea, design, features, technology stack, and time taken to <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>product development company</span> or freelancers or managing the development in-house. Choose any option — it needs an investment of money and time.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Time Required to Build an MVP</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Building the MVP’s first version should not exceed two weeks. The first version contains the basic features of an MVP, a like Empathy Map, Prioritised MVP Backlog, an Ecosystem Map, a User Journey Map, and a Stakeholder Map. The cost ranges from $15 to $75 per hour, which can vary depending on the project’s complexity.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>MVP Cost of Design</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The MVP cost also depends on the design complexity. The best approach is to evaluate the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>User Interface</span> (UI) to analyze the MVP cost of design. A seamless User Experience (UX) relies upon a simple, easily understandable, navigational UI. The main components that decide the MVP cost of design are</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>The number of Features and Complexity</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Prioritization of features and their complexity are the two most important factors that define the cost of building an MVP. It’s difficult to list and prioritize the features for your next MVP. Adopt the following two approaches to segregate your MVP's right and much-needed features:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Blue Ocean Strategy</li>
                                <li className='text-[24px] font-sans font-light mt-4'>MoSCoW Method</li>
                            </ul>
                        </div>
                        <div className='bg-[#FFF9EC] mt-5'>
                            <div className='px-24 py-4'>
                                <img src={clients_quotes} />
                                <h5 className='text-[25px] font-extralight mt-7'>In this age of information abundance and overload, those who get ahead will be the folks who figure out what to leave out, so they can concentrate on what’s important to them.</h5>
                                <div className='flex mt-5'>
                                    <div>
                                        <img src={xEric_Ries} />
                                    </div>
                                    <div className='ml-6'>
                                        <h5 className='text-[21px] font-bold mt-3.5'>Austin Kleon, a Renowned Novelist</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h5 className="font-extrabold text-[41px]">
                                FAQs
                            </h5>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Is MVP a terminology or a mindset?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>More often than not, MVP is thought to be a technology-related term. It is a mindset that an organization can adopt to get initial feedback on an idea with minimum resources. It works within the agile methodology framework, and the idea is to launch a basic usable version of a product to test its viability in the market.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>How do we differentiate between PoC vs. Prototype vs. MVP?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>proof of concept</span> targets researchers or developers and is a pre-product stage. It tests the idea's technical feasibility and is not aimed at sales. A prototype that might target potential investors & focus groups is made at a minimum budget and tests the idea's look, flow, and UI. Building an MVP requires more time and money than the other two. It is a product with basic core functionalities and features to see how the product idea will sell. It targets early adopters and investors.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>What's the difference between an MVP, MMP, and MLP?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>An MVP, we know, is a product with the minimum basic features ready to be tested in the market to gather and learn about the market needs and desires. The MMP, or the minimum marketable product, is the best version you come up with after a bit of trial & error. Extending the vocabulary, the MLP or the minimum loveable product goes beyond functionality and focuses on quality - on the ''enjoyable" aspect besides the functional and usable.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>How many features should an MVP include?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>There is no final well-defined strategy for MVP features to have as an essential list. But you do need to watch the competitor list and the cost of adding features. Follow a user-centered approach. Analyze your competitors to see the product'sproduct's what, why, and how, and do a SWOT analysis. An analysis of the competitors will tell you where you stand, your product's potential, and what you should do to gain a competitive edge.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={xyour} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>MVP DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    How to Prioritize Features for Your Minimum Viable Product (MVP)</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={ythumbnail} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>MVP DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    What is a Minimum Viable Product (MVP)? Types, Examples, Benefits</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={zux} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>MVP DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    MVP Design Done Right With 7 Proven UX Design Principles </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MinimumViableProduct