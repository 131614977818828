import React from 'react'
import {
    x_testing,
    z_IBM,
    What_Testing,
    zacceptance_testing,
    xload_testing,
    ybeta_testing,
    x_Thumbnail,
    y_automation,
    z_Solutions,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar18 from '../../componets/sidebar/Sidebar18';

const SoftwareTesting = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar18 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                Software Testing
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${x_testing})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[30%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                            What is Software Testing
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                            Who was the IBM’s Black Team? Here’s a quick tale on the evolution of effective software testing.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">Back in the 1960s, buggy software was considered defective software. Because of the lack of a standard software testing process, IBM was losing a lot of money. This was when IBM realized that there were around 10-20% efficient software testers in the team — people who had a passion for finding bugs in the software product. IBM put these like-minded testers on a single team and hoped that they would ramp up the bugs finding process by at least 20%.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">This proved beneficial for IBM as software quality improved at a tremendous rate. The new team was twice as effective compared to their peers and was proud of the new role. The software testers enjoyed their work so much that they considered themselves villainous destroyers (whom the programmers feared) and even started to show up to work dressed in black. Hence came to light IBM’s Black Team.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={z_IBM} className="w-full" />
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans	font-light">Since then, software testing has evolved as a process in many ways. This art of identifying bugs in the software product is entrenched deeply in the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>software development process</span>.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>Global Software Testing Services market size is estimated to grow at a CAGR of 12% with USD 34.49 billion during the forecast period 2021-2025.—</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans	font-light">Now let’s move on to find out what is software testing and the related concepts in detail.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            What is Software Testing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>Goal:</span> Follow the best techniques and leverage the best tools to identify and fix the existing bugs in the software</p>
                            <p className="text-[27px] font-sans font-light mt-5">Software testing is the process of identifying and reporting bugs in the software product under development. It helps in quality assurance and validation that the software is functional and running.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={What_Testing} className="w-full" />
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans font-light">The goals of software testing are to ensure that the software:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Meets the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>business and functional requirements</span></li>
                                <li className='text-[24px] font-sans font-light mt-4'>provides desired output for all input types</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Responds quickly to user’s input</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Works optimally in all kinds of environments</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Meets stakeholders’ expectations</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Is ready to be shared with the respective stakeholders</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Quality Assurance vs Quality Control vs Software Testing
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Confusion between quality assurance, quality control, and software testing is common. Although all three serve the primary objective, i.e., ensure that the software fulfills the defined quality standards, the goal of each of them varies.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Here’s the difference between software testing, software quality assurance, and quality control:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Quality Assurance: </span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Focuses on the prevention of bugs from occurring. The quality assurance team ensures that all the quality standards and practices are in place that helps maintain the software quality across the development cycle.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Quality Control: </span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Focuses on validating the adherence to the recorded software requirements. The quality control team helps validate that the developed software meets the technical software requirements.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Software Testing: </span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Focuses on identifying and fixing existing bugs. The software testing team is responsible for identifying the existing bugs in the software and reporting it back to the developers.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[24px] font-sans	font-light'>Here’s a comparison table that differentiates QA, QC, and testing:</p>
                        </div>
                        <div className='mt-5'>
                            <table className="table table-bordered table-light">
                                <thead>
                                    <tr className=''>
                                        <th className='text-[21px] !px-2 py-2 !bg-[#FEE75B] w-[430px] text-center'>Quality Assurance</th>
                                        <th className='text-[21px] !px-2 py-2 w-[450px] !bg-[#CEF0FF] text-center'>Quality Control</th>
                                        <th className='text-[21px] !px-2 py-2 w-[450px] !bg-[#F8A2A1] text-center'>Software Testing</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Is part of STLC (Software Test Life Cycle)</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Is categorized as a branch of quality assurance</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Is categorized as a branch of quality control</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Focuses on the prevention of bugs from occurring</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Focuses on validating whether the developed software meets the recorded functional and business requirements</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Focuses on identification and reporting of existing bugs in the software</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Process-oriented</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF] '>
                                            <p className='text-[20px] font-light font-sans'>Product-oriented</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Product-oriented</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Involved: The software development team and the stakeholders</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Involved: The software development team</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Involved: Testers and developers</p>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            What are the Attributes of Quality Software?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Software quality attributes help in understanding — what defines quality in software. It significantly helps the development team understand the aspects they should be focusing on when concerned about quality delivery.</p>
                            <p className="text-[27px] font-sans font-light mt-5">The most noteworthy attributes of good software include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Efficiency </span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Ensures that the amount of computing resources and code is in place to perform the intended action.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Reliability </span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Ensures that the software performs the intended action without bugs and delays</p>
                        </div> <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Usability</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Ensures that minimal effort is required to perform the intended action, i.e., the interface is easy to use and operate</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Survivability </span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Ensures that the critical software functions can be performed efficiently even when a part of the system is facing an issue</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>5. <span className='ml-3'>Integrity</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Ensures that the access to the software and data is controlled, i.e., only the authorized personnel can gain access to the software</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Manual Testing vs Automated Testing
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The entire software testing process is a combination of both manual and automated tests (consider this the best approach forward). It is up to the assurance and testing team as to what testing they prefer at what stages of the development process.</p>
                            <p className="text-[27px] font-sans font-light mt-5">In manual software testing, the testers perform the test cases without any assistance from available tools and software.</p>
                            <p className="text-[27px] font-sans font-light mt-5">In automated testing, the test cases are executed automatically without any human involvement using different tools and software.</p>
                            <p className="text-[27px] font-sans font-light mt-5">For a more detailed comparison, refer to the table below:</p>
                        </div>
                        <div className='mt-5'>
                            <table className="table table-bordered table-light">
                                <thead>
                                    <tr className=''>
                                        <th className='text-[21px] !px-2 py-2 !bg-[#FEE75B] w-[430px] text-center'>Manual Testing</th>
                                        <th className='text-[21px] !px-2 py-2 w-[450px] !bg-[#CEF0FF] text-center'>Automated Testing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Performed by quality assurance and testing team</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Performed using software testing tools</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Suitable for exploratory, usability, and ad hoc testing</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Suitable for white box testing, load testing, and performance testing</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Should be performed when experienced and talented quality assurance and testing experts are available</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF] '>
                                            <p className='text-[20px] font-light font-sans'>Any test that needs to be repeated occasionally during the development process should be automated</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Is less reliable as it is prone to human errors</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Is fast and reliable as it runs using tools and pre-written test scripts</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            What are the Different Software Testing Levels?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Primarily there are three levels of software testing, which include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Unit Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Unit testing helps in bugs identification in a single module of the code. In Agile development, unit testing implies identifying the bugs in a single user story developed over a time-boxed sprint cycle.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Integration Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Integration testing helps test the integrated version of the software, i.e., when two or more modules are clubbed together. It helps in verifying the wholeness of the system and identification of bugs (if any).</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>System Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>System testing helps test the entire software system when clubbed together. Here, all the modules are integrated to check the entire software for bugs and whether it meets the business and the functional requirements.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            What are the Different Types of Software Testing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">There are several software testing types to choose from. Each of these testing techniques has a specific use case they tend to. Let’s have a look at some of them:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Ad-hoc Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is an unstructured software testing technique that is performed randomly without using any test use cases. This type of testing helps identify bugs that went undetected by the other standard tests</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Acceptance Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is a formal software testing technique that helps validate whether the software meets the user’s needs and business and functional requirements. It is often conducted by the end users and is also known as “User Acceptance Testing.”</p>
                        </div>
                        <div className='mt-5 w-[80%] mx-auto'>
                            <img src={zacceptance_testing} className="" />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Regression Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Regression testing is brought into the picture when a major change is made to the part of the software. The different test cases are re-implemented to check whether the software still performs as expected (after the change).</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Security Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is a type of testing that helps ensure that the software is protected against outside attacks from unauthorized persons.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>5. <span className='ml-3'>Smoke Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is an initial software testing that helps identify the basic problems, which may restrict the software from running.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>6. <span className='ml-3'>Load Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Load testing is a type of test that helps determine the efficiency of software under stress conditions, i.e., if there is an increase in the number of users or when it is handling a large data chunk.</p>
                        </div>
                        <div className='mt-5 w-[80%] mx-auto'>
                            <img src={xload_testing} className="" />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>7. <span className='ml-3'>Alpha/Beta Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Alpha test is conducted by a group of end-users or a testing team group at the developer’s site. It is an internal acceptance testing technique that proves helpful for most off-the-shelf software.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Alpha testing is followed by beta testing. It is an external user acceptance testing technique where limited end users test the software and report faults and bugs.</p>
                        </div>
                        <div className='mt-5 w-[80%] mx-auto'>
                            <img src={ybeta_testing} className="" />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Software Testing Benefits
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The benefits of having a software quality assurance and testing process in place include:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Minimizes the risk of errors</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Enhances the value proposition of the final product</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Helps promote continuous improvement of the software development process</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Lowers the chances of errors in the later stages of the development process</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Heps meet the client requirements, which, in turn, helps enhance the development team’s credibility</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Makes it easier to iterate through the software when performing the maintenance activities</li>   
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            What are the Different Types of Software Testing Tools?
                            </h5>
                        </div>
                        <div className='mt-5'>
                            <table className="table table-bordered table-light">
                                <thead>
                                    <tr className=''>
                                        <th className='text-[21px] !px-2 py-2 !bg-[#FEE75B] w-[430px] text-center'>Tool Type</th>
                                        <th className='text-[21px] !px-2 py-2 w-[450px] !bg-[#CEF0FF] text-center'>What Does it do?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Program Monitors</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Checks the source code for errors</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Code Coverage</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>It helps get an idea about the extent of the source code covered while running a test. The higher the percentage of test coverage, the fewer are the chances of undetected bugs</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Symbolic Debugging</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF] '>
                                            <p className='text-[20px] font-light font-sans'>Is a tool type that helps in inspecting and analyzing the code variables at the point of error</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Automated Functional GUI Testing</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>This tool helps in frequent running system-level tests throughout the GUI (Graphical User Interface)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Benchmarks</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF] '>
                                            <p className='text-[20px] font-light font-sans'>A tool that helps run run-time performance comparisons</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Performance Analysis</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Helps highlight hot spots (a part of the source code where most of the instructions are executed) and resource usage</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            FAQs Related to Software Testing
                            </h5>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>What is Code Coverage?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Code coverage or test coverage measures the extent to which the source code is covered when test cases are run.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>What is Shift Left Testing?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Shift left testing is a practice of implementing software testing from early on. The focus of shift left testing is to shift testing tasks to the left early in the software development and delivery lifecycle.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>What are the Latest Software Testing Trends?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The latest software testing trends include:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Test Automation</li>
                                <li className='text-[24px] font-sans font-light mt-4'>DevOps and Agile Testing</li>
                                <li className='text-[24px] font-sans font-light mt-4'>AI (Artificial intelligence) for testing</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Regression testing</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>What is Crowdsourced Testing?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Crowdsourced testing is an emerging software testing trend where dispersed and a temporary workforce is deployed for software application testing. It is similar to staff augmentation, where staff is outsourced from across organizations on a project-to-project basis.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>5. <span className='ml-3'>What is Fault Seeding in Software Testing?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Fault seeding is a technique used to validate the effectiveness and efficiency of a testing process. In this technique, one or more faults are intentionally added to the code base without letting the testers know. If the tester can identify the seeded faults, one can be assured that the software testing is being effectively conducted.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Conclusion
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">If developers are the creators, testers ensure that the creation is bug-free. Software testing is a building block of software that covers a lot of things that are essential for ensuring quality delivery. An organized software testing process instills confidence in the software and helps in enhancing the overall user experience.</p>
                            <p className="text-[27px] font-sans font-light mt-5">In this write-up, we tried to cover everything around software testing, including — what software testing is, its approaches, levels, type, benefits, process, principles, best practices, and FAQs.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={x_Thumbnail} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  QUALITY ASSURANCE AND......</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    Test Driven Development: How it works, Pros and Cons</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={y_automation} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  QUALITY ASSURANCE AND......</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    Mobile Testing Automation: A Five-step Guide</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={z_Solutions} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  QUALITY ASSURANCE AND......</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    Develop Better Quality Software with Agile Testing in 2023 </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SoftwareTesting