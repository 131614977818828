// import React from 'react'
import {
  author,
  author_image1612440983author_1,
  author_2,
  author_i3,
  author_i4,
  authorj_i5,
  btech,
  adobe,
  clutch,
  forrester,
  gartner,
  goodfirms,
  netsolutions,
  W3,
  Group66110,
  logo223,
  eiysys1,
  sumsub3,
  Group66111,
  csharpcorner1,
  alchemy,
  GreyScale,
  mcn1,
  LH1,
  The15,
  Cloud_Computing,
  softwaredevelopment,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment4,
  softwaredevelopment5,
  softwaredevelopment6,
  softwaredevelopment7,
  softwaredevelopment8,
  softwaredevelopment9,
  softwaredevelopment10,
  softwaredevelopment11,
  softwaredevelopment12,
  softwaredevelopment13,
  softwaredevelopment14,
  softwaredevelopment16,
  softwaredevelopment17,
  softwaredevelopment18,
  softwaredevelopment19,
  Hiring,
  GoogleCloudSQL,
  Digital_tra2,
  Howto,
  Clutch_1,
  g21,
  goodfirms1,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import Slider from "react-slick";
import Select from "react-select";
const CustomSoftware = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px]">
              <h1 className="sm:text-[23px] max-sm:text-[20px] font-black text-[#D4386C] max-sm:text-center  max-sm:mt-[80px]">
                SOFTWARE DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:w-[100%] max-sm:text-[13px] max-sm:text-center max-sm:mt-3">
                SOFTWARE DEVELOPMENT COMPANY Reliable and Scalable Software to
                Develop Your Businesses
              </h1>
              <p className="text-[23px] italic sm:mt-4 max-sm:mt-2 sm:w-[80%]  max-sm:w-[100%] max-sm:text-[15px] max-sm:text-center">
                Rapidsofts is a software development company with two decades of
                experience creating custom solutions for every budget and niche.
                With us, digital commerce, product and platform development, and
                other custom software development services are within reach of
                almost every business. If you want to ensure your business'
                software is top of the line and cutting edge, you've come to the
                right place.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-5 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[30px]">
              <h1 className="sm:text-[30px] max-sm:text-[20px] max-sm:text-center font-black">
                Need Help with Software Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px] max-sm:text-[15px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-[150px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={netsolutions} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="sm:mt-[70px] max-sm:mt-[20px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[17px] max-sm:text-center">
                <b>
                  An Award-winning, Full-service Custom Software Development
                  Company
                </b>
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[15px] max-sm:text-center">
                Today's consumer expects to be able to reach the brands they
                love anytime and from any device, enjoying an interactive
                experience on their phones, tablets, and desktops.
              </p>
              <p className="sm:text-3xl sm:mt-5 max-sm:mt-2 max-sm:text-[15px] max-sm:text-center">
                Our software development team develops and deploys web apps,
                mobile apps, e-commerce applications, digital experience
                platforms, and complex Software as a Service (SaaS) platforms
                designed to meet the needs of our clients.
              </p>
              <div className="flex mt-5 max-sm:mb-3">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px]  hover:bg-[#F13623] hover:text-white sm:h-[50px] max-sm:text-[10px]"
                >
                  Explore Services
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4 max-sm:text-[10px]"
                >
                  Explore Technology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="">
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[30px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] max-sm:text-center tracking-widest sm:w-[600px]">
                Create Compelling Digital Experiences for Your Users
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                You know your audience and what they want. Our custom software
                development company can use your ideas and research to deliver
                what your audience desires, whether it's beautiful graphics, fun
                games or quizzes, or speedy and reliable shopping functions.
              </p>
              <p className="sm:text-[22px] font-light sm:mt-11 max-sm:mt-5 max-sm:text-center">
                The Rapidsofts team comprises a dynamic group of coders, server
                administrators, and digital experience designers with over 20
                years of experience in UI and UX technologies.
              </p>
              <p className="sm:text-[22px] font-light sm:mt-11 max-sm:mt-5 max-sm:text-center">
                The Rapidsofts team comprises a dynamic group of coders, server
                administrators, and digital experience designers with over 20
                years of experience in UI and UX technologies.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="sm-ml-[100px]"></img>
                <button
                  type="button"
                  className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment1} className="mt-[100px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[10px]">
                  An Expert Full-stack Development Team
                </h4>
                <p className="text-[22px] font-light sm:pt-4 max-sm:pt-2 max-sm:text-[15px] max-sm:text-center max-sm:mt-[10px]">
                  Many software solutions companies have one or two focuses.
                  Rapidsofts takes this to the next level, with a full-stack
                  development team to work with front-end and back-end
                  technologies.
                </p>
                <p className="sm:text-[22px] font-light mt-11 max-sm:text-[15px] max-sm:text-center">
                  Our custom software development services include coding and
                  design for:
                </p>
                <ul className="list-disc mt-4 ml-5">
                  <li className="sm:text-2xl mt-3 max-sm:text-[15px] max-sm:text-center">
                    Android apps
                  </li>
                  <li className="sm:text-2xl mt-3 max-sm:text-[15px] max-sm:text-center">
                    iOS apps
                  </li>
                  <li className="sm:text-2xl mt-3 max-sm:text-[15px] max-sm:text-center">
                    Browser-based apps
                  </li>
                  <li className="sm:text-2xl mt-3 max-sm:text-[15px] max-sm:text-center">
                    Cross-platform apps
                  </li>
                  <li className="sm:text-2xl mt-3 max-sm:text-[15px] max-sm:text-center">
                    Custom-coded programs
                  </li>
                </ul>
                <p className="mt-4 sm:text-2xl max-sm:text-[15px] max-sm:text-center">
                  Whatever your software needs, our team can build the solution
                  you're looking for.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center">
                Software Strategy and Consulting
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[14px]  max-sm:text-center">
                There's more to successful custom software development services
                than good code. That's why we work with business analysts and UX
                specialists to put together products that help your business
                achieve its goals. We develop solutions to make your business
                more efficient, increase its reach and engagement, and
                accelerate growth.
              </p>
              <p className="sm:text-[22px] font-light mt-11 max-sm:text-[14px]  max-sm:text-center">
                All our projects include quality assurance (QA) testing services
                to make sure everything works perfectly.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment2} className="sm:ml-[100px]"></img>
                <button
                  type="button"
                  className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex mt-[150px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment3} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] max-sm:text-center tracking-widest sm:w-[600px] max-sm:mt-[10px]">
                  Modern Custom Software Development Services
                </h4>
                <p className="sm:text-[22px] font-light sm:pt-4 max-sm:pt-2 max-sm:text-[15px]">
                  As a business grows, it often needs more complex software to
                  ensure all customers have a good experience. We have a proven
                  record of designing, developing, deploying, and maintaining
                  CRM, CMS and other products for businesses operating on an
                  international stage.
                </p>
                <p className="sm-text-[22px] font-light mt-11  max-sm:text-[15px]">
                  Whether you're looking for a custom-built app or a system to
                  integrate with your existing platform, our software
                  development company can help you.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          {/* <div className="flex mt-[150px]">
            <div className="w-[50%]">
            <div className="">
                <img src={softwaredevelopment4} className="mt-[100px]"></img>
              </div>
            </div>
            <div className="w-[50%]">
            <div className="">
            <h4 className="font-extrabold text-[52px] tracking-widest w-[600px]">
            An Expert Full-stack Development Team
              </h4>
              <p className="text-[22px] font-light pt-4">
              Many software solutions companies have one or two focuses. Rapidsofts takes this to the next level, with a full-stack development team to work with front-end and back-end technologies.
              </p>
              <p className="text-[22px] font-light mt-11">
              Our custom software development services include coding and design for:
              </p>
           <ul className="list-disc mt-4 ml-5">
            <li className="text-2xl mt-3">Android apps</li>
            <li className="text-2xl mt-3">iOS apps</li>
            <li className="text-2xl mt-3">Browser-based apps</li>
            <li className="text-2xl mt-3">Cross-platform apps</li>
            <li className="text-2xl mt-3">Custom-coded programs</li>
           </ul>
           <p className="mt-4 text-2xl">Whatever your software needs, our team can build the solution you're looking for.</p>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            </div>
          </div> */}
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center">
                Custom Product Development
              </h4>
              <p className="sm:text-[22px] max-sm:text-[15px] font-light pt-4 max-sm:text-[15px]">
                Delivering a smooth, user-focused experience is essential to
                custom software development. We help companies from startups to
                scale-ups bring their ideas to fruition by choosing the right
                technologies and using a rigorous, enterprise-level approach to
                engineer results-driven solutions with a strong ROI.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment4} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[15px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex mt-[150px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment5} className="mt-[100px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center max-sm:mt-3">
                  An Expert Full-stack Development Team
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  Many software solutions companies have one or two focuses.
                  Rapidsofts takes this to the next level, with a full-stack
                  development team to work with front-end and back-end
                  technologies.
                </p>
                <p className="sm:text-[22px] font-light mt-11 max-sm:text-[15px] max-sm:text-center">
                  Our custom software development services include coding and
                  design for:
                </p>
                <ul className="list-disc mt-4 ml-5">
                  <li className="sm:text-2xl mt-3 max-sm:text-[15px]">Android apps</li>
                  <li className="sm:text-2xl mt-3  max-sm:text-[15px]">iOS apps</li>
                  <li className="sm:text-2xl mt-3  max-sm:text-[15px]">Browser-based apps</li>
                  <li className="sm:text-2xl mt-3  max-sm:text-[15px]">Cross-platform apps</li>
                  <li className="sm:text-2xl mt-3  max-sm:text-[15px]">Custom-coded programs</li>
                </ul>
                <p className="mt-4 sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                  Whatever your software needs, our team can build the solution
                  you're looking for.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5 max-sm:text-[15px]"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[30px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Digital Experience Platforms
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                Some of the products we deliver are built on popular CRM and CMS
                platforms such as AEM, Drupal, WordPress, or Kentico
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                However, we offer custom software development and solutions from
                scratch if those platforms do not meet your needs. We use robust
                languages, libraries, and extensive user experience design
                knowledge to create a product that holds the user's attention
                and drives conversions.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment6} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[30px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment7} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] max-sm:text-center tracking-widest sm:w-[600px] max-sm:mt-[20px]">
                  Mobile App Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  Delivering an engaging, intuitive user experience to your
                  customers’ fingertips is a must in today’s mobile-first world.
                </p>
                <p className="sm:text-[22px] font-light sm:mt-11 max-sm:mt-5 max-sm:text-[15px] max-sm:text-center">
                  We’re the best at software development outsourcing, thanks to
                  our full suite of development services for iOS, Android, and
                  Cross-Platform apps. In addition, we specialize in innovative,
                  scalable mobile solutions to help you stand out in your
                  industry.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[15px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5 max-sm:text-[15px]"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center">
                Designing the Digital Experience
              </h4>
              <p className="sm:text-[22px]  font-light pt-4 max-sm:text-center">
                Usability is often overlooked in web development. An app or
                website can look good but still be hard to use.
              </p>
              <p className="smtext-[22px] font-light pt-4 max-sm:text-center">
                At Rapidsofts, our UX specialists understand how users think and
                can map out a positive user experience for your products. Our UX
                team builds designs that match your brand and provide a
                loyalty-building, conversion-driving experience with every
                click, swipe, tap, or scroll.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment8} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="max-sm:text-[15px] sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[30px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment9} className="mt-1"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center">
                  SaaS Development
                </h4>
                <p className="sm:text-[22px] max-sm:text-[15px] font-light pt-4 max-sm:text-center">
                  For a growing business, Software-as-a-Service (SaaS) can be an
                  effective way of driving revenue. However, to achieve true
                  scalability, it's not enough to simply outsource your
                  development and run a script in the cloud.
                </p>
                <p className="text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  Reliability, scalability, and security are significant
                  challenges that need to be managed by a team with a commitment
                  to ongoing growth and learning.
                </p>
                <p className="text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  Our team has more than ten years of experience in SaaS
                  development and cloud technology for startups and enterprises.
                  We're here to help you get results.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center">
                Security, Maintenance and Support
              </h4>
              <p className="sm-text-[22px] font-light pt-4 max-sm-text-[15px] max-sm:text-center">
                Our custom software development services don't end when we're
                finished coding and have launched your product. Once a product
                has been deployed, the next challenge is to keep it running well
                — this means audits, updates, patches, and day-to-day support
                and troubleshooting.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm-text-[15px] max-sm:text-center">
                These tasks are time-consuming, and using internal IT staff—no
                matter how amazing they are—can result in costs from lost time
                spent on other projects. Plus, if your staff members aren't
                trained in software solutions, your software's code could be
                damaged, and you'll need to bring in a third party to do an
                expensive cleanup.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-center">
                Instead, choose Rapidsofts as your partner for ongoing
                maintenance. Our engineers will always be on hand to ensure your
                app, website, or software performs at its best.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment10} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment11} className=""></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest sm:w-[600px] max-sm:text-center max-sm:mt-[20px]">
                  Modernizing Your Platform
                </h4>
                <p className="sm:text-[22px] max-sm:text-[15px] font-light pt-4 max-sm:text-center">
                  If your brand's app or website is starting to show its age,
                  investing in a modern solution could help your brand keep (or
                  regain) its competitive edge. Our developers have expertise in
                  transforming legacy systems, making the process of upgrading
                  more straightforward and allowing you to see results a little
                  bit sooner.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:mt-[400px] max-sm:mt-[100px]">
            <p className="text-6xl text-bold max-sm:text-[20px] max-sm:text-center">
              <b>Solutions for Your Industry</b>
            </p>
            <p className="mt-4 sm:text-2xl max-sm:text-[15px]">
              Our team has worked with many industries, including:
            </p>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="sm:w-[33.3%] max-sm:w-[100%] sm:mr-[100px]">
              <div className="card w-full  sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment12}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Automotive</b>
                  </p>
                  <p className="mt-4">
                    We can create apps that help automotive companies collect
                    data, drive conversions, and develop customer loyalty.
                  </p>
                  <p className="mt-4">
                    If you hope to reach your customers online and make sales
                    via an automotive e-commerce platform, we have solutions for
                    you.
                  </p>
                  <p className="mt-4">
                    We have built fleet tracking solutions, dealer and service
                    center locators, and IoT automotive apps to keep the vehicle
                    industry trucking along.
                  </p>
                  <button
                    type="button"
                    className="mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5 w-[100%] m-auto"
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:w-[33.3%] max-sm:w-[100%] sm:mr-[100px] max-sm:mt-[50px]">
              <div className="card w-full  sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment13}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Education</b>
                  </p>
                  <p className="mt-4">
                    As online learning grows in popularity, customized edTech
                    and eLearning software solutions are becoming more and more
                    necessary. Our software design company has built learning
                    management systems (LMS), synchronous and asynchronous
                    online classrooms, mobile learning apps, and other remote
                    learning solutions to ensure students and teachers can
                    continue to reach their full potential.
                  </p>
                  <p className="mt-4">
                    Plus, Rapidsofts knows how stressful things are, and so we
                    strive to make our educational custom software development
                    services as intuitive as possible.
                  </p>
                  <button
                    type="button"
                    className="mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5 w-[100%] m-auto"
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:w-[33.3%] max-sm:w-[100%] max-sm:mt-[50px]">
              <div className="card w-full sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment14}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Media, Publishing, and Entertainment</b>
                  </p>
                  <p className="mt-4">
                    We have developed software solutions for streamlining the
                    publishing cycle, reliable streaming and OTT programs,
                    training entertainment industry leaders, and more. Our
                    ultimate goal in our custom software development services
                    for the entertainment industry is to make our clients' days
                    smoother and their customers' experiences positive.
                  </p>
                  <button
                    type="button"
                    className="mt-[110px] mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px]  w-[100%]"
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:flex mt-[70px]">
            <div className="sm:w-[33.3%] max-sm:w-[100%] sm:mr-[100px]">
              <div className="card w-full  sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment16}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Healthcare</b>
                  </p>
                  <p className="mt-4">
                    Healthcare is increasingly digitized to make processes more
                    manageable for practitioners and patients. We've created
                    software allowing patients to access their medical records
                    from home, practitioners to keep fast and accurate records,
                    and conduct telemedicine visits, among other solutions.
                  </p>
                  <button
                    type="button"
                    className="mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[175px] w-[100%]"
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:w-[33.3%] max-sm:w-[100%] sm:mr-[100px] max-sm:mt-[50px]">
              <div className="card w-full  sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment17}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Sports and Fitness</b>
                  </p>
                  <p className="mt-4">
                    Rapidsofts has made sports and fitness software that works
                    with devices and accessories like watches. Our software has
                    included gamification and geolocation options, fitness
                    streaming services, fantasy sports apps, and more.
                  </p>
                  <button
                    type="button"
                    className="mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px]  w-[100%] mt-[197px]"
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:w-[33.3%] max-sm:w-[100%] max-sm:mt-[50px]">
              <div className="card w-full sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment18}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Retail</b>
                  </p>
                  <p className="mt-4">
                    Our software design company experts have created marketplace
                    and eCommerce solutions for B2B, B2C, and DTC retailers.
                    Beyond purchasing and inventory programs, we have created
                    marketing solutions—including those using geofencing—and
                    analytics and optimization services.
                  </p>
                  <button
                    type="button"
                    className="mt-[170px] mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px]  w-[100%]"
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:flex mt-[70px]">
            <div className="sm:w-[33.3%] max-sm:w-[100%] sm:mr-[100px]">
              <div className="card w-full  sm:h-[580px]">
                <div className="ml-3 mr-3">
                  <img
                    src={softwaredevelopment19}
                    className="w-[100px] mt-4"
                  ></img>
                  <p className="mt-3 text-2xl">
                    <b>Food and Beverage</b>
                  </p>
                  <p className="mt-4">
                    Whether your food and beverage company works in the B2B or
                    B2C world, our company creates software development
                    solutions to meet your needs. We've made everything from
                    food ordering apps to food distribution software to
                    inventory tracking and more.
                  </p>
                  <button
                    type="button"
                    className="mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[220px] w-[100%] "
                  >
                    Request to Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <p className="sm:text-7xl w-[90%] sm:m-auto max-sm:text-[20px] max-sm:text-center">
              <b>FAQs About Rapidsofts for Software Development</b>
            </p>
            <div className=" mt-[50px]">
              <div className="sm:w-[80%] m-auto">
                <Accordion className="mb-5 max-sm:mt-[-100px]">
                  <div>
                    <Accordion.Item eventKey="0" className="">
                      <Accordion.Header>
                        <h5 className="sm:text-[26px] max-sm:text-[15px]">
                          What are the benefits of partnering with a mobile app
                          consultant?
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="text-[20px] font-light text-3xl">
                          A mobile app development consultant like Rapidsofts
                          brings much more to the table beyond technical
                          ability. Here’s a snapshot of the top benefits:
                        </div>
                        <ul className="list-disc ml-4">
                          <li className="mt-3 text-2xl">
                            All you need to make your mobile app dreams a
                            reality is available under one roof.
                          </li>
                          <li className="mt-3 text-2xl">
                            A full suite of mobile application development
                            consulting services makes the process faster, more
                            seamless, and more cost-effective.
                          </li>
                          <li className="mt-3 text-2xl">
                            An accelerated time to market sharpens your
                            competitive edge.
                          </li>
                          <li className="mt-3 text-2xl">
                            A dedicated external and virtual app development
                            team frees your internal team to prioritize other
                            revenue-building activities.
                          </li>
                          <li className="mt-3 text-2xl">
                            The difference in time zones means we work on your
                            mobile app while you sleep!
                          </li>
                          <li className="mt-3 text-2xl">
                            Digital transformation and mobile accessibility are
                            no longer nice-to-have – they’re a must for today’s
                            on-the-go world. A mobile app development partner
                            invested in your success makes the process smoother,
                            safer, and easier.
                          </li>
                        </ul>
                        <div></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <Accordion.Item eventKey="1" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[15px]">
                        How do consultants audit a mobile app?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        A mobile app audit is the first step to ensuring you’re
                        providing an optimal user experience. Generally
                        speaking, here’s the process:
                      </div>
                      <ul className="list-disc ml-5">
                        <li className="mt-3 text-2xl">
                          <b>Step 1:</b> The app development consultant
                          discusses the goals for the audit with you. This is
                          also the perfect time to review business objectives
                          and ensure the audit’s focus is perfectly aligned with
                          your needs.
                        </li>
                        <li className="mt-3 text-2xl">
                          <b>Step 2:</b> You and the consultant go through a
                          “guided app demo” — a walkthrough of the app’s user
                          roles, functionality, and workflow. This helps the
                          auditing team understand the app's purpose and
                          features, so they can effectively audit its functional
                          requirements.
                        </li>
                        <li className="mt-3 text-2xl">
                          <b>Step 3:</b> The development team conducts a
                          functional and technical documentation review to
                          better understand the architecture, components,
                          third-party libraries, etc., used to develop the app.
                          Ideally, you (the client) can provide this
                          documentation, so the development team can quickly
                          ramp up for the audit.
                        </li>
                        <li className="mt-3 text-2xl">
                          <b>Step 4:</b> The prescribed audits are conducted by
                          the mobile app development company (i.e., UX/Ui, code,
                          security, compatibility, etc.)
                        </li>
                        <li className="mt-3 text-2xl">
                          <b>Step 5:</b> The findings are compiled, and an audit
                          report is created and provided to the client.
                        </li>
                        <li className="mt-3 text-2xl">
                          <b>Step 6:</b> The recommendations are implemented.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[15px]">
                        At what point in the product design process should a
                        business invest in app development consulting?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        App development consulting is, as the question
                        indicates, an investment, but it’s not one you should
                        put off until the end. In fact, an experienced mobile
                        app development consultant can save you money, time, and
                        effort by being your first investment. After all, a
                        strong mobile app consultant can help you:
                      </p>
                      <ul className="list-disc ml-5">
                        <li className="mt-3 text-2xl">
                          Thoroughly research your marketplace and competition
                          to find your unique position and potential competitive
                          advantage.
                        </li>
                        <li className="mt-3 text-2xl">
                          Define the problem your app solves and who (audience)
                          needs your mobile app the most.
                        </li>
                        <li className="mt-3 text-2xl">
                          Decide what kind of mobile app(s) you’re going to
                          develop: native (iOS, Android), hybrid, or
                          cross-platform.
                        </li>
                        <li className="mt-3 text-2xl">
                          Identify monetization opportunities.
                        </li>
                        <li className="mt-3 text-2xl">
                          Create a secure, compliant solution.
                        </li>
                      </ul>
                      <p className="mt-4 text-2xl">
                        The sooner you consult a seasoned app consultant, the
                        more efficient and effective your mobile app development
                        process can be!
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="">
              <h5 className="sm:text-[34px] max-sm:text-[20px] font-extrabold text-center sm:pt-20 ">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Hiring} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">PRODUCT DEVELOPMENT</p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12">
                      Monolithic vs Microservices Architecture: Which Option is
                      Right for Your Enterprise?
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={GoogleCloudSQL} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12">
                      What is the Difference Between AWS, Azure, and Google
                      Cloud?
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Digital_tra2} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, REMOTE
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12">
                      7 Steps for Building an Effective Nearshore Software
                      Development Team
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomSoftware;
