import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  blockchain_banner,
  Blockchain_Solutions1,
  Blockchain_sol2,
  Blockchain_sol3,
  Blockchain_sol4,
  Blockchain_sol5,
  Blockchain_sol6,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  ChatGPT,
  Digital_tra1,
  Hiring,
  arvrbanner,
} from "../../assets";
import { Link } from "react-router-dom";
const Augment = () => {
  return (
    <>
      <div className="container">
        <div className="flex mt-4">
          <div className="w-[50%]">
            <div className="mt-[160px]">
              <p className="text-3xl">
                <b>AR and VR</b>
              </p>
              <p className="mt-2 mb-2">
              Bringing advancements in the fray to help you gain the necessary momentum. Experience the marvel of VR and AR with RevInfotech
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <img src={arvrbanner}></img>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <p className="text-4xl">
              <b>Presenting the Best AR and VR Package for You</b>
            </p>
            <p className="mt-4">
            Endearing the harsh market conditions is a difficult task in itself, but running a non-profit enterprise makes it all the more demanding. Keeping up with the competition, struggling to keep the enterprise going, and maintaining the latest market standards are routine hurdles faced by NPOs. RevInfotech takes pride in its capabilities of helping out NPOs in every tech and digital ordeal. Our services are catered to encourage non-profit organizations to enhance their proceedings and get ahead on the market. Our expertise and years of experience make it easier for us to find out the loopholes in the market algorithms. You can avail of it all at affordable rates as well. Just reach our support and get it all done.
            </p>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AI & Machine Learning</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                  Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                  Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                  See More Digital Transformation…
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-5">
        <div className="text-center text-white p-5">
          <p className="text-3xl">
          Top-Notch Tech Solutions
          </p>
        </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
        Get AR & VR Services
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              AUGMENTED REALITY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Get your business equipped with the prowess of augmented reality and enhance your proceedings multi-folds with RevInfotech.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              VIRTUAL REALITY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Want to know why VR is the talk of the digital town? Experience it first hand and avail of its premium benefits with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              INTERNET OF THINGS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Witness the true power of IoT with RevInfotech and help your business on the road to advancement easily and safely.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
        Best-in-Class Benefits
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              INTUITIVE INDUSTRY SOLUTIONS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              As your organization grows, the competition in the market grows rapidly as well. This puts a burden on your venture to keep up with sudden shifts. With our tech solutions, you can reduce the burden and optimize the available resources easily.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              BETTER INTERACTION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Not fully understanding your business functions can restrict your productivity. Let us research your project and improve the interaction. With RevInfotech, you can get a wide knowledge of your business insights.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              IMPROVED UI AND UX
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                User interface and user experience are central to your organization's success. No business wishes to face an unhappy client as it ruins their reputation. Let our tech solutions improve your UI and UX to their full potential.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              ENHANCED RESULTS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Our tech solutions can make calculated predictions for your business. With our algorithms, you can infer results and undergo desired transformations. Keep up with the market and stabilize you growth with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              FACILITATE AUTOMATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              You can only go so far without automation and the latest tech. Let us introduce the magic of automation into your system. We can integrate seamless automation into your procedures to perform boring tasks easily and safely.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
            How AR and VR Solutions can Help You Grow?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
            Keeping up with the latest technological advancements is the need of the modern market, and no business can survive without its integration. Augmented reality and virtual reality have emerged as some of the most viable tech solutions for your business to make the necessary cut. With the integration of AR and VR, your business can fulfill the modern market demands of consumers. Increase your customer satisfaction rate with us and build brand prestige.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
            Why Choose RevInfotech for AR and VR Solutions?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
            Just knowing that your business needs to integrate the latest tech can severely hinder your productivity. You need to make the most of the technologies and RevInfotech can help you achieve the same thing with ease. We house years of knowledge and experience, allowing us to devise the best strategies for your business. Our experts have gained proficiency in multiple industries and tech is certainly amidst them. We offer quality AR and VR integration services at the best possible prices. Reach our experts and get started easily.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5">
            <img src={Hiring} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
            March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
              Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5">
            <img src={Digital_tra1} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 ">
            <img src={Digital_tra2} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Augment;
