import React from 'react'
import {
  Dueloworkmobile,
  gwaworkmobile,
  zsafety,
  VoyLegal,
  Bondsworkmobile,
  avanceworkmobile,
  IMGcsworkmobile,
  Podeumworkmobile,
  largeengineeringwormobile,
  beaknwork,
  fourfourwork,
  appeeworkmobile,
  emrworkmobile,
  webcastworkmobile,
  busylittlekidsworkmobile,
  scoffworkmobile,
  ocrworkmobile,
  yPreemobile,
  VIPCARSmobile,
  Nualightdesktop,
  fitnes,
  lauren,
  ncfit,
  ready,
  comptrain,
  rinnai,
  arete,
} from "../assets";
const SuccessStories = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="flex">
          <div className="sm:mt-[100px] max-sm:mt-[70px] max-sm:w-[100%]">
            <p className="sm:text-6xl max-sm:text-2xl max-sm:text-center">
              <b>Success Stories</b>
            </p>
          </div>
        </div>
        <div className="sm:flex flex-wrap">
          <div className="sm:w-[32%] max-sm:w-[100%] drop-shadow-lg">
            <div className="sm:mt-5 max-sm:mt-3">
              <img src={fitnes} className=" object-cover w-[500px] h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid sm:h-[250px] max-sm:h-[280px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>Grown Strong App</b>
                  </p>
                  <p className="text-sm">wealth of healthy
                    recipes, meal plans, and nutritional</p>
                  <p className="text-lg mt-4">
                    <b>Fueling your body with the right nutrition is essential
                      for overall well-being</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[32%] max-sm:w-[100%] sm:ml-5 mr-5 drop-shadow-lg">
            <div className="mt-5">
              <img src={lauren} className=" w-[500px] h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>Lauren App</b>
                  </p>
                  <p className="text-sm">Fitness & Training</p>
                  <p className="text-lg mt-4">
                    <b>Lauren App provides expert to
                      helping you make informed choices to
                      support your fitness goals</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[23px]"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[32%] max-sm:w-[100%] drop-shadow-lg">
            <div className="mt-5">
              <img src={ncfit} className="w-[500px]  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>NCFIT Workout App</b>
                  </p>
                  <p className="text-sm">Fitness & Training</p>
                  <p className="text-lg mt-4">
                    <b> Our App provide you
                      guidance and motivation to reach your goals and
                      unlock your full potential.</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[23px]"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[70%] max-sm:w-[100%] drop-shadow-lg">
            <div className="mt-5">
              <img src={arete} className="w-100 h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>Arate</b>
                  </p>
                  <p className="text-sm">AUTOMOTIVE</p>
                  <p className="text-lg mt-4">
                    <b>
                      Multi-channel eCommerce Solution for One of IRUK'S Top 500
                      Retailers
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[53px] mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[28%] max-sm:w-[100%] sm:ml-5 drop-shadow-lg">
            <div className="mt-5 float-right">
              <img src={ready} className="w-100 h-[555px]"></img>
              <div className="bg-[#EAEBEC] grid sm:h-[243px] max-sm:h-[280px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>THE READY STATE</b>
                  </p>
                  <p className="text-sm">OPTIMIZING YOUR MOVEMENT</p>
                  <p className="text-lg mt-4">
                    <b>
                      This app is designed to help you achieve a
                      state of readiness and physical condition.
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[25px]"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[32%] max-sm:w-[100%]">
            <div className="mt-5">
              <img src={comptrain} className="w-[500px] h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid sm:h-[250px] max-sm:h-[280px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>COMPTRAIN</b>
                  </p>
                  <p className="text-sm">FITNESS ENTHUSIAST</p>
                  <p className="text-lg mt-4">
                    <b>
                      Comptrain is
                      designed to empower you, motivate you, and help you
                      achieve your fitness goals like never before.
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[32%] max-sm:w-[100%] sm:ml-5 mr-5">
            <div className="mt-5">
              <img
                src={rinnai}
                className=" w-[500px] h-[550px]"
              ></img>
              <div className="bg-[#EAEBEC] grid sm:h-[250px] max-sm:h-[280px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>RINNAI</b>
                  </p>
                  <p className="text-sm"> COONECT RINNAI WATER HEATER</p>
                  <p className="text-lg mt-4">
                    <b>
                    This innovative app puts
                      the power in your hands, allowing you to effortlessly
                      manage and customize your water heating preferences
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[23px]"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[32%] max-sm:w-[100%]">
            <div className="mt-5">
              <img src={Dueloworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>DUELO, INC.</b>
                  </p>
                  <p className="text-sm">SPORTS & FITNESS</p>
                  <p className="text-lg mt-4">
                    <b>
                      A Pioneering Online Sports Betting Platform for a Dynamic
                      US Market
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[53px] mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-[30%]">
            <div className="mt-5">
              <img src={gwaworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>GWA</b>
                  </p>
                  <p className="text-sm">RETAIL & ECOMMERCE</p>
                  <p className="text-lg mt-4">
                    <b>
                      Digital Customer Experience Across Multiple Properties
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="w-[68%] ml-5">
            <div className="mt-5">
              <img
                src={zsafety}
                className="w-100  h-[550px]"
              ></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>DRIVE FOCUS</b>
                  </p>
                  <p className="text-sm">HEALTHCARE</p>
                  <p className="text-lg mt-4">
                    <b>
                      Building a Video Driving Simulator for People with
                      Cognitive Disabilities
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-12 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[68%]">
            <div className="mt-5">
              <img src={VoyLegal} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>VOYLEGAL</b>
                  </p>
                  <p className="text-sm">LEGAL</p>
                  <p className="text-lg mt-4">
                    <b>
                      Custom Software to Power Legal Recruitment Startup with
                      CRM & Market Research Capabilities
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-12 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[30%] ml-5">
            <div className="mt-5">
              <img src={Bondsworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>BONDS OF LONDON</b>
                  </p>
                  <p className="text-sm">RETAIL & ECOMMERCE</p>
                  <p className="text-lg mt-4">
                    <b>
                      A Digital B2B Sales Platform to Work in Tandem with
                      Traditional Sales Processes
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img src={avanceworkmobile} className="w-100 h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>AVANCE</b>
                  </p>
                  <p className="text-sm">EDUCATION & TRAINING</p>
                  <p className="text-lg mt-4">
                    <b>
                      Spanish-language App for Employees to Improve Their
                      Soft-skills
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%] ml-5 mr-5">
            <div className="mt-5">
              <img src={IMGcsworkmobile} className="w-100 h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>WME | IMG</b>
                  </p>
                  <p className="text-sm">SPORTS, ENTERTAINMENT</p>
                  <p className="text-lg mt-4">
                    <b>
                      Product Licensing Approval Workflow Solution for a Brand
                      Licensing Industry Leader
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img src={Podeumworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>STEVEN HERSON</b>
                  </p>
                  <p className="text-sm">EDUCATION & TRAINING</p>
                  <p className="text-lg mt-4">
                    <b>
                      An Innovative, Resource Curation Based Educational
                      Platform
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img
                src={largeengineeringwormobile}
                className="w-100  h-[550px]"
              ></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>ON-DEMAND SERVICES STARTUP</b>
                  </p>
                  <p className="text-sm">RETAIL & ECOMMERCE</p>
                  <p className="text-lg mt-4">
                    <b>
                      A Digital Experience Strategy for Building More Effective
                      Mobile Apps for Their Global Salesforce
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[66%] ml-5">
            <div className="mt-5">
              <img src={beaknwork} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>BEAKN</b>
                  </p>
                  <p className="text-sm">RETAIL & ECOMMERCE</p>
                  <p className="text-lg mt-4">
                    <b>IoT-based Customer Loyalty Program</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-12 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[66%]">
            <div className="mt-5">
              <img src={fourfourwork} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>HAYMARKET CONSUMER MEDIA</b>
                  </p>
                  <p className="text-sm">MEDIA, PUBLISHING & ENTERTAINMENT</p>
                  <p className="text-lg mt-4">
                    <b>The World's Most Innovative Football Stats App</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-12 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%] ml-5">
            <div className="mt-5">
              <img src={appeeworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>APPEE</b>
                  </p>
                  <p className="text-sm">DIGITAL SOLUTIONS</p>
                  <p className="text-lg mt-4">
                    <b>
                      Analytics Driven Survey Platform for Real-time Customer
                      Engagement
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%] ml-5 mr-5">
            <div className="mt-5">
              <img src={emrworkmobile} className="w-100 h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>EMR</b>
                  </p>
                  <p className="text-sm">HEALTHCARE</p>
                  <p className="text-lg mt-4">
                    <b>
                      A Bespoke Decision Support Engine to Enable the Healthcare
                      Staff to Modify Test Rules
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[45px] mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img src={webcastworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>WEBCASTMYEVENT.CO.UK</b>
                  </p>
                  <p className="text-sm">MEDIA, PUBLISHING & ENTERTAINMENT</p>
                  <p className="text-lg mt-4">
                    <b>Live Event Broadcasting Video Platform</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[72px] mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img
                src={busylittlekidsworkmobile}
                className="w-100  h-[550px]"
              ></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>BUSY LITTLE KIDS</b>
                  </p>
                  <p className="text-sm">EDUCATION & TRAINING</p>
                  <p className="text-lg mt-4">
                    <b>
                      An iPhone App for Working Mothers to Keep Their Kids
                      Engaged
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[45px] mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img src={ocrworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>OCR</b>
                  </p>
                  <p className="text-sm">HEALTHCARE</p>
                  <p className="text-lg mt-4">
                    <b>Mobile Social Platform for Healthcare Professionals</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[66%] ml-5">
            <div className="mt-5">
              <img src={scoffworkmobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>SCOFF</b>
                  </p>
                  <p className="text-sm">FOOD & BEVERAGE</p>
                  <p className="text-lg mt-4">
                    <b>
                      Double-sided Marketplace that Enables Users to Buy and
                      Sell Food Online
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img src={yPreemobile} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>YPREE</b>
                  </p>
                  <p className="text-sm">MEDIA, PUBLISHING & ENTERTAINMENT</p>
                  <p className="text-lg mt-4">
                    <b>Location-based Dating App for Startup</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-14 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%] ml-5 mr-5">
            <div className="mt-5">
              <img
                src={VIPCARSmobile}
                className="w-100  h-[550px]"
              ></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>VIP CARS</b>
                  </p>
                  <p className="text-sm">SOFTWARE</p>
                  <p className="text-lg mt-4">
                    <b>Mobile App for Rental Car Broker</b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-14 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[32%]">
            <div className="mt-5">
              <img src={Nualightdesktop} className="w-100  h-[550px]"></img>
              <div className="bg-[#EAEBEC] grid h-[250px]">
                <div className="ml-5 mr-5">
                  <p className="mt-3">
                    <b>NUALIGHT</b>
                  </p>
                  <p className="text-sm">RETAIL & ECOMMERCE</p>
                  <p className="text-lg mt-4">
                    <b>
                      Content Management System for Global LED Lighting Retailer
                    </b>
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                  >
                    Read Case Study
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default SuccessStories