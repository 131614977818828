// import React from 'react'
import {
  crossplatformappdevelopment,
  BestOnlineFoodDeliveryApps,
  nativevs,
  shouldilaunch,
  edplacecasesmallpic,
  pageantbigcasestudpic,
  voylegalcasesmallpic,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const CrossAppWebDevelopement = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                CROSS PLATFORM APPLICATION DEVELOPMENT SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                We Build Innovative, Versatile Cross-platform Apps for Startups
                & Enterprises
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Accelerate your time to market, optimize your budget & transform
                your business.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking to Build Cross-platform Apps?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Expert, Full-service Cross-platform App Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Savvy cross-channel mobile strategy is built on numerous
                factors, including speed to market, compatible technology, easy
                updates, and overall cost-effectiveness — and only
                cross-platform app development encompasses it all. As a top-tier
                cross platform mobile app development agency, our multi-platform
                mobile technology experts engineer high-performing
                cross-platform apps that deliver excellent engagement and
                conversions across platforms, devices, industries, and
                audiences.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[200px] max-sm:mt-[50px]">
          <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
            <b>WHAT WE DO FOR YOU</b>
          </p>
        </div>
        <div className="mt-[30px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>
              Cross Platform App Dev to Reduce Time to Market and Development
              Costs
            </b>
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            We provide cross platform mobile app development services that let
            you create apps that work across multiple operating systems quickly
            and efficiently. Ensure you're reaching the highest number of mobile
            users with your product while also supporting lower dev budgets and
            increased bottom lines.
          </p>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <img src={crossplatformappdevelopment} className="mx-auto"></img>
          </div>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
              <b>Cross Platform App Dev for All Organizations</b>
            </p>
            <p className="sm:text-2xl mt-5 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
              Whether you're a start-up, SMB, enterprise organization or public
              or nonprofit agency, our cross platform mobile app development
              agency has the resources and expertise to provide cross platform
              app development that works for you. We provide cross platform
              mobile development services to organizations in a wide array of
              industries, including:
            </p>
          </div>
          <div className="sm:flex">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <ul className="list-disc sm:ml-[100px] mt-5">
                <li className="sm:text-3xl max-sm:text-[20px]">MVP app development for startups</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">
                  Physical and online retail and commerce
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">
                  Food and beverage service and manufacturing
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">SaaS development</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px] ">
                  Healthcare and other regulated industries
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">Automotive</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">Entertainment</li>
              </ul>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <ul className="list-disc sm:ml-[100px] mt-5 max-sm:text-[20px]">
                <li className="sm:text-3xl">Publishing and media</li>
                <li className="sm:text-3xl mt-4">Education </li>
                <li className="sm:text-3xl mt-4">Redesign</li>
                <li className="sm:text-3xl mt-4">HR and training</li>
                <li className="sm:text-3xl mt-4">Sports, fitness and wellness</li>
              </ul>
            </div>
          </div>
          <div className="mt-[50px]">
            <p className="sm:text-5xl ml-5 max-sm:text-[20px] max-sm:text-center">
              <b>Common FAQs About Cross Platform App Dev with Rapidsofts</b>
            </p>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        What is cross platform app development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        Cross platform app development refers to the process of
                        developing an app that will work across multiple
                        platforms and operating systems. Most commonly, this may
                        be developing an app that can be packaged for both
                        Android and Apple devices. However, it can be developed
                        as an app for any cross-channel uses.
                      </div>
                      <div>
                        Cross platform app dev is in contrast to native app dev,
                        which occurs when you're creating an app specifically
                        for Android, iOS or another operating system. When
                        developing native apps, programmers use specific codes.
                        Android calls for using Kotlin, Java or C++ and iOS
                        calls for using Swift or Objective-C. Products developed
                        in these various languages aren't ready to deploy on the
                        other systems.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      Why develop cross platform mobile apps?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      Developing cross platform mobile apps allows you to
                      provide your app to the highest number of users and not
                      alienate any section of your user base or target audience.
                      Mobile device shares by operating system as of 2021 broke
                      down roughly as:
                    </div>
                    <div className="text-[20px] font-light text-3xl">
                      As you can see, by developing a mobile app that works on
                      both Android and iOS, you can essentially cover all mobile
                      device users. If your app doesn't work on iOS, you're
                      leaving out a quarter or more of potential users,
                      depending on the preferences of your target audience. And
                      ignoring Android leads to an even larger missed
                      opportunity for most companies
                    </div>
                    <div className="text-[20px] font-light text-3xl">
                      You could obviously develop an iOS app and then develop an
                      Android app — or vice versa. It's also an option to
                      develop both apps separately but in tandem. But all of
                      these choices mean more work, expense and time. Turning to
                      cross platform app development processes instead of
                      individual app dev options may help save you time and
                      money, getting apps to market faster and within a more
                      feasible budget for your organization.
                    </div>
                    <div className="text-[20px] font-light text-3xl">
                      However, cross platform app development has a few
                      challenges. It requires more complicated code because
                      you're combining components native to each platform as
                      well as non-native components that can be made to work on
                      each. These types of apps also don't support all
                      native-only features, including certain 3D effects or
                      animations, limiting some design and customer experience
                      elements to what works across all platforms involved.
                      Update challenges can also occur because a cross platform
                      app has to be separately updated when Apple or Google
                      makes updates to platforms — native iOS and Android apps
                      tend to be updated automatically.
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How much does cross platform app development cost?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Cross platform app development costs depend on a variety
                      of factors, including the project’s complexity, types of
                      integrations you need, how many platforms you want to
                      develop for, and what type of dev team you're working
                      with. Pricing will vary depending on the cross platform
                      mobile app development agency you choose.
                    </p>
                    <p className="text-[20px] font-light">
                      In some cases, cross platform app dev costs are lower than
                      other forms of app dev, and certainly it's usually less
                      expensive to develop an app that works on multiple
                      platforms all at once instead of developing apps for each
                      platform individually. Cross platform app dev is based on
                      a single code that can be reused, which is faster and
                      takes less effort for quality testing.
                    </p>
                    <p className="text-[20px] font-light">
                      Starting prices for cross platform app dev projects can
                      range on average from $15,000 to $25,000 or more, though
                      some apps may cost much more and the simplest apps may
                      come in under that range. On the high end for a complex
                      flagship app, you might be looking at $500,000 or more.
                    </p>
                    <p className="text-[20px] font-light">
                      The only way to understand the costs of your specific
                      cross platform app project is to get an estimate. Reach
                      out to Rapidsofts today and provide us with a little
                      information about what you want to accomplish with your
                      app. Our team can help you understand the options you have
                      and the potential costs of each.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      What is the cross platform app development process?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      The cross platform app development process works like most
                      app dev processes:
                    </p>
                    <p className="text-[20px] font-light">
                      As smartphones and tablets continue to grow in popularity,
                      more software development comes with a mobile component to
                      ensure users can access their favorite websites and
                      programs on their favorite devices.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How long does cross platform app development take?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Cross platform app development is often faster than native
                      app development — and is certainly faster than developing
                      individual apps for each platform you want to appear on.
                      However, the exact timeline of your project depends on
                      factors such as your app’s complexity and how many
                      platforms you want to deploy on.
                    </p>
                    <p className="text-[20px] font-light">
                      An average time to market for apps is between four and six
                      months. The simplest apps may be completed in weeks rather
                      than months, and the most complex apps might take more
                      time to develop. To understand potential timelines related
                      to your specific app development project, reach out to Net
                      Solutions today. Our teams can work with you to provide
                      various options and help you understand the length of each
                      so you can make an educated decision about your app dev
                      project.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How do you choose a cross platform app development
                      company?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Choosing the right cross platform app dev company is
                      important to ensuring your project’s success. Look for a
                      company with experience in cross app dev as well as
                      experience working with companies in your industry and of
                      your type and size. The needs of a small business or
                      startup are often different from those of an enterprise or
                      nonprofit, for example, and common challenges in creating
                      apps for retail organizations differ from those related to
                      creating apps for healthcare companies.
                    </p>
                    <p className="text-[20px] font-light">
                      Ask questions about the dev firm's cross app development
                      experience, including what platforms they work with, what
                      problems they've been able to solve, and whether they
                      offer full-scope services to move your project from
                      strategy all the way to implementation.
                    </p>
                    <p className="text-[20px] font-light">
                      You may also want to ask about post-implementation
                      services and support. What options does the partner offer
                      for modifications once an app is launched and will they
                      help you get the app into app stores?
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                    How do you keep my cross platform app data secure?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                    Most app platforms have built-in security, and that's definitely true for both iOS and Android. For example, Android apps are held on their own virtual machines, which means code and functions from each app in the app store never interact. That helps increase the security for each.
                    </p>
                    <p className="text-[20px] font-light">
                    At Rapidsofts, we work to provide even more security, ensuring your app data is secure from the start of your project through implementation and beyond. That includes using DevSecOps procedures to build security into the app development frameworks we use.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>
                  Success Stories: Our Track Record of IT Consulting Success
                  Across Several Industries and Business Verticals
                </b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={edplacecasesmallpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Future-proof Your Business with Innovations that Win
                      Fans — and Awards
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how developed a multiple award-winning, data-rich,
                    real-time Android app that took an existing iOS football
                    stats app to the next level for a leading sports and
                    entertainment company.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      frontrushfrontrush We Help Ambitious Businesses Design &
                      Execute Their Vision
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we helped a scrappy sports start-up bring its
                    unique concept for athlete recruiting software to life with
                    a strategy that delighted end-users and exceeded the
                    company’s wildest dreams.
                  </p>
                  <img
                    src={voylegalcasesmallpic}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Up-level Your Customer Experience by Re-engineering
                      Existing Products
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how we engineered a mobile app for a professional
                    coaching and training company from their flagship web-based
                    product for more data efficiency, versatility, and
                    scalability.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={shouldilaunch} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={nativevs} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={BestOnlineFoodDeliveryApps}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CrossAppWebDevelopement;
