import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  Groupmin,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  ChatGPT,
  Digital_tra1,
  Hiring,
  rubybanner,
  OpenSeaFullLogodark,
  image232,
  image2324,
} from "../../assets";
import { Link } from "react-router-dom";
const MetaverseDevelopment = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${rubybanner})` }}
        className="h-[18rem] flow-root"
      >
        <div className="text-center mt-[80px]">
          <p className="text-white text-4xl">
            A company that develops NFT marketplaces
          </p>
          <p className="text-white  mt-2">
            Platforms for profitable, attractive, and scalable marketplaces
          </p>
          <button
            type="submit"
            class="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Know More
          </button>
        </div>
      </div>
      <div className="container">
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <div className="">
              <p className="text-3xl">
                <b>The best company for developing NFT marketplaces</b>
              </p>
              <p className="mt-2 mb-2 mr-5">
                In addition to holding unparalleled experience in building
                scalable trading platforms, Revinfotech Solutions is one of the
                most reputed and the best NFT marketplace development companies
                in the world. We offer a range of NFT solutions, from token
                creation to ecosystems. Please get in touch with us.
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                TALK TO OUR EXPERTS
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AR and VR</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                    Enterprise Mobility
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
        <div className="flex mt-[70px]">
          <div className="w-[50%]">
          <img src={Groupmin} className="m-auto "></img>
          </div>
          <div className="w-[50%]">
            <div className="">
              <p className="text-3xl"><b>Are you ready to create opportunities with NFT Marketplace Development?</b></p>
              <p className="mt-3">NFT marketplace can be built by enterprises or creators dealing with digital content, especially those that deal directly with digital content. Millions of artists can put their own content on display, which is a welcome change for the community otherwise struggling to make a living.</p>
              <p className="text-2xl mt-3"><b>Using our NFT marketplace development services will benefit your business in the following ways:</b></p>
              <p>Attract millions of NFT holders to the same platform
DeFi investment plans can be implemented as attractive revenue models
Take a look beyond the crowded crypto exchange market
With Revinfotech’s NFT marketplace services, you can protect the ownership rights of all collectibles and digital arts produced under your banner name.</p>
            </div>
          </div>
        </div>
        <div className="mt-5">
        <div className="text-center p-5">
          <p className="text-4xl">
          <b>NFT Marketplace Development for White Labels</b>
          </p>
          <p className="mt-3">A white-label solution is a product-ready solution developed by a company and sold to business models that need a platform right away. By developing an NFT marketplace with a white label label, business models gain brand awareness and establish brand identity. To help our customers deploy a highly sophisticated NFT platform quickly in the digital market, we provide comprehensive white label NFT development services.</p>
          <div className="flex mt-5">
            <div className="w-[25%]">
              <div className="bg-[#093D77] p-4">
                <img src={OpenSeaFullLogodark}  className="m-auto"></img>
              </div>
            </div>
            <div className="w-[25%] ml-5">
              <div className="bg-[#093D77] p-4">
                <img src={image232} className="m-auto"></img>
              </div>
            </div>
            <div className="w-[25%] ml-5">
              <div className="bg-[#093D77] p-4">
                <img src={image2324}  className="m-auto"></img>
              </div>
            </div>
            <div className="w-[25%] ml-5">
              <div className="bg-[#093D77] p-4">
                <img src={OpenSeaFullLogodark}  className="m-auto"></img>
              </div>
            </div>
          </div>
          <div className="flex mt-5">
            <div className="w-[25%]">
              <div className="bg-[#093D77] p-4">
                <img src={OpenSeaFullLogodark}  className="m-auto"></img>
              </div>
            </div>
            <div className="w-[25%] ml-5">
              <div className="bg-[#093D77] p-4">
                <img src={OpenSeaFullLogodark} className="m-auto"></img>
              </div>
            </div>
            <div className="w-[25%] ml-5">
              <div className="bg-[#093D77] p-4">
                <img src={OpenSeaFullLogodark}  className="m-auto"></img>
              </div>
            </div>
            <div className="w-[25%] ml-5">
              <div className="bg-[#093D77] p-4">
                <img src={OpenSeaFullLogodark}  className="m-auto"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Quality Services & Benefits
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BEST STRATEGIES
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Merely equipping AI in your proceedings won’t do your business
                any good. Make optimal use of the technology to devise the best
                strategies with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BUSINESS PLANNING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Do not take unwarranted steps on a whim. Let us research the
                market and prepare thorough plans for your next big business
                step.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                DATA SECURITY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Secure your consumer data with the latest blockchain technology
                and win the trust of your consumers. Never fall back with
                Rapidsofts.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Get Premium Benefits
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                DATA OPTIMIZATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                As your organization grows, the wastage of data and valuable
                resources increases as well. With our AI solutions you can
                minimize your wastage and optimally use the dormant data.
                Improve your productivity with our data optimization services.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                EXTENSIVE BUSINESS INSIGHTS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Not understanding your business capabilities fully can render
                your resources useless. Allow us to research your venture and
                know how it works. With RevInfotech, you can get an extensive
                knowledge of your business insights.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVED CX
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                Customer experience is pivotal for an organization’s success. An
                unhappy customer means your system has failed to perform its
                job. Do not let such failure ruin your reputation and allow our
                AI solutions to improve your CX to its fullest potential.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                RESULT PREDICTIONS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Our AI solutions are capable of making calculated predictions
                for your business. With our AI algorithms, you can deduce the
                results and undergo viable shifts to make them desirable. Keep
                up with the market and grow optimally with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                FACILITATE AUTOMATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                You can only go so far with manual labor and assistance. Allow
                us to introduce the magic of automation to your system. We can
                integrate seamless automation within your proceedings to carry
                out tedious tasks easily and securely.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
              How AI and Machine Learning can Help You?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              The times where mere human resources were able to assure a firm’s
              growth are gone. With changing times, the need for automation has
              massively increased. This need has led to the emergence of aspects
              like AI and ML. Both AI and ML paves the path of advancement and
              automation for an organization. When you integrate smart
              automation within your venture, you experience numerous business
              advantages you never knew existed. So avail the benefits today
              with RevInfotech and reach the top.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
              Why Pick RevInfotech for AI and ML Solutions?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              Only knowing the benefits of AI and ML won’t do your business any
              good. You need to make the most out of the tech and RevInfotech
              can help you achieve the same with ease. Our experts have years of
              knowledge and experience under their belt, allowing them to
              perceive the market from a different perspective. Their shrewd
              skill set allows them to understand every current and upcoming
              trend. Avail RevInfotech to witness the marvel of advancement. Let
              us bring all your worries to a halt.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra1} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MetaverseDevelopment;
