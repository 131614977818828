import React from 'react'
import { media_aso, Monetization_Solutions, dp, pwm, ims, mobilit_solutions, green_dividers, zzimg, ximg, vimg, Webcast_Event, work_image, zxu, soaq_image, flimskill, Hubpx, ttimg, zimg, trish } from '../../assets';


const MediaEntertainment = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className="sm:flex">
                    <div className="sm:w-[60%] max-sm:w-[100%]">
                        <div className="sm:mt-[170px max-sm:mt-[80px]">
                            <h1 className='sm:text-[22px] text-[#F13623] tracking-widest font-extrabold max-sm:text-[20px] max-sm:text-center'>MEDIA AND ENTERTAINMENT SOFTWARE DEVELOPMENT SOLUTIONS</h1>
                            <h1 className='sm:text-[77px] font-black max-sm:text-[20px] max-sm:text-center'>Captivate, Engage & Retain Your Audience with Custom Digital Media Solutions</h1>
                            <p className='sm:text-[33px] font-extralight'>Grow your business with customer-centric apps, products and platforms.</p>
                        </div>
                    </div>
                    <div className="sm:w-[40%] max-sm:w-[100%]">
                        <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[50px] sm:w-[450px]">
                            <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Need Help with Development?</h1>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                                    id="username"
                                    type="text"
                                    placeholder="Name*"
                                />
                            </div>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder="Email*"
                                />
                            </div>
                            <div className="flex mt-2">
                                <div className="w-35%">
                                    <select
                                        id="countries"
                                        className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option selected>Choose a country</option>
                                        <option value="US">India</option>
                                        <option value="CA">Canada</option>
                                        <option value="FR">France</option>
                                        <option value="DE">Germany</option>
                                    </select>
                                </div>
                                <div className="w-[65%] ml-3">
                                    <input
                                        className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="phone"
                                        type="phone"
                                        placeholder="+91"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <textarea
                                    id=""
                                    row="5"
                                    className="w-full px-3 h-20 py-2"
                                    placeholder="Message*"
                                ></textarea>
                            </div>
                            <div className="mt-3 mb-16">
                                <button
                                    type="button"
                                    className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                                >
                                    Get In Touch
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='bg-[#F9D94E] sm:w-[95%] max-sm:w-[100%] ml-auto sm:px-32 sm:pt-24 mt-5 flow-root'>
                <div>
                    <img src={media_aso} />
                </div>
                <div>
                    <div className='border-l-8 border-sky-400 relative top-[-69px] ml-5'>
                        <div className='sm:pl-8'>
                            <p className='pt-28 font-black sm:text-[20px] text-[#F13623] tracking-widest max-sm:text-[20px] max-sm:text-center'>THE Rapidsofts APPROACH</p>
                        </div>
                    </div>
                    <div className='sm:px-16 max-sm:px-8 top-[-64px] sm:w-[60%]'>
                        <h5 className='sm:text-[46px] font-extrabold mt-[-60px] max-sm:text-[20px] max-sm:text-center'>Data-driven IT Media and Entertainment Solutions</h5>
                        <p className='sm:text-[25px] font-light max-sm:text-[20px] max-sm:text-center'>For media, entertainment, and publishing companies, content is king — but it doesn’t rule on its own. For more than two decades, we’ve delivered world-class media and entertainment software development solutions for all aspects of digital media and publishing businesses, from distribution to broadcasting and streaming, content management, and more. Our expert team of business analysts, UX/UI designers, forward-thinking engineers, and security pros design and build modern media development software and digital products that sharpen your competitive edge as they take your business from now to next.</p>
                        <div className='max-sm:text-center'>
                            <button
                                type="button"
                                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] mt-5 hover:bg-[#F13623] hover:text-white"
                            >
                                Explore Services
                            </button>
                            <span className="ml-7 mt-5">
                                <button
                                    type="button"
                                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white mb-16"
                                >
                                    Request a Quote
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mx-auto sm:mt-5 max-sm:mt-2'>
                <div className='pt-4'>
                    <h5 className='font-bold sm:text-[55px] max-sm:text-[20px] max-sm:text-center'>Services</h5>
                </div>
                <div className='sm:flex flex-row sm:pt-20 max-sm:pt-10'>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>eCommerce & Monetization Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>The media and entertainment market is evolving at lightning speed, driven by new opportunities to deliver the personalized experience that today’s customers demand and expect. Depending on your unique media and entertainment software needs, our experienced pros can customize leading eCommerce solutions Shopify, Magento, or Drupal or build a unique, bespoke digital product that aligns your business goals with your audience’s demands.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={Monetization_Solutions} className="sm:h-[502px] max-sm:mt-5" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[50%] max-sm:w-[100%]'>
                        <img src={dp} className="h-[495px]" />
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Digital Publishing</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Creating and nurturing reader relationships is a multi-channel endeavor that thrives on consistency, interactivity, and personalization. Our seasoned media and entertainment solutions team empowers you to meet your audience where it is by designing and implementing media development software and engagement tools, including games, and by leveraging analytics to better predict and respond to customer needs, preferences, and habits.</p>
                        <button
                            type="button"
                            className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[40%] max-sm:w-[100%] mt-4'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Publishing Workflow Management</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>One of the most profound impacts of digital publishing is that it enables global content creators to easily participate in the creation, publication, and promotion of their work. We’re experts in designing and deploying automated media entertainment enterprise solutions that help businesses define and control their publishing workflow, maximizing collaboration opportunities, and minimizing publication cycles for books, magazines, journals, and newspapers.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={pwm} className="h-[502px]" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[50%] max-sm:w-[100%]'>
                        <img src={ims} className="h-[495px]" />
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Information Management Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Nowadays, you’ve got to balance the dynamic interplay between data and delivery to create a seamless customer experience for fickle audiences. Our expert entertainment, media, and publishing team devises centralized data inflow and outflow systems using advanced information mapping techniques and analytics. This empowers you with leading-edge media entertainment enterprise solutions that help you quickly and efficiently address customer needs, including subscription and content management, for better service online and offline.</p>
                        <button
                            type="button"
                            className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Mobility Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Engaging and retaining the attention of the on-the-go customer is the biggest challenge for digital publishers. As a leading media app development company, we provide a wide range of end-to-end, customer-focused digital product development services, from expert mobile UX/UI design to AR/VR features, integrated cloud storage, secure billing for premium content, and other business-building solutions.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={mobilit_solutions} className="h-[502px]" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
            </div>
            <div className='sm:flex flex-row pt-20 sm:w-[79%] mx-auto max-sm:p-4'>
                <div className="sm:ml-3">
                    <div className="">
                        <img
                            src={ttimg}
                            className="w-full object-cover"
                        ></img>
                    </div>
                </div>
                <div className="sm:ml-3">
                    <div className="">
                        <img src={zimg} className=" w-full object-cover"></img>
                    </div>
                </div>
                <div className="sm:ml-3">
                    <div className="">
                        <img
                            src={trish}
                            className="w-full object-cover"
                        ></img>
                    </div>
                </div>
            </div>
            <div className='container mx-auto sm:mt-5'>
                <h5 className='sm:text-[56px] font-bold max-sm:text-[20px] max-sm:text-center'>Success Stories</h5>
                <div className='sm:px-16 '>
                    <div className='sm:flex flex-row mt-5'>
                        <div className='mt-5 sm:w-[45%]'>
                            <div>
                                <img src={Webcast_Event} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Create Live Event Broadcasting Platforms that Connect Worldwide Audiences</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>See how we built a scalable, SaaS-based, white-labeled platform for live webcasting that integrates interactive tools, including Q&A and polls, for a dynamic viewing experience.</p>
                            </div>
                        </div>
                        <div className='mt-5 sm:w-45%] max-sm:w-[100%] ml-auto'>
                            <div className=''>
                                <img src={soaq_image} />
                            </div>
                            <div className='mt-4 sm:w-[500px] max-sm:w-[100%]'>
                                <h5 className='text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Build Flagship Video-driven Platforms for Ambitious Startups</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Learn how we designed and deployed the world’s first intelligent enterprise video solution that makes content “sticky,” bolsters employee engagement, and aligns organizations through a smooth user experience.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={flimskill} />
                            </div>
                            <div className='mt-5 sm:w-[500px] max-sm:w-[100%]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Build Digital Training Platforms for Entertainment Industry Leaders</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Explore how we helped FilmSkills, a top industry-standard motion picture and television production training company, develop the world's first video-driven, smart educational platform for aspiring filmmakers.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <h5 className='sm:text-[44px] font-extralight text-center max-sm:text-[20px] max-sm:text-center'>Have a Digital Media or Publishing Project in Mind?</h5>
                    <div className='w-[160px] mx-auto'>
                        <button
                            type="button"
                            className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MediaEntertainment