import React from 'react'
import { Education_training, Learning_management, Virtual_classroom, learning_applications, Remote_learning, purchases_technology, zrpid, zgaimg, zgbms, zsoft, EDplace, ascent_hbr, coach_cs_image, coach_cs_image1, fillskills_cs_image, burst_tools, xbimg, xaimg, ximg } from '../../assets';
const EducationTraining = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className="sm:flex">
                    <div className="sm:w-[60%] max-sm:w-[100%]">
                        <div className="sm:mt-[170px] max-sm:mt-[50px]">
                            <h1 className='sm:text-[22px] text-[#F13623] tracking-widest font-extrabold max-sm:text-[20px] max-sm:text-center'>EDUCATION & TRAINING</h1>
                            <h1 className='sm:text-[68px] font-black max-sm:text-[20px] max-sm:text-center'>Revolutionize Your Teaching & Training with Customized edTech & eLearning Solutions</h1>
                            <p className='sm:text-[33px] font-extralight max-sm:text-[20px] max-sm:text-center'>Deliver an A+ online educational experience for improved outcomes and greater success.</p>
                        </div>
                    </div>
                    <div className="sm:w-[40%] max-sm:w-[100%]">
                        <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[50px] sm:w-[450px]">
                            <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Need Help with Development?</h1>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                                    id="username"
                                    type="text"
                                    placeholder="Name*"
                                />
                            </div>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder="Email*"
                                />
                            </div>
                            <div className="flex mt-2">
                                <div className="w-35%">
                                    <select
                                        id="countries"
                                        className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option selected>Choose a country</option>
                                        <option value="US">India</option>
                                        <option value="CA">Canada</option>
                                        <option value="FR">France</option>
                                        <option value="DE">Germany</option>
                                    </select>
                                </div>
                                <div className="w-[65%] ml-3">
                                    <input
                                        className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="phone"
                                        type="phone"
                                        placeholder="+91"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <textarea
                                    id=""
                                    row="5"
                                    className="w-full px-3 h-20 py-2"
                                    placeholder="Message*"
                                ></textarea>
                            </div>
                            <div className="mt-3 mb-16">
                                <button
                                    type="button"
                                    className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                                >
                                    Get In Touch
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='bg-[#F9D94E] sm:w-[95%] max-sm:w-[100%] ml-auto sm:px-32 sm:pt-24 mt-5 flow-root'>
                <div>
                    <img src={Education_training} />
                </div>
                <div>
                    <div className='border-l-8 border-sky-400 relative top-[-69px] ml-5'>
                        <div className='sm:pl-8'>
                            <p className='pt-28 font-black text-[20px] text-[#F13623] tracking-widest max-sm:text-[20px] max-sm:text-center'>THE Rapidsofts APPROACH</p>
                        </div>
                    </div>
                    <div className='sm:px-16 max-sm:px-8 top-[-64px] sm:w-[60%]'>
                        <h5 className='sm:text-[46px] font-extrabold mt-[-60px] max-sm:text-[20px] max-sm:text-center'>Innovative, End-to-End EdTech Solutions</h5>
                        <p className='sm:text-[25px] font-light max-sm:text-[20px] max-sm:text-center'>The rise of remote education and training has empowered organizations to cater to individuals while delivering opportunities to learners worldwide. As a leading edTeach and eLearning solutions company, our expert UX/UI experts, designers, and developers are dedicated to creating dynamic learning experiences that grab attention, encourage participation, foster focus, and support memory retention. From animation to video, gamification, interactive features, micro-learning, and more, we tailor edTech and eLearning solutions for your educational organization so you can create a culture of academic success.</p>
                        <div className='max-sm:text-center'>
                            <button
                                type="button"
                                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                            >
                                Explore Services
                            </button>
                            <span className="sm:ml-7 mt-5">
                                <button
                                    type="button"
                                    className=" max-sm:mt-5 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white mb-16"
                                >
                                    Request a Quote
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mx-auto sm:mt-5'>
                <div className='pt-4'>
                    <h5 className='font-bold sm:text-[55px] max-sm:text-[20px] max-sm:text-center'>Services</h5>
                </div>
                <div className='sm:flex flex-row sm:pt-20 max-sm:pt-5'>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Learning Management Systems</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>With the mainstreaming of online learning for public and private sector schools (K-12), colleges, and universities, the opportunities for customized experiences have exploded. As experts in eLearning and EdTech platform development, our skilled engineers tailor solutions for leading Learning Management Systems (LMS) to align with your institution’s standards for development and assignment of coursework, student progress tracking, and the measurement and reporting of student outcomes.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={Learning_management} className="h-[502px]" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[50%] max-sm:w-[100%]'>
                        <img src={Virtual_classroom} className="h-[495px]" />
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Virtual Classroom Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Unlike a traditional classroom, a virtual one puts both live (synchronous) and self-paced (asynchronous) tools at your learners’ fingertips. Our experienced development team has expertise in creating online classrooms that seamlessly integrate audio, video, chat, whiteboards, group collaboration tools, and other engaging features for a robust, interactive experience.</p>
                        <button
                            type="button"
                            className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[40%] max-sm:w-[100%] mt-4'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Mobile Learning Applications</h5>
                        <p className='font-light text-[21px] mt-4'>Empower your on-the-go team, students, and their parents (when applicable) with education and training delivered to the tool they carry everywhere: their smartphones. Our experienced eLearning app development team designs and deploys smart mobile learning solutions, including micro-learning platforms, that support engagement and retention while delivering cost, resource, and time savings for end-users and your organization alike.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={learning_applications} className="h-[502px]" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[50%] max-sm:w-[100%]'>
                        <img src={Remote_learning} className="h-[495px]" />
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Remote Learning Solutions</h5>
                        <p className='font-light text-[21px] mt-4'>Distance learning isn’t only about the classroom experience — it involves a whole system to support online learning success. We create full educational content management systems, which include robust video applications, online publishing, and digital content repositories, school or university intranet and web portals, and other customized digital destinations.</p>
                        <button
                            type="button"
                            className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='flex-flex flex-row pt-20'>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Proximity Marketing/Sales Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Marketing to your customer at the right place and right time with relevant, personalized interactions is a critical business driver. Our expert team of marketing pros and eCommerce developers are experienced in designing end-to-end, location-aware solutions based on GPS, NFC, BLE beacons, and QR codes that feed smart retail intelligence and fuel sales.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={purchases_technology} className="h-[502px]" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
            </div>
            <div className='sm:flex flex-row pt-20 w-[79%] mx-auto'>
                <div className="sm:ml-3">
                    <div className="">
                        <img
                            src={xbimg}
                            className="w-full object-cover"
                        ></img>
                    </div>
                </div>
                <div className="sm:ml-3">
                    <div className="">
                        <img src={xaimg} className=" w-full object-cover"></img>
                    </div>
                </div>
                <div className="sm:ml-3">
                    <div className="">
                        <img
                            src={ximg}
                            className="w-full object-cover"
                        ></img>
                    </div>
                </div>
            </div>
            <div className='container mx-auto mt-5'>
                <h5 className='sm:text-[56px] font-bold max-sm:text-[20px] max-sm:text-center'>Success Stories</h5>
                <div className='sm:px-16'>
                    <div className='sm:flex flex-row mt-5'>
                        <div className='mt-5 sm:w-[45%]'>
                            <div>
                                <img src={EDplace} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Build Award-winning, Scalable Mobile eLearning Apps</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>See how we partnered with an ambitious EdTech startup to build a home-learning mobile app, developing it from a robust MVP to a highly successful platform that parents and students love.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={ascent_hbr} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Create Popular Digital Learning Platforms that Drive Profitability</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Learn how we designed and deployed a skill enhancement platform for the Harvard Business Review’s Ascend, whose lightweight delivery of valuable content made it a must-subscribe for ambitious Millennial professionals.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={coach_cs_image} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Engineer Enterprise Multi-tenant SaaS Solutions for Global Clients</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Explore how we created a turnkey performance development platform for global organizations — small businesses to multinational enterprises — that provides unique training tools and solutions built on the latest technology platforms.</p>
                            </div>
                        </div>
                        <div className='mt-5 sm:w-[45%] ml-auto'>
                            <div className=''>
                                <img src={coach_cs_image1} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Innovate Video-driven Training Solutions for Better Business Outcomes</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Discover how we created the first intelligent enterprise video training platform designed to maximize employee engagement, which won a spot on Gartner’s 2018 List of Cool Vendors in Content Services.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={fillskills_cs_image} />
                            </div>
                            <div className='mt-5 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Develop Innovative Features for Better Platform Performance</h5>
                                <p className='text-[20px] font-light mt-4'>Discover how we improved the UX/UI for a leading provider of industry-standard motion picture and television production training with a custom-designed, user-focused digital training platform.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={burst_tools} />
                            </div>
                            <div className='mt-5 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>A Cross Platform Digital Training Experience</h5>
                                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>IsaiX is a Canadian technology firm that offers employee engagement products and solutions that are being used by leading organizations and global brands in the US, Canada, and the UK.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <h5 className='sm:text-[44px] font-extralight text-center max-sm:text-[20px] max-sm:text-center'>Have an eLearning or Training Project in Mind?</h5>
                    <div className='sm:w-[160px] mx-auto max-sm:text-center'>
                        <button
                            type="button"
                            className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EducationTraining