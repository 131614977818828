import React from 'react'
import { Digital_banner_img_1, user, digital_analytics2, digital_analytics_chart2, digi_anlytics, clients_quotes, Mario_maruffi, digital_analytics11, digi_anlytics_img3, ebook_img, Top_Data, Data_Visualization, Comparison_between, Key_Principles, digital_chart1, chart2, netflix_device, digital_bg4, digital_e_book2, digital_hart3, digital_bhart4, digital_lhart5, digital_expe_book3 } from '../../assets';
import { Link } from "react-router-dom";
import Sidebar4 from '../../componets/sidebar/Sidebar4';

const ExperienceDesign = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='w-[400px] h-[500px] mt-[68px] fixed overflow-auto'>
                    <Sidebar4 />
                </div>
                <div className='ml-[420px]'>
                    <ul className='flex pt-[85px]'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Digital Experience Design</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${Digital_banner_img_1})` }} className="w-full h-[400px] bg-center bg-cover mt-5">
                        <div className='relative left-[53px] top-[80px] w-[32%]'>
                            <h5 className='font-extrabold text-[41px] text-white'>Digital Experience Design</h5>
                        </div>
                    </div>
                    <div className='px-10'>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-light'>Technology is useless unless it is easy to use and offers a satisfactory customer experience holistically. This is why businesses investing heavily in technology will have to invest equally in offering digital experience design. A powerful design which is tied strongly to your customer needs and expectations will ensure that the end user feels control, satisfaction, and happiness throughout their interaction with the product/service.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <img src={digital_chart1} />
                        </div>
                        <div className='mt-4'>
                            <p className='text-[27px] font-sans font-light'>To nail experience design, you’ll have to start with user research — the best way to understand user needs, thinking, and expectations.</p>
                            <p className='text-[27px] font-sans font-light mt-4'><span className='text-[#F13623]'>89.2% of companies conduct research before the design stage.</span> — State of User Research 2021 report by <span className='font-bold border-b-[2px] border-[#F13623]'>User Interviews</span>,</p>
                            <p className='text-[27px] font-sans font-light mt-4'>In this guide, we will cover the A-Z of experience design and how it goes on to define your business success.</p>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <div>
                            <img src={user} />
                        </div>
                        <div className='px-10'>
                            <div className='border-l-8 border-sky-400 relative top-[-50px]'>
                                <p className='px-2 pt-24 font-black text-[58px]'>What is Data Analytics?</p>
                            </div>
                            <div className='px-4'>
                                <div className=''>
                                    <p className='text-[27px] font-sans font-light'>Experience design is a holistic approach that focuses on the overall quality of user experience across customer journeys and channels. Experience design primarily focuses on creating a powerful and positive overall experience that the users will stick to the customer’s conscience.</p>
                                    <p className='text-[27px] font-sans font-light mt-5'>The domain primarily focuses on creating a seamless, positive, and consistent overall experience measured in the form of user satisfaction and engagement levels.</p>
                                </div>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>A good and meaningful experience design implies that the user interface is neat and clean, the user experience design is seamless across touchpoints, and the overall customer experience across channels is satisfactory.</h5>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <h5 className='font-extrabold text-[41px]'>Elements of Digital Experience Design</h5>
                                    <p className='text-[27px] font-sans font-light mt-5'>Experience design (XD) is a broad term that covers everything from a neat and clean user interface to the overall customer experience a user receives.</p>
                                    <p className='text-[27px] font-sans font-light mt-5'>The essential elements of digital experience design include — user interface (UI), user experience design (UXD), and customer experience (CX).</p>
                                </div>
                                <div className='px-32 mt-5'>
                                    <img src={chart2} />
                                </div>
                                <div className='mt-5'>
                                    <p className='text-[27px] font-sans font-light'>Let us discuss each of these elements in detail:</p>
                                </div>
                                <div className='mt-5'>
                                    <p className='font-bold text-[27px]'>1. <span className='ml-3'>User Interface (UI)</span></p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>Defines the visual appeal of the interface.</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>KPI: Cleanliness, attractiveness, and understandability</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>User interface (UI) is the front-end interface that is visible to the users when they interact with a digital product or a service. In other words, UI is the interaction point between the user and your brand.</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>The user interface should be understandable and easy to interpret across touchpoints and channels.</p>
                                </div>
                                <div className='bg-[#FFF9EC] mt-5'>
                                    <div className='px-24 py-4'>
                                        <img src={clients_quotes} />
                                        <h5 className='text-[25px] font-extralight mt-7'>A user interface should be so simple that a beginner in an emergency can understand it within 10 seconds.</h5>
                                        <div className='flex mt-5'>
                                            <div>
                                                <img src={Mario_maruffi} />
                                            </div>
                                            <div className='ml-6'>
                                                <h5 className='text-[21px] font-bold mt-3.5'>Ted Nelson</h5>
                                                <span className='text-[16px] font-medium'>American pioneer of information technology</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <p className='font-bold text-[27px]'>2. <span className='ml-3'>User Experience Design (UXD)</span></p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>Defines the quality of interaction when using a single device or a single channel.</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>KPI: Promptness and effectiveness of the system to perform the intended task</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>User experience design defines the satisfaction of interaction that a user has with a brand when performing a particular action. User experience design focuses on usability, accessibility, friendliness, responsiveness, and intuitiveness of the system.</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>The user interface should be understandable and easy to interpret across touchpoints and channels.</p>
                                    <p className='text-[24px] font-sans font-light mt-4'>There are 8 steps for implementing user experience design, which include:</p>
                                    <ul className='list-disc mt-5 ml-[25px]'>
                                        <li className='text-[24px] font-sans font-light mt-4'>Stakeholder Interviews</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Conduct User Research</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Execute UX Audit</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Gather Requirements</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Build Information Architecture</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Work on Visual Design</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Build Prototype</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Implement Testing</li>
                                    </ul>
                                </div>
                                <div style={{ backgroundImage: `url(${ebook_img})` }} className="w-full h-[350px] bg-center bg-cover mt-5">
                                    <div className='relative left-[53px] top-[50px] w-[22%]'>
                                        <h5 className='font-extrabold text-[41px]'>Digital Experience Platforms</h5>
                                        <Link to={""}><button type="button" className='px-4 py-2 text-[24px] text-white bg-[#F13623] font-extrabold h-16 mt-3 w-[200px]'>Read Me</button></Link>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <p className='font-bold text-[27px]'>3. <span className='ml-3'>Customer Experience (CX)</span></p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>Defines the lifetime experience a user has with the product</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>KPI: loyal customers, long-term customer relationship, word-of-mouth marketing</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>The customer experience (UX) focuses on building a relationship-level experience, i.e., the quality of experience a user has with your brand over a lifetime.</p>
                                </div>
                                <div className='mt-5'>
                                    <h5 className='font-extrabold text-[41px]'>Digital Experience Design Example</h5>
                                    <p className='text-[24px] font-sans	font-light mt-4'>A customer wishes to watch Money Heist on Netflix. He first subscribes to Netflix for a month, searches for the series, and finally watches it. Will he continue the subscription for another month?</p>
                                </div>
                                <div className='mt-5'>
                                    <h5 className='font-extrabold text-[41px]'>A good UI will ensure that Netflix has:</h5>
                                    <ul className='list-disc mt-5 ml-[50px]'>
                                        <li className='text-[24px] font-sans font-light mt-4'><strong>Ideal information architecture</strong> — The content is nicely categorized and labeled</li>
                                        <li className='text-[24px] font-sans font-light mt-4'><strong>Visual Design</strong> — The interface design looks minimalistic and attractive</li>
                                        <li className='text-[24px] font-sans font-light mt-4'><strong>Usable Interface</strong> — All the desired UI controls are available and easily findable while streaming, such as play, pause, leap controls, subtitles, language options, etc.</li>
                                    </ul>
                                </div>
                                <div className='mt-5'>
                                    <h5 className='font-extrabold text-[41px]'>A good UXD will ensure that the customer can:</h5>
                                    <ul className='list-disc mt-5 ml-[50px]'>
                                        <li className='text-[24px] font-sans font-light mt-4'>Find a suitable plan and can easily subscribe to the service without much effort</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Search for “Money Heist” immediately once he enters the name into the search bar</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Start watching the series as soon as he clicks/touches the play button</li>
                                    </ul>
                                </div>
                                <div className='mt-5'>
                                    <h5 className='font-extrabold text-[41px]'>A good UI will ensure that Netflix has:</h5>
                                    <ul className='list-disc mt-5 ml-[50px]'>
                                        <li className='text-[24px] font-sans font-light mt-4'>Gets a personalized experience in the form of recommendations from Netflix</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Gets timely resolutions from the Netflix team whenever the customer raises a query</li>
                                        <li className='text-[24px] font-sans font-light mt-4'>Does not feel any friction in performing any action across the platform whether accessing it through the mobile, tablet, or a desktop</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='bg-[#E2E8FF] mt-5 h-[450px]'>
                                <div className='flex'>
                                    <div className='relative left-[53px] top-[50px] w-[52%]'>
                                        <h5 className='font-extrabold text-[41px]'>We Captured Netflix's Success in Building a Stellar Customer Experience-driven Mobile App.</h5>
                                        <Link to={""}><button type="button" className='px-4 py-2 text-[24px] text-white bg-[#F13623] font-extrabold h-16 mt-3 w-[200px]'>Read Me</button></Link>
                                    </div>
                                    <div className='relative top-[32px] left-[100px]'>
                                        <img src={netflix_device} className="h-[500px]" />
                                    </div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <div className='pt-20'>
                                    <p className='text-[24px] font-sans font-light mt-4'>In all, experience design will ensure that every stage of the process, as mentioned above. offers a connected, seamless, and satisfactory experience on the whole. It is the overall end-to-end experience that a customer transits through across stages and channels.</p>
                                </div>
                                <div className='mt-5'>
                                    <h5 className='font-extrabold text-[41px]'>Digital Experience Design: Concepts</h5>
                                    <p className='text-[24px] font-sans font-light mt-4'>Essential concepts that are a part of digital experience design include:</p>
                                </div>
                                <div className='mt-5'>
                                    <p className='font-bold text-[27px]'>1. <span className='ml-3'>Human-Centered Design</span></p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>The human-centered design focuses on human behavior and their mindset when interacting with a user interface design. It primarily considers humans by focusing on empathy when designing digital products and services.</p>
                                </div>
                                <div className='bg-[#FFF9EC] mt-5'>
                                    <div className='px-24 py-4'>
                                        <img src={clients_quotes} />
                                        <h5 className='text-[25px] font-extralight mt-7'>A user interface should be so simple that a beginner in an emergency can understand it within 10 seconds.</h5>
                                        <div className='flex mt-5'>
                                            <div>
                                                <img src={Mario_maruffi} />
                                            </div>
                                            <div className='ml-6'>
                                                <h5 className='text-[21px] font-bold mt-3.5'>Ted Nelson</h5>
                                                <span className='text-[16px] font-medium'>American pioneer of information technology</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <p className='font-bold text-[27px]'>2. <span className='ml-3'>Information Architecture (IA)</span></p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>Information architecture is about labeling, structuring, and organizing content for a digital product or service so that it looks orderly and is navigable. The primary objective of IA is to make the product usable and its features findable and discoverable.</p>
                                </div>
                                <div className='bg-[#FFF9EC] mt-5'>
                                    <div className='px-24 py-4'>
                                        <img src={clients_quotes} />
                                        <h5 className='text-[25px] font-extralight mt-7'>A user interface should be so simple that a beginner in an emergency can understand it within 10 seconds.</h5>
                                        <div className='flex mt-5'>
                                            <div>
                                                <img src={Mario_maruffi} />
                                            </div>
                                            <div className='ml-6'>
                                                <h5 className='text-[21px] font-bold mt-3.5'>Ted Nelson</h5>
                                                <span className='text-[16px] font-medium'>American pioneer of information technology</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <p className='font-bold text-[27px]'>3. <span className='ml-3'>Dark Patterns</span></p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>Dark patterns in UX design are tricks that a brand uses to manipulate a user into performing an action they didn’t intend to in the first place. Businesses feel that it is an easy way to increase traffic to a website and increase conversions. However, dark patterns are the biggest reason for abandonments and bad CX.</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>A popular type of dark pattern that influences the experience design is — roach motel, i.e., subscription to a product or service is easy. However, when it comes to canceling the subscription — finding the cancel option is tricky.</p>
                                    <p className='text-[24px] font-sans	font-light mt-4'>An essential part of building a great experience design is to be wary of dark patterns. These dark patterns ruin the overall customer experience of any user.</p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#E2E8FF] mt-5 h-[450px]'>
                            <div className='flex'>
                                <div className='relative left-[53px] top-[50px] w-[52%]'>
                                    <h5 className='font-extrabold text-[41px]'>Want to learn more about different types of dark patterns?</h5>
                                    <Link to={""}><button type="button" className='px-4 py-2 text-[24px] text-white bg-[#F13623] font-extrabold h-16 mt-3 w-[200px]'>Read Me</button></Link>
                                </div>
                                <div className='relative top-[32px] left-[100px]'>
                                    <img src={netflix_device} className="h-[500px]" />
                                </div>
                            </div>
                        </div>
                        <div className='px-14 pt-14'>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Skeuomorphism</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Designing for familiarity is the idea behind skeuomorphism. The UI design elements used across the digital space should be intuitively designed, i.e., the user should know in advance that clicking a particular button will trigger what action.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>UX trends such as flat design and neumorphism should also be considered when designing the user interface of the digital product or service.</p>
                            </div>
                            <div style={{ backgroundImage: `url(${digital_bg4})` }} className="w-full h-[400px] bg-center bg-cover mt-5">
                                <div className='relative left-[53px] top-[80px] w-[70%]'>
                                    <h5 className='font-extrabold text-[41px] text-white'>Here are more UX/UI trends for 2021 that can help nail digital experience design by focusing on user experience design.</h5>
                                    <Link to={""}><button type="button" className='px-4 py-2 text-[24px]  bg-[#FDE65B] font-extrabold h-16 mt-3 w-[200px]'>Click here</button></Link>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Ethnography</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Ethnography allows a business to walk into their customer’s shoes and understand their behavior, mindset, attitude, aptitude, and emotions.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Ethnography is a user research methodology that helps uncover the human expectations from a digital product and service under development.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Listen to this audio to gain a better understanding of digital ethnography when designing digital products.</p>
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>Digital Experience Design Framework</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>A digital experience design framework is imperative to offer a meaningful and effective digital experience across products and services.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Here’s a 4-point framework that we call 4E’s of digital experience design:</p>
                                <ul className='list-disc ml-5'>
                                    <li className='text-[24px] font-sans font-light mt-4'><strong className='font-bold'>Explore:</strong> <span>Use user research methods to understand the customer needs and expectations from you</span></li>
                                    <li className='text-[24px] font-sans font-light mt-4'><strong className='font-bold'>Evaluate:</strong> <span>Run usability testing for existing user experience touchpoints to validate the cohesiveness of the system on the whole</span></li>
                                    <li className='text-[24px] font-sans font-light mt-4'><strong className='font-bold'>Experiment:</strong> <span>Ideate and implement new ideas that will help fill the existing customer experience gaps</span></li>
                                    <li className='text-[24px] font-sans font-light mt-4'><strong className='font-bold'>Engage:</strong> <span>Keep innovating and improving the digital experience design based on observation, feedback, and monitoring results</span></li>
                                </ul>
                            </div>
                            <div className='mt-5'>
                                <img src={digital_e_book2} />
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>Digital Experience Design: Your Multi-Touchpoint Digital Strategy</h5>
                                <h5 className='font-semibold text-[30px] mt-5'>A business needs to serve its customers with relevant, useful, and connected experiences across touchpoints to ensure ongoing engagement.</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>Here’s an overview of the stages involved in devising a robust digital experience design strategy:</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Identifying Customer Journeys</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>The first step is to identify the different customer journeys that your buyer personas maneuver through when interacting with the digital product/service.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Once you have identified customer journeys (for the most important ones), the next step is to map the customer journeys across touchpoints. Customer journey mapping will help visualize the steps that the customers take when performing an intended action.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={digital_hart3} />
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Document the Journeys</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Use the “persona design” approach to document the customer journeys. Here you create profiles of different buyer personas that highlight the needs of identified user profiles.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>The approach helps with digital ethnography, i.e., identify emotional trigger points, user behavior, and their expectations from a brand.</p>
                            </div>
                            <div className='mt-5 w-[50%] mx-auto'>
                                <img src={digital_bhart4} />
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Identify Different Touchpoints and Channels</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>After identifying and documenting the customer journeys, the next step is to build a customer experience map. This map will help analyze customer behavior and interaction across touchpoints and channels.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Here’s how a customer experience map looks like:</p>
                            </div>
                            <div className='mt-5'>
                                <img src={digital_lhart5} />
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Work on Offering Omnichannel Customer Experience</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Omnichannel customer experience implies that the customer has access to various touchpoints spread across channels that are connected and in cadence with each other.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Even if a customer leaves a particular channel midway, they can resume from the same point in the journey (irrespective of whether they use the same channel or a different one).</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>The number of companies investing in the omni-channel experience has jumped from 20% to more than 80%.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Establish Command and Control</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Command and control help distinguish the different touchpoints and channels from different focus groups.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>For example, both marketing and sales manage Twitter. Both the parties will ensure that a consistent brand image is showcased and transparency is maintained when managing interactions over the official social media account.</p>
                                <p className='text-[24px] font-sans font-light mt-4'>Also, command and control ensure that consistency across the experience design is maintained when customers switch channels.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={digital_expe_book3} />
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>Conclusion</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>Maintaining a connected and consistent customer experience throughout is essential for every online business. The hierarchy of nailing experience design starts with building a user interface and goes on to managing the user experience design across touchpoints and channels.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>If everything falls in place, a customer feels satisfied and happy with the overall experience, i.e., they have zero complaints or pain points across the customer journey.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>This write-up offers a comprehensive view into every aspect of the experience design and the process to ensure brand cadence across channels and touchpoints.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>In all, if the overall journey of a customer with a brand is smooth and seamless, be certain of one thing — you have a well thought off experience design.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
            <div className='flex gap-5'>
                <div className='w-[31%] shadow-2xl mt-5'>
                    <img src={Key_Principles} />
                    <div className='px-4'>
                        <div className='flex mt-3'>
                            <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                            <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                        </div>
                        <div className='mt-4'>
                            <div className='flex'>
                                <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Data Analytics & Insights</Link >
                                <Link to={""} className='text-[#F13623] ml-2 tracking-widest text-[12px]'>Data Visualization</Link>
                            </div>
                            <div className='mt-4'>
                                <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    5 Key Principles of a Good Dashboard Design </Link>
                            </div>
                        </div>
                        <div>
                            <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                        </div>
                    </div>
                </div>
                <div className='w-[31%] shadow-2xl mt-5'>
                    <img src={Data_Visualization} />
                    <div className='px-4'>
                        <div className='flex mt-3'>
                            <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                            <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                        </div>
                        <div className='mt-4'>
                            <div className='flex'>
                                <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Data Analytics & Insights</Link >
                                <Link to={""} className='text-[#F13623] ml-2 tracking-widest text-[12px]'>Data Visualization</Link>
                            </div>
                            <div className='mt-4'>
                                <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    Top 7 Data Visualization Trends & Tools for the Years to Come </Link>
                            </div>
                        </div>
                        <div>
                            <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                        </div>
                    </div>
                </div>
                <div className='w-[31%] shadow-2xl mt-5'>
                    <img src={Comparison_between} />
                    <div className='px-4'>
                        <div className='flex mt-3'>
                            <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                            <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                        </div>
                        <div className='mt-4'>
                            <div>
                                <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Hadoop Vs Spark — Choosing the Right Big Data Framework</Link >
                            </div>
                            <div className='mt-4'>
                                <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    Hadoop Vs Spark — Choosing the Right Big Data Framework  </Link>
                            </div>
                            <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
        </>
    )
}

export default ExperienceDesign