// import React from 'react'
import {
  
  ascend1,
  Hiring,
  GoogleCloudSQL,
  digitalcustomerexperiencestrategy,
  Digital_tra2,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  digitalexpplatform,
  GWA1m,
  flimskillm,
  acomplete,
  Thumbnail,
  Thumbnail1,
  dtdiagram,
  bannerods,
  netsolutions,
  mobilebanner,
  netsolutions1,
  dxp,
  trish,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const Django = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] max-sm:text-[20px] max-sm:text-center font-black text-[#D4386C]">
                TOP-TIER DJANGO DEVELOPMENT COMPANY & SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Harness the Power of Django for Exceptional User Experiences &
                Results
              </h1>
              <p className="sm:text-[23px] max-sm:text-[20px] max-sm:text-center italic mt-4 w-[80%]">
                Maximize customer engagement, conversion, and retention with
                Django customized apps, sites, and portals.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for Django Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={trish} className="w-full sm:h-[600px] object-cover" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>OUR APPROACH TO OFFSHORE DEVELOPMENT</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Full-Spectrum Django Development Services</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Since Django’s launch in 2005, Rapidsofts has been using the
                full-stack Python web development framework to build scalable,
                secure solutions. It’s one thing to hire Django developers; it’s
                another to hire an expert Django development agency like Net
                Solutions. We amplify the framework's power with our Agile
                methodology for project management, delivering exceptional
                outcomes for various projects, from highly personalized websites
                and mobile apps to full-on enterprise applications.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>
              Transform Your Business with Our Custom Django Development
              Services
            </b>
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            As a full-service Django web development company, we offer clients a
            wide variety of opportunities to leverage our expertise, from Django
            consulting to robust design, development, and support services,
            including:
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Custom Django App Development
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Django is our go-to Python framework for rapid app development —
                and it's our top tech choice for developing the backend of
                mobile apps. As a Django web development company, we appreciate
                having its “batteries included” full-stack capabilities at our
                fingertips to develop highly functional, personalized, and
                interactive mobile applications tailored to fit our clients’
                needs.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px]"
                ></img>
                  <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Django Web Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  In today’s highly competitive world, customers expect instant
                  connection and fully responsive service – and it’s not so
                  simple to hire a Django programmer that can effectively
                  maximize flexibility, speed, and data security for a website,
                  portal, or web app. We specialize in customized Django-driven
                  solutions for highly scalable, responsive software that
                  captures and converts customer attention and supports
                  long-lasting brand/buyer relationships.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                API Development and Integration
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Our Django development services go beyond building simple front
                ends. We provide sophisticated APIs/web services that empower
                you to make better business decisions for all of your digital
                needs and unique use cases. As an end-to-end Django software
                development agency, we utilize the core components of the Django
                REST system, including transparency, adaptability, quality, and
                serialization, to deliver secure and feature-rich RESTful APIs.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center  max-sm:mt-[50px]">
                  Migration to Django
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  As your tech stack or app ages, concerns about performance,
                  viability, and ongoing support naturally arise. Before it’s
                  too late, it’s time to hire Django experts (aka our team!) for
                  app modernization and to get more from your data. We provide
                  complete accountability and transparency while migrating your
                  data and apps to this flexible, scalable framework.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className=" mt-[50px]">
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-100px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        Is Django still used for software development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        elieve it or not, we get this question from time to
                        time! That may be because Django has been around since
                        2005 when it was released as an open-source project.
                        Adrian Holovaty and Simon Willison, developers who
                        worked at the news website World Online, invented it to
                        “meet fast-moving newsroom deadlines while satisfying
                        the tough requirements of experienced web developers.”
                        Today, Django’s tagline is: “The web framework for
                        perfectionists with deadlines.” And the tech can be used
                        to build any kind of website, including content
                        management systems (CMS), eCommerce sites, and even
                        social networks.
                      </div>
                      <div></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      Is Django good for software development?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      Django isn’t good for software development… it’s
                      excellent! Here are the top reasons:
                    </div>
                    <ul className="list-disc ml-3">
                      <li>
                        Allows rapid development: Django helps developers to
                        take applications from the conceptualization stage to
                        completion as quickly as possible and prioritizes
                        scalability. This ensures business applications reach
                        the market swiftly without compromising the quality.
                      </li>
                      <li>
                        Highly scalable and customizable: The whole point of
                        your business is to grow and drive profit – the Django
                        framework is designed to scale easily and be customized,
                        allowing your sites, apps, and platforms to not only
                        handle high traffic but also never miss a beat with your
                        customers.
                      </li>
                      <li>
                        Robust security features: Django provides top-notch
                        security and protection against privacy breaches such as
                        SQL injection, cross-site scripting, cross-site request
                        forgery, and clickjacking.
                      </li>
                      <li>
                        “Batteries included”: Django comes full-featured,
                        enabling developers to get sites and apps up and running
                        quickly. It’s also been engineered to “do the right
                        things” to protect the software automatically.
                      </li>
                      <li>
                        Streamlined and efficient: Django code is written using
                        design principles and patterns that encourage the
                        creation of maintainable and reusable code. In
                        particular, it uses the Don't Repeat Yourself (DRY)
                        principle, so there is no unnecessary duplication,
                        reducing the amount of code.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">Is Django in high demand?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Even though it’s been around since 2005, Django remains a
                      top in-demand web development framework. (And we’re not
                      just saying that because we’re a Django development
                      company — it’s true!) It’s been reported that Django has
                      had a market share of over 28% since 2021. Plus, if you
                      Google, “hire Django developer,” you’ll see how popular it
                      is as a search term!
                    </p>
                    <p className="text-[20px] font-light">
                      (That said, it’s not so simple to just type in “hire
                      Django developers” or “hire Django programmer” and find
                      vendors who will deliver consistent, high-quality work.
                      That’s why we believe a full-service Django development
                      company is a better choice.)
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      Is Django used in companies?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Absolutely! Django is a full-stack framework that
                      satisfies the needs of all kinds of organizations, from
                      startups to enterprises. No matter the application, it's
                      an excellent choice, as it's explicitly designed to make
                      developing complex database-driven websites easy. Django
                      facilitates secure and faster development by freeing
                      developers from performing repetitive web development
                      tasks. Plus, it’s got a vast reservoir of excellent
                      features, enabling Django developers to create robust,
                      high-performing applications.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">9939226086
                      What major companies use Django?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Even though Python is considered a simple coding language,
                      it's still a coding language. To get the best results from
                      a web development project, you need people who know the
                      ins and outs of the language and how to pair it with the
                      right technical solutions to bring your ideas to life. A
                      custom Python web development company can be that resource
                      for you. When you outsource web development, you also
                      don't have to tie up internal IT teams for the project.
                      That leaves them free to attend to other business-facing
                      needs.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="a">
                  <Accordion.Header>
                    <h5 className="text-[26px] max-sm:text-[20px]">
                      Is Django used in companies?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      bsolutely! Django is a full-stack framework that satisfies
                      the needs of all kinds of organizations, from startups to
                      enterprises. No matter the application, it's an excellent
                      choice, as it's explicitly designed to make developing
                      complex database-driven websites easy. Django facilitates
                      secure and faster development by freeing developers from
                      performing repetitive web development tasks. Plus, it’s
                      got a vast reservoir of excellent features, enabling
                      Django developers to create robust, high-performing
                      applications.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      What major companies use Django?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Thanks to its infinite scalability and full stack, the
                      Django framework has been used for many high-profile
                      companies, including:
                    </p>
                    <ul className="list-disc ml-3">
                      <li>Spotify</li>
                      <li>Instagram</li>
                      <li>Pinterest</li>
                      <li>Disqus</li>
                      <li>Bitbucket</li>
                      <li>Eventbrite</li>
                      <li>Prezi</li>
                      <li>The Knight Foundation</li>
                      <li>Mozilla</li>
                      <li>National Geographic</li>
                      <li>Open Knowledge Foundation</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How much does a Django website cost?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      This is a great question – because, truthfully, websites
                      range in cost from a couple of hundred dollars (USD) to
                      five or more figures! Django is open-source software, so
                      if you have the expertise, you could even use the
                      framework to build your site yourself. But most look to
                      hire a Django developer, which runs anywhere from $30 per
                      hour up.
                    </p>
                    <p className="text-[20px] font-light">
                      The best thing to do is put together your project wish
                      list and contact us for more information on pricing.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      What is Django mainly used for?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Django is perfect for web and mobile application
                      development. It’s particularly well-suited for complex or
                      enterprise projects with loads of user interactions, heavy
                      traffic, advanced functions or technology (i.e., machine
                      learning, IoT), and/or high-volume content.
                    </p>
                    <p className="text-[20px] font-light">
                      That said, it works great for smaller projects, too – and
                      best of all, it’s easy to maintain and scale as your
                      business grows.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={ascend1}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      HubpixHubpix Case Study We Develop Platforms That Drive
                      Activation & Grow Sales
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Check out how our seasoned researchers and engineers
                    designed a unique platform that connects brands with
                    independent retailers using leading-edge cloud and mobile
                    strategies.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={flimskillm}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      AdvanceAdvance Case Study We Build World-Class Apps on
                      Startup Budgets
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we used Django to power the back-end portal for an
                    ambitious Spanish-language app startup, delivering an MVP on
                    a tight timeframe and limited budget.
                  </p>
                  <img
                    src={GWA1m}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Develop Bespoke Platforms That Empower Better Client
                      Outcomes
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how we developed a custom decision support engine
                    that empowers the staff of a healthcare company to easily
                    and quickly write, change, and test rules that facilitate
                    patient data.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    CLOUD SERVICES,  PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    A Guide To Cloud-Native Application Development
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    MVP DEVELOPMENT,  PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    How to Prioritize Features for Your Minimum Viable Product (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    7 Steps to Build an Effective Application Modernization Roadmap
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Django;
