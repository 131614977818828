import {
  softwaredevelopment,
  EMR,
  FMCGGiant,
  WooClubm,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment4,
  softwaredevelopment5,
  softwaredevelopment6,
  softwaredevelopment7,
  Hiring,
  GoogleCloudSQL,
  Digital_tra2,
  Pythonwebdevelopmentimage,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
import Slider from "react-slick";
import Select from "react-select";
const Python = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C]  max-sm:text-[20px] max-sm:text-center">
                PYTHON WEB & SOFTWARE DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%]  max-sm:text-[20px] max-sm:text-center">
                Build a Successful Digital Business with Custom Python Web
                Development Services
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%]  max-sm:text-[20px] max-sm:text-center">
                Grow your business with robust, high-performance and
                user-centric apps and websites. Rapidsofts helps your brand
                reach consumers and engage them in a meaningful way with
                scalable development for small businesses and enterprises.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black  max-sm:text-[20px] max-sm:text-center">
                Looking for Python Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={Pythonwebdevelopmentimage} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  text-2xl">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2  max-sm:text-[20px] max-sm:text-center">
                <b>Custom Python Web Development Services</b>
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                There's no such thing as one-size-fits-all in the world of
                software. Every brand and every user base has unique needs.
                Growing businesses need platforms that they can depend on to run
                well day in and day out. If you look under the hood of some of
                the top sites and apps, you'll quite often find they're powered
                by Python.
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                From academia to machine learning, Software-as-a-Service and
                high-performance web portals, Python is capable of powering it
                all. Our enterprise-level Python development solutions are
                tailor-made for your business.
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                If you think our Python software development services can help
                your brand, contact us today for a free consultation with the
                development team.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Services
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Explore Technology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="">
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Full-cycle Python Web Development
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                At Rapidsofts, we offer full life cycle Python development
                services, including creating engaging mobile apps, data
                analytics and aggregation tools, building feature-rich websites
                and designing web crawlers for collecting information for almost
                any industry. We're proud of our innovative Python-driven work
                that pushes the limits of what technology can do, helping our
                clients gain a competitive edge.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                As a leading Python web development company, we work with
                frameworks and tools such as Django, Flask, Bottle, and Tornado,
                creating portable and scalable solutions for your business.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <dv className="">
            <p className="sm:text-6xl sm:mt-[100px] max-sm:mt-[50px] max-sm:text-[20px] max-sm:text-center">
              <b>Services</b>
            </p>
          </dv>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment1} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                  Web Applications
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Our scalable Python software development services use the
                  latest frameworks to create software solutions that give end
                  users the best possible experience. Our goal is to capture the
                  attention of your users and engage them in a positive manner,
                  building lasting loyalty.
                </p>
                <p className="mt-4 sm:text-2xl  max-sm:text-[20px] max-sm:text-center">
                  Python is a powerful programming tool, but good tools alone
                  won't create an excellent end user experience. That's why our
                  Python web development company includes highly talented UI/UX
                  developers with many years of experience to create smooth,
                  engaging and positive experiences for your customers.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Integrating Other Microsoft Platforms
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                In addition to working with custom software developed with the
                ASP.NET platform, Rapidsofts can integrate other platforms
                that deliver standard functions, such as enterprise resource
                planning or business management. Our expert ASP Net web
                development services empowers us to build your software
                ecosystem to include custom solutions as well as Microsoft
                Business Solutions platforms like Microsoft Dynamics CRM and the
                Sharepoint collaborative document management software.
              </p>
              <p className="sm:text-[22px] font-light mt-11  max-sm:text-[20px] max-sm:text-center">
                These platforms can be configured for many different business
                cases and we can make sure that they deliver the functionality
                you want and work with your other applications to share data as
                needed. We use powerful Microsoft tools and components to make
                sure your applications function as an integrated, streamlined
                software ecosystem.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment2} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment3} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                  Python Web Development Services and APIs
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Boost your iOS and Android projects with simple and
                  high-performance integrations or increase the value of your
                  enterprise back end systems with CRMs and ERPs to allow you to
                  better understand the data your users provide. Our Python
                  development services go beyond simple front ends, including
                  sophisticated APIs that you can either use to drive decisions
                  in your business or offer up to customers to support their own
                  integrations.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Cloud-based and SaaS Development
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                The business model of supplying Software-as-a-Service has too
                much potential to ignore. For those who get it right, there
                waits the reward of reliable revenue and customer loyalty for
                many years to come. As a custom Python web development company,
                we have over a decade of experience in building custom SaaS
                solutions for enterprises and start-up clients. Our Python
                technology team uses this robust and scalable language to create
                solutions that are easy to maintain, versatile and efficient.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment4} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment5} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                  Ongoing Support and Maintenance
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  In the world of software development, projects are always
                  evolving. Our Python software development services include
                  ongoing maintenance and support, from troubleshooting,
                  proactive security updates, and upgrades to day-to-day
                  management. If you need new features or improvements, our
                  developers are always happy to help.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Python Migrations
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                If you have an older stack or an old app that was developed in a
                legacy language and you're concerned about ongoing support,
                performance or viability, be sure to contact us. Our Python
                development services include migrating your data and apps from
                your existing tech stack to Python, allowing you to get more
                from your data.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment6} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment7} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                  AI and Machine Learning
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Machine learning can offer invaluable insight into the way
                  your customers think and engage with your brand, allowing you
                  to spot trends that may otherwise be overlooked. AI and
                  machine learning can be used to find problems in your
                  production line, identify more efficient ways of managing your
                  supply chain, reduce fraud and waste and generally improve
                  your business. Python is the foundation of many AI and machine
                  learning technologies.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <p className="sm:text-7xl sm:w-[90%] m-auto  max-sm:text-[20px] max-sm:text-center">
              <b>
                Common FAQs about Working With Rapidsofts for Python Web
                Development
              </b>
            </p>
            <div className=" mt-[50px]">
              <div className="sm:sm:w-[80%] m-auto">
                <Accordion className="mb-5 max-sm:mt-[-100px]">
                  <div>
                    <Accordion.Item eventKey="0" className="">
                      <Accordion.Header>
                        <h5 className="sm:text-[26px]  max-sm:text-[20px]">What is Python?</h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="text-[20px] font-light text-3xl">
                          Python is a programming or coding language. It's
                          considered fairly easy to learn for developers and is
                          widely used in many software development projects.
                          It's also used for rapid or agile app development. One
                          of the benefits of Python is that it supports modules
                          and packages, making it easy to build various
                          solutions and then use them as building blocks in
                          final products.
                        </div>
                        <div></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <Accordion.Item eventKey="1" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                        How do you build a website with Python?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        Beginner developers can often create basic websites with
                        Python by pairing it with HTML and CSS. But you don't
                        have to be a coder to build a website or other product
                        with Python. Instead, you can work with an experienced
                        Python web development company such as Rapidsofts to
                        bring your vision to life.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                        Why use Python for web development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        As a custom Python web development company, we favor
                        this particular coding language for a number of reasons,
                        including:
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                        When is Python used for web development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        As a general-purpose language, Python can be used for a
                        variety of web development purposes. Primarily, it's
                        used to code back-end utility components of a website
                        while HTML or JavaScript are used to code front-end
                        components like the user interface.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                        Why hire a Python development company?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        Even though Python is considered a simple coding
                        language, it's still a coding language. To get the best
                        results from a web development project, you need people
                        who know the ins and outs of the language and how to
                        pair it with the right technical solutions to bring your
                        ideas to life. A custom Python web development company
                        can be that resource for you.
                      </p>
                      <p className="text-[20px] font-light">
                        When you outsource web development, you also don't have
                        to tie up internal IT teams for the project. That leaves
                        them free to attend to other business-facing needs.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                        How do you find an ASP.NET development company?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        You can search online, ask a business partner if they've
                        worked with one they liked or check with your IT teams
                        to get some recommendations. Or, you can reach out to
                        Rapidsofts today. We're an ASP.NET development
                        company with plenty of technical resources and know-how
                        to get your project completed.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                        Where to find a Python development company?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        You can find Python development companies online. Make
                        sure to check reviews and referrals and ensure the
                        company has the resources and ability to meet your
                        business needs. Rapidsofts provides Python software
                        development services to companies worldwide. Our Python
                        development teams work closely with you to ensure the
                        success of your projects, so don't hesitate to reach out
                        for more information.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="sm:w-[90%] m-auto">
              <div className="sm:mt-[100px] max-sm:mt-[50px]">
                <p className="sm:text-6xl  max-sm:text-[20px] max-sm:text-center">
                  <b>Success Stories</b>
                </p>
              </div>
              <div className="sm:flex">
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={FMCGGiant}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      <b>Building Digital Empowerment in Rural India</b>
                    </p>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      We developed an app for a well-known brand that sells
                      fast-moving consumer goods that has asked to remain
                      anonymous. The app was designed to empower women
                      entrepreneurs living in rural India. It was based on
                      Python, PostgreSQL and Java for Android, ensuring that it
                      would run on low-end Android phones.
                    </p>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      Using the app, women living in rural villages were able to
                      sell products from their homes and manage their inventory,
                      ads and billing. The company had already tried to launch
                      such an app once before, but they found that it had a low
                      adoption rate. They came to Rapidsofts to identify
                      problems with the existing app. You can read more about
                      the issues we found and how we solved them in this case
                      study.
                    </p>
                  </div>
                </div>
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={WooClubm}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      <b>Shoppable Video Content</b>
                    </p>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      Two Worlds Unite Corp asked us to develop a shoppable
                      video content platform to allow retailers, brands and
                      individuals to promote their goods. The app was powered by
                      Python, ran on AWS and was available for iOS and Android.
                      It offered a unique way for participants to tag videos of
                      their product on a scene by scene basis, increasing
                      interest in goods and driving sales.
                    </p>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      Implementing the video tagging features and integrating
                      them with a multi-language, multi-currency sales platform
                      was not easy, because there's no direct method to create
                      thumbnails using the YouTube API. Using our Agile
                      methodology, we were able to find innovative solutions,
                      creating a robust and scalable platform that supports a
                      high number of users while maintaining good performance.
                      You can learn more about the project in this case study.
                    </p>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      If you would like to know more about our Python
                      development services or have an idea for a project,
                      contact us today to request a free consultation.
                    </p>
                    <img
                      src={EMR}
                      className="mt-5 w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      <b>We Innovate Mobile Solutions for Enterprise Clients</b>
                    </p>
                    <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                      See how we built a mobile platform that streamlines
                      communication between project teams, increases
                      productivity, and empowers managers to track time used on
                      specific tasks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20  max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Hiring} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={GoogleCloudSQL} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Digital_tra2} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND{" "}
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Python;
