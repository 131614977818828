import React from 'react'
import {
    x_Saas,
    ween_iaas,
    end_software,
    x_business,
    y_image,
    xlanguages,
    saas_business,
    factor_app,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar17 from '../../componets/sidebar/Sidebar17';

const SaaS = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar17 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                SaaS
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${x_Saas})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[30%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                                What is SaaS (Software as a Service)?
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                What is Software as a Service (SaaS)? SaaS is a self-service software that can be accessed through the web and is managed by a third-party vendor. This cloud-based model is the most successful software delivery model today. Before diving into the SaaS concept, it is useful to take a time capsule to the 60s and gain an understanding of how it all started.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">The early time-sharing SaaS model was the IBM 360 Model 67. This was the IBM mainframe that provided processing power to banks and government agencies. These organizations relied on IBM to rent virtual power and space. This service came to be known as time-sharing.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">In the 1970s and 1980s, personal computers gained popularity and organizations decided to invest in on-premise applications that came in the form of CD-ROMs and floppy disks. The problems that persisted with this model were the high licensing fees, limited to no updates, and minimal tech support.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">The next evolution was the SaaS’s forerunner, application service provider or ASP, that gained attention around 1996. The ASP model provided access to a software application over a network (through HTTP). Looks similar to SaaS, right?</p>
                            <p className="text-[27px] font-sans	font-light mt-4">However, ASP is based on the single-tenant architecture (i.e., a single instance of a software application serves only one customer). Moreover, the vendor needs to manually set up the login and environment, which eventually led to its adoption failure.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">Finally, 1999 marked the entry of the first pure customer relationship management (CRM) SaaS product — Salesforce. The company built its SaaS product from scratch and popularized it with the marketing statement, <span className='font-bold'>“The End of Software.”</span></p>
                        </div>
                        <div className='mt-5'>
                            <img src={end_software} className="w-full" />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is SaaS in Cloud Computing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">SaaS stands for software-as-a-service, a cloud computing model for the delivery of software on an on-demand basis. The end-user does not need to install the software application on their computer system. Instead, they can access the software through the internet at any time, from anywhere.</p>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>But, what is the cloud computing model?</span></p>
                            <p className="text-[27px] font-sans font-light mt-5">The word cloud is a metaphor for the internet, and the cloud-like shape symbolizes a distributed virtual network. Cloud computing helps avail computing resources on-demand (such as computing power and storage space).</p>
                            <p className="text-[27px] font-sans font-light mt-5">In the case of a SaaS application, the cloud acts as a central point from where software can travel to many locations at the same time to offer a virtual service.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={x_business} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                SaaS vs On-Premise Applications
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Before moving on to <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>develop a SaaS application</span>, let us understand the difference between SaaS and on-premise.</p>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>SaaS Application:</span> This is a cloud-based software that is developed and maintained by a third-party vendor. SaaS applications support a multi-tenant architecture (i.e., a single instance of the software is shared across the server and serves multiple users).</p>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>On-Premise Application:</span> This is a bespoke software customized according to the organization’s needs and is private to them (i.e., it is not shared). On-premise applications can be built by the in-house team or an outsourcing partner can help in the development process.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Refer to the table below for a parameter-wise comparison between SaaS and on-premise:</p>
                        </div>
                        <div className='mt-5'>
                            <table className="table table-bordered table-light">
                                <thead>
                                    <tr className=''>
                                        <th className='text-[21px] !px-2 py-2 !bg-[#FEE75B] w-[430px] text-center'>Parameter</th>
                                        <th className='text-[21px] !px-2 py-2 w-[450px] !bg-[#CEF0FF] text-center'>On-Premise</th>
                                        <th className='text-[21px] !px-2 py-2 w-[450px] !bg-[#F8A2A1] text-center'>SaaS</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Definition</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Customized software that is private to an organization. On-premise is also known as enterprise software.</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>A common software solution that is deployed on the server by the vendor and available to many.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Hosting</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>On-premise servers or third-party hosting providers such as AWS, Azure, and GCP</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Managed by the software vendor</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>TOC (Total Cost of Acquisition)</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF] '>
                                            <p className='text-[20px] font-light font-sans'>One-time development fees, ongoing maintenance fee</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Monthly, bi-annual, or annual fee based on SaaS solution’s pricing model</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#FEE75B]'>
                                            <div className=''>
                                                <p className='text-[20px] font-light font-sans'>Control</p>
                                            </div>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#CEF0FF]'>
                                            <p className='text-[20px] font-light font-sans'>Full control over the software</p>
                                        </td>
                                        <td className='text-[px] !px-8 py-2 text-[20px] !bg-[#F8A2A1]'>
                                            <p className='text-[20px] font-light font-sans'>Less control as everything is managed by a third-party provider</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is the Difference Between SaaS, PaaS, and IaaS
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">SaaS, IaaS, and PaaS are the three models of cloud computing. Here is how they are different from each other:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">IaaS (Infrastructure as a service):</strong>IaaS is a cloud-based model where the vendor manages the operating system needs such as virtualization, storage, networking, and server space.</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">PaaS (Platform as a Service): </strong>PaaS is a cloud-based model where the vendor manages the hardware and software resources on its own infrastructure. Used mainly by developers, a PaaS solution allows them to develop, run, and manage the applications easily.</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">SaaS (Software as a Service):</strong>SaaS is a cloud-based model where the vendor manages the entire application. The users can directly gain access to the software and enjoy its benefits from their web browsers.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={ween_iaas} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Vertical SaaS vs Horizontal SaaS
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The target audience helps define the type of SaaS product a business should build. That is, will it be a vertical SaaS or a horizontal SaaS product.</p>
                            <p className="text-[27px] font-sans font-light mt-5">For a better understanding, here’s a difference between the two:</p>
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>Vertical SaaS:</span> If the target audience belongs to a specific industry, such as healthcare, banking, or retail, the business will have a vertical SaaS model. This software solution has a narrower market and only solves problems for that particular industry.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Vertical SaaS is often the only option for organizations dealing with critical data. For instance, banks rely on vertical SaaS due to compliance and regulation constraints.</p>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>Example:</span> Clio, a vertical SaaS solution for legal practice management, or Textura for construction management business</p>
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>Horizontal SaaS:</span> A common SaaS solution that solves problems of various industries is called a horizontal SaaS model. This software solution has a broader market and offers a common solution for all (irrespective of the industry type).</p>
                            <p className="text-[27px] font-sans font-light mt-5"><span className='font-bold'>Example:</span> userProfile.data.tutorial_status</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                The SaaS Architecture
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Software as a service is based on a multi-tenant architecture. In this architecture, a single instance of software is shared across the server, and several tenants (users) can access it from anywhere and at any time.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>Multi-tenancy is a requirement for a SaaS vendor to be successful.</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-[27px] font-sans font-light mt-5">In a multi-tenant setup, the different customers are unaware of each other’s data as it is stored exclusively across dedicated databases. Moreover, the customers can customize the SaaS solution at their end without affecting others’ views and settings.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={y_image} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Advantages of SaaS
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The benefits of SaaS are different for vendors and customers. The SaaS benefits from both perspectives are discussed below:</p>
                            <p className="text-[27px] font-sans font-light mt-5">For a business investing in SaaS:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'><span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Gartner estimates</span> that worldwide end-user spending for SaaS would be around $171,915 million in 2022.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>According to a recent <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Statista survey</span>, 38% of respondents believe that SaaS plays a significant role in achieving their business goals. This means SaaS is going to play a significant role in the way forward.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Offers higher revenue over time (if there is <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>product-market fit</span>)</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Offers competitive edge (if there is disruption to the market or a better value proposition is offered compared to competitors)</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans font-light mt-5">For the end-user:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Anywhere, anytime access</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Flexible pricing (pay-as-you-go-basis)</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Ability to solve the core problems</li>
                                <li className='text-[24px] font-sans font-light mt-4'>walkthrough</li>
                                <li className='text-[24px] font-sans font-light mt-4'>No maintenance burden</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Easy scalability</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            SaaS Challenges + Solutions
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The demand for SaaS is high, which means that many small-scale and large-scale businesses are investing in building SaaS applications. However, most of them are failing to create a lasting impression.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>An estimated 92% of SaaS companies fail within 3 years despite growth and funding.</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-[27px] font-sans font-light mt-5">Several bricks go into building a robust wall. The same is the case with a SaaS product. If a business does not work cautiously on building the foundation, the wall is likely to fall.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Here are some reasons why SaaS products fail and the corresponding solutions to overcome the challenges at hand.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Missing Product-Market Fit</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A product-market fit is achieved when a business creates a product that solves the problems of their target audience. If there is a need and the product manages to address it, the business has achieved a product-market fit. In such a case, their customers become their marketers, who endorse the product through word of mouth.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Failure is evident if the business fails to address the problems and develop a SaaS solution that is not pragmatic.</p>
                            <p className='text-[24px] font-bold mt-4'>Solutions:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Research the market. Before coming up with a SaaS idea, understand the needs and problems of the target audience. Brainstorm around ideas and create a list that seems promising.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Analyze existing SaaS businesses in the market to see if they already have a product based on a short list of ideas. Also, analyze where the existing solutions lack so that the new product can offer a better experience.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>For new ideas, create a proof of concept to check the technical feasibility of the idea.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Create discussion groups, a combination of development teams, stakeholders, and limited test users to validate the need for a specific SaaS product in the market.</li>
                            </ul>
                            <p className='text-[24px] font-sans	font-light mt-4'>Finalize the idea that would make a difference after running a vote across the discussion groups.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Great Product, No Marketing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Most SaaS businesses follow an approach — (1) Develop a product, (2) agile test that product, (3) launch the product into the market with a launch plan that includes announcing it on Twitter or other social media channels and waiting for it to be the next big thing in the market.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Unfortunately, it does not work that way. Instead, the ideal approach is to adopt the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>product mindset over a project mindset</span>. Marketing is equally important because, without effective marketing initiatives, even a great product is likely to go unnoticed.</p>
                            <p className='text-[24px] font-bold mt-4'>Solutions:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Create a SaaS marketing plan by looping in marketing, sales, customer service reps.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Focus on SEO wherever there is content. The better the Google rank, the better the chances of conversions. A business can also spend on Google AdWords for better reach.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Publicize SaaS offering through social media posts, email campaigns, blog posts, guest posts, etc. Businesses can also try influencer marketing, which works wonders nowadays.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>To gather eyeballs on the product, offer free trials to the customers. Urge them to try the product and receive valuable results. The pitch should be talking about how the new product will make a difference in the user’s life.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Maintain a healthy relationship with the early adopters. If they stick to the product initially, send them a gift. This is something that Freshbooks does. This SaaS company sends a cake to its loyal customers to maintain an ongoing relationship.</li>

                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Inability to Respond to Change</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The inability to showcase agility skills when responding to change leads to product failures. For instance, a business launched a SaaS product a month ago, and they receive feedback that they fail to respond and iterate through — failure will be the result.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>If the business responds to feedback but spends a lot of time iterating through the changes, failure is yet again evident. In short, they need to showcase agility skills to stay relevant and valuable.</p>
                            <p className='text-[24px] font-bold mt-4'>Solutions:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Build an MVP before moving to the full-scale product. An MVP that presents its core functionalities should be the goal. This can be a good starting point for gathering loyal customers and honest feedback to start revisions.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Emphasize SaaS application development by following the microservices architecture (i.e., smaller modules of the bigger application that are loosely coupled). This makes it easier to iterate through the suggested changes as the change to a particular module does not affect the other modules’ functionality. Thus, less downtime.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Maintain a lower amount of cruft in the code (i.e., the unwanted parts of the code). The lower the cruft, the lower the technical debt.</li>
                            </ul>
                            <p className='text-[24px] font-sans	font-light mt-4'>Most importantly, give it time. Overnight success is a myth; do not fall for it. Instead, drive constant efforts towards: Launch. Market. Gather Feedback. Iterate.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            11 Point Checklist to Ensure SaaS Success
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">To succeed in a SaaS-triggered digital transformation initiative, there are a few best practices that align well with the changing times and needs.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Here is a glimpse into some of them:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>The focus should be to create a PoC followed by a prototype and then an MVP.<span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'> Learn about PoC vs Prototype</span> vs MVP</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Create interactive advisory videos for the customers to help them understand the working of the UI and other noteworthy benefits.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Offer distinct and affordable pricing models that are better than the competitors. These offerings should be easy to understand, as complex pricing models can put off potential customers.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Work on enhancing the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>experience design</span> across touchpoints to eliminate churn rate. These days the end-to-end customer experience of most of the SaaS products seems disconnected. A business can make a difference here by offering seamless sign-ups and intuitive and responsive UX.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Analyze the business’ product portfolio from time to time. Make informed decisions by filtering out product offerings and services that offer little to no value and show no signs of growth. According to McKinsey, SaaS vendors that manage their portfolios achieve enterprise-value-to-revenue multiples twice those of passive portfolio managers.</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Offer integration capability for the SaaS product as this can help to offer an omnichannel experience to the target customers.</li>   
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Conclusion
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">SaaS stands for software as a service, a public cloud model that delivers software through the internet on a subscription basis. The evolution of SaaS through the years is noteworthy. The subscription-based SaaS model is gaining traction, and the current crisis driving businesses to adopt more remote and technological options is putting more wind in the sails for the software industry.</p>
                            <p className="text-[27px] font-sans font-light mt-5">A report by Gartner predicts that SaaS revenue will grow to nearly $143.7 billion by 2022. This brings a great opportunity to bank upon SaaS as a business model for generating high-value revenue streams.</p>
                            <p className="text-[27px] font-sans font-light mt-5">This write-up is a comprehensive guide on SaaS that answers everything that is necessary to know before venturing into a SaaS business.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={factor_app} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  SAAS APPLICATION DEVEL.....</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    11 Most Popular Programming Languages for 2023</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={saas_business} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  SAAS APPLICATION DEVEL.....</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    SaaS Business Model: A Beginner Tech Entrepreneur’s Guide for 2023</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={xlanguages} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  SAAS APPLICATION DEVEL.....</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    What is the 12-Factor App Methodology? </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaaS