import React from 'react'
import { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";


const Sidebar3 = () => {
    const [open, setOpen] = useState("");

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    return (
        <>
            <div className='static'>
                <Fragment>
                    <Accordion open={open === 1}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(1)}>
                                <h5 className="text-[21px] font-bold"> Digital Transformation</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div className='pl-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Introduction</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Digital Transformation?</Link>
                            </div>
                            <div className='pl-4 mt-4 flex'>

                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Why Does Digital Transformation Matter?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">F.O.C.U.S. — The Digital Transformation</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">The ‘5 Ds’ Driving The Digital Transformation</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">The ROI Of Digital Transformation</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Digital Transformation Across Various Industries</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Parting Words</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Top Data Analytics Trends for 2022</Link>
                            </div>

                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 2}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(2)}>
                                <h5 className="text-[21px] font-bold">Digital Commerce</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">B2B eCommerce</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-3'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Magento</Link></p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 3}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(3)}>
                                <h5 className="text-[21px] font-bold">Product Development</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] '>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3 '><Link to={""} className="hover:text-[#F13623]">Agile Software Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Cloud Computing</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Custom Software Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">DevOps</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Minimum Viable Product</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Mobile App Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Outsourcing</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Software as a Service (SaaS)</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Software Testing</Link></p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 4}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(4)}>
                                <h5 className="text-[21px] font-bold">Experience Design</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody>
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">User Experience</Link></p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 5}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(5)}>
                                <h5 className="text-[21px] font-bold">Digital Experience Platforms</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody>
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Customer Relationship Management (CRM)</Link></p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="text-[21px] font-bold"><Link to={""} className="hover:text-[#F13623]">Data Analytics & Insights</Link></h5>
                    </div>
                </Fragment>
            </div>
        </>
    )
}

export default Sidebar3