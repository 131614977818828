import React from 'react'
import { retail_app, shopping_mall, trends_tablet, solution_sales, solution_inventory, purchases_technology, green_dividers, zzimg, ximg, gagan, ecp_image, work_image, zxu, gwa_img, konga_image, Hubpx, trish, gaimg, tabhi } from '../../assets';

const RetailECommerce = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className="sm:flex">
                    <div className="sm:w-[60%] max-sm:w-[100%]">
                        <div className="sm:mt-[170px] max-sm:mt-[50px]">
                            <h1 className='sm:text-[22px] text-[#F13623] tracking-widest font-extrabold  max-sm:text-[20px] max-sm:text-center'>RETAIL APP DEVELOPMENT</h1>
                            <h1 className='sm:text-[77px] font-black  max-sm:text-[20px] max-sm:text-center'>Delight Your Customers with Innovative Digital Retail Solutions</h1>
                            <p className='sm:text-[33px] font-extralight  max-sm:text-[20px] max-sm:text-center'>Go beyond the cart with full-spectrum retail app development for superior online shopping experiences.</p>
                        </div>
                    </div>
                    <div className="sm:w-[40%] max-sm:w-[100%]">
                        <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[50px] sm:w-[450px]">
                            <h1 className="sm:text-[30px] font-black  max-sm:text-[20px] max-sm:text-center">Need Help with Development?</h1>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                                    id="username"
                                    type="text"
                                    placeholder="Name*"
                                />
                            </div>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder="Email*"
                                />
                            </div>
                            <div className="flex mt-2">
                                <div className="w-35%">
                                    <select
                                        id="countries"
                                        className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option selected>Choose a country</option>
                                        <option value="US">India</option>
                                        <option value="CA">Canada</option>
                                        <option value="FR">France</option>
                                        <option value="DE">Germany</option>
                                    </select>
                                </div>
                                <div className="w-[65%] ml-3">
                                    <input
                                        className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="phone"
                                        type="phone"
                                        placeholder="+91"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <textarea
                                    id=""
                                    row="5"
                                    className="w-full px-3 h-20 py-2"
                                    placeholder="Message*"
                                ></textarea>
                            </div>
                            <div className="mt-3 mb-16">
                                <button
                                    type="button"
                                    className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                                >
                                    Get In Touch
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='bg-[#F9D94E] sm:w-[95%] max-sm:w-[100%] ml-auto sm:px-32 sm:pt-24 mt-5 flow-root'>
                <div>
                    <img src={retail_app} />
                </div>
                <div>
                    <div className='border-l-8 border-sky-400 relative top-[-69px] ml-5'>
                        <div className='sm:pl-8'>
                            <p className='pt-28 font-black text-[20px] text-[#F13623] tracking-widest  max-sm:text-[20px] max-sm:text-center'>THE Rapidsofts APPROACH</p>
                        </div>
                    </div>
                    <div className='sm:px-16 max-sm:px-8 top-[-64px] sm:w-[60%]'>
                        <h5 className='sm:text-[46px] font-extrabold mt-[-60px]  max-sm:text-[20px] max-sm:text-center'>Data-forward, Digital-first Retail and eCommerce Solutions</h5>
                        <p className='sm:text-[25px] font-light  max-sm:text-[20px] max-sm:text-center'>Today’s shoppers are savvy: with a slew of information at their fingertips, they make decisions in split seconds. For more than 20 years, we’ve been at the forefront of online shopping development, helping retailers leverage the power of IT to automate, personalize, and optimize every point along the customer journey. With full-stack development capabilities and expert UX/UI specialists, we create bespoke digital solutions for retail and eCommerce stores from scratch or customize leading platforms like Magento and Shopify.</p>
                        <div className='max-sm:text-center'>
                            <button
                                type="button"
                                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] mt-5 hover:bg-[#F13623] hover:text-white"
                            >
                                Explore Services
                            </button>
                            <span className="ml-7 mt-5">
                                <button
                                    type="button"
                                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white mb-16"
                                >
                                    Request a Quote
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mx-auto mt-5'>
                <div className='sm:pt-4'>
                    <h5 className='font-bold sm:text-[55px]  max-sm:text-[20px] max-sm:text-center'>Retail App Development Services</h5>
                </div>
                <div className='sm:flex flex-row sm:pt-20 max-sm:pt-10'>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <h5 className='font-semibold sm:text-[32px]  max-sm:text-[20px] max-sm:text-center'>Retail Software Development</h5>
                        <p className='font-light sm:text-[21px] mt-4  max-sm:text-[20px] max-sm:text-center'>Digital transformation is the secret sauce in making every shopping experience optimal, whether online or in-store. We design and deploy omnichannel infrastructure using state-of-the-art processes and technology for enterprise retail eCommerce solutions that personalize interactions across connected channels for better sales, ROI, and retention.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={shopping_mall} className="sm:h-[502px] max-sm:mt-4" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[50%] max-sm:w-[100%]'>
                        <img src={trends_tablet} className="h-[495px]" />
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
                        <h5 className='font-semibold sm:text-[32px]  max-sm:text-[20px] max-sm:text-center'>Marketplace & eCommerce Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4  max-sm:text-[20px] max-sm:text-center'>With fans hungry for your content and merchandise, you need a high-performing, mobile-first software solution that seamlessly converts buyers to browsers. We help you build your B2B, B2C, or DTC business by customizing one of the leading eCommerce platforms, including Shopify, Magento, and Drupal, or by engineering a bespoke product or platform aligned with your business objectives and audience needs.</p>
                        <button
                            type="button"
                            className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[40%] max-sm:w-[100%] mt-4'>
                        <h5 className='font-semibold sm:text-[32px]  max-sm:text-[20px] max-sm:text-center'>CRM & ERP Integrations</h5>
                        <p className='font-light text-[21px] mt-4'>Catering to your customers with a rich interactive experience while automating and optimizing your workflows brings your business the best of all worlds. We specialize in customized integrations, including CRM, ERP, and CMS, that help your organization sell more products/services faster and more efficiently.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={solution_sales} className="h-[502px] max-sm:mt-4" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[50%] max-sm:w-[100%]'>
                        <img src={solution_inventory} className="sm:h-[495px]" />
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
                        <h5 className='font-semibold sm:text-[32px]  max-sm:text-[20px] max-sm:text-center'>Supply Chain Management Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4  max-sm:text-[20px] max-sm:text-center'>Your online business' success depends on the speed, accuracy, and strength of your supply chain. We help you take control of your eCommerce inventory on-the-go, with customized, feature-rich solutions for managing transportation, warehousing, orders, vendor/suppliers, and reverse logistics.</p>
                        <button
                            type="button"
                            className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
                <div className='sm:flex flex-row pt-20'>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <h5 className='font-semibold sm:text-[32px]  max-sm:text-[20px] max-sm:text-center'>Proximity Marketing/Sales Solutions</h5>
                        <p className='font-light sm:text-[21px] mt-4  max-sm:text-[20px] max-sm:text-center'>Marketing to your customer at the right place and right time with relevant, personalized interactions is a critical business driver. Our expert team of marketing pros and eCommerce developers are experienced in designing end-to-end, location-aware solutions based on GPS, NFC, BLE beacons, and QR codes that feed smart retail intelligence and fuel sales.</p>
                        <button
                            type="button"
                            className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                    <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
                        <img src={purchases_technology} className="sm:h-[502px] max-sm:mt-4" />
                        <button
                            type="button"
                            className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
                        >
                            Request a Quote
                        </button>
                    </div>
                </div>
            </div>
            <div className='sm:flex  pt-20 sm:w-[79%] mx-auto max-sm:p-5'>
                <div className="sm:ml-3">
                    <div className="">
                        <img
                            src={trish}
                            className="w-full object-cover"
                        ></img>
                    </div>
                </div>
                <div className="sm:ml-3">
                    <div className="">
                        <img src={gaimg} className=" w-full object-cover"></img>
                    </div>
                </div>
                <div className="sm:ml-3">
                    <div className="">
                        <img
                            src={tabhi}
                            className="w-full object-cover"
                        ></img>
                    </div>
                </div>
            </div>
            <div className='container mx-auto mt-5'>
                <h5 className='sm:text-[56px] font-bold  max-sm:text-[20px] max-sm:text-center'>Success Stories</h5>
                <div className='sm:px-16 max-sm:px-8'>
                    <div className='sm:flex flex-row mt-5'>
                        <div className='mt-5 sm:w-[45%] max-sm:w-[100%]'>
                            <div>
                                <img src={ecp_image} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623]  max-sm:text-[20px] max-sm:text-center'>We Help Omnichannel Retailers Win Awards — and More Business</h5>
                                <p className='sm:text-[20px] font-light mt-4  max-sm:text-[20px] max-sm:text-center'>Learn how we engineered a multi-channel eCommerce solution and CMS for a top IRUK car parts retailer, improving search speed, automation, supply chain logistics, and sales.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={work_image} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623]  max-sm:text-[20px] max-sm:text-center'>We Create Award-winning Digital Experiences for Global Brands</h5>
                                <p className='sm:text-[20px] font-light mt-4  max-sm:text-[20px] max-sm:text-center'>Explore how we helped IMG Worldwide win the Kentico Site of the Year Award for the “Run to the Beat” website by leveraging Kentico’s best features, including friendly UX, .NET compatibility, and fast development.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={zxu} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623]  max-sm:text-[20px] max-sm:text-center'>We Build Global eCommerce Solutions that Scale & Grow Businesses</h5>
                                <p className='sm:text-[20px] font-light mt-4  max-sm:text-[20px] max-sm:text-center'>Learn how we built an unparalleled, personalized user experience across multiple websites, devices and countries for an international sportswear company.</p>
                            </div>
                        </div>
                        <div className='mt-5 sm:w-[45%] max-sm:w-[100%] ml-auto'>
                            <div className=''>
                                <img src={gwa_img} />
                            </div>
                            <div className='mt-4 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623]  max-sm:text-[20px] max-sm:text-center'>We Up-level Digital Customer Experiences Across Multiple Properties</h5>
                                <p className='sm:text-[20px] font-light mt-4  max-sm:text-[20px] max-sm:text-center'>See how we helped a trusted name in Australia’s building and construction sector reinvent their cross-platform eCommerce experience with a mobile-friendly architecture redesign and legacy upgrades.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={konga_image} />
                            </div>
                            <div className='mt-5 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623]  max-sm:text-[20px] max-sm:text-center'>Ecommerce App Based on Magento</h5>
                                <p className='sm:text-[20px] font-light mt-4  max-sm:text-[20px] max-sm:text-center'>Explore how we helped a leading global online marketplace increase its revenue by 450% with use-centric iOS and Android apps.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={Hubpx} />
                            </div>
                            <div className='mt-5 sm:w-[500px]'>
                                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623]  max-sm:text-[20px] max-sm:text-center'>We Support Retailers in Improving the In-store Experience for Brands & Customers</h5>
                                <p className='sm:text-[20px] font-light mt-4  max-sm:text-[20px] max-sm:text-center'>Discover how we developed a unique platform that connects brands with 22K+ independent retailers and empowers both ends of the chain to improve in-store promotions and sales.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <h5 className='sm:text-[44px] font-extralight text-center  max-sm:text-[20px] max-sm:text-center'>Have a Sports or Fitness App in Mind?</h5>
                    <div className='sm:w-[160px] mx-auto'>
                        <button
                            type="button"
                            className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RetailECommerce