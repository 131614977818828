import React from 'react'
import { fina_banner, dig_wallet, custom_web, lend_solutions, ux_servces, dig_transfrmtn, green_dividers, imac_computer, user_experience, together_macbook, private_b, sadara_bg,tanada,Clutch_1, g21, goodfirms1,fin_development } from '../../assets';
import Sidebar6 from '../../componets/sidebar/Sidebar6';

const BankingFinancial = () => {
  return (
    <>
      <div className='container mx-auto'>
        <div className="flex">
          <div className="w-[60%]">
            <div className="mt-[170px]">
              <h1 className='text-[22px] text-[#F13623] tracking-widest font-extrabold'>EXPERT FINANCIAL SOFTWARE DEVELOPMENT</h1>
              <h1 className='text-[77px] font-black'>Customer-Focused Digital Transformation for Banks & Financial Institutions</h1>
              <p className='text-[33px] font-extralight'>Improve user experiences, operational efficiency, and profitability with custom digital solutions.</p>
              <ul className="flex mt-5 gap-4">
                <li>
                  <img src={Clutch_1} className="" />
                </li>
                <li>
                  <img src={g21} className="" />
                </li>
                <li>
                  <img src={goodfirms1} className="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="w-[40%]">
            <form className="bg-[#F9D94E] shadow-2xl px-16 pt-9 pb-8 mb-4 float-right mt-[150px] w-[450px]">
              <h1 className="text-[30px] font-black">Looking for Banking & Financial Software Development?</h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='bg-[#F9D94E] w-[95%] ml-auto px-32 pt-24 mt-5'>
        <div>
          <img src={fina_banner} />
        </div>
        <div>
          <div className='border-l-8 border-sky-400 relative top-[-69px] ml-5'>
            <div className='pl-8'>
              <p className='pt-28 font-black text-[20px] text-[#F13623] tracking-widest'>THE Rapidsofts APPROACH</p>
            </div>
          </div>
          <div className='px-16 top-[-64px] w-[60%]'>
            <h5 className='text-[46px] font-extrabold mt-[-60px]'>FinTech App Development Services Tailored to Your Unique Needs</h5>
            <p className='text-[25px] font-light'>The digital financial services space is continuously evolving, which presents challenges and opportunities. As an experienced financial software development company, we focus on creating user-friendly, compliant solutions from digital wallets to custom web and mobile apps that support intuitive, secure transactions for your customers and efficient, productivity-enhancing, worry-free systems for your team. For your peace of mind, our certifications include ISO 9001, ISO 27001, PCI, and TISAX.</p>
            <div className=''>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
              >
                Explore Services
              </button>
              <span className="ml-7 mt-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white mb-16"
                >
                  Request a Quote
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto mt-5'>
        <div className='pt-4'>
          <h5 className='font-bold text-[55px]'>Our Banking & Financial Software Development Services</h5>
          <p className='font-light text-[21px] mt-5'>We provide a wide range of financial services software development options, including:</p>
        </div>
        <div className=' flex flex-row pt-20'>
          <div className='w-[40%]'>
            <h5 className='font-semibold text-[32px]'>Digital Wallet (eWallet) Development</h5>
            <p className='font-light text-[21px] mt-4'>The movement to send and receive online payments has accelerated in our post-pandemic world, requiring more sophisticated eCommerce development solutions with simple, intuitive, secure UX and UI. Our experienced team of financial services software development experts provides end-to-end digital wallet app development services, including seamless peer-to-peer (P2) money transfers to digital payments, for scalable, safe payment solutions.</p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className='w-[50%] ml-auto'>
            <img src={dig_wallet} className="h-[502px]" />
          </div>
        </div>
        <div className=' flex flex-row pt-20'>
          <div className='w-[50%]'>
            <img src={custom_web} className="h-[495px]" />
          </div>
          <div className='w-[40%] ml-auto'>
            <h5 className='font-semibold text-[32px]'>Custom Web & Mobile Apps</h5>
            <p className='font-light text-[21px] mt-4'>The finance industry has increasingly relied on web and mobile apps to better serve its clients through convenient, secure apps. We offer a broad range of fintech web and app development services handled by our seasoned team of Android, iOS, and hybrid developers who are experienced in user-friendly design, ironclad data security, and complex integrations for optimal outcomes.</p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className=' flex flex-row pt-20'>
          <div className='w-[40%] mt-4'>
            <h5 className='font-semibold text-[32px]'>Lending Solutions</h5>
            <p className='font-light text-[21px] mt-4'>The days of banks and other large financial institutions as the sole option for borrowing and lending money are over — P2P lending or crowdfunding is one of the hottest growth areas in financial services. As a leading fintech software development company, we are focused on providing our clients with nimble solutions so they can support their customers, such as underserved populations, with faster, reliable, innovative digital services.</p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className='w-[50%] ml-auto'>
            <img src={lend_solutions} className="h-[502px]" />
          </div>
        </div>
        <div className=' flex flex-row pt-20'>
          <div className='w-[50%]'>
            <img src={ux_servces} className="h-[495px]" />
          </div>
          <div className='w-[40%] ml-auto'>
            <h5 className='font-semibold text-[32px]'>UX Services for Better Banking Experiences</h5>
            <p className='font-light text-[21px] mt-4'>Traditional banking services have a reputation for being complicated, unwieldy, and frustrating, but in the age of fintech, banks and other financial institutions have an opportunity to present a much more transparent, trustworthy, client-focused picture. As an</p>
            <p className='font-light text-[21px] mt-2'>enterprise app development company with more than 23 years of UX design experience, we can help you upgrade your customer experience with financial software development tailored to your unique needs.</p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className=' flex flex-row pt-20'>
          <div className='w-[40%]'>
            <h5 className='font-semibold text-[32px]'>Digital Transformation</h5>
            <p className='font-light text-[21px] mt-4'>Making a move to modernize can feel daunting — particularly in industries like financial services, where compliance is essential. We specialize in strategic digital transformation that considers all critical interactions, digital touchpoints, and workflow intricacies to create easy-to-use platforms that maximize efficiencies and accuracy and streamline processes.</p>
            <p></p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className='w-[50%] ml-auto'>
            <img src={dig_transfrmtn} className="h-[502px]" />
          </div>
        </div>
      </div>
      <div className=' flex flex-row pt-20 w-[90%] mx-auto'>
        <div className=''>
          <img src={green_dividers} />
        </div>
        <div className='ml-3'>
          <img src={imac_computer} />
          <img src={user_experience} className="mt-2" />
        </div>
        <div className='ml-3'>
          <img src={together_macbook} />
        </div>
      </div>
      <div className='container mx-auto mt-5'>
        <h5 className='text-[56px] font-bold'>Our Financial Software Development Process</h5>
        <p className='font-normal text-[21px] mt-4'>Every financial software development company has its own approach to the software development life cycle (SDLC). We follow the Agile software development lifecycle for all our custom software development services, including our banking and financial services clients.</p>
        <p className='font-normal text-[21px] mt-4'>Our SDLC has six main steps:</p>
        <div className='w-[70%] mx-auto mt-5'>
          <img src={fin_development} />
        </div>
        <div className='mt-5'>
          <h5 className='text-[56px] font-bold'>FAQs About Rapidsofts’ Fintech App Development Services & Financial Software Development Services</h5>
        </div>
        <div className='w-[60%] mx-auto mt-5'>
          <Sidebar6 />
        </div>
        <div className='mt-5'>
          <h5 className='text-[56px] font-bold'>Success Stories</h5>
        </div>
        <div className='px-16'>
          <div className='flex flex-row mt-5'>
            <div className='mt-5 w-[45%]'>
              <div>
                <img src={private_b} />
              </div>
              <div className='mt-4 w-[500px]'>
                <h5 className='text-[30px] font-semibold hover:text-[#F13623]'>We Help Financial Platforms Better Serve Their Customers (And Bottom Line)</h5>
                <p className='text-[20px] font-light mt-4'>See how we helped a huge content-driven site for wealth management and banking level up their customer experience with greater transparency, improved communication, and more personal service.</p>
              </div>
              <div className='mt-5'>
                <img src={sadara_bg} />
              </div>
              <div className='mt-4 w-[500px]'>
                <h5 className='text-[30px] font-semibold hover:text-[#F13623]'>We Help Banks and Financial Institutions Develop New Customer Acquisition Channels</h5>
                <p className='text-[20px] font-light mt-4'>Learn how we helped an innovative Middle Eastern bank with interests in loyalty management, digital marketing, social media, and viral marketing with an app designed to deliver irresistible deals to customers.</p>
              </div>
            </div>
            <div className='mt-5 w-[45%] ml-auto'>
              <div className=''>
                <img src={tanada} />
              </div>
              <div className='mt-4 w-[500px]'>
                <h5 className='text-[30px] font-semibold hover:text-[#F13623]'>Our Fintech App Development Services Make Innovation Accessible</h5>
                <p className='text-[20px] font-light mt-4'>Discover how we helped an ambitious fintech company extend no-interest micro-loans to deserving SMEs and individuals by designing a user-friendly eWallet app that makes participation easy.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <h5 className='font-black text-[20px] text-[#F13623] tracking-widest text-center'>GOT A PROJECT IN MIND?</h5>
          <h5 className='text-[44px] font-extralight text-center'>We Provide Leading-Edge Financial Software Development for Business Growth You Can Count On</h5>
          <div className='w-[160px] mx-auto'>
            <button
              type="button"
              className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-4 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
            >
              Let's Talk
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BankingFinancial