
import {
  blum,
  casestudyeureka,
  BestOnlineFoodDeliveryApps,
  nativevs,
  pageantbigcasestudpic,
  digitalcustomerexperiencestrategy,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  designprocess,
  trish,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
const UserExperience = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                USER EXPERIENCE DESIGN AGENCY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                UX Design Services That Delight and Thrive
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Unleash the power of Rapidsofts’ UX design services to create
                digital products and platforms that strengthen trust,
                engagement, and loyalty with your users.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16  max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with UX Design?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={trish} className="w-full sm:h-[600px] object-cover" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>We Ensure Your UX is Your X-Factor</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Human-centric user experience design services are essential for
                creating effective interactions with your site’s visitors.
                Businesses can expand their strategic vision and leverage their
                website to better serve their customers by leveraging powerful
                designs.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Partnering with Rapidsofts gives you the advantage of an
                established user experience design agency on your side. By
                investing in a professional user experience (UX) design that
                balances emotion and function, customers will have more
                appealing and rewarding interactions with your products and
                services.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                By applying this philosophy to our user experience design
                services for web development and mobile app development, we
                empower brands to remain adaptable over time as they create
                impactful products and platforms that put the user first.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mt-[30px]">
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Digging Deep to Find the Road Map
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                A game changing UX strategy focuses on aligning every touchpoint
                along the customer journey with your business's ultimate vision
                of the user experience. Consider this path your roadmap to
                improve customer engagement, customer service, and revenue from
                where they are today to where you want them to be tomorrow and
                beyond.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Our investigative and co-creative method of providing UX design
                services revolves around a deep dive into your customers'
                habits, desires, needs, and challenges. Consumer behavior is
                changing, and businesses have to move faster if they want to
                turn their brand’s promises into the reality their consumers
                will experience.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Our goal at Rapidsofts is creating a UX design strategy that
                builds on how your customers view your current online presence.
                With your current UX as the foundation, we craft our UX design
                services around your business’ capabilities to present your
                digital product in a way that’s still “on-brand” but even more
                visually appealing and easy-to-use.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px]"
                ></img>
                  <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Product Prototyping Make, Learn, Repeat as Necessary
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  The prototyping process is an invaluable step of our process
                  as a user experience design agency. It’s essential in
                  developing the kind of digital product and smooth user
                  experience modern users have come to expect. With a simple
                  sketch, the spark ignites, and the iterative dance of creating
                  and learning begins. Prototyping reveals many opportunities
                  for innovation while saving money in the long run.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Every ounce of feedback we get from the design process brings
                  us closer to a cohesive final design and enables us to deliver
                  first-class UX agency services. That’s why we say, “Good UX
                  design doesn’t just happen; it’s prototyped.”
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[700px] max-sm:text-[20px] max-sm:text-center">
                Rapid-fire, Creative, Collaborative
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                A design sprint is a dynamic, targeted team session that
                supercharges the innovation and creativity behind our UX agency
                services. Over the course of a few days, we work together to
                expand our understanding of what the user needs, along with the
                business’s objectives, and consider creative methods of merging
                the two.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Once we’ve sketched all possible solutions, it’s time for our
                team to pick the best ones. Afterward, we go through the
                prototyping and validation cycle. During this time, our team is
                constantly updating and testing to answer critical business
                questions as they arise. This accelerated pace allows our team
                to pivot quickly, deliver high-value outcomes, lower the project
                risk, and achieve faster delivery of your final product.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                We focus our UX services on the consumer’s sensory experience
                throughout the design process. Our goal is to understand the
                sights, sounds, and feelings they associate with your product
                and website (i.e., anticipation, patience, excitement).
                Businesses that meet their customers where they are emotionally
                build better brand loyalty and, ultimately, perform better than
                their competition.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[700px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Capturing the Voice of the Consumer
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Excellent customer interactions require iteration and
                  innovation. Sound UX design services improve the overall user
                  experience through usability testing and honest feedback. This
                  helps us engineer a more seamless experience by eliminating
                  user flow silos.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  As a team, we’re able to reach a broad range of users by
                  testing in any environment. This approach allows us to
                  understand the relationship between user perception and
                  product use. By spending more time on your site (and with your
                  products or services), we uncover insights that help you make
                  better decisions for the future health of your business.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Effective UX services create a platform that communicates in
                  the consumer’s voice. When we can deliver on their
                  expectations, aversions, and preferences, it can only result
                  in a more intuitive user experience.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  We want our end product to be something the user interacts
                  with effortlessly and naturally. This is extremely important
                  when it comes to mobile applications, since the majority of
                  today’s web traffic comes from mobile devices. Whether it’s
                  our Android app development services, or if we step in as iOS
                  app developers, the final product needs to be intuitive and
                  anticipate the user’s needs and emotions.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  To our team, that means an eternal loop of interactions from
                  both sides. The idea is to create a seamless digital
                  experience that your users can understand and relate to—but
                  also an experience that understands your users.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  For more information on user experience design services and
                  what they can do for your business, with a Rapidsofts
                  representative today and request your free consultation.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className=" mt-[50px]">
            <p className="sm:text-5xl ml-5 max-sm:text-[20px] max-sm:text-center">
              <b>
                Common FAQs about Working With Rapidsofts as Your User
                Experience Design Agency
              </b>
            </p>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">What is UX?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        User experience (UX) refers to a customer’s interaction
                        with a company’s digital presence. It refers to the way
                        a company’s website and digital products or services
                        make its customers think, feel, and act. In other words,
                        UX refers to a customer’s experience with a company’s
                        entire online identity. It’s also meant to evaluate
                        whether or not those interactions accomplish the user’s
                        goals and if they’re easy and enjoyable to use.
                      </div>
                      <div></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="text-[26px]">What is a UX designer?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      A UX designer is a professional who utilizes various
                      tools, research, and expertise to design the best possible
                      digital experience for customers. You can think of UX
                      designers as user advocates — their goal is to develop a
                      deep understanding of the customer and then use this
                      understanding to guide the design of a product, service,
                      or digital application.
                    </div>
                    <div className="text-[20px] font-light text-3xl">
                      Ultimately, a UX designer seeks to make a business’s
                      offerings more accessible, easier to use, and more
                      enjoyable for the customer. They do this by leveraging
                      in-depth customer research and design thinking.
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      What is the difference between UX and UI?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      While UX describes the overall experience someone has with
                      a company or product, UI (user interface) represents the
                      visual aspects of that company or product. UX designers
                      deal with research and user-centric design thinking. UI
                      designers take these same principles and apply them to the
                      user interface.
                    </p>
                    <p className="text-[20px] font-light">
                      UX and UI are inseparable -- UI design typically takes
                      place within a UX design process. Understanding the UX of
                      a product or service is an essential first step before
                      designing the visual UI interaction. The UX process guides
                      the direction of UI design through research and analysis.
                      Once the user requirements are established, the role of UI
                      is to bring them to life.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px] max-sm:text-center">What is UX testing?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      UX testing is part of the larger UX design process. It’s a
                      way for designers to measure the effectiveness of their
                      design in real-world environments. The testing involves a
                      sample group of users interacting with the product or
                      service and providing feedback.
                    </p>
                    <p className="text-[20px] font-light">
                      The intention here is to see how the product performs with
                      a small group of users that represent the business’s
                      target audience. Multiple test formats, including
                      usability testing, A/B testing, and focus groups, are
                      utilized to determine how the product stands up in
                      real-world situations.
                    </p>
                    <p className="text-[20px] font-light">
                      Armed with the feedback provided from the testing, product
                      teams can either validate their initial design intentions
                      or modify their approach to deliver a great user
                      experience.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px] max-sm:text-center">
                      What is a UX design process?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      A great UX design process relies on “design thinking” and
                      several other techniques that enable a team to build and
                      test a product that meets the users’ needs. Typically,
                      these processes follow five phases, but this can change
                      depending on the needs of the product or business.
                    </p>
                    <p className="text-2xl mt-2">
                      <b>1. Definition</b>
                    </p>
                    <p className="text-[20px] font-light">
                      UX and UI are inseparable -- UI design typically takes
                      place within a UX design process. Understanding the UX of
                      a product or service is an essential first step before
                      designing the visual UI interaction. The UX process guides
                      the direction of UI design through research and analysis.
                      Once the user requirements are established, the role of UI
                      is to bring them to life.
                    </p>
                    <p className="text-2xl mt-2">
                      <b>2. Research</b>
                    </p>
                    <p className="text-[20px] font-light">
                      Design teams begin an extensive research phase once an
                      idea is defined and refined. The team uses this time to
                      conduct comprehensive user, market, and competitor
                      research. A good product designer views this phase of UX
                      design as an integral part of the larger process. Thorough
                      research makes for better design decisions moving forward.
                    </p>
                    <p className="text-2xl mt-2">
                      <b>3. Analysis</b>
                    </p>
                    <p className="text-[20px] font-light">
                      After the team gathers enough data through research, the
                      analysis phase can begin. Developers review the data to
                      gain insights into why users request certain features.
                      During analysis, UX designers confirm that the most
                      critical assumptions outlined in the definition phase are
                      accurate.
                    </p>
                    <p className="text-2xl mt-2">
                      <b>4. Design</b>
                    </p>
                    <p className="text-[20px] font-light">
                      Once the user requirements, desires, and expectations are
                      clear, the project moves to the actual design phase. Here,
                      the team works on everything from information architecture
                      to interface design. The most effective design phases are
                      extremely collaborative both across the design team itself
                      and with the principal stakeholders in the project.
                    </p>
                    <p className="text-2xl mt-2">
                      <b>5. Validation</b>
                    </p>
                    <p className="text-[20px] font-light">
                      The final and most crucial step in the UX design process
                      is validation. This phase determines whether everything
                      done up to this point meets the needs and expectations of
                      the users and stakeholders. The UX team validates
                      everything across the previous phases and ensures a great
                      user experience by running the product through various
                      user testing sessions.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={casestudyeureka}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      Our Innovative UX Design Powers Optimized & Scalable
                      Digital Strategy
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how helping a global engineering company apply UX
                    insights can result in a more effective mobile app for their
                    international salesforce.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Reinvent Existing Products for Greater Impact &
                      Efficiency
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Explore how our design thinking improved lives in India by
                    building a new mobile app that pairs rural Indian women with
                    economic opportunities.
                  </p>
                  <img
                    src={blum}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Uncover Novel Solutions that Drive Revenue & Growth
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how our field research and ecosystem mapping connects
                    brands with retailers on a unique platform and creates
                    winning relationships on both sides.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={designprocess} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                  EXPERIENCE DESIGN
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    The 8 Steps of UX Design Process – How to Do it the Right Way
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={nativevs} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    EXPERIENCE DESIGN,  USER EXPERIENCE DESIGN
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    What is Personalization in UX?
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={BestOnlineFoodDeliveryApps}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    EXPERIENCE DESIGN
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    Why You Should Pursue Collaborative Design to Build Products
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserExperience;
