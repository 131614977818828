import React from 'react'
import { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Sidebar6 = () => {
    const [open, setOpen] = useState("");

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
  return (
    <div className='static'>
                <Fragment>
                    <Accordion open={open === 1}>
                        <div className=' px-2.5 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(1)}>
                                <h5 className="text-[30px] font-bold">How do you develop a fintech app?</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody>
                            <div className='px-4'>
                                <p className='font-light tracking-wider text-[22px]'>Just the idea that you’re asking about how to develop a fintech app shows you’re in the right place. 😉 As described above, the Software Development Life Cycle (SDLC) involves several stages, from requirement gathering and analysis to design, development, testing, deployment, and maintenance. Considering the constraints of being in a heavily regulated industry, working with an experienced fintech software development company makes sense.</p>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 2}>
                        <div className=' px-2.5 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(2)}>
                                <h5 className="text-[30px] font-bold">What is a financial software developer?</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody>
                            <div className='px-4'>
                               <p className='font-light tracking-wider text-[22px]'>A financial software developer is a coder or engineer experienced in developing, deploying, and maintaining (updating) software or apps for banks and businesses requiring specialized financial software development. They may work on the app's back end, like developing payment gateways, or on the front end, user-facing experience, like customer experience initiatives.</p>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 3}>
                        <div className=' px-2.5 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(3)}>
                                <h5 className="text-[30px] font-bold">Why choose Rapidsofts to be your financial software ?</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody>
                            <div className='px-4'>
                               <p className='font-light tracking-wider text-[22px]'>With more than 23 years in the software and app development business, we have a seasoned team of financial software developers who are skilled at developing solutions that leverage cost and time efficiencies while maintaining full compliance. Also, we have attained top-level global security certifications, including ISO 9001, ISO 27001, PCI, and TISAX. We are an end-to-end financial software development company.</p>
                            </div>
                        </AccordionBody>
                    </Accordion>
                </Fragment>
            </div>
  )
}

export default Sidebar6