
import {
  casestudyreact1,
  softwaredevelopment,
  EMR,
  WooClubm,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment6,
  Hiring,
  GoogleCloudSQL,
  Digital_tra2,
  Pythonwebdevelopmentimage,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const Vue = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                CUSTOM VUE.JS DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Enjoy Fast, Seamless Mobile or Web Application Development With
                Our Vue.js Experts
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Ace your business objectives and delight your customers with
                intuitive UI and UX.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for Vue.Js App Development Services?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={Pythonwebdevelopmentimage} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Our Approach to Vue.js App Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                For over two decades, we’ve been the go-to JavaScript
                development company for businesses from startups to scaleups and
                enterprise companies. So, when Vue.js was introduced in 2014 by
                former Angular team member Evan You, we immediately adopted it
                as our core front-end framework.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Today, we offer end-to-end Vue.js development services,
                empowering our clients to build finely tuned and optimized
                applications utilizing advanced tools, supporting libraries, and
                component-level caching capabilities. Thanks to our Agile
                methodology and DevOps culture, we can quickly and accurately
                design and build custom applications from scratch that delight
                users and boost your bottom line.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Services
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Explore Technology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
            <b>
              Transform Your Business with Our Custom Vue.js Development
              Services
            </b>
          </p>
          <p className="sm:text-3xl mt-5 max-sm:text-[20px] max-sm:text-center">
            Vue.js is an open-source, progressive JavaScript framework built to
            be fast, lightweight, and infinitely flexible — the perfect tool to
            build a next-generation mobile or web application. We're your
            one-stop solution if you want to outsource Vue.js developers to
            create incredible user experiences. From analysis and ideation to
            expert UX design services and throughout the development process, we
            ensure that your business objectives drive the design and execution
            of every aspect of your project.
          </p>
          <p className="sm:text-3xl mt-5 max-sm:text-[20px] max-sm:text-center">
            We offer the following Vue.js Web Development Services:
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Vue.js Development
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                As a leading Vue.js development company, we have the technical
                expertise and core competencies to develop lag-free, fully
                secure, and easily scalable applications tailored to your unique
                business needs. Our seasoned Vue.js team takes the natural
                benefits of Vue.js and leverages its flexibility and speed to
                accelerate your project’s time to market, sharpening your
                competitive edge and meeting the demands of today’s customers’
                ever-evolving needs.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment1} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  API and Third-party Integration With Vue.js
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Our expert Vue.js team creates bespoke applications that
                  seamlessly integrate third-party products for rich user
                  experiences, code maintainability, and excellent scalability
                  and responsiveness.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                React Native Migration/ Legacy App Migration
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Thanks to React Native’s core benefits of using a single
                codebase for multiple platforms and a dynamic, active community
                of developers, there are countless reasons migrating to the
                framework is a smart business move. From lower development costs
                to smooth, error-free cross-platform UX, reusable code,
                extensive third-party plug-ins, and native app compatibility,
                there’s a reason that today’s leading apps, like Instagram,
                Skype, Shopify, SoundCloud, Microsoft, Uber, and Salesforce,
                among others, use React Native.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment2} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment3} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Vue.js Component Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Among our most popular Vue.js app development services is
                  custom Vue.js component development. We design and deliver
                  feature-rich, optimally functional, and reusable UI components
                  for a variety of web applications.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Vue.js Web App Modernization
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Whether you already have a core solution using Vue.js or are
                  using another framework and want to update your app or
                  platform’s performance, our team of expert Vue.js developers
                  is here to help. We specialize in hassle-free Vue.js app
                  modernization that upgrades your user experience without
                  downtime.
                </p>
                <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="mt-[20px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                At a Glance: Why Choose Rapidsofts as Your Vue.js Development
                Partner?
              </h4>
              <ul className="list-disc mt-4">
                <li>
                  We’re an industry leader in the JavaScript development space
                  with expertise in Vue.js.
                </li>
                <li className="mt-3">
                  We offer full-spectrum Vue.js development services that
                  leverage the lightweight framework’s core benefits to
                  future-proof your business.
                </li>
                <li className="mt-3">
                  Our 12-factor app methodology makes the app development
                  process seamless and accelerated.
                </li>
                <li className="mt-3">
                  We have a carefully vetted pool of talented Vue.js specialists
                  with in-depth knowledge and extensive experience in developing
                  UI/UX design, interactive UI development, third-party
                  integration, and cutting-edge lead generation and conversion
                  strategies.
                </li>
              </ul>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment6} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <p className="sm:text-7xl sm:w-[90%] m-auto max-sm:text-[20px] max-sm:text-center">
              <b>FAQs About Rapidsofts’ Vue.Js App Development Services</b>
            </p>
            <div className="mt-[50px]">
              <div className="sm:w-[80%] m-auto">
                <Accordion className="mb-5 max-sm:mt-[-100px]">
                  <div>
                    <Accordion.Item eventKey="0" className="">
                      <Accordion.Header>
                        <h5 className="sm:text-[26px] max-sm:text-[20px]">
                          What is Vue.js development?
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="text-[20px] font-light text-3xl">
                          Vue (pronounced “view”) is an innovative JavaScript
                          framework used to build a variety of web and mobile
                          apps and platforms. Using HTML, CSS, and JavaScript as
                          core libraries, it offers a progressive, modular
                          approach to development that simplifies the process of
                          building sophisticated digital solutions. Our
                          developers love Vue.js for its flexibility, speed, and
                          robust community that helps inspire and optimize the
                          engineering process.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <Accordion.Item eventKey="1" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        Is Vue.js better than React?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        As a leading custom web development company well-versed
                        in both Vue.js and React, we value both frameworks for
                        different reasons. But one of the stand-out aspects of
                        Vue.js is that it takes the best features of React (and
                        Angular, too), simplifies them, and delivers an
                        excellent overall perfect user experience. Here’s a
                        snapshot of the pros and cons of Vue.js:
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        How do you create a website in Vue.js?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-3xl font-light">
                        Website creation involves several stages, from discovery
                        and ideation to design and development. (Learn more
                        about our Agile DevOps process for web and app design.)
                        If you’re using Vue.js, the development process entails
                        using modular code blocks.
                      </p>
                      <p className="text-3xl font-light">
                        What to know more? Get a free consultation with our
                        Vue.js experts.
                      </p>
                      <p className="mt-2 text-3xl">
                        So, how can you be sure about your React development
                        hires? Whether you’re working with an agency or
                        individual programmers, pay close attention to the React
                        Native app development services offered. And be sure to
                        consider if your source has the flexibility, talent, and
                        resources you need to deliver your project on time and
                        on budget.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="w-[90%] m-auto">
              <div className="sm:mt-[100px] max-sm:mt-[50px]">
                <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                  <b>Success Stories</b>
                </p>
              </div>
              <div className="sm:flex">
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={casestudyreact1}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        casestudy-hubpixcasestudy-hubpix We Develop Strategic
                        B2B Cross-Platform Apps for Future-Forward Companies
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Learn how our team of researchers and engineers created a
                      mobile solution to connect brands with retailers,
                      eliminating print waste and improving ROI across the board
                    </p>
                  </div>
                </div>
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={WooClubm}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        casestudy-avancecasestudy-avance We Help Ambitious
                        Entrepreneurs Build World-Class Apps on a Start-Up
                        Budget
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Discover how we took an innovative video training app from
                      concept to reality with a core features-only Minimum
                      Viable Product (MVP) that minimized time to market and
                      cost and optimized the app’s successful launch.
                    </p>
                    <img
                      src={EMR}
                      className="mt-5 w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>We Build Hassle-Free Apps for Social Networks</b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      See how we helped a novel social networking platform
                      develop a mobile experience powered by a unique algorithm
                      to make it easier for users to find nearby events based on
                      their interests, locations, and availability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Hiring} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      A Guide To Cloud-Native Application Development
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={GoogleCloudSQL} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Digital_tra2} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vue;
