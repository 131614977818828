import React from 'react'
import {
    devopss,
    devops_image,
    startupss,
    architecturee,
    integrations,
    backlog,
    cultures,
    thumbgf,
    devsecops
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar13 from '../../componets/sidebar/Sidebar13';

const DevoOps = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar13 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                DevOps
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${devopss})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[30%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                                What is DevOps?
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                DevOps signifies close collaboration between the development and operations teams. Though the term is common among IT organizations today, it was an unchartered territory back in 2009. Flickr introduced the concept at OReilly Velocity with a presentation titled: “<span className='font-bold'>10+ Deploys Per Day: Dev and Ops Cooperation at Flickr</span>.”
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                The interesting thing about this presentation was the two presenters — John Allspaw, the Vice President of Technical Operations, and Paul Hammond, the Director of Engineering. This was the first time that the people from development came together with those in operations to promote the core idea behind DevOps: Release Early, Release Often!
                            </p>
                        </div>
                        <div className="mt-5">
                            <img src={devops_image} />
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans	font-light">DevOps has grown leaps and bounds since then. Many businesses implementing <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>DevOps solutions</span> are nailing exemplary deploy frequencies/day and have become living examples of how its implementation can impact efficiency and productivity. Businesses well-known for implementing DevOps include Amazon with 23,000 deploys/day, Google with 5500 deploys/day, Netflix with 500 deploys/day, and the list goes on. That is the power of an entrenched <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>DevOps culture!</span></p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is DevOps?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>DevOps</span> is a portmanteau for Development and Operations, which comprises practices, culture, and tools that help increase software development velocity to ensure faster time to market. The primary objective of DevOps is to break the silos that exist between the development and the operations teams.
                            </p>
                            <p className="text-[27px] font-sans font-bold mt-5">
                                DevOps has its roots entrenched in the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Agile development methodology</span>.
                            </p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>"83 percent of IT decision-makers report their organizations are implementing DevOps practices to unlock higher business value through better quality software, faster delivery times, more secure systems, and the codification of principles.".</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className="text-[27px] font-sans	font-light">For a clearer perspective, here is a breakdown of the responsibilities of the “development” and the “operations” team in the software development process:</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                DevOps vs Agile
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                The shortcoming of this approach was that it could not nicely integrate the operations team into the process. Thus, DevOps was introduced to fill the gap. In DevOps, development and operations work in tandem for on-time software delivery.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                The shortcoming of this approach was that it could not nicely integrate the operations team into the process. Thus, DevOps was introduced to fill the gap. In DevOps, development and operations work in tandem for on-time software delivery.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">DevOps inherits the principles and values of the Agile Manifesto and extends the focus of software development to include automation and collaboration (between development and operations).</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Why DevOps?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                DevOps addresses the shortcomings of Agile (i.e., the incremental and iterative development did not lead to the iterative and quick deployments).
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Agile only focused on faster development, which was not enough. Faster deployment should have followed faster development to meet the customer demands promptly.</p>
                            <p className="text-[27px] font-sans font-light mt-5">DevOps focuses on faster development, faster deployment and promotes collaboration between the development and operations teams.</p>
                            <p className="text-[22px] font-sans font-bold mt-5">DevOps = Development + Operations</p>
                            <p className="text-[22px] font-sans font-bold mt-5">DevOps = Communication + Collaboration</p>
                            <p className="text-[22px] font-sans font-bold mt-5">DevOps = Faster Development + Faster Deployment</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                The Three DevOps Principles
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5"> The following DevOps principles are inspired by “The Phoenix Project,” a fictional novel around IT and DevOps.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Systems Thinking</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>There needs to be a continuous and constant flow between the development and the operations teams. The key to achieving this flow is to implement a continuous integration/continuous delivery/continuous deployment (CI/CD) pipeline, limiting WIPs (work in progress), and introducing automation.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Promote Feedback Loops</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Fast feedback is equal to quick learnings. The focus of the DevOps team should be to identify faults as soon as possible. The best practices for promoting quick feedback include — introducing automation test suites, employing tools for detecting a failure in the deployment pipelines, and monitoring continuously.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Continuous Experimentation and Learning</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Continuous learning is a primary parameter that defines the success of DevOps initiatives. The best practices for fostering a continuous learning culture include — building trust among teams, effective communication and collaboration, and allowing Devs and Ops teams to work on non-functional requirements.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                DevOps Framework: CALMS
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">CALMS is a DevOps framework introduced by Jez Humble, the co-author of “The DevOps Handbook.” CALMS stands for — Culture, Automation, Lean, Measurement, and Sharing.</p>
                            <p className="text-[27px] font-sans font-light mt-5">This framework measures an organization’s ability to adapt to the DevOps culture and its implementation success.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Here’s what CALMS framework signifies:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Culture</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>DevOps is a cultural change rather than an adoption process. The major step towards this cultural change comes from communication and collaboration between the development and operations teams.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Automation</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Automation aims to eliminate the burden of manual testing and repeatable tasks. To implement DevOps — automating the build, test, and deploy (i.e., the CI/CD pipelines) is the first step forward.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Lean</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Lean is a management approach that aims to deliver value through eliminating waste and showcasing delivery speed. In context to DevOps, continuous improvement (in the form of retrospectives and A/B testing) and embracing failure are add-on lessons inspired by Lean practices.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Measurement</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Measurement of success forms the foundation for continuous improvement. When implementing DevOps, the team needs to measure:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>The lead time between development and deployment</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The frequency at which bugs are reported</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The turnaround time to recover from failure</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The retention/churn rate for the software</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <img src={startupss} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Benefits of DevOps
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The primary advantages of DevOps at the organizational level include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Speed</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>DevOps helps introduce automation to the integration, delivery, and deployment of the software. With the aid of the CI/CD pipelines, the software can be released to the production environment multiple times a day.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold'>Related Article:</span>Why Release Software to Production Multiple Times a Day?</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Scalability</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Automation plays a significant role in managing the changing software requirements and ensuring that the system can be scaled efficiently and with speed.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Promotes Cross-Functional Setups</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>DevOps breaks the silos that exist between the development and the operations teams. When an organization makes this cultural shift, the two teams work closely and maintain ongoing communication to manage their shared responsibilities collaboratively.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Reduced Failure Rate</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Software changes can sometimes lead to failed integrations, thus causing compilation issues in the software. However, DevOps introduces automation to the development and deployment pipelines, wherein chances of change failure get significantly reduced.</p>
                            <p className="text-[22px] font-sans font-bold mt-5 ml-[40px]">Google’s DevOps report suggests that DevOps leads to a seven times lower change failure rate (i.e., changes are 1/7 less likely to fail).</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            DevOps Practices
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The commonly used practices that are categorized under DevOps include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Continuous Integration (CI)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>As a part of continuous integration, the developers regularly put the code changes into a central repository. Automated tests are run on the code to identify bugs and other runtime errors.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Continuous integration</span> aims to shorten the test loops so that the software changes can be updated without delays.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Continuous Delivery (CD)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>As a part of continuous delivery, the code changes are automatically built, tested, and prepared for deployment in the live environment. Developers are notified of bugs, and the code is automatically retested, and this loop continues until the code is ready for deployment.</p>
                        </div>
                        <div className="mt-5">
                            <img src={architecturee} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Continuous Deployment (CD)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>As a part of continuous deployment, the new code changes are automatically released to a live environment. That is, the updates and upgrades are available to the end-users.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Continuous deployment aims to ensure that the code changes are deployed to a live environment quickly and securely.</p>
                        </div>
                        <div className="mt-5">
                            <img src={integrations} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Microservices Architecture</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Microservices architecture is a design approach to build applications in the form of loosely coupled, independently existing services. This defies the monolith approach to development, where a malfunction to a feature results in downtime of the entire application.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Microservices architecture is a powerful technology for developing applications with API capability. Netflix is a prominent example of a service that is built using a microservices architecture.</p>
                            <p className='text-[20px] font-sans	font-bold mt-4 ml-[40px]'>Microservices Architecture: Loosely coupled code blocks + Respective databases = Single application</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            DevOps Lifecycle: Explained
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The DevOps lifecycle is nearly similar to the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'> Agile software development lifecycle</span>, but with additional emphasis on the operations stage.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Here is an overview of each of these stages:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Requirements Analysis</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The functional requirements of the software are discussed and prioritized. The team creates a product backlog where they priority-arrange all the features of the software.</p>
                        </div>
                        <div className="mt-5">
                            <img src={backlog} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Design</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The design team creates the prototype that, in turn, helps visualize the UI/UX flow of the product. The focus of the design phase is to create a great user experience.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Develop</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The developers write code at this stage to convert the idea into a workable product. The development team works in time-boxed sprints to develop the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>user stories</span> on an incremental basis.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Testing and Continuous Integration</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>As a part of the DevOps process, testing is performed by leveraging automation. Continuous integration is also performed at this stage where the developers put their code into a central repository for running automated tests.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>5. <span className='ml-3'>Continuous Deployment</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The code is pushed into the live environment consistently and reliably. That is, the product or the changes are made available to the end-users. Continuous deployment usually happens several times a day once the code passes from all approvals and quality checks gates.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Conclusion
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">DevOps is a cultural shift that aims to reduce software development and deployment cycles. The implementation of DevOps at the organizational level helps break the silos between the development and operations teams.</p>
                            <p className="text-[27px] font-sans font-light mt-5">To develop and deploy software and improve time-to-market, DevOps is a go-to practice. This write-up covers everything around DevOps and how it is essential to embrace the change where faster release cycles are a priority.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={devsecops} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DEVOPS,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    What is DevSecOps? Definition, Challenges, and Best Practices</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={cultures} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DEVOPS,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    How Can CXOs Build A DevOps Culture</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={thumbgf} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DEVOPS,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    What is CI/CD? Continuous Integration & Delivery Explained </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevoOps