import {
  omnichannel1,
  Lbsm,
  xu,
  BestOnlineFoodDeliveryApps,
  shouldilaunch,
  pageantbigcasestudpic,
  digitalcustomerexperiencestrategy,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  trish,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
const EcommerceDevelopement = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C]  max-sm:text-[20px] max-sm:text-center">
                ECOMMERCE WEB DESIGN SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%]  max-sm:text-[20px] max-sm:text-center">
                Empower Your Shoppers With A Superior eCommerce Solution
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%]  max-sm:text-[20px] max-sm:text-center">
                Experience ultimate buy-in for your brand. Partner with Net
                Solutions’ eCommerce web development services to build a
                platform that exceeds your customers’ expectations.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="m:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black  max-sm:text-[20px] max-sm:text-center">
                Need Help with E-Commerce?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={trish} className="w-full sm:h-[600px] object-cover" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl  max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2  max-sm:text-[20px] max-sm:text-center">
                <b>Digital Delight at Every Consumer Touchpoint</b>
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                In today’s digital marketplace, simply being responsive is not
                enough. Your goal should be to build brand loyalty and attract
                new customers who recognize the value you bring to their
                shopping experience. To do that, ecommerce personalization is a
                vital element - you must anticipate your shoppers’ needs to
                grasp attention & retain loyalty. With the growing number of
                informed customers seeking exclusive experiences, this has
                become the need of the hour.
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                Modern times call for the right partner. A partner who
                understands that eCommerce success requires the perfect marriage
                between digital commerce analytics as well as innovation in
                mobile app development and web app development services.
                Fortunately, that’s exactly what we specialize in at Net
                Solutions.
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                We optimize every aspect of your presence in the digital
                marketplace. Rapidsofts is here to help you win the hearts,
                minds, and wallets of your customers by exceeding their
                expectations and bringing you the maximum impact from your data
                and design.
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                Our Agile-focused process delivers exceptional shopping
                experiences. We can provide you with eCommerce development
                solutions ranging from single-product sale pages to
                comprehensive omnichannel experiences and everything in between.
                This complete suite of eCommerce assets will allow you to engage
                customers across multiple touchpoints and deliver on your
                promises. You’ll also begin to see a significant boost to your
                store’s revenue both by bringing more customers in and
                increasing their lifetime value through upsell opportunities and
                increased retention.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[200px] max-sm:mt-[50px]"></div>
        <div className="mt-[30px]">
          <p className="sm:text-7xl  max-sm:text-[20px] max-sm:text-center">
            <b>Our Professional eCommerce Development Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                eCommerce Strategy
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Think of Rapidsofts as your partner in surprising and
                delighting your customers.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Leverage the experience of a custom software development company
                that has been building strategic, shopper-first digital commerce
                platforms for over two decades. Our reputation in the world of
                eCommerce web design services proves we have what it takes to
                get your project started on the right foot and see it through to
                the finish line.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Whether you're a B2B or B2C enterprise, it's not just about
                having the right tools. It's about using those tools to their
                fullest potential without disrupting what you're already doing
                right.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Branding, storytelling, amazing design, back-office
                optimization, smart digital marketing, and secure tech are all
                tools that need to be used effectively to ensure your online
                store’s success. Additionally, you’ll need to make regular site
                improvements and updates to maintain a seamless shopping
                experience. Our experts can assist you in bringing all of these
                elements together for explosive growth in your eCommerce store.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                By enlisting our eCommerce development services, you get the
                advantage of our experienced and knowledgeable team going to
                work for you. Experts in UX design services, specialized
                eCommerce business analysts, and technical architects will come
                together to provide a results-driven, 360-degree strategy that’s
                robust and scalable. Plus, we give priority to ecommerce
                security in today’s digital environment. We can accommodate
                everything from a simple one-page site that lets you sell
                products to a complex storefront that dazzles your customers.
                Regardless of your business’s size, you can experience the
                benefit of professional eCommerce web design services.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px] sm:mt-[200px] max-sm:mt-[50px]"
                ></img>
                 <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Customized Digital Experiences
                </h4>
                <p className="sm:text-2xl  pt-4  max-sm:text-[20px] max-sm:text-center">
                  Your eCommerce store needs to strike the perfect balance of
                  style and substance to tide over the gamut of eCommerce
                  challenges. It’s the only way to capture the attention of
                  today’s online shoppers and cultivate consistent conversions.
                </p>
                <p className="mt-5 sm:text-2xl  max-sm:text-[20px] max-sm:text-center">
                  Our eCommerce web development services produce platforms that
                  deliver on your brand’s promises. We begin with a custom
                  eCommerce strategy that focuses on intuitive, secure user
                  interactions. The end goal is an eCommerce solution that’s
                  completely scalable and seamlessly merges your back-office and
                  customer-facing features.
                </p>
                <p className="mt-5 sm:text-2xl  max-sm:text-[20px] max-sm:text-center">
                  You don’t have to build a custom eCommerce platform from
                  scratch to work with Rapidsofts. Our certified developers
                  are well-versed in optimizing ready-made platforms like
                  Magento and WooCommerce to get the most out of the features
                  they provide.
                </p>
                <p className="mt-5 sm:text-2xl  max-sm:text-[20px] max-sm:text-center">
                  We can also provide your brand with unique custom eCommerce
                  experiences. Rapidsofts can supply fully-optimized customer
                  journeys for your online store that anticipate customer
                  challenges and keep users happy throughout their shopping
                  experience.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Retail Experience Optimization
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Today’s online shopper makes quick decisions. The goalposts for
                customer engagement and retention are constantly moving. Our
                eCommerce web design services ensure that you stay one step
                ahead by delivering advanced data analytics to intuitive
                dashboards in real-time.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Now, you can easily anticipate shoppers’ needs and provide the
                precise digital commerce experience they’re looking for.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Our eCommerce development solutions improve your customer-facing
                features as well. Users will be presented with outstanding
                retail service that will keep them coming back.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                The checkout experience can be a major sticking point for online
                shoppers. Your customers want a smooth and secure checkout
                experience. Easy payment gateways, frictionless checkout, and
                customer loyalty program tools are just a few of the eCommerce
                development services we provide to help you connect your
                customers' experiences across their entire retail journey.
              </p>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Whether you need to streamline your returns process, empower
                your customer-facing employees, modernize your digital inventory
                or integrate your brick-and-mortar and online platforms, Net
                Solutions has the tools and experience necessary to optimize
                your store’s overall retail experience.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:m:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Ongoing Improvements
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  eCommerce apps and platforms typically require many moving
                  parts to successfully guide the customer from “first touch” to
                  checkout. It’s nearly impossible for one person to juggle them
                  all effectively.
                </p>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  As a business owner, your focus should be on business
                  development. Creating new products and engaging with your
                  customers is the best use of your time. With that said, the
                  other aspects of your eCommerce store still need attention.
                </p>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  For example, who's going to deal with “background” issues that
                  affect your eCommerce site’s efficiency? What about when
                  problems arise that affect your website or mobile app's
                  usability, who's going to keep your site going? These issues
                  may not seem important, but any slowdown in the efficiency of
                  your site or mobile app will affect your bottom line unless
                  you have a partner like Rapidsofts.
                </p>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Our programmers do more than just create your eCommerce
                  development solutions. They monitor them for you, too. You can
                  feel confident knowing the health of your eCommerce platform
                  is in their expert hands. The Rapidsofts team will provide
                  regular code audits to help you break through bottlenecks in
                  your store’s workflow and maximize your efficiency by
                  performing CRO audits, firewall updates, troubleshooting, and
                  implementing customized solutions to amplify your brand's
                  growth.
                </p>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Our task list goes well beyond simply fixing bugs. We’ll work
                  alongside you to ensure that layout, coding, security
                  management, traffic insights, and store updates keep humming
                  along without unnecessary downtime. Your site functions at its
                  best for longer periods, which means you reach more customers
                  and increase conversions.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="mt-[50px]">
            <p className="sm:text-5xl sm:ml-5  max-sm:text-[20px] max-sm:text-center">
              <b>eCommerce FAQs</b>
            </p>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px]  max-sm:text-[20px]">What is eCommerce?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        Ecommerce, short for electronic commerce, is the buying
                        and selling of goods and services through online
                        platforms. Businesses can sell to other businesses (B2B)
                        or directly to customers (B2C) through branded digital
                        storefronts. Consumers can also sell to each other (C2C)
                        using third-party marketplaces like eBay, Etsy, and
                        Amazon.
                      </div>
                      <div>
                        Ecommerce encompasses a wide range of activities that
                        involve exchanging payment online for a product,
                        including:
                      </div>
                      <ul className="list-disc ml-5">
                        <li className="text-2xl mt-4">
                          Shopping for books, groceries, and other goods
                        </li>
                        <li className="text-2xl mt-4">Making donations</li>
                        <li className="text-2xl mt-4">
                          Booking a hotel or vacation rental
                        </li>
                        <li className="text-2xl mt-4">
                          Purchasing theater or museum tickets
                        </li>
                        <li className="text-2xl mt-4">
                          Subscribing to streaming services
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                      How do you design and develop an eCommerce website?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      There are two ways to build an eCommerce website:
                    </div>
                    <ul className="list-disc ml-5">
                      <li className="text-2xl mt-4">
                        Software-as-a-service (SaaS) platforms such as Shopify
                        or BigCommerce offer off-the-shelf ecommerce web design
                        services that don’t require programming knowledge. You
                        can choose a design or template that reflects your
                        brand, add your logo and product information, and launch
                        the site without needing to code. Hosting, payment
                        gateways, and encryption are included for convenience,
                        but each software platform has its own limitations
                      </li>
                      <li className="text-2xl mt-4">
                        Custom solutions are built specifically for your
                        business needs by a professional eCommerce web design
                        company. These developers may use open-source platforms
                        such as Magento or offer fully-customizable eCommerce
                        development services that provide you with your own site
                        built from the ground up.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="text-[26px]  max-sm:text-[20px]">
                      How much does an eCommerce website cost?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      SaaS ecommerce platforms cost about $30 to $300 per month,
                      depending on what features you need.
                    </p>
                    <p className="text-[20px] font-light">
                      The cost of a custom-built site depends on the vision you
                      have for your eCommerce store. Web developers typically
                      assess your needs and provide a quote for building and
                      launching the store based on the project's scope. In
                      addition, ongoing maintenance and support is usually
                      billed monthly.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[20px]">What is eCommerce software?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Ecommerce software is a series of computer programs and
                      applications that tell your online store how to respond to
                      customer interactions like clicking on a product page or
                      filling out a shipping address. The software includes the
                      front-end interface that consumers use and the back-end
                      databases and systems necessary for you to fulfill orders
                      and accept payment.
                    </p>
                    <p className="text-[20px] font-light">
                      Ecommerce software offers a wide range of functionalities.
                    </p>
                    <ul className="list-disc mt-5 ml-5">
                      <li className="text-2xl mt-4">
                        Browse and search products
                      </li>
                      <li className="text-2xl mt-4">
                        Add items to a shopping cart
                      </li>
                      <li className="text-2xl mt-4">
                        Use discount codes and gift cards
                      </li>
                      <li className="text-2xl mt-4">Choose shipping options</li>
                      <li className="text-2xl mt-4">Make payments</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                      What is eCommerce development?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Ecommerce development is the process of designing and
                      building a company's digital storefront. An experienced
                      eCommerce web design company will start by researching how
                      a company works, the challenges it faces, and its digital
                      commerce needs. This knowledge is used to craft eCommerce
                      development solutions that allow for affordable agility
                      and scalability. Once the concept is developed, the
                      eCommerce site is built, tested, and launched.
                    </p>
                    <p className="text-[20px] font-light">
                      A basic online store can involve just a few product pages
                      and a simple checkout process. More advanced storefronts
                      can include features that support a company's overall
                      business operations and growth. For example, more complex
                      components such as omnichannel retailing, order
                      fulfillment, and marketing analytics can be integrated
                      into your site to streamline operations and boost
                      productivity.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                      How long does it take to build an eCommerce website?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      SaaS eCommerce platforms enable you to create a digital
                      storefront in as little as a day. Keeping to this timeline
                      requires you to have your domain name, business logo,
                      sales copy, product images, and inventory prices already
                      available. This type of out-of-the-box software features
                      user-friendly interfaces with drag-and-drop editors and
                      checklists to help you easily complete your store’s setup.
                    </p>
                    <p className="text-[20px] font-light">
                      A store built using custom features may take a few weeks
                      and usually requires coding. Building and developing an
                      eCommerce website from scratch may take months. The
                      project timeline depends on the level of functionality and
                      complexity your project requires. Aspects like requiring
                      integration with existing systems may make things more
                      complex.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[20px]">
                      What is an ecommerce developer?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      An eCommerce developer oversees the process of building
                      and launching a digital storefront. Providing high-quality
                      eCommerce web development services relies on understanding
                      the capabilities and limitations of different ecommerce
                      platforms and how to best fit them together.
                    </p>
                    <ul className="list-disc mt-5 ml-5">
                      <li className="text-2xl mt-4">
                        Analyze the needs of a company
                      </li>
                      <li className="text-2xl mt-4">
                        Select the right tech stack to achieve their individual
                        business goals
                      </li>
                      <li className="text-2xl mt-4">
                        Design the architecture of the digital storefront
                      </li>
                      <li className="text-2xl mt-4">Choose shipping options</li>
                      <li className="text-2xl mt-4">
                        Work closely with programmers who code, build, and test
                        the site
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl  max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:m:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={xu} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Build Global eCommerce Solutions that Scale & Grow
                      Businesses
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    Discover how Rapidsofts and a major international
                    sportswear company collaborated to create an unparalleled,
                    personalized cross-platform user experience spanning
                    multiple countries.
                  </p>
                </div>
              </div>
              <div className="sm:m:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Create Award-winning, Premium Mobile Shopping
                      Experiences
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    Explore how we helped a leading global online marketplace
                    increase its revenue by 450% with thoughtful, innovative,
                    and intuitive iOS and Android apps.
                  </p>
                  <img
                    src={Lbsm}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Develop Robust Marketplaces that Cater to Buyers &
                      Sellers
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    See how we developed a clever bilateral platform for an
                    innovative food industry player that serves up a satisfying
                    experience for both restaurants and consumers.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20  max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:m:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={shouldilaunch} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:m:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={omnichannel1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:m:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={BestOnlineFoodDeliveryApps}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EcommerceDevelopement;
