import React from "react";
import {
  discussing_treatment,
  records_tablet,
  using_mobile,
  using_management,
  doctor_telemedicine,
  watch_phone,
  ttimg,
  vzimg,
  imgs,
  vvimg,
  iemr_cs,
  work_image,
  zxu,
  OCR_image,
  konga_image,
  Hubpx,
  zgaimg,
  zzimg,
  zgbms,
} from "../../assets";
const Healthcare = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[170px] max-sm:mt-[80px]">
              <h1 className="sm:text-[22px] text-[#F13623] tracking-widest font-extrabold max-sm:text-[20px] max-sm:text-center">
                HEALTHCARE SOFTWARE AND APP DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[77px] font-black max-sm:text-[20px] max-sm:text-center">
                Improve Patient Outcomes with Healthcare Software Solutions
              </h1>
              <p className="sm:text-[33px] font-extralight max-sm:text-[20px] max-sm:text-center">
                Transform your health care business with comprehensive,
                compliant healthcare software development services.
              </p>
            </div>
          </div>
          <div className="sm:w-[48%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[50px] sm:w-[450px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Have a Healthcare App Idea? Talk With Our Experts.
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-[#F9D94E] sm:w-[95%] max-sm:w-[100%] ml-auto sm:px-32 sm:pt-24 mt-5 flow-root">
        <div>
          <img src={discussing_treatment} />
        </div>
        <div>
          <div className="border-l-8 border-sky-400 relative top-[-69px] ml-5">
            <div className="sm:pl-8">
              <p className="pt-28 font-black sm:text-[20px] text-[#F13623] tracking-widest max-sm:text-[20px] max-sm:text-center">
                THE Rapidsofts APPROACH
              </p>
            </div>
          </div>
          <div className="sm:px-16 max-sm:px-8 top-[-64px] sm:w-[60%]">
            <h5 className="sm:text-[46px] font-extrabold mt-[-60px] max-sm:text-[20px] max-sm:text-center">
              Innovative, Patient-first Healthcare Software Solutions
            </h5>
            <p className="sm:text-[25px] font-light max-sm:text-[20px] max-sm:text-center">
              Today’s technology revolution in healthcare empowers medical
              professionals to improve health, longevity, and clinical outcomes
              for patients and clients. For more than 20 years, we’ve been the
              custom healthcare software development company at the forefront of
              IT innovation for the industry.
            </p>
            <p className="sm:text-[25px] font-light max-sm:text-[20px] max-sm:text-center">
              Rapidsofts offers full-stack software development capabilities, as
              well as first-class healthcare app development services by
              bringing together an expert{" "}
              <span className="font-medium border-b-[2px] border-[#F13623] hover:border-none">
                user experience design agency
              </span>{" "}
              , QA pros who ensure regulatory compliance, and ironclad security.
            </p>
            <div className="max-sm:text-center">
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
              >
                Explore Services
              </button>
              <span className="sm:ml-7 mt-5">
                <button
                  type="button"
                  className="max-sm:mt-5 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white mb-16"
                >
                  Request a Quote
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-5">
        <div className="sm:pt-4">
          <h5 className="font-bold sm:text-[55px] max-sm:text-[20px] max-sm:text-center">
            Healthcare Software Development Services
          </h5>
        </div>
        <div className="sm:flex flex-row sm:pt-20 max-sm:pt-5">
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Patient Portals
            </h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              An optimized digital patient experience is at the heart of any
              effective healthcare IT system. Our expert UX/UI team and
              healthcare app developers have the expertise necessary to devise
              and deploy patient portals that make it quick, intuitive, and
              seamless for patients to get care and make payments — maximizing
              your EHR investment and ROI. Healthcare facilities can also
              maximize their EHR investment through Rapidsofts’ seamless data
              flow between platforms and parent EHR systems.
            </p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className="sm:w-[50%] max-sm:w-[100%] ml-auto">
            <img src={records_tablet} className="h-[502px]" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[50%] max-sm:w-[100%]">
            <img src={using_mobile} className="h-[495px]" />
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%] ml-auto">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Mobile Healthcare App Development Services
            </h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              Health and wellness are top-of-mind nowadays, and leveraging a
              healthcare software solutions company can go a long way in helping
              providers and patients collaborate from wherever they are. Our
              expert healthcare app development services can help you create
              custom mobile apps that track vital signs, simplify sharing lab
              results, and create opportunities to improve patients’ health and
              well-being. Our work can provide positive real-world impact by
              making healthcare services accessible to groups with traditional
              accessibility issues, like seniors in retirement communities and
              private homes.
            </p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[40%] max-sm:w-[100%] sm:mt-4">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Medical Practice Management & Operations
            </h5>
            <p className="font-light text-[21px] mt-4">
              A savvy medical practice uses technology to automate and
              streamline processes while maintaining HIPAA and other regulatory
              compliance. Our custom healthcare software development company is
              experienced in building medical data management systems
              encompassing all business functions, from patient registration to
              laboratory charting, pharmacy and inventory management, insurance
              claims processing, and accounting In addition, medical
              professionals can leverage Rapidsofts’ cutting-edge technology to
              manage everyday office tasks like scheduling, route optimization,
              upcoming visits, as well as patient and employee data.
            </p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className="sm:w-[50%] max-sm:w-[100%] ml-auto">
            <img src={using_management} className="sm:h-[502px] max-sm:mt-5" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row sm:pt-20">
          <div className="sm:w-[50%] max-sm:w-[100%]">
            <img src={doctor_telemedicine} className="sm:h-[495px]" />
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%] ml-auto">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Telemedicine
            </h5>
            <p className="font-light text-[21px] mt-4">
              One of the most significant digital healthcare accomplishments has
              been making medical services accessible to patients when they need
              them most. As a leading Telemedicine software development company,
              we have experience building bespoke telemedicine systems that
              seamlessly integrate EHR systems, deliver superior video quality,
              and empower physicians and patients to connect securely and
              privately for better outcomes. We’ve also helped our clients set
              up eCommerce platforms for ordering and tracking OTC drugs, as
              well as pharmacy transfers.
            </p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Healthcare Analytics
            </h5>
            <p className="font-light text-[21px] mt-4">
              Healthcare providers have a quantifiable and qualifiable impact on
              their patients in today's digital world. Our enterprise healthcare
              software development services include state-of-the-art analytics
              to give you insights into all areas of your business. Rapidsofts
              can provide a cutting-edge platform to handle everything from
              management to patient records, costs, diagnoses, creative support,
              better treatments and care, and waste and cost-cutting measures.
            </p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className="sm:w-[50%] max-sm:w-[100%] ml-auto">
            <img src={watch_phone} className="sm:h-[502px]" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
      </div>
      <div className="sm:flex flex-row pt-20 sm:w-[79%] mx-auto max-sm:p-2">
        <div className="sm:ml-3">
          <div className="">
            <img src={zgaimg} className="w-full object-cover"></img>
          </div>
        </div>
        <div className="sm:ml-3">
          <div className="">
            <img src={zzimg} className=" w-full object-cover"></img>
          </div>
        </div>
        <div className="sm:ml-3">
          <div className="">
            <img src={zgbms} className="w-full object-cover"></img>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-5">
        <h5 className="sm:text-[56px] font-bold max-sm:text-[20px] max-sm:text-center">
          Success Stories
        </h5>
        <div className="sm:px-16 max-sm:px-5">
          <div className="sm:flex flex-row mt-5">
            <div className="mt-5 sm:w-[45%] max-sm:w-[100%]">
              <div>
                <img src={iemr_cs} />
              </div>
              <div className="mt-4 sm:w-[500px]">
                <h5 className="sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center">
                  We Engineer Innovative Digital Platforms that Support Better
                  Patient Outcomes
                </h5>
                <p className="sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                  Discover how we empowered healthcare staff to deliver faster,
                  more accurate diagnoses through EMR decision support engine
                  technology. Employees were able to quickly write, change, and
                  test rules that facilitate patient data for quick and precise
                  outcomes.
                </p>
              </div>
            </div>
            <div className="mt-5 sm:w-[45%] max-sm:w-[100%] ml-auto">
              <div className="">
                <img src={OCR_image} />
              </div>
              <div className="mt-4 sm:w-[500px]">
                <h5 className="text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center">
                  We Build Social Platforms for Medical Professionals
                </h5>
                <p className="sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center">
                  One of our most impactful, innovative achievements as a
                  healthcare app development company was a social platform that
                  advanced collaboration within the medical community. Click
                  here and read on to learn how this healthcare social app is
                  empowering global healthcare practitioners to safely and
                  securely connect, network, and share notes on the industry’s
                  latest data and trends.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5">
          <h5 className="sm:text-[44px] font-extralight text-center max-sm:text-[20px] max-sm:text-center">
            Have a Healthcare App Project in Mind?
          </h5>
          <div className="sm:w-[160px] mx-auto max-sm:text-center">
            <button
              type="button"
              className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
            >
              Let's Talk
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Healthcare;
