
import {
  platformmigration,
  azuremigrationservices,
  cloudoptimizationservices,
  cloudengineeringservices,
  refactorgraph,
  trish,
  edplacecasesmallpic,
  pageantbigcasestudpic,
  voylegalcasesmallpic,
  Thumbnail3,
  howtoprioritizefeaturesforyourmvp,
  Thumbnail4,
  personalmultidiagram,
  ongoingdiagram,
  zsoft,
  zimg,
  zrpid,

} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const CloudMigration = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                SEAMLESS CLOUD MIGRATION SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Leverage the Power of the Cloud to Future-Proof Your Business
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Turn your business into a 24/7 results-driving machine with a
                custom cloud solution.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for Cloud Migration?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={trish} className="w-full sm:h-[600px] object-cover" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts WAY</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>
                  Fully Customized Cloud Migration Services Unique to Your IT
                </b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Thanks to the pandemic, cloud-based infrastructure is no longer
                a nice-to-have: it’s a must. We’ve been providing cloud
                migration consulting services since the inception of the first
                cloud service, Amazon Web Services (AWS), in the early 2000s.
                Today our seasoned developers start with a rigorous assessment,
                then develop a custom roadmap, workflow model, and ecosystem
                collaborations for seamless digital transformation. You can rest
                easy knowing we’ll determine the best cloud-based model for your
                business — public, private, or hybrid — and will safeguard your
                security and business continuity every step of the way through
                execution, optimization, and ongoing support.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>We’ll Help Your Define a Cloud Migration Strategy</b>
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] mt-5 max-sm:text-[20px] max-sm:text-center">
            The first step in successful cloud adoption is migration. When you
            hire us for cloud migration consulting, you’ll find it’s not just
            about the technical tools — our analysts and developers understand
            that cloud technology is instrumental in solving crucial business
            challenges. We look at every aspect of your business and ensure that
            the migration strategy we choose optimizes your budget, scalability,
            productivity, and efficiency.
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            Further, as a leading cloud migration company, we have a proven,
            systematic approach that our specialists tailor to your needs,
            goals, and environment’s complexity. Our cloud migration consulting
            services involve five essential stages: rehost, refactor, revise,
            rebuild, and replace.
          </p>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <img src={refactorgraph} className="m-auto"></img>
          </div>
          <div className="sm:mt-[150px] max-sm:mt-[50px]">
            <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
              <b>
                Cloud Migration Consulting Services & Cloud Migration
                Implementation
              </b>
            </p>
            <p className="sm:text-2xl mt-3 w-[90%] mt-5 max-sm:text-[20px] max-sm:text-center">
              Unlock the cloud’s full potential with leading-edge tools and
              customized strategies and roadmaps that optimize your business. As
              your partner in cloud migration, we help future-proof your
              business without disruption or interruption, saving you time,
              money, and resources.
            </p>
            <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
              Our cloud migration services include:
            </p>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Cloud Engineering Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                While the migration aspects of cloud adoption are one piece of
                the pie, the bigger picture is all about cloud engineering — the
                business side of the transition. By focusing on speed, agility,
                security, automation, and simplification of complex
                applications, our cloud engineering specialists design and build
                a cloud framework that empowers your team to work more
                efficiently and better serve your customers.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={cloudengineeringservices}
                  className="sm:ml-[100px]"
                ></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={cloudoptimizationservices} className=""></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Cloud Optimization Services
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  At the heart of our cloud migration consulting services is
                  optimization. We’re experts in planning and building
                  innovative, scalable, secure, and compliant cloud ecosystems.
                  We help our clients with in-depth analysis of all core
                  aspects: infrastructure, application setup, data flow, APIs,
                  and database management. Our cloud optimization work results
                  in a streamlined system that supports high performance across
                  all aspects of your business — operations, network, storage,
                  and computation.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                AWS Cloud Services (AWS)
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                AWS is a leading cloud provider that global businesses, from
                small businesses to enterprises across all industries and
                verticals, rely on for innovative, agile, cost-effective cloud
                computing. For over a decade, we’ve been providing AWS
                consulting services — in fact, we’re one of the first companies
                to be designated as an AWS consulting partner. Our team of cloud
                migration consulting experts has vast experience designing and
                implementing custom solutions that make AWS migration simple and
                seamless.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={azuremigrationservices} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Platform Migration
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Platform migration, be it from an on-premise system to the
                  cloud (i.e., AWS, Azure, Google Cloud) or cloud-to-cloud
                  migration (i.e., AWS to Azure), takes careful planning and
                  meticulous execution. You can count on our cloud migration
                  consulting services to cover every aspect of the process,
                  ensuring compatibility, ironclad security, and data integrity.
                  Our specialists conduct rigorous analysis and comprehensive
                  testing, giving you peace of mind throughout the process.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Azure Migration Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Microsoft’s Azure cloud platform is trusted by global companies
                for many reasons, from its comprehensive infrastructure (both
                enterprise and hybrid) to its robust functionality that makes
                cloud migration simple for businesses and budgets of all sizes
                and scopes. We’re a Microsoft Partner with a full team of
                developers certified in Azure managed services who are dedicated
                to making cloud migration a smooth, cost-effective process.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={platformmigration} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Platform Migration
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Platform migration, be it from an on-premise system to the
                  cloud (i.e., AWS, Azure, Google Cloud) or cloud-to-cloud
                  migration (i.e., AWS to Azure), takes careful planning and
                  meticulous execution. You can count on our cloud migration
                  consulting services to cover every aspect of the process,
                  ensuring compatibility, ironclad security, and data integrity.
                  Our specialists conduct rigorous analysis and comprehensive
                  testing, giving you peace of mind throughout the process.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={zsoft}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img src={zimg} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={zrpid}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={edplacecasesmallpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Develop Platforms That Drive Activation & Grow Sales
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Check out how our seasoned researchers and engineers
                    designed a unique platform that connects brands with
                    independent retailers using leading-edge cloud and mobile
                    strategies.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      AdvanceAdvance Case Study We Build World-Class Apps on
                      Startup Budgets
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we used Django to power the back-end portal for an
                    ambitious Spanish-language app startup, delivering an MVP on
                    a tight timeframe and limited budget.
                  </p>
                  <img
                    src={voylegalcasesmallpic}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Develop Bespoke Platforms That Empower Better Client
                      Outcomes
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how we developed a custom decision support engine
                    that empowers the staff of a healthcare company to easily
                    and quickly write, change, and test rules that facilitate
                    patient data.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className=""> 
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={howtoprioritizefeaturesforyourmvp}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail4} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail3} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CloudMigration;
