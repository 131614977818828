
import {
  omnichannedl,
  konga1m,
  wooclubandriod,
  androidappdevelopment,
  acomplete,
  Thumbnail1,
} from "../../assets";
import React, { useState } from "react";
const AntroidDeveloper = () => {

  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                NATIVE ANDROID APP DEVELOPMENT SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Deliver a custom mobile experience to your on-the-go customers
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Scale and grow your business with a custom-engineered native app
                for the world’s leading smartphone.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with Android Apps?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-dark  sm:text-6xl mb-5 max-sm:text-[20px] max-sm:text-center">
                <b>Expert, Full-Service Android App Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                While Apple may often seem like the name of the game with apps
                (and is certainly important!), Android is an even larger
                platform. Worldwide, Android makes up more than 70% of mobile
                operating systems! And there are millions of apps in the Google
                Play Store, where Android users go to download applications.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Developing an app can help you reach thousands or even millions
                of users. But the benefits of launching an app are maximized
                when you create a fast, reliable, and easy-to-find app with the
                assistance of an Android application development company.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Rapidsofts is here to be your partner in this endeavor.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Comprehensive Android Application Development Services</b>
            <p className="sm:text-2xl mt-3 max-sm:text-[20px] max-sm:text-center">
              Businesses from across a wide variety of industries rely on Net
              Solutions for Android app development services that solve
              challenges and drive revenue,
            </p>
            <p className="sm:text-2xl mt-3 mb-5 max-sm:text-[20px] max-sm:text-center">
              Our experienced team of Android app developers never stop learning
              about the newest Android SDKs and paradigms, furthering their
              expertise in even the most complex, leading-edge implementations
              such as AR/VR, smartwatches, beacons, and IoT devices.
            </p>
          </p>
          <img src={androidappdevelopment} className="mt-5 mx-auto"></img>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[0px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Android App Testing
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Rapidsofts' Android app developers have a steady regimen of
                  robust app testing during and after the development stage. If
                  needed, this can even include beta testing with live users.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Custom Web App Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Listening to your customers and anticipating their needs is
                  vital to earning their business and loyalty. We provide smart
                  solutions to your most complex challenges by using the best in
                  enterprise web app development services. We’re more than just
                  a vendor. Consider us your partner for all of your single-page
                  applications (SPAs), progressive web apps (PWAs), portals,
                  intranets, and cross-platform experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Android App Engineering
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Our goal as an Android app development company, is to make
                  sure your app is engineered to your exact specifications.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Along with this, our team provides additional oversight and
                  support for functional development and aesthetic design. The
                  end result is an app that meets the needs of your customer
                  base while looking great.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center  max-sm:mt-[50px]">
                  Essential Android App Development Services
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Along with crafting apps for a variety of industries, we also
                  have a full suite of mobile app consulting services to ensure
                  your app sees long-term, consistent performance and
                  conversions. Here are some of our key areas of focus.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Support Services to Meet Ongoing Needs
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Depending on your service contract with Rapidsofts, we also
                  can provide ongoing support services. Examples of these
                  services include making changes as your team determines
                  necessary adjustments, as well as app upgrades.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Rapidsofts Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={wooclubandriod} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      WooClubWooClub Case Study Woo Club: Video Commerce
                      Platform
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    This unique app lets users watch videos and shop for items
                    shown in them, featuring products alongside the video. The
                    user can purchase the item directly rather than heading to
                    another website. Alternatively, they can save items for
                    later.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={konga1m}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>kongakonga Case Study Konga: Mobile Ecommerce App</b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    The app gives users of Konga, the largest eCommerce store in
                    Nigeria, online shopping on the go.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    DIGITAL COMMERCE
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    What is PIM, its benefits, and the best PIM software for eCommerce?
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={omnichannedl} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    DIGITAL COMMERCE
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    Omnichannel vs Multichannel Retailing: The Complete Guide
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                    B2B COMMERCE,  DIGITAL COMMERCE
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                    How to Choose the Right B2B eCommerce Platform
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AntroidDeveloper;
