import React from 'react'
// import { Sidebar } from 'react-pro-sidebar'
// import Sidebar1 from '../../componets/Sidebar'
import Sidebar from '../../componets/sidebar/Sidebar'
import { digital_analytics, Digital_analytics1, digital_analytics2, digital_analytics_chart2, digi_anlytics, clients_quotes, Mario_maruffi, digital_analytics11, digi_anlytics_img3, ebook_img, Top_Data, Data_Visualization, Comparison_between, Key_Principles } from '../../assets';
import { Link } from "react-router-dom";


const DataAnalytics = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='sm:w-[400px] max-sm:w-[100%] sm:h-[500px] mt-[68px] sm:fixed overflow-auto'>
                    <Sidebar />
                </div>
                <div className='sm:ml-[420px]'>
                    <ul className='flex pt-[85px] max-sm:hidden'>
                        <li>
                            <Link to={""} className="font-medium text-[18px] hover:text-[#F13623]">Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px] hover:text-[#F13623]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px] hover:text-[#F13623]">Home Knowledge Hub</Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${digital_analytics})` }}
                        className="w-full sm:h-[400px]  max-sm:h-[200px] bg-center bg-cover mt-5"
                    >
                        <div className="relative sm:left-[53px] top-[80px] sm:w-[30%]">
                            <h5 className="font-extrabold sm:text-[41px] text-white max-sm:text-[20px] max-sm:text-center">
                            Data Analytics & Insights
                            </h5>
                        </div>
                    </div>
                    <div className='px-6 mt-5'>
                        <p className='sm:text-[27px] font-sans	font-light max-sm:text-[20px] max-sm:text-center'>Data is the inevitable force that continues to grow every single day — about 2.5 quintillion bytes of data is created every day. With everyone going digital, this data is expected to reach <span className='font-bold border-b-[2px] border-[#F13623]'>149 zettabytes by 2024</span>. Now if the data is growing, the complexity to extract meaningful insights from it will apparently increase exponentially. So, how do organizations take advantage of data and move ahead in the highly competitive business landscape?</p>
                        <p className='sm:text-[27px]  ui-sans-serif font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Data analytics is the solution that will help trigger data-driven and data-informed decision-making. In fact, leaders that take charge and invest in data analytics are more likely to generate leads and conversions, and, in turn, revenue.</p>
                    </div>
                    <div className='sm:px-32 mt-5'>
                        <h5 className='px-16  py-8 sm:text-[40px] font-extralight border-l-8 border-yellow-400 max-sm:text-[20px] max-sm:text-center'>53 percent of CEOs consider themselves the primary leader of their company’s analytics agenda. — McKinsey</h5>
                    </div>
                    <div className='px-6 mt-5'>
                        <p className='sm:text-[27px] font-sans	font-light max-sm:text-[20px] max-sm:text-center'>So, how to get started and leverage data to the best of your capabilities?</p>
                        <p className='sm:text-[27px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>In this write-up, we explore data analytics in detail to help you conduct better customer and market research. Here is what we will cover:</p>
                    </div>
                    <div className='mt-5'>
                        <img src={Digital_analytics1} />
                        <div className='sm:px-10'>
                            <div className='border-l-8 border-sky-400 relative top-[-100px]'>
                                <p className='px-2 pt-36 font-black sm:text-[58px] max-sm:text-[20px] max-sm:text-center'>What is Data Analytics?</p>
                            </div>
                            <div className='px-2.5'>
                                <div>
                                    <p className='sm:text-[27px] font-sans	font-light max-sm:text-[20px] max-sm:text-center'>Data analytics help in collecting, analyzing, cleaning, and extracting meaningful information from the abundance of available data. A data analytics software aids in the process to enable fast, data-driven, and data-informed decision making</p>
                                    <p className='sm:text-[24px] font-medium mt-5 max-sm:text-[20px] max-sm:text-center'>With data analytics, finding a needle in a haystack does not seem challenging after all.</p>
                                    <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Accelerating data analytics efforts will help problem-solving, decision-making, minimizing risk, and predictive analysis. <span className='font-medium border-b-[2px] border-[#F13623]'>Data analytics services</span> are a powerful tool that provides insight into the past and the present while also giving you the power to estimate the future.</p>
                                    <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Example of a powerful data analytics tool — Google Analytics — that every other organization uses for understanding how their web pages across the site are performing. Another popular example is that of Google Trends that is leveraged to help figure out the trending search terms.</p>
                                    <p className='sm:text-[26px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>This single page gives you a glimpse of the popularity of the input term with respect to — time, searches, region, categories, and more.</p>
                                    <div className='mt-5'>
                                        <Link to={""}>
                                            <img src={digital_analytics2} />
                                        </Link>
                                    </div>
                                    <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Where the popularity around data analytics SaaS products is growing, you could step forward and be a leader in the domain.</p>
                                    <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>To enter this lucrative market, the first step is to find the “problem that you want to solve for.”</p>
                                    <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Difference Between Qualitative and Quantitative Data</h5>
                                    <p className='sm:text-[26px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Both qualitative and quantitative data are part of data analytics. Any business considering data analytics needs to be aware about the difference between the two.</p>
                                    <p className='sm:text-[26px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Here’s what they imply:</p>
                                    <p className='font-bold sm:text-[23px] mt-5 max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Qualitative Data</span></p>
                                    <p className='sm:text-[24px] font-sans	font-light mt-4 sm:sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>It is mainly descriptive data that highlights the characteristics of type of content under observation. Qualitative data cannot be presented in the form of numbers, graphs, or charts.</p>
                                    <p className='sm:text-[24px] font-sans	font-light mt-4 sm:sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>For example, qualitative data revolves around how the website looks and what impression it gives.</p>
                                    <p className='font-bold sm:text-[23px] mt-5 max-sm:text-[20px] max-sm:text-center'>2. <span className='ml-3'>Qualitative Data</span></p>
                                    <p className='sm:text-[24px] font-sans	font-light mt-4 sm:sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>This is statistical-oriented analytics that provides insights that are measurable and conclusive. Quantitative data can be presented with the help of data visualization techniques such as charts, graphs, etc.</p>
                                    <p className='sm:text-[25px] font-sans	font-light mt-4 sm:sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>You can get quantitative data from running surveys, A/B testing, metrics from Google Analytics, etc.</p>
                                    <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Types of Data Analytics</h5>
                                    <Link to={""} >
                                        <img src={digital_analytics_chart2} className="mt-5" />
                                    </Link>
                                    <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Data Analytics Processs</h5>
                                    <p className='sm:text-[26px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Here’s a complete process that goes into data analysis:</p>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Define a Problem</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>What do you need from the data? What type of insights you are looking for. For example, you want to analyze how your customers are perceiving your brand.</p>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>2. <span className='ml-3'>Data Collection</span></p>
                                        <p className='text-[24px] font-sans	font-light mt-4 sm:ml-[33px]'>Collect the data from multiple available sources. There are two types of sources to collect data, which include:</p>
                                        <ul className='list-disc max-sm:p-3'>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Structured data:</strong><span className='font-light'>This is organized data that is easily discoverable, such as name, email address, location, phone number, etc. You can also say that it is custom data collected from internal sources such as CRM software, Google Analytics, social media engagement metrics, ERP systems, etc.</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Unstructured data: </strong><span className='font-light'>This is unorganized data without a predefined format, which makes it challenging to collect, process, and infer. You can collect this data from external sources such as audio and video files, social media APIs, globally available data, etc.</span></li>
                                        </ul>
                                        <div className='sm:px-32 mt-5'>
                                            <h5 className='px-16  py-4 sm:text-[40px] font-extralight border-l-8 border-yellow-400 max-sm:text-[20px] max-sm:text-center'>95 percent of businesses need to manage unstructured data. — Forbes</h5>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>3. <span className='ml-3'>Data Cleaning</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>Your data analytics team will help you clean the data, i.e., remove redundancies, inaccuracies, and invaluable from the collected data. This is the most time-consuming and crucial task of the entire process.</p>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>4. <span className='ml-3'>Data Analytics</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>Data analytics is implemented using data mining, business intelligence, or <span className='font-medium border-b-[2px] border-[#F13623]'>data visualization tools</span>. This step helps infer meaningful and actionable insights.</p>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>5. <span className='ml-3'>Make Decisions</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>You have the data and meaningful insights. Now it is time to make data-driven and data-informed decisions. The business drivers across the organization will brainstorm the way forward.</p>
                                    </div>
                                    <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Data Analytics: Tools and Techniques</h5>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Data Mining</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>Data mining is all about accessing and analyzing the databases for knowledge discovery. The data mining tools help analyze large datasets to uncover hidden patterns such as trends, statistics, and other data that is otherwise invisible.</p>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Effective data mining helps in:</p>
                                        <ul className='list-disc sm:ml-14 max-sm:p-3'>
                                            <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Organizing the distributed data into one place</li>
                                            <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Eliminating redundancies across data sets</li>
                                            <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Sorting through relevant data</li>
                                            <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Making data-informed decision</li>
                                        </ul>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>2. <span className='ml-3'>Business Intelligence</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>Key to faster and informed decision making</p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>This technology helps analyze historical and current data related to business information. BI also helps run predictive analysis that then helps drive a business towards growth.</p>
                                        <div className='sm:px-32 mt-5'>
                                            <h5 className='px-16  py-4 sm:text-[40px] font-extralight border-l-8 border-yellow-400 max-sm:text-[20px] max-sm:text-center'>The global business intelligence market is expected to grow to $29.48 billion by 2022, with a CAGR of 11.1%. — Reuters</h5>
                                        </div>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>The strategies used to implement successful business intelligence include:</p>
                                    </div>
                                    <div>
                                        <ul className='list-disc max-sm:p-3'>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Set Objectives:</strong><span className='font-light'>What would you like to measure? Setting objectives means that you need to be clear on the key performance indicators you would like to measure and monitor.</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Find the Right Business Intelligence Software: </strong><span className='font-light'>There are many cloud-based solutions available in the market. Choosing the right one that aligns with your business and functional requirements should be a priority.</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Gather and Clean Data: </strong><span className='font-light'>All the data from sales, marketing, and other sources needs to be gathered and data cleaning needs to be implemented to prepare it for effective business intelligence.</span></li>
                                        </ul>
                                    </div>
                                    <div style={{ backgroundImage: `url(${digi_anlytics})` }} className="w-full h-[470px] bg-center bg-cover mt-5">
                                        <div className='relative sm:left-[90px] top-[50px]'>
                                            <h5 className='font-extrabold text-[41px] sm:w-[500px] max-sm:text-[20px] max-sm:text-center'>How a team of 3 helped an Automobile E-commerce to build a sales & inventory strategy using Power BI in just 60 days?</h5>
                                            <Link to={""}><button type="button" className='px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 max-sm:text-[15px] max-sm:text-center '>Access Full Case Study</button></Link>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>3. <span className='ml-3'>Data Visualization</span></p>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[33px] max-sm:text-[20px] max-sm:text-center'>Data visualization helps create visually delightful and easy to decipher charts and reports, thus enabling fast decision-making. The goal of visualization is to represent complex data in an understandable format.</p>
                                    </div>
                                    <div className='bg-[#FFF9EC] mt-5'>
                                        <div className='sm:px-24 py-4 max-sm:px-10'>
                                            <img src={clients_quotes} />
                                            <h5 className='sm:text-[25px] font-extralight mt-7 max-sm:text-[20px] max-sm:text-center'>Good data visualization reduces the users’ cognitive load while improving their decision-making.</h5>
                                            <div className='flex mt-5'>
                                                <div>
                                                    <img src={Mario_maruffi} />
                                                </div>
                                                <div className='ml-6'>
                                                    <h5 className='sm:text-[21px] font-bold mt-3.5 max-sm:text-[20px] max-sm:text-center'>Mario Maruffi</h5>
                                                    <span className='sm:text-[16px] font-medium max-sm:text-[20px] max-sm:text-center'>Experience Design, Consultant</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <h5 className='sm:text-[25px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>Common types of data visualization include — charts, graphs, videos, images, funnels, etc.</h5>
                                        <div className='px-32 py-16'>
                                            <img src={digital_analytics11} />
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className='sm:text-[25px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>The data visualization process is a five-step process, which includes:</h5>
                                        <ul className='list-disc max-sm:p-3'>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Data Collection:</strong><span className='font-light'>Gather data from all the available sources</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Running data analytics:</strong><span className='font-light'>Finding meaningful information by parsing through the available data.</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Visualize:</strong><span className='font-light'>Choose a data visualization software and the type of data visualization you need (graphs, pie charts, images, etc.)</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Create a Report: </strong><span className='font-light'>Focus on creating a report around the findings and visualizations available. The content should align with the visualizations to offer meaningful insights.</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><strong>Share:</strong><span className='font-light'>The final step is to share the report across the organization or your users (for whatever audience you are creating the report)</span></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Benefits of Data Analytics</h5>
                                        <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Some of the benefits of following data analytics practices include:</p>
                                        <ul className='list-disc max-sm:p-3'>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Enables faster and effective decision making</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Helps forecast, plan, budget, and innovate in a strategic manner</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Provides a competitive edge</span></li>
                                            <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Improve conversion rate optimization</span></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Data Analytics Best Practices</h5>
                                        <p className='text-[24px] font-sans	font-light mt-4'>Here are some best practices to follow when creating a product with data-analytics capabilities:</p>
                                        <div className='mt-4'>
                                            <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Identify a Pain Point</span></p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 ml-[32px] max-sm:text-[20px] max-sm:text-center'>What is the problem that you are solving for?</p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 ml-[32px] max-sm:text-[20px] max-sm:text-center'>Before you hire a data analytics team, you need to know why you need a data analytics software. As it is your business, you will have a better understanding of what data you need to analyze and what is the objective of doing so.</p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 ml-[32px] max-sm:text-[20px] max-sm:text-center'>For example, a CXO does not have time for going through all the organizational driven data. Data analytics can help them gain access to the right information at the right time.</p>
                                        </div>
                                    </div>
                                    <div style={{ backgroundImage: `url(${digi_anlytics_img3})` }} className="w-full h-[420px] bg-center bg-cover mt-5">
                                        <div className='relative sm:left-[53px] top-[50px]'>
                                            <h5 className='font-extrabold sm:text-[41px] sm:w-[364px] max-sm:text-[20px] max-sm:text-center'>How we Designed a Data Monitoring Dashboard for Quality-conscious CXOs</h5>
                                            <Link to={""}><button type="button" className='px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3'>Access Case Study</button></Link>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>2.<span className='ml-3'>Hire a Team of Experts</span></p>
                                        <div className='sm:ml-8 mt-4'>
                                            <p className=' font-sans font-light sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>The next step is to hire a team for data analytics, design, and <span className='font-medium border-b-[2px] border-[#F13623]'>product development</span>. Here’s why:</p>
                                            <div className='ml-3 mt-4'>
                                                <h5 className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>a:<span className='ml-3'>Data Analytics</span></h5>
                                                <p className=' font-sans font-light sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>The analytics team will help collect, clean, organize data from across sources.</p>
                                            </div>
                                            <div className='ml-3 mt-4'>
                                                <h5 className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>b:<span className='ml-3'>Design team</span></h5>
                                                <p className=' font-sans font-light sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>The design team will help create a <span className='font-medium border-b-[2px] border-[#F13623]'>dashboard design</span> while considering the best data visualization practices.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ backgroundImage: `url(${ebook_img})` }} className="w-full h-[350px] bg-center bg-cover mt-5">
                                        <div className='relative left-[53px] top-[50px] w-[56%]'>
                                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>What are the five key principles of a meaningful dashboard design?</h5>
                                            <Link to={""}><button type="button" className='px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 sm:w-[229px]'>Read Now</button></Link>
                                        </div>
                                    </div>
                                    <div className='ml-8 mt-5'>
                                        <h5 className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>c:<span className='ml-3'>Product development</span></h5>
                                        <p className=' font-sans font-light sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>The development team will <span className='font-medium border-b-[2px] border-[#F13623]'>create an MVP</span> nd then strategically move towards adding/updating features iteratively and incrementally based on feedback.</p>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>3.<span className='ml-3'>Introduce the Data Analytics Software in the Organization/Market</span></p>
                                        <p className=' font-sans font-light sm:text-[23px] sm:ml-8 mt-4 max-sm:text-[20px] max-sm:text-center'>Host your data analytics software on the cloud and make it available for everyone. If your tool is for internal usage, run training sessions for your workforce to enhance its understandability. Similarly, if you want to turn it into your<span className='font-medium border-b-[2px] border-[#F13623]'>Business Model</span>— run marketing campaigns to spread awareness around the problem it solves.</p>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='font-bold sm:text-[23px] max-sm:text-[20px] max-sm:text-center'>4.<span className='ml-3'>Keep Updating</span></p>
                                        <p className=' font-sans font-light sm:text-[23px] sm:ml-8 mt-4 max-sm:text-[20px] max-sm:text-center'>Follow a<span className='font-medium border-b-[2px] border-[#F13623] ml-1'>product mindset over project mindset</span>and keep updating the software based on new market research and data analytics trends.</p>
                                    </div>
                                    <div className='mt-5'>
                                        <img src={Top_Data} />
                                        <div className='sm:px-10 '>
                                            <div className='border-l-8 border-sky-400 relative top-[-100px]'>
                                                <p className='px-2 pt-36 font-black sm:text-[49px] max-sm:text-[20px] max-sm:text-center'>Top Data Analytics Trends for 2022</p>
                                            </div>
                                            <div>
                                                <p className='text-[24px] font-sans	font-light'>The top data analytics trends to follow include:</p>
                                                <ul className='list-disc max-sm:p-3'>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Clean and organized data structures</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Relying on artificial intelligence (AI) for running advanced analytics</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Create simplified dashboards that follow trending design practices</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Increase in demand for real-time analytics</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>The emergence of embedded analytics</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>The rise in demand for machine-learning</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Predictive analytics will take a front seat</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[50px] max-sm:text-[20px] max-sm:text-center'><span className='font-light'>Cloud adoption of analytics solutions</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Conclusion</h5>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>If data is like an iceberg in the ocean — you need to run analytics for the tip of the iceberg as well as what lies at the bottom. Effective data analytics helps in discovering everything about your business and your customers and making informed decisions in turn.</p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>This comprehensive blog covers everything that you should know about data analytics and its relevance for your business. If done right, you could find your way ahead in the leaders vs laggards race.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Featured Blogs</h5>
                <div className='sm:flex gap-5'>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Key_Principles} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Data Analytics & Insights</Link >
                                    <Link to={""} className='text-[#F13623] ml-2 tracking-widest text-[12px]'>Data Visualization</Link>
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                        5 Key Principles of a Good Dashboard Design </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Data_Visualization} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Data Analytics & Insights</Link >
                                    <Link to={""} className='text-[#F13623] ml-2 tracking-widest text-[12px]'>Data Visualization</Link>
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                        Top 7 Data Visualization Trends & Tools for the Years to Come </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Comparison_between} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Hadoop Vs Spark — Choosing the Right Big Data Framework</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        Hadoop Vs Spark — Choosing the Right Big Data Framework  </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataAnalytics