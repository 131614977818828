import React from 'react'
import { Link } from "react-router-dom";
import { blockchain_banner, Blockchain_Solutions1, Blockchain_sol2, Blockchain_sol3, Blockchain_sol4, Blockchain_sol5, Blockchain_sol6, value_trade, internet_of, increased_transparency, Digital_tra2, ChatGPT, Digital_tra1 } from '../../assets';


const BlockchainDev = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[32px]'>
                            Affordable Blockchain Services for Smart Business Solutions
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Gain the edge on your competition in this digital era. Get the Best blockchain services on the web with Rapidsofts.
                        </p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-32 h-10 text-[12px] font-extrabold mt-9'>Know more</button></Link>
                    </div>
                    <div className='w-[45%] mt-5'>
                        <img src={blockchain_banner} />
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>

                            Witness the Marvel of Blockchain at Your Disposal
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>

                            The internet has given birth to numerous vital inventions and the majority of them have led to the evolution of the market. With the global market experiencing major shifts routinely, evolution has become a must for every organization. The same is true for every industry as tech like blockchain will act as the stimulant to bring change. RevInfotech has been at the heart of the digital market for many years, and our expertise in the blockchain circle can help you gain momentum as well. Join the prestigious blockchain circle with us.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4'>
                        <h5 className='font-bold text-[25px]'>Our Blockchain Technologies</h5>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5]'>
                            <Link to={""}><img src={Blockchain_Solutions1} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Blockchain Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={Blockchain_sol2} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Blockchain Integration</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={Blockchain_sol3} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Crypto Consultancy</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={Blockchain_sol4} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Blockchain Idea Generation</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={Blockchain_sol5} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Crypto Trading</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={Blockchain_sol6} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Defi</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 mt-3 bg-yellow-300'>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal ">See More Technologies…</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#093D77] w-full h-40 mt-5'>
                <h5 className='text-center font-extrabold text-[33px] text-white pt-14'>Enjoy the Feel of True Advancement with Our Blockchain Solutions</h5>
            </div>
            <div className='container mx-auto'>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Quality Services & Benefits</h5>
                <div className='flex flex-row gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg'>
                        <div className='px-10 py-11'>
                            <img src={value_trade} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>VALUE TRADE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Start trading value like a professional or open your trading service with Rapidsofts. Get your business started with our blockchain solutions.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={increased_transparency} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>INCREASED</h5>
                            <h5 className='text-[22px] font-bold text-center tracking-widest'>TRANSPARENCY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>With our blockchain services, you do not have to falter about transparency. Rapidsofts ensures that the quality standards remain to your expectations.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={internet_of} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>DATA SECURITY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Secure your consumer data with the latest blockchain technology and win the trust of your consumers. Never fall back with Rapidsofts.</p>
                        </div>
                    </div>
                </div>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Get Added Benefits</h5>
                <div className='flex flex-row flex-wrap gap-5 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>GET ADDED ADVANTAGES</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Forget the tediousness of record-keeping with our effective blockchain solutions. Record maintenance can consume a considerable amount of resources. Save those vital resources with our help and utilize them effectively.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>INCREASED SECURITY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Transaction maintenance is an added benefit of blockchain technology. With blockchain, you can stay assured that there will not be any unwarranted modifications without your consent. Avail of this level of security with Rapidsofts.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>BETTER TRANSPARENCY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Blockchain’s distributed ledger approach enhances the transparency aspect of your transactions. As every node within the network carries the document’s copy, your need of keeping multiple files reduces while your transparency increases.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>ENHANCED</h5>
                            <h5 className='text-[22px] font-bold text-center tracking-widest'>PRODUCTIVITY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>As the hassles of traditional practices decrease, any unnecessary blockage from the chain of command and routine processes is also eradicated. This results in your output charts improving steadily and surely.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>SMART AUDITABILITY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>With blockchain’s smart transaction recording, the auditability aspect of the factor improves as well. Your every transaction is recorded for unforeseen periods, thus, allowing you to edit or review them in any way you desire.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>
                            How Blockchain Technology can help Your Business?
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Nowadays, every other business is aiming to garner a global consumer base. This increases the competition level exponentially, while the target market remains constant. Therefore, organizations are forced to enhance their proceedings to attract more consumers. Doing so is only possible with effective and efficient means like blockchain. Blockchain facilitates numerous benefits like security, stability, and traceability. Integrating blockchain within your venture is a necessity in today’s market, and you can achieve the same with us.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]'>
                        <h5 className='text-[20px]'>
                            Why Pick Rapidsofts for Your Blockchain Needs?
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Rapidsofts has been a household name in the digital domain for a long time. The firm has invested in numerous promising ventures, and our hard work has led to their comprehensive success. Although blockchain as an industry is something relatively new in the market, RevInfotech has had its eyes gouged on it from long before. We understand the complete potency of blockchain technology and how it can revolutionize the market. That is why we evolve our practices for formulating the best solutions for us and our clients.                        </p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9'>Help My Business</button></Link>
                    </div>
                </div>
                <h5 className='text-[34px] font-extrabold text-center pt-20'>Insights</h5>
                <div className='flex flex-row gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={ChatGPT} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 23, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Making the Old New: Hiring Fresh
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Digital_tra1} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 24, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[30px] px-4 font-semibold leading-9 mb-12'>
                                Trends, Challenges, and Solutions for Healthcare’s Digital Transformation
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5 '>
                        <img src={Digital_tra2} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 27, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Here are some strategies, checklists, and FAQs about cloud migration
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlockchainDev