import React from 'react'
import { digital_img,Data_Visualization, Comparison_between, Key_Principles, Digital_ofc, Digital_technologies_used, FOCUS_Digital, The_Digital_ROI,healthcare } from '../../assets';
import { Link } from "react-router-dom";
import Sidebar3 from '../../componets/sidebar/Sidebar3';

const DigitalTransformation = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='w-[400px] h-[500px] mt-[68px] fixed overflow-auto'>
                    <Sidebar3/>
                </div>
                <div className='ml-[420px]'>
                    <ul className='flex pt-[85px]'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">  Digital Transformation</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${digital_img})` }} className="w-full h-[400px] bg-center bg-cover mt-5">
                        <div className='relative left-[53px] top-[80px] w-[30%]'>
                            <h5 className='font-extrabold text-[41px]'>What Is Digital Transformation?</h5>
                            <p className='text-[27px] font-sans	font-light mt-4'>Rethink Your Business for the Omnichannel Age</p>
                        </div>
                    </div>
                    <div className='px-32 py-16'>
                        <p className='text-[50px] font-sans	font-extralight'>Digital transformation and a focus on customer experience can generate a 20-30% increase in customer satisfaction and economic gains of 20-50%.</p>
                    </div>
                    <div className='mt-5 px-10'>
                        <p className='text-[27px] font-sans	font-light mt-5'>The customer experience is no longer the next competitive battleground. The paradigm shifts away from the “traditional consumer- business relationship” and toward fulfilling digital experiences with the products and businesses that customers choose. This has resulted in profound implications for how companies will organize to deliver and excel in serving seamless customer experience.</p>
                        <p className='text-[27px] font-sans	font-light mt-5'>Businesses that strive to be customer-centric must pass through a process of a digital transformation to meet this consumer’s varying demands.</p>
                        <p className='text-[27px] font-sans	font-light mt-5'>With nearly 78% of companies undergoing a digital transformation, it should come as no surprise that today, businesses are trying to leverage the power of digital transformation to shape themselves, to shape customers, and then to shape customer’s expectations.</p>
                        <p className='text-[27px] font-sans	font-light mt-5'>However, even though almost every company seems to be going through digital transformation, <span className='font-medium border-b-[2px] border-[#F13623]'>84% of digital transformation efforts fail.</span></p>
                        <p className='text-[27px] font-sans	font-light mt-5'>Big names like <span className='font-medium border-b-[2px] border-[#F13623]'>GE, Ford, and P&G</span> are some notable failed digital transformation examples. These companies poured about $1.3 trillion into digital transformation initiatives, 70% of which was wasted on failed programs. One of the reasons: they didn’t truly understand the concept of “digital transformation.”</p>
                    </div>
                    <div className='px-32 mt-5'>
                        <div className='px-16 py-8 border-l-8 border-yellow-400'>
                            <h5 className='text-[40px] font-extralight mt-4'>A study says that 69% of people are either neutral or don’t feel confident when they are asked to explain the digital transformation to someone.</h5>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <img src={Digital_ofc} />
                    </div>
                    <div className='px-10'>
                        <div className='border-l-8 border-sky-400 relative top-[-66px]'>
                            <p className='px-1 pt-36 font-black text-[58px]'>Digital Transformation Definition:</p>
                        </div>
                        <div className='px-3'>
                            <div>
                                <p className='text-[27px] font-sans font-light'>In today’s experience era, embarking upon a CX-led digital transformation journey is no longer a luxury, but a necessity for survival. However, to achieve this, you have to transform all areas of your business — culture, technology, and processes — by integrating them with the fuel of digital technology. What is Digital Transformation?</p>
                            </div>
                            <div className='px-32 mt-5'>
                                <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                    <h5 className='text-[40px] font-extralight mt-4'>Digital transformation is the process of integrating and leveraging emerging and digital technologies across all areas of a business to meet the dynamic business and market needs.</h5>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='text-[27px] font-sans	font-light'>A <span className='font-bold border-b-[2px] border-[#F13623]'> successful digital transformation</span> demands a shift of focus to the edge of the enterprise and more agile data centers to support that edge. For building agile business through digital transformation, it is also required to rethink the usage of people, technology, and processes to fundamentally change the business model, driven in pursuit to deliver a seamless customer experience around products and services.</p>
                            </div>
                            <div className='mt-5 px-28'>
                                <img src={Digital_technologies_used} />
                            </div>
                            <div>
                                <h5 className='text-[50px] font-extrabold mt-4'>Why Does Digital Transformation Matter?</h5>
                            </div>
                            <div className='mt-5'>
                                <p className='text-[27px] font-sans	font-light'>Before the ubiquity of the internet, Daniel Bell, the late Harvard sociologist, predicted in his seminal work — <span className='font-bold border-b-[2px] border-[#F13623]'>The Coming of Post-Industrial Society</span> — that came true after four decades: the advent of the Information Age. Today, the IT industry is worth $2 trillion, much as Bell foretold.</p>
                                <p className='text-[27px] font-sans	font-light mt-5'>Bell’s dynamic is accelerating today in the 21st century with the bombardment of various emerging technologies, including IoT, AI, big data, and elastic cloud computing. It is changing the landscape of <span className='font-bold border-b-[2px] border-[#F13623]'>digital commerce</span>, customer engagement, and marketing in such a way that it impacts every area of your business.</p>
                            </div>
                            {/* <div style={{ backgroundImage: `url(${Digital_transformation_trends})` }} className="w-full h-[400px] bg-center bg-cover mt-5">
                                <div className='relative left-[53px] top-[80px] w-[30%]'>
                                    <h5 className='font-extrabold text-[41px]'>What Is Digital Transformation?</h5>
                                    <p className='text-[27px] font-sans	font-light mt-4'>Rethink Your Business for the Omnichannel Age</p>
                                </div>
                            </div> */}
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>F.O.C.U.S. — The Digital Transformation Framework</h5>
                                <p className='text-[27px] font-sans	font-light mt-5'>In reality, the digital transformation strategy of every organization will vary based on their specific demands and challenges, thereby paving the way for a customized digital transformation framework. However, there are a few constituents that remain common in the digital transformation framework that every business leader should consider as they embark on the digital transformation journey.</p>
                                <div className='px-16'>
                                    <img src={FOCUS_Digital} />
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Formation of Connected Platforms</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>“Platform” is defined as a services-based framework, which is used to build an interoperable set of services that can be amalgamated to fabricate applications to achieve business goals.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A robust platform approach in the digital transformation journey allows your business to leverage a structured, scalable, and flexible foundation, which can eventually help speed up development time.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Operational Agility</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold border-b-[2px] border-[#F13623]'>Agile Transformation</span> is at the heart of “Digital,” and scaling is essential for any successful digital transformation in large enterprises. Even a study reveals that 68% of companies identified “building agile business through digital transformation” as one of their most important initiatives of digital transformation.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Culture and Leadership</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Companies today can uncover novel and important sources of data. What’s important is ensuring that you have the culture, talent, and organizational vision needed to turn that data into insights. Digital business transformation is hard-pressed to happen in the absence of leadership commitment; there are numerous failed digital transformation examples that bear out this fact.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Unified Digital Technology</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>An ideal digital transformation strategy aims for using technology in the right way, in a secure manner. For instance, enterprises should leverage the support of the cloud to set up robust digital transformation frameworks.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Research indicates that CEOs see cloud computing as the second-biggest technology trend (after the Internet of Things) that will significantly impact their business in the coming years.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Seamless Customer Experience</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Why do you plan to undergo a digital transformation? If you fail to answer “to deliver a good CX,” then you’ve never fully understood the answer to the crucial question of “what is digital transformation?”. Remember, customers are the most important stakeholders of any organization, and serving them in the best possible way is the sole aim of digital transformation.</p>
                            </div>
                            <div className='px-32 mt-5'>
                                <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                    <h5 className='text-[40px] font-extralight mt-4'>95% of respondents in the <span className='font-bold border-b-[2px] border-[#F13623]'>Couchbase Survey</span> said that the ultimate aim of digital transformation efforts should be to give customers and end-users a truly unique experience.</h5>
                                </div>
                            </div>
                            <div>
                                <p className='text-[27px] font-sans	font-light mt-5'>This digital transformation framework will accommodate any organization that is planning and preparing for change. The first checkbox to tick before embarking upon a digital transformation journey is to accept that your business requires a transformation and then must be willing to accept the fact that data will give guidance, however, leadership must take decisions.</p>
                                <h5 className='font-extrabold text-[41px] mt-5'>The “5 Ds” Driving the Digital Transformation</h5>
                                <p className='text-[27px] font-sans	font-light mt-5'>In this era of Digital Darwinism, the success of your organizations’ digital transformation strategy cannot just rely upon deploying more technologies. Several other vital drivers that enable the successful completion of the digital transformation process are:</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Digital-Savvy Leaders</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Digital transformation needs to be adopted across the<span className='font-bold border-b-[2px] border-[#F13623]'>enterprise,</span> not just in a few teams that are trying to improve their domains. And it requires full support from leadership According to the <span className='font-bold border-b-[2px] border-[#F13623]'>Harvard Business Review,</span> digital transformations that don’t have strong leadership from the top tend to be ad hoc and chaotic..</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[40px] font-extralight mt-4'>When Angela Ahrendts took over as CEO of Burberry, she launched a bold ‘Art of the Trench’ campaign and an aggressive move towards digital transformation, which signaled her high level of ambition and rejuvenated the organization.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Digital Security</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Data security is a concern for most visionaries as cybersecurity is complex, dynamic, and fast-changing. The latest reports from industry research experts like Forrester and Gartner are pointing towards the same concerns.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Thus, successful digital transformation requires a transformation of security as well, which is a top-most challenge faced by several organizations when embarked upon a digital transformation journey.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[40px] font-extralight mt-4'>55% of companies said that security was the number-one challenge they face when implementing digital enablement technologies.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Data Silos: Unlocked</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Today, organizations are sitting on mountains of unrefined data, oblivious about how to store, process, and utilize that valuable resource. Data and analytics are two vital components that drive digital business.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is vital to break the traditional data pipelines and introduce a process-oriented data methodology that focuses on collaborative data management practice.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Digital Culture</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Siloed mindsets and a poor customer focus have long bedeviled organizations. In today’s digital era, finding a solution to these cultural problems is no longer an option, rather a necessity.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A digital culture encourages and enables employees to look outward and engage with customers and partners to develop new solutions. <span className='font-bold border-b-[2px] border-[#F13623]'>McKinsey Digital research</span> reveals that “Organizational Culture” is one of the biggest impediments to successful digital transformation projects.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[40px] font-extralight mt-4'>Organizations in which the senior management team communicates openly about digital transformation progress and success are <span className='font-bold border-b-[2px] border-[#F13623]'>8  times more likely to be successful</span>.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Digital Twins</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Digital twins refer to the digital replica of a real-world entity or system. The technology that was initially limited to the manufacturing world has entered the digital world. It is now getting merged with technologies like the Internet of Things, Artificial Intelligence, and Data Analytics, thereby emerging as one of the top <span className='font-bold border-b-[2px] border-[#F13623]'>digital transformation trends</span>.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A digital culture encourages and enables employees to look outward and engage with customers and partners to develop new solutions. <span className='font-bold border-b-[2px] border-[#F13623]'>McKinsey Digital research</span> reveals that “Organizational Culture” is one of the biggest impediments to successful <span className='font-bold border-b-[2px] border-[#F13623]'>digital transformation projects</span>.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[40px] font-extralight mt-4'>The global <span className='font-bold border-b-[2px] border-[#F13623]'>digital twin market size</span> is projected to reach USD 48.2 billion by 2026.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <img src={The_Digital_ROI} />
                            </div>
                            <div className='mt-5'>
                                <h5 className='text-[50px] font-extrabold'>Digital Transformation Across Various Industries</h5>
                                <p className='text-[24px] font-sans	font-light mt-5'>The role of digital technology in today’s era of experience is rapidly drifting away from being a driver of marginal efficiency. It is shifting towards being an enabler of fundamental innovation and disruption. Business leaders across all sectors are grappling with the strategic implications of these transformations for their organizations, industry ecosystems, and society.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Automotive</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Connected vehicles, digitized ecosystems, and autonomous vehicles help to build massive value in the automotive sector. According to a study by GE Digital, by 2022, automotive manufacturers expect that 24% of their plants will be smart factories.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The automotive industry has understood that meeting the consumer demands for a digitally improved experience is vital when the customers are researching, purchasing, and operating a car.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Healthcare</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The digital transformation in the healthcare industry is driven by the aging population challenge, increasing costs, increasing chronic diseases, and changed expectations of customers.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The four digital transformation trends — smart care, care anywhere, empowered care, and intelligent healthcare enterprise — will play a vital role in the healthcare industry.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[40px] font-extralight mt-4'>79% of the healthcare consumers quote ‘search for doctors ratings and reviews’ as the topmost reason they prefer digital.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Media</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The dynamic audience of today’s world demands customized and personalized content, excellent viewing recommendations, and online tools to recreate “office water-cooler” conversations. However, media purveyors are unable to meet these ever-changing demands of consumers.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Coming up with the right content and its presentation in the correct context requires innovation and digital transformation. Intelligent advertising operations and insightful content production can help to build an enormous value in the media and entertainment industry.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Logistics</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A study says that to build a robust global digital platform in the logistics sector, $1.5T is at stake. The importance of these platforms will increase in time to come because it will open the doors for the small companies to compete with the established giants.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>In this industry rife with existing inefficiencies, digital transformation could be the savior. Crowdsourcing is expected to have the most significant impact on the logistics industry.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Customer Analytics</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>With the consumer controlling this entire game, consumer industries are being forced to rethink their digital value proposition. This industry is dealing with a flood of forces simultaneously.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>From an aging population to Millennials entering the workforce, the Internet of Things to online consumer communities, regulatory reforms to consumers’ increased focus on responsible environmental practices, consumer companies are under the microscope.</p>
                            </div>
                            <div className='px-16 mt-5'>
                                <img src={healthcare} />
                            </div>
                            <div className='mt-5'>
                                <h5 className='text-[50px] font-extrabold'>Parting Words</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>Digital technology, despite its seeming ubiquity, has only begun to penetrate most industries. As it continues its advancement, the implications for revenues, profits, and opportunities will be dramatic and profuse.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Organizations with a good digital transformation strategy on the table can grow faster. However, digital transformation is a complex process and considering its complexity, organizations need to create a substantial ecosystem to drive change seamlessly.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>Being digital provides organizations with an opportunity to boost customer experience. However, it requires cross-functional leadership — CMO, CTO, CIO, and CXO — to get it right. In a nutshell, digital transformation is about blending technology around a shared vision with no room for silo mentalities anymore.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Key_Principles} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Data Analytics & Insights</Link >
                                    <Link to={""} className='text-[#F13623] ml-2 tracking-widest text-[12px]'>Data Visualization</Link>
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                        5 Key Principles of a Good Dashboard Design </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Data_Visualization} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Data Analytics & Insights</Link >
                                    <Link to={""} className='text-[#F13623] ml-2 tracking-widest text-[12px]'>Data Visualization</Link>
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                        Top 7 Data Visualization Trends & Tools for the Years to Come </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Comparison_between} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Hadoop Vs Spark — Choosing the Right Big Data Framework</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        Hadoop Vs Spark — Choosing the Right Big Data Framework  </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalTransformation