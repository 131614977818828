import React from 'react'
import { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Sidebar5 = () => {
    const [open, setOpen] = useState("");

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    return (
        <>
            <div className='static'>
                <Fragment>
                    <Accordion open={open === 1}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(1)}>
                                <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]"> Product Development</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div className='pl-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Introduction</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Product Development?</Link>
                            </div>
                            <div className='pl-4 mt-4 flex'>

                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What are the Stages of Product Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is the difference between Product Development & Software Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Why is it Vital to Release Software to Production Multiple Times a Day?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Product Development Trends 2021</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is the Importance of Product-Market Fit?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Web Product Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Mobile App Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Agile Product Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is MVP Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Cloud-based Product Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is the Importance of Quality Assurance (QA) in Product Development?</Link>
                            </div>
                            <div className='pl-4 mt-4'>
                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Why is Software Outsourcing a Good Business Strategy?</Link>
                            </div>
                        </AccordionBody>
                        <AccordionBody className=" !bg-[#E6E9F0]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] '>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3 '><Link to={""} className="hover:text-[#F13623]">Agile Software Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Cloud Computing</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Custom Software Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">DevOps</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Minimum Viable Product</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Mobile App Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Outsourcing</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Software as a Service (SaaS)</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Software Testing</Link></p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 2}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(2)}>
                                <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]">Digital Commerce</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'>B2B eCommerce</p>
                                    </li>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-3'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'>Magento</p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 3}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(3)}>
                                <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]">Experience Design</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'>User Experience</p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 4}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(4)}>
                                <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]">Digital Experience Platforms</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody>
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623] ml-3">Customer Relationship Management (CRM)</Link>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]"><Link to={""}>Digital Transformation</Link></h5>
                    </div>
                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]"><Link to={""}>Data Analytics & Insights</Link></h5>
                    </div>
                </Fragment>
            </div>
        </>
    )
}

export default Sidebar5