// import React from 'react'
import {
  Img1,
  frontrushm,
  GWA1m,
  flimskillm,
  Thumbnail,
  Thumbnail1,
  vnimg,
  acomplete,
  zrpid,
  zgaga,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const DevOps = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                DEVOPS SOLUTIONS & SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Disrupt Your Market with Accelerated Product Releases Driven by
                Expert DevOps Consulting
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Amplify your agility & win a competitive edge by continuously
                evolving your customer experience.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Need Help with DevOps?</h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
          <div className="sm:ml-3">
            <div className="">
              <img
                src={vnimg}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img src={zrpid} className=" w-full object-cover"></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img
                src={zgaga}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          {/* <img src={bannerods} className="w-full h-[600px]" /> */}
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-dark  sm:text-6xl mb-5 max-sm:text-[20px] max-sm:text-center">
                <b>Continuous Delivery, Consistent Value</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                The opportunities in digital product development evolve and
                expand on a daily basis, which is why agile brands win. For more
                than 20 years, we've embraced the process of continuous
                improvement to minimize waste and cost and maximize
                opportunities. So, even before seamless collaboration between
                Development and Operations crystallized as DevOps, its spirit
                has been the cornerstone of our culture. Today you can count on
                us to continuously innovate, deploy, and iterate for faster,
                automated, reliable software releases.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[25px] max-sm:text-center">
            <b>Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  DevOps Consulting Services
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Understanding what customers want and acting on real-world
                  inputs to execute quickly and flawlessly in software
                  development can be two different things without a rock-solid
                  DevOps-driven approach. We partner with you by devising DevOps
                  strategy to power either a new project or to optimize existing
                  implementations for improved performance.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Custom Web App Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Listening to your customers and anticipating their needs is
                  vital to earning their business and loyalty. We provide smart
                  solutions to your most complex challenges by using the best in
                  enterprise web app development services. We’re more than just
                  a vendor. Consider us your partner for all of your single-page
                  applications (SPAs), progressive web apps (PWAs), portals,
                  intranets, and cross-platform experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Development & Deployment Pipelining
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Your technical team delivers best when they are focused on
                  devising solutions rather than continually reinventing
                  collaborative approaches or engaging in repetitive tasks. Our
                  certified DevOps team members are experts in setting up
                  integrative processes, including Continuous Integration (CI),
                  Continuous Testing (CT), and Continuous Deployment (CD) —
                  individually or in combination — to ensure problems are
                  rapidly identified and rectified for ongoing business success.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Infrastructure & Environment Management
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Smart management of the tools, applications, processes, and
                  daily operations is crucial to accelerating product releases.
                  Our seasoned DevOps services company is here to plan and
                  execute configuration management and automate provisioning and
                  scaling setups – including extreme load graceful degradation
                  and disaster recovery/rollback mechanisms. When our DevOps
                  pros manage your infrastructure and environment, it frees you
                  up to focus on other business development tasks.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Monitoring & Maintenance
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Keeping the release process seamless and trouble-free is a
                  proactive, detail-driven discipline. Our certified DevOps team
                  are experts in logging, monitoring, and maintaining the
                  infrastructure running your application with time-tested best
                  practices and workflows. Our five-step iterative process runs
                  from identifying risks and controls, through monitoring and
                  reporting implementation to automated remediation for swift,
                  reliable releases and better end products.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  eCommerce Platform Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  An intuitive shopping experience and easy, secure checkout are
                  essential for today’s online customers. Rapidsofts is a
                  certified Adobe Solution Partner for Magento—Adobe’s leading
                  eCommerce platform. Our online store specialists can provide
                  your team with fully customized eCommerce development
                  solutions. We engineer the right solution for your brand — one
                  that optimizes traffic and boosts your sales by turning
                  browsers into buyers.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Containerization
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Containerization has measurable benefits for both developers
                  and IT operations when it comes to creating and deploying
                  applications swiftly and securely. Our expert team consults,
                  configures, and orchestrates containerization of applications
                  though popular technologies like Kubernetes for lightweight,
                  flexible, agile application development, monitoring, and
                  maintenance.
                </p>
              </div>
            </div>
          </div>

          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={Img1} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Accelerate Time to Market to Optimize B2B Opportunities
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we designed and deployed a vendor licensing
                    management app for an international sports talent agency on
                    a tight timeline and budget thanks to an Agile/DevOps
                    approach.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={frontrushm}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      GWAGWA Group Case Study We Help Clients Swifty Modernize &
                      Upgrade with Innovative DevOps-driven Solutions
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how we helped a leading home improvement retailer
                    simultaneously redesign and upgrade more than a dozen legacy
                    websites, portals, and apps using an iterative process of
                    continuous integration.
                  </p>
                  <img
                    src={GWA1m}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Innovative Novel Business Models through an Iterative,
                      Agile Process
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Explore how our DevOps culture led us to swiftly develop a
                    novel two-way convenience-sector marketplace model that
                    connects brands with retailers featuring a unique feedback
                    loop that drives better activations — and sales.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:pt-5 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 ax-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 ax-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND T
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DevOps;
