import React from 'react'
import  { Component } from "react";
import ReactDOM from "react-dom"; 

const Career = () => {
    const options = [
        { value: "chocolate", label: "Chocolate" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
      ];
    return (
        <>
            <div className="container pt-24">
                <div className="bg-[#093D77] max-sm:hidden">
                    <div className="flex">
                        <div className="w-[15%]">
                            <form className="mt-2 mb-2">
                                <label
                                    for="default-search"
                                    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                                >
                                    Search
                                </label>
                                <div className="relative">
                                    <input
                                        type="search"
                                        id="default-search"
                                        className="block w-[100%] h-[50px] pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-3"
                                        placeholder="Search"
                                        required
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="w-[15%]">
                            <select
                                id="countries"
                                className="mt-2 ml-10 h-[50px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option selected>All Job Category</option>
                                <option value="US">Admin</option>
                                <option value="CA">Business Management</option>
                                <option value="FR">Engineering</option>
                                <option value="DE">Finace</option>
                                <option value="DE">information Technology & Services</option>
                                <option value="DE">IT & Information Security</option>
                                <option value="DE">Marketing</option>
                                <option value="DE">Product</option>
                                <option value="DE">Reserch & Design</option>
                                <option value="DE">Sales</option>
                            </select>
                        </div>
                        <div className="w-[15%]">
                            <select
                                id="countries"
                                className="mt-2 ml-[70px] h-[50px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option selected>All Job Type</option>
                                <option value="US">Full Time</option>
                                <option value="CA">Part Time</option>
                                <option value="FR">Remote</option>
                            </select>
                        </div>
                        <div className="w-[15%]">
                            <select
                                id="countries"
                                className="mt-2 ml-[100px] h-[50px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option selected>All Job Location</option>
                                <option value="US">Anywhere</option>
                                <option value="CA">Anywhere in India</option>
                                <option value="FR">Dubai</option>
                                <option value="DE">Gurgaon</option>
                                <option value="DE">Irivine</option>
                                <option value="DE">Ludhiana</option>
                                <option value="DE">San Francisco</option>
                                <option value="DE">Shimla</option>
                            </select>
                        </div>
                        <div className="w-[15%]">
                            <select
                                id="countries"
                                className="mt-2 ml-[130px] h-[50px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option selected>All Experience</option>
                                <option value="US">1+</option>
                                <option value="CA">2+</option>
                                <option value="FR">3+</option>
                                <option value="DE">4+</option>
                                <option value="DE">5+</option>
                                <option value="DE">6+</option>
                                <option value="DE">7+</option>
                                <option value="DE">Experienced</option>
                                <option value="DE">Fresher</option>
                            </select>
                        </div>
                    </div>
                </div>
                {/* <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%] hover:animate-pulse">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-2xl font-black">
                                    <b>Front end Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 hover:animate-pulse max-sm:mt-5">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-2xl font-black">
                                    <b>Dot Net Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Service</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 hover:animate-pulse max-sm:mt-5">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-2xl font-black">
                                    <b>Ruby on Rails Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 hover:animate-pulse max-sm:mt-5">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-2xl font-black">
                                    <b>Cloud DBA </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%] hover:animate-pulse">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className=" text-[20px] font-black">
                                    <b>Test Engineer QA Java +Selenium </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 hover:animate-pulse max-sm:mt-5">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>UI PATH APP Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'></p>+3
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] sm:ml-3 hover:animate-pulse max-sm:mt-5">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Software Tester [Manual] </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 hover:animate-pulse max-sm:mt-5">
                        <div className="card bg-[#F8FBFF]">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>SEO/SEM Expert </b>
                                </p>
                                <p className='mt-1 font-semibold'>SEO/SEM Expert </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%] bg-[#F8FBFF] hover:animate-pulse">
                        <div className="card">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Devops Engineer [Azure] </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>
                                        <h2 class="awsm-job-post-title" id="react-js-developer">
                                            React Js Developer{" "}
                                        </h2>
                                    </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Node JS Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>PHP Laravel Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>.Net core Senior Software Engineer </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Blockchain Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Business Development Executive </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Business Development Executive </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Go Developer </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Graphic Designer </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Business Management </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Partner Solution Architect Pre sales </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Business Development Manager </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Head Inside Sales </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Client Service Manager </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Partner Account Manager </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Enterprise Partner Account </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Business Analyst </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Product Specialist Account  </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Head of Online Acquisition </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b> Lifecycle Marketing Manager </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Head of Marketing </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Senior Paid Media Manager </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Demand Generation Manager </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Marketing Technology Architect </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>2+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Marketing Technology Architect </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Head of Strategic Finance </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Corporate Accounting Manager</b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:flex mt-4">
                    <div className="sm:w-[25%] max-sm:w-[100%]">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Sr. Business Development Executive </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Online Bidder </b>
                                </p>
                                <p className='mt-1 font-semibold'>Dot Net Developer </p>
                                <p className='mt-1 font-semibold'>Information Technology & Services</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Designer Lead </b>
                                </p>
                                <p className='mt-1 font-semibold'>IT & Information Security</p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>Gurgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>Experienced,Fresher</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                    <div className="sm:w-[25%] max-sm:w-[100%] sm:ml-3 max-sm:mt-5">
                        <div className="card bg-[#F8FBFF] hover:animate-pulse">
                            <div className="pl-5 py-4">
                                <p className="text-[20px] font-black">
                                    <b>Designer </b>
                                </p>
                                <p className='mt-1 font-semibold'>Cloud DBA </p>
                                <p className='mt-1 font-semibold'>Full Time</p>
                                <p className='mt-1 font-semibold'>urgaon , Shimla</p>
                                <p className='mt-1 font-semibold'>4+</p>
                                <p className='mt-1 font-semibold'>More Details</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Career