import React from 'react'
import { Link } from "react-router-dom";
import { esteem_independenece, black_imageicon1600, black_imageicon1601, black_imageicon161, black_imageicon1602, black_imageicon1603, low_level, full_name, small_time, Hiring, Google_Cloud, Content_Management, software_vendor, and_thats } from '../../assets';


const ProfessinolService = () => {
    return (
        <>
            <div style={{ backgroundImage: `url(${esteem_independenece})` }} className="w-full h-72 bg-cover ">
                <div className='container mx-auto'>
                    <h5 className='text-white text-center text-5xl font-extrabold pt-7'>Esteemed Independent</h5>
                    <p className='text-white text-center text-[23px] font-bold'>Vendor Management Software</p>
                    <div className='w-[9%] mx-auto mt-[-15px]'>
                        <Link to={""} className="text-center"><button type="button" className='px-2 py-1.5 bg-yellow-300 w-32 h-10 text-[12px] font-extrabold mt-9'>Know more</button></Link>
                    </div>
                </div>
            </div>
            <div className='container mx-auto'>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>
                            Keep Up with the Latest Market Changes
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            The global market is as unpredictable as it gets. One day you are seeing mobile trends come and another day a web app is killing it. When you adapt to the web shift, you get hit with an AI wave. Adding this to the ever-changing customer behavior and digital dynamic shift can incur huge changes to your venture. Adapting to these shifts can consume your valuable time and resources. Therefore, you need a partner like RevInfotech to help your business proceed with its routines while the market changes are being taken care of. RevInfotech houses experts with extensive market knowledge and experience to help your brand get accustomed to any market fluctuation.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4'>
                        <h5 className='font-bold text-[25px]'>More Industries</h5>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5]'>
                            <Link to={""}><img src={black_imageicon1603} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal"> Technology Consulting</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon1600} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Backend Development Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon1601} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">SAP Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon161} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Digital Integration Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon1602} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Internet of Thing</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 mt-3 bg-yellow-300'>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal ">See More Industries..</Link>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-full gap-5 mt-5'>
                    <div className='w-[25%]'>
                        <h5 className='text-[47px] font-extrabold text-center'>15+</h5>
                        <p className='text-[18px] font-bold text-center'>Successful Ventures</p>
                    </div>
                    <div className='w-[25%]'>
                        <h5 className='text-[47px] font-extrabold text-center'>10+</h5>
                        <p className='text-[18px] font-bold text-center'>Upcoming Projects</p>
                    </div>
                    <div className='w-[25%]'>
                        <h5 className='text-[47px] font-extrabold text-center'>100+</h5>
                        <p className='text-[18px] font-bold text-center'>Satisfied Customers</p>
                    </div>
                </div>
            </div>
            <div className='bg-[#093D77] w-full h-40 mt-5'>
                <div className='container mx-auto px-44'>
                    <h5 className='text-center font-extrabold text-[33px] text-white pt-14'>Rapidsofts: Helping Your Organization Keep Up with Sharp Market Turns</h5>
                </div>
            </div>
            <div className='container mx-auto'>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Rapidsofts Esteemed Software Development Services</h5>
                <div className='flex flex-row flex-wrap gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>QUICK PRODUCT DEVELOPMENT</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Capitalize on the latest market trends by quickly computing premium product ideas. Bring the ideas into fruition with our fast product development services. With Rapidsofts, you can come out of every obstacle unscathed. .</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>TRIED AND TESTED METHODOLOGIES</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our digital approach and methodologies have been tried and tested to provide the best online solutions. Our functional, compliance and management testing ensures optimal utilization of your capabilities and resources.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>ADDED AGILITY AND RESPONSIVENESS</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Integrate qualities like agility and responsiveness within your undertakings with our improved value chain. Experience quality like never before with Rapidsofts and let your intuitiveness run wild. Bring uniqueness on board with our assurance.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>SHORT SOFTWARE DEPLOYMENT CYCLE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Faced with an unwavering market hurdle? RevInfotech will help you breakthrough with an apt digital solution. Get equipped with functioning software with our smooth product development and deployment practices.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>ENHANCED PRODUCT QUALITY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>We ensure your product reaches an industry-leading standard. With our esteemed services, there’s no need to settle for suboptimal quality standards. Allow Rapidsofts to revolutionize your product development practices.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <img src={software_vendor} />
                    </div>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[25px]'>Why Choose Rapidsofts as Your Independent Vendor Management Software?</h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Adapting your business to every new market turn can be highly volatile and risky. Moreover, these shifts can cost you considerably, hindering the quality of your business proceedings. That is why you need the help of an experienced consultant cum partner to study every industry trend and devise adequate solutions to mount upon them. Rapidsofts emerges as the one-stop station for every digital requirement with its unique UX, impeccable designs, and short software deployment practices.
                        </p>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[25px]'>Meeting Every Digital Shift with a Thorough Plan</h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Merely devising a strategy on a whim will never work in the current market settings. Therefore, a business needs to carefully assess the current and upcoming changes to the point where they have a rough idea of what to do

                            The same can only be achieved with thorough practice and research. Companies do not need to implement every market trend, but they need to capitalize on every promising and suited shift. Doing so becomes easy with the help of a capable entity like Rapidsofts.
                        </p>
                    </div>
                    <div className='w-[45%] mt-24'>
                        <img src={and_thats} />
                    </div>
                </div>
                <h5 className='text-[34px] font-extrabold text-center pt-20'>Insights</h5>
                <div className='flex flex-row gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Content_Management} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>March 3, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Keeping Up With Content Management Systems in 2023: A Complete Guide
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Hiring} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>March 2, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Making the Old New: Hiring Fresh
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5 '>
                        <img src={Google_Cloud} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 28, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[30px] px-4 font-semibold leading-9 mb-12'>
                                Everything you need to know about Google Cloud SQL
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfessinolService