import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from '../view/main';
import Header from '../componets/Header';
import Contact from '../view/contact';
// import Careers from '../view/careera';
import MachineLearning from '../view/services/machineLearning';
import BlockchainDev from '../view/services/BlockchainDev';
import Augment from '../view/services/Augment';
import DefiDevelopement from '../view/services/DefiDevelopement';
import DataScience from '../view/services/DataScience';
import DesignStudio from '../view/services/DesignStudio';
import IotDevelopement from '../view/services/IotDevelopement';
import Robotic from '../view/services/Robotic';
import MetaverseDevelopment from '../view/services/MetaverseDevelopment';
import AgileSoftware from '../view/services/AgileSoftware';
import DigitalMarketing from '../view/services/DigitalMarketing';
import DigitalSoluation from '../view/services/DigitalSoluation';
import ProductDevlopment from '../view/services/ProductDevlopment';
import ProfessinolService from '../view/services/ProfessinolService';
import ResearchDevelopment from '../view/services/ResearchDevelopment';
import BusinessTechnology from '../view/services/BusinessTechnology';
import DataAnalytics from '../view/more/DataAnalytics';
import CustomSoftware from '../view/services/CustomSoftware';
import DigitalExperience from '../view/services/DigitalExperience';
import MobileApp from '../view/services/MobileApp';
import DigitalTransformation from '../view/more/DigitalTransformation';
import DigitalCommerce from '../view/more/DigitalCommerce';
import ExperienceDesign from '../view/more/ExperienceDesign';
import ProductDevelopment from '../view/more/ProductDevelopment';
import DataAnalyst from '../view/services/DataAnalyst';
import DigitalTransform from '../view/services/DigitalTransform';
import Offsore from '../view/services/Offsore';
import Dedicated from '../view/services/Dedicated';
import Experience from '../view/services/Experience';
import ProducrDevelopement from '../view/services/ProducrDevelopement';
import ApplicationModern from '../view/services/ApplicationModern';
import Outsoursing from '../view/services/Outsoursing';
import ConsultingService from '../view/services/ConsultingService';
import WebDevelopement from '../view/expertise/WebDevelopement';
import Php from '../view/expertise/Php';
import Laravel from '../view/expertise/Laravel';
import NetDevelopement from '../view/expertise/NetDevelopement';
import Python from '../view/expertise/Python';
import Django from '../view/expertise/Django';
import Javascript from '../view/expertise/Javascript';
import Angular from '../view/expertise/Angular';
import ReactJs from '../view/expertise/ReactJs';
import Vue from '../view/expertise/Vue';
import DevOps from '../view/expertise/DevOps';
import Aws from '../view/expertise/Aws';
import CloudMigration from '../view/expertise/CloudMigration';
import ItConsulting from '../view/expertise/ItConsulting';
import SportsFitness from '../view/industries/SportsFitness';
import RetailECommerce from '../view/industries/RetailECommerce';
import MediaEntertainment from '../view/industries/MediaEntertainment';
import Healthcare from '../view/industries/Healthcare';
import FoodBeverage from '../view/industries/FoodBeverage';
import EducationTraining from '../view/industries/EducationTraining';
import BankingFinancial from '../view/industries/BankingFinancial';
import Automotive from '../view/industries/Automotive';
import MobileAppDevelopement from '../view/expertise/MobileAppDevelopement';
import AntroidDeveloper from '../view/expertise/AntroidDeveloper';
import CrossAppWebDevelopement from '../view/expertise/CrossAppWebDevelopement';
import Fluter from '../view/expertise/Fluter';
import ReactNative from '../view/expertise/ReactNative';
import Ios from '../view/expertise/Ios';
import QaTesting from '../view/expertise/QaTesting';
import UserExperience from '../view/expertise/UserExperience';
import CrmDevelopement from '../view/expertise/CrmDevelopement';
import HubspotDevelopement from '../view/expertise/HubspotDevelopement';
import EcommerceDevelopement from '../view/expertise/EcommerceDevelopement ';
import Mageto from '../view/expertise/Mageto';
import DataBaseDevelopement from '../view/expertise/DataBaseDevelopement';
import DigitalExperiences from '../view/more/DigitalExperiences';
import CustomerRelationship from '../view/more/CustomerRelationship';
import B2bCommerce from '../view/more/B2bCommerce';
import Magento from '../view/more/Magento';
import ErrorDesign from '../view/more/ErrorDesign';
import Agile from '../view/more/Agile';
import CloudComputing from '../view/more/CloudComputing';
import CustomSoftwareDevelopment from '../view/more/CustomSoftwareDevelopment';
import DevoOps from '../view/more/DevoOps';
import MobileAppDevelopment from '../view/more/MobileAppDevelopment';
import MinimumViableProduct from '../view/more/MinimumViableProduct';
import Outsourcing from '../view/more/Outsourcing';
import SaaS from '../view/more/SaaS';
import SoftwareTesting from '../view/more/SoftwareTesting';
import AboutUs from '../view/company/AboutUs';
import Process from '../view/company/Process';
import Career from '../view/company/Career';
import Insights from '../view/Insights';
import SuccessStories from '../view/SuccessStories';

const Navigation = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route
            index
            element={<Main />}
          />
          <Route
            path="contact-us/"
            element={<Contact />}
          />
          <Route path="machineLearning" element={<MachineLearning />} />
          <Route path="blockchain-development" element={<BlockchainDev />} />
          <Route path="ar-and-vr-services" element={<Augment />} />
          <Route path="DefiDevelopement" element={<DefiDevelopement />} />
          <Route path="data-science-analytics" element={<DataScience />} />
          <Route path="design-studio" element={<DesignStudio />} />
          <Route path="iot-development" element={<IotDevelopement />} />
          <Route path="robotics-process-automation-rpa" element={<Robotic />} />
          <Route path="metaverse-development" element={<MetaverseDevelopment />} />
          {/* <Route path="agile-software-development-company" element={<AgileSoftware/>} /> */}
          <Route path="ecommerce-digital-marketing-services" element={<DigitalMarketing />} />
          <Route path="business-technology-consulting-services" element={<DigitalSoluation />} />
          <Route path="outsourced-software-development-company" element={<ProductDevlopment />} />
          <Route path="esteemed-independence" element={<ProfessinolService />} />
          <Route path="research-and-development-company-in-india" element={<ResearchDevelopment />} />
          <Route path="strategic-business-technology-consulting-services" element={<BusinessTechnology />} />
          <Route path="data-analytics-insights" element={<DataAnalytics />} />
          <Route path="software-development-company" element={<CustomSoftware />} />
          <Route path="digital-experience-platform" element={<DigitalExperience />} />
          <Route path="home" element={<Main />} />
          <Route path="mobile-app-consulting" element={<MobileApp />} />
          <Route path="digital-experience-platforms" element={<DigitalExperiences />} />
          <Route path="digital-transformation" element={<DigitalTransformation />} />
          <Route path="digital-commerce" element={<DigitalCommerce />} />
          <Route path="digital-experience-design" element={<ExperienceDesign />} />
          <Route path="product-development" element={<ProductDevelopment />} />
          <Route path="data-analytics" element={<DataAnalyst />} />
          <Route path="digital-transformations" element={<DigitalTransform />} />
          <Route path="offshore-development-services" element={<Offsore />} />
          <Route path="dedicated-development-team" element={<Dedicated />} />
          <Route path="experience-design" element={<Experience />} />
          <Route path="product-developments" element={<ProducrDevelopement />} />
          <Route path="application-modernization-services" element={<ApplicationModern />} />
          <Route path="it-outsourcing-services" element={<Outsoursing />} />
          <Route path="software-consulting-services" element={<ConsultingService />} />
          <Route path="web-development-company" element={<WebDevelopement />} />
          <Route path="php-development" element={<Php />} />
          <Route path="laravel-development-company" element={<Laravel />} />
          <Route path="asp-.net-development" element={<NetDevelopement />} />
          <Route path="python-web-development" element={<Python />} />
          <Route path="django-development-company" element={<Django />} />
          <Route path="javascript-development-company" element={<Javascript />} />
          <Route path="angular-development-company" element={<Angular />} />
          <Route path="react-native-development-company" element={<ReactJs />} />
          <Route path="vue-js-development-company" element={<Vue />} />
          <Route path="devops-solutions-services" element={<DevOps />} />
          <Route path="aws-development" element={<Aws />} />
          <Route path="cloud-migration-services" element={<CloudMigration />} />
          <Route path="it-consulting-services" element={<ItConsulting />} />
          <Route path="sports-fitness" element={<SportsFitness />} />
          <Route path="retail-ecommerce" element={<RetailECommerce />} />
          <Route path="media-publishing-entertainment" element={<MediaEntertainment />} />
          <Route path="healthcare-apps-solutions" element={<Healthcare />} />
          <Route path="food-beverage" element={<FoodBeverage />} />
          <Route path="education-training" element={<EducationTraining />} />
          <Route path="banking-financial-software-development-services" element={<BankingFinancial />} />
          <Route path="automotive" element={<Automotive />} />
          <Route path="mobile-app-development" element={<MobileAppDevelopement />} />
          <Route path="android-development" element={<AntroidDeveloper />} />
          <Route path="cross-platform-app-development" element={<CrossAppWebDevelopement />} />
          <Route path="flutter-development-company" element={<Fluter />} />
          <Route path="react-native-development-company" element={<ReactNative />} />
          <Route path="ios-development" element={<Ios />} />
          <Route path="software-consulting-service" element={<QaTesting />} />
          <Route path="experience-designs" element={<UserExperience />} />
          <Route path="crm-development-services" element={<CrmDevelopement />} />
          <Route path='hubspot-consulting' element={<HubspotDevelopement />} />
          <Route path='digital-commerces' element={<EcommerceDevelopement />} />
          <Route path='magento-development' element={<Mageto />} />
          <Route path='database-development-services' element={<DataBaseDevelopement />} />
          <Route path="customer-relationship-management" element={<CustomerRelationship />} />
          <Route path="b2b-ecommerce" element={<B2bCommerce />} />
          <Route path="magento" element={<Magento />} />
          <Route path="experience-designa" element={<ErrorDesign />} />
          <Route path="agile-software-development" element={<Agile />} />
          <Route path="cloud-computing" element={<CloudComputing />} />
          <Route path="custom-software-development" element={<CustomSoftwareDevelopment />} />
          <Route path="devoops" element={<DevoOps />} />
          <Route path="mobile-app-developments" element={<MobileAppDevelopment />} />
          <Route path="mvp-developments" element={<MinimumViableProduct />} />
          <Route path="outsourcing" element={<Outsourcing />} />
          <Route path="saas" element={<SaaS />} />
          <Route path="software-testing" element={<SoftwareTesting />} />
          <Route path="about-us" element={<AboutUs/>} />
          <Route path="process" element={<Process/>} />
          <Route path="careers" element={<Career/>} />
          <Route path="insights" element={<Insights/>} />
          <Route path="success-stories" element={<SuccessStories/>} />
        </Route>
      </Routes>
    </Router>
  )
}

export default Navigation