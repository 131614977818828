import React from 'react'
import {
    z_banner,
    xoutsourcing,
    ydevelopment,
    zoutsourcing,
    zaugmentation,
    yteam,
    xteam,
    objectives,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar16 from '../../componets/sidebar/Sidebar16';

const Outsourcing = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar16 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                Outsourcing
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${z_banner})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[30%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                                What is Outsourcing?
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                The global <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>IT outsourcing market </span> is expected to reach USD 425.19 billion by 2026. The outsourcing industry helps pave the way for many businesses aiming to achieve their set digital transformation goals.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">Outsourcing has indeed been a go-to option for businesses irrespective of the business size and industry they operate in. Even the most successful businesses have likely outsourced some part of IT at some point.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">Here’s an outsourcing example. GitHub, the internet hosting provider for <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>software developers</span> using Git, outsourced their MVP (minimum viable product). Github wanted to hire Scott Chacon, a Git expert, to do the job. However, the tight budgets posed a considerable roadblock. So, instead, they outsourced the backend development to Scott and succeeded at reducing expenditures.</p>
                            <p className="text-[27px] font-sans	font-light mt-4">Any business can benefit significantly from outsourcing and use it as a medium to improve its bottom line. Here is a guide that discusses everything related to outsourcing.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is Outsourcing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Outsourcing is a strategic process of handing over a task to someone outside an organization on a project-to-project basis. In other words, a business pays the money and communicates their requirements, and the outsourced agency does the job for them.</p>
                            <p className="text-[27px] font-sans font-light mt-5">It is similar to hiring an architect to build a house. The landowner pays the money and communicates how they want it to look, and the architect delivers a product that meets client expectations.</p>
                            <p className="text-[27px] font-sans font-light mt-5">The two business entities sign an agreement that mentions the deliverables and other standard formalities.</p>
                            <p className="text-[27px] font-sans font-light mt-5">According to a <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Deloitte report</span>, the significant objectives of outsourcing include:</p>
                        </div>
                        <div className='mt-5'>
                            <img src={objectives} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What are the Different Types of Outsourcing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Before a business chooses to outsource, it becomes imperative to understand different types of outsourcing, which include:</p>
                        </div>
                        <div className='mt-5'>
                            <img src={xoutsourcing} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'> Onshore Outsourcing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Onshore outsourcing is about assigning a task/job to a third-party provider residing in the same country. In this case, the business can consider arranging face-to-face meetings weekly or monthly to discuss the requirements, work status, and other project management details.</p>
                            <p className='font-bold text-[27px] mt-5'>Pros of Onshoring:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Similar mindset, language, culture, and timezone</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Easy accessibility to the outsourcing agency</li>
                                <li className='text-[24px] font-sans font-light mt-4'>High level of control and monitoring</li>
                            </ul>
                            <p className='font-bold text-[27px] mt-5'>Cons of Onshoring:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Cons of Onshoring:</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Lack of skills when looking for talent in a narrowed down region</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'> Offshore Outsourcing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Offshoring is outsourcing to an 'offshore' company, i.e. from a distant country, to perform a particular business function. A typical use case for this type of outsourcing is offshore development services to India.</p>
                            <p className='font-bold text-[27px] mt-5'>Pros of Offshoring:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Lower investments in context with labor costs</li>
                                <li className='text-[24px] font-sans font-light mt-4'>An excellent opportunity to explore a new market for expanding operations</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Tax benefits</li>
                            </ul>
                            <p className='font-bold text-[27px] mt-5'>Cons of Onshoring:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Cultural and social barriers</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Managing the huge gaps between time zones</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Communications and language barriers</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'> Nearshore Outsourcing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Nearshoring means outsourcing projects to a business operating in a nearby country (i.e., a country with touching borders).</p>
                            <p className='font-bold text-[27px] mt-5'>Pros of nearshoring:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Accessible, in-person communication considering the proximity</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Similar culture and time zones</li>
                            </ul>
                            <p className='font-bold text-[27px] mt-5'>Cons of nearshoring:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Expensive compared to offshoring</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Limited options as the business sticks to a constrained geographical location</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'> Insource Outsourcing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Insourcing is about hiring experts for the job in-house and providing them with all the resources to get the job done. There is no need to outsource the job to someone else (who manages the resource requirements independently).</p>
                            <p className='font-bold text-[27px] mt-5'>Pros of insourcing:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>High level of control and supervision</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Complete ownership of processes, technology, and security</li>
                                <li className='text-[24px] font-sans font-light mt-4'>A great opportunity to build a “Do it Yourself” culture</li>
                            </ul>
                            <p className='font-bold text-[27px] mt-5'>Cons of insourcing:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'>Lack of expertise and experience working around the latest digital transformation trends</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Expensive compared to outsourcing</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What are the Reasons for Outsourcing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Outsourcing lets a business focus on its core competencies while the technical team handles the development part. Here are some of the qualities of the outsourced team that help accelerate innovation.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Domain Expertisey</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>For a business looking to outsource, having domain expertise is a priority. Building an insourcing team (an in-house team) with adequate talent and expertise can take months or years and adds to the labor costs.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>However, when a business invests and outsources, they gain access to the best talent that can understand their requirements, have a stronghold over the process, and showcase flexibility.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Market Knowledge</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold'>Outsourcing agencies</span> have experience working for a bunch of clients. They understand the market dynamics and know their capabilities. For instance, an Agile software development company conducts retrospectives frequently.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>These retrospectives help them understand what they did right while working on the previous projects, what went wrong and why, and how they can improve and not commit the same mistakes again.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Process Discipline</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A well-organized development process helps a startup preserve its money while bringing productivity to the table. An experienced outsourced partner will know the ins and outs of the process, which goes beyond the understanding of the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>SDLC process</span>.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Moreover, they understand product backlogs and the importance of <span>backlog grooming</span>, which, in turn, helps in priority-based sprint planning. All this knowledge helps them stay on track and deliver expected results.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Good Knowledge of DevOps</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Development and operations (DevOps) have been a primary bind that ensures faster time to market through automation. <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Hiring DevOps engineers</span> separately for a startup can be expensive and bring risks related to inadequate knowledge and experience.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>An outsourcing partner, on the other hand, helps with development as well as maintaining DevOps cadence. They have adequate automation tests that run at the backend and understand the technical aspects of the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>CI/CD process</span>. This helps introduce a release to the live environment.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Benefits of Outsourcing Software Development
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Outsourcing is one of the best options for businesses looking for growth. Here are some of the outsourcing benefits:</p>
                        </div>
                        <div className='mt-5'>
                            <img src={ydevelopment} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Cost Savings</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Every business, small or big, hopes to achieve more while spending less. That is how a business maintains a cash-flow positive. Moreover, considering the impact of COVID-19 on businesses, the desire to increase cost savings is at an all-time high.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>With outsourcing, a business spends less on resources, less on managing an in-house team, and less on upskilling their workforce to match themselves to the changing market dynamics. So, yes, outsourcing is a nice strategy to increase cost savings.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Lower Down Failure Risk</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Around 95% of startups fail</span>. One of the primary reasons behind this high rate of failure is the insourcing teams' inability to meet the startup's needs—for example, agility, time to market, or changing requirements.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Another noteworthy reason behind failed startups is that they get outcompeted. To lower down the risk of failure, hiring the right talent is imperative, which brings us to outsourcing. Why spend time and money on looking for an insourcing team when an outsourced team is an option.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Pace up Digital Transformation</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>With <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>remote work</span> taking the lead, cloud technology adoption is gaining traction. Every business (old, existing, new) is embracing cloud computing and looking to establish a virtual presence. Artificial Intelligence (AI) and machine learning are also acting as catalysts for growth.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>To take advantage of the changing dynamics, outsourcing to an expert with prior experience and working knowledge of emerging technologies is the best option.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Embrace the Agile Mindset</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Startups believe in the “just ship it” philosophy nowadays. Why? Because they fear getting outcompeted.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>This is why embracing the <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>product mindset</span> is imperative. How is that done? By outsourcing the tasks to an organization with a better experience and a strong portfolio.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What are the Risks of Outsourcing?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Outsourcing can have its share of risks associated with it. Here are some of the most common outsourcing challenges:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Inability to Arrange Physical Meetings</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>According to traditional practice, the client and the outsourced partner arranges a meeting to discuss requirements, deliverable, budget, timelines, etc. This might be easier with an onshore outsourced partner and more challenging to achieve with an offshore or a nearshore partner.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Moreover, considering the changing sentiments and business dynamics after the pandemic, physical meetings have replaced virtual meetings. However, this can kill the value that physical meetings bring to the table.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>No Control Over Regulation</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Regulating an in-house team is always easy. The team can be held accountable for the delayed deliverables, and it is always easy to identify impediments that cause delays.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>With outsourcing, this type of regulation and accountability takes a backseat. If Agile antipatterns are causing impediments, it will be challenging to get an in-depth view of the situation.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>The difference in Time Zones</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Nearshoring and offshoring bring significant challenges related to differences in time zones. When one business might be prepping up for the day ahead, the outsourced team might be wrapping for the day.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>This might be an issue when the business leaders want to assess the work done in real-time. Discussing work status reports can be taxing in this case since one of the teams will have to stretch work hours to find a suitable time to connect.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Data Security</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Sharing sensitive data with an outsourced agency can be a risky business. Cybersecurity is essential to avoid any digital attacks on the data.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>However, sometimes no matter how strong the encryptions are, the risk of losing it to an attack stays in the backhead. This is why businesses often hesitate to outsource. In fact, many IT-enabled companies could not afford remote work during the pandemic due to the same data security reasons.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                When to Outsource?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Consider outsourcing when:</p>
                            <ul className='list-disc ml-10'>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">The business model is product-based —</strong>that requires a one-time product development team that can help accelerate launch times</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">The startup has tight budgets — </strong>and are looking forward to reducing the overall development expenditure</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">When experimenting with the latest digital transformation trends —</strong>such as AI and machine learning.</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                The KPIs of Effective Outsourcing
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Consider outsourcing when:</p>
                        </div>
                        <div className='mt-5'>
                            <img src={zoutsourcing} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Clean code</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is essential to ensure that a clean and organized code is maintained that showcases a well-thought-off software architecture. A clean code will ensure that introducing changes to the code in subsequent releases can be accomplished seamlessly, thus helping gain a competitive advantage.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Minimum technical debt</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Technical debt is the cost of reworks that come later if the initial development goes off-track. To maintain minimum technical debt, ensure that there are no piled-up bugs that the development team ignores. Also, as stated in the first point, there should be no messy code.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Quality Delivery</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The MVP (Minimum Viable Product) should solve the user problems and reflect the core idea in a workable product. The software should not fall short on essential aspects when it comes to satisfaction. These aspects include experience design and functionality.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Frequent Catch Ups</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Make sure that the outsourced partner maintains frequent communication. They should arrange weekly or monthly meetups to convey the work status and discuss any challenges. This activity ensures that the client is involved in the process and at every stage of the development.</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Conclusion
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Outsourcing has existed and will be the mainstay for most businesses, small or big, new or old. It has been a significant help to businesses aiming to prove their value proposition and improve their ROIs.</p>
                            <p className="text-[27px] font-sans font-light mt-5">Consider this guide “outsourcing 101” which discusses the basics of outsourcing. It is a good reference point to start a successful and strategically thought-off outsourcing journey.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={xteam} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  REMOTE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                        7 Steps for Building an Effective Nearshore Software Development Team</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={yteam} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  REMOTE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                        How to Hire a Remote Software Development Team?</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={zaugmentation} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  REMOTE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        What is IT Staff Augmentation and How Can It Benefit Your Team? </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Outsourcing