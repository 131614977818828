import React from 'react'
import {
    dev_hub,
    software_dev,
    wonderful,
    process,
    Thumbnailss,
    boost,
    Understanding,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar12 from '../../componets/sidebar/Sidebar12';

const CustomSoftwareDevelopment = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar12 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                Software Development
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${dev_hub})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[33%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                                Custom Software Development
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                Custom software is tailor-made software that is private and internal to a business. Market players such as Amazon, Blackberry, Google, and Microsoft support custom software to solve daily business problems and foster productivity.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                Basecamp, the well-known commercial-off-the-shelf (COTS) software, was initially launched as custom software. Basecamp functioned as a web designing firm in 2003. At that time, the business was attempting to complete multiple projects simultaneously. However, cluttered project management led to miscommunication and late deliveries.
                            </p>
                        </div>
                        <div className="mt-5">
                            <img src={software_dev} />
                        </div>
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                In the quest to solve the communication and project management problems, Basecamp tried a couple of COTS software solutions but they found that the current products had complicated UX and did not meet their requirements. Finally, Basecamp built its own custom software for project management. They deployed the custom built software internally, which led to increased productivity!                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                Custom software development could be the key to leapfrogging the competition. Explore this write-up to understand everything about custom software development in detail.                            </p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is Custom Software?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Custom software or bespoke software is tailor-made software that fulfills the needs of a single customer or business organization. The software only addresses the needs of that particular business and is built from scratch by an in-house or outsourced team.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>Custom software development services is a $70 billion market that has been growing at 5% per year.</p>
                            </div>
                        </div>
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                Custom software is the opposite of commercial-off-the-shelf software (COTS). COTS is commonly built to serve a particular industry and solve common problems faced by that industry niche.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                Businesses custom build software when no existing COTS software solves the existing business problem.                            </p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What are the Differences Between Custom Software and COTS?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Custom software or bespoke software is tailor-made software that fulfills the needs of a single customer or business organization. The software only addresses the needs of that particular business and is built from scratch by an in-house or outsourced team.</p>
                        </div>
                        <div className='mt-5'>
                            <table className="table">
                                <thead>
                                    <tr className=''>
                                        <th className='text-[24px] px-10 py-4 !bg-[#FEE75B] w-[430px]'>Custom Software</th>
                                        <th className='text-[24px] px-10 py-4 w-[450px] !bg-[#CEF0FF]'>Commercial off-the-shelf</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#FEE75B]'>Solves a unique problem for a particular business</td>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#CEF0FF]'>Solves common problems for a large audience spread across sectors and organizations</td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#FEE75B]'>TOC is higher</td>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#CEF0FF]'>Cost is based on plans (monthly or annually)</td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#FEE75B]'>Software features are based on functional and business needs</td>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#CEF0FF]'>Software features are predefined by the vendor</td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#FEE75B]'>Better control over how the software feels, looks, and performs</td>
                                        <td className='text-[px] px-10 py-4 text-[20px] !bg-[#CEF0FF]'>Less control over what the software does and how it performs</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Why Choose Custom Software Development?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Custom software development is essential for businesses looking to solve unique problems specific to their business. Also, it gives complete control over the application (i.e., features, updates, configurations, cloud hosting, etc.).</p>
                            <p className="text-[27px] font-sans font-light mt-5">Other reasons businesses may choose to invest in custom software are:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>The business has an out-of-the-box solution for the existing problem</li>
                                <li className='text-[24px] font-sans font-light mt-4'>A business plans to license the software to other businesses in the future</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The software will help greatly to solve the core business problems and will thus contribute to the businesses’ secret sauce</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Long term TCO of build is less than the long-term TCO of buy</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Existing COTS may not support API integrations</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The cost of manual API integrations is high, or development and integration of business-specific APIs is required</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Factors to Consider When Building Custom Software
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Consider the following factors when building a custom software solution:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Deciding Between Build vs Buy</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Before building custom software, the first thing is to decide between build vs buy. If a business can find a COTS solution that can solve the problem instantly, why would they waste efforts and money building it?</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Is it unique or a common problem?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>What is the expected TOC of build vs TOC of buying?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Do the existing COTS solutions offer API integrations?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Is the business looking forward to achieving competitive advantage or competitive parity?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Do the available COTS solutions offer scalability?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Do the available COTS solutions match the feature requirements?</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Choice Between In-House Development and Outsourcing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>If a business chooses to build custom software, deciding between in-house development and <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>outsourcing</span> is essential at an early stage.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>In-house development is viable if a business is operating at an enterprise level and has an experienced development team at its end.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>However, outsourcing is a good practice to follow when looking for cost savings. According to a <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>Deloitte Report</span>, 70% of businesses consider outsourcing as it leads to cost reductions.</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Gain access to domain expertise</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Knowledge of market dynamics</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Presence of process discipline</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Access to the latest tech stack</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Good knowledge of DevOps</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Creating Wonderful User Experience</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Custom software’s design should be top-notch with respect to intuitiveness, user-friendliness, navigation, findability and discoverability, and usability.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>This is possible when the best UI/UX design practices are followed when designing the software.</p>
                        </div>
                        <div className="mt-5">
                            <img src={wonderful} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Custom Software Development Process
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The step-by-step process to build a custom software includes:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Ideation</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>At this stage of custom software development, the business needs to define the problems they are facing and the corresponding ideas to address those problems.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>It is essential to be clear about:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>How will the software help improve productivity?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>How will it be different from existing COTS solutions?</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Requirements Analysis</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Forming clear requirements is an essential part of custom software development. The requirements analysis should focus on stating the business and the functional requirements clearly and concisely. Following the analysis in this stage, the software requirements will be finalized.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The primary characteristics of a software requirement include:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Concise:</strong>The requirement along with its intent should be described in clear and direct language</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Consistent: </strong>Should not contradict any other requirement</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Correct:</strong>Should be technically feasible to build</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Verifiable: </strong>Should be possible to identify it</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Design</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The design team builds a prototype to validate the look and feel of the software. The design is then presented to the business stakeholders, who can then approve or disapprove of the design and suggest changes.</p>
                        </div>
                        <div className="mt-5">
                            <img src={process} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What are the Advantages of Building Custom Software?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The top benefits of custom software development include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Better Control:</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'> The business will have complete control over choosing the software project features, its updates, and availability</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Reduced Long-Term TOC:</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Though the first-time investment is higher, the long-term cost of ownership reduces since licensing and support cost increases can be avoided</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Competitive Advantage:</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>If the software solves a unique problem for a business, it can be further licensed to other organizations. This can make them a market leader in that domain</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Legacy Software Modernization:</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Custom software development can help modernize existing legacy software systems to integrate the latest <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>digital transformation trends</span></p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Custom Software Development: Use Cases
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Here are example use cases for custom software development in different industries:</p>
                        </div>
                        <div className='mt-5'>
                            <table className="table">
                                <thead>
                                    <tr className=''>
                                        <th className='text-[24px] !px-10 py-4 !bg-[#FEE75B] w-[430px]'>Industry</th>
                                        <th className='text-[24px] !px-10 py-4 w-[450px] !bg-[#CEF0FF]'>Custom Software Examples</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#FEE75B]'> <p className='font-bold text-[27px]'>1. <span className='ml-3'>Digital Commerce</span></p></td>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#CEF0FF]'> <ul className='list-disc ml-14'>
                                            <li className='text-[24px] font-sans font-light mt-4'>Product Information Management (PIM) System</li>
                                            <li className='text-[24px] font-sans font-light mt-4'>Inventory Management System</li>
                                        </ul></td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#FEE75B]'> <p className='font-bold text-[27px]'>2. <span className='ml-3'>Healthcare</span></p></td>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#CEF0FF]'> <ul className='list-disc ml-14'>
                                            <li className='text-[24px] font-sans font-light mt-4'>Electronic Health Record System (EHR)</li>
                                            <li className='text-[24px] font-sans font-light mt-4'>Tax Invoicing System</li>
                                            <li className='text-[24px] font-sans font-light mt-4'>A decision support engine to modify test rules</li>

                                        </ul></td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#FEE75B]'> <p className='font-bold text-[27px]'>3. <span className='ml-3'>EdTech</span></p></td>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#CEF0FF]'> <ul className='list-disc ml-14'>
                                            <li className='text-[24px] font-sans font-light mt-4'>Digital Collaboration Platform for Parents</li>
                                            <li className='text-[24px] font-sans font-light mt-4'>Student management system</li>
                                        </ul></td>
                                    </tr>
                                    <tr>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#FEE75B]'> <p className='font-bold text-[27px]'>4. <span className='ml-3'>Corporate</span></p></td>
                                        <td className='text-[px] !px-10 py-4 text-[20px] !bg-[#CEF0FF]'> <ul className='list-disc ml-14'>
                                            <li className='text-[24px] font-sans font-light mt-4'>Digital learning platform for employees</li>
                                            <li className='text-[24px] font-sans font-light mt-4'>Customer relationship management software (CRM)</li>
                                            <li className='text-[24px] font-sans font-light mt-4'>Enterprise video platform for enhancing employee engagement</li>

                                        </ul></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Custom Software Development: Best Practices
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">The best practices for developing custom software include:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Conduct a cost-benefit analysis at an early stage. That is, will it be beneficial to develop software with respect to the expenditure it will incur?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Ensure that there are no COTS solutions available that match the desired requirements set</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Be clear about the requirements from early on. That is, what problem will the software solve, and how it will be a unique solution to the business problems</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Choose a custom software development partner wisely. Look for testimonials, reviews, case studies, and arrange a call to understand their development process</li>
                                <li className='text-[24px] font-sans font-light mt-4'>When outsourcing custom software development, knowing where to outsource is an essential step. For example, will the outsourcing be nearshored, onshored, offshored, insourced, or homeshored?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Make early decisions around hosting the custom software. That is, will it be hosted on-premise or on a public cloud such as Amazon AWS, Azure, GCP, etc.</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                FAQs Related to Custom Software Development
                            </h5>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>When Should a Business Invest in Building Custom Software?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A business should invest in building custom software when the:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>The software solves a problem intrinsic to the business operations</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Idea holds the potential to disrupt the market</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Business aims to gain a competitive advantage</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Idea contributes to the secret sauce</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The software complements the businesses’ blue sky thinking</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>What is a Hybrid Model in Custom Software Development?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A hybrid model simply implies choosing both COTS and custom software as the tech stack at an organization. The strategy should be to buy COTS software that matches 70-80% of the requirements and develop custom software for requirements that aren’t available in existing COTS software.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>What are the Cost Factors Involved in Building Custom Software?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The different cost factors for building custom software include:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Software development team</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Licensing</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Cloud-based infrastructure</li>
                                <li className='text-[24px] font-sans font-light mt-4'>User and admin support</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Software Maintenance</li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                            Conclusion
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">Custom software development involves creating tailor-made software for only one customer. This software supports a unique set of requirements that serves the needs of the business developing the software. Unlike COTS, custom software is a common software solution available to all.</p>
                            <p className="text-[27px] font-sans font-light mt-5">This is a comprehensive piece on custom software development that discusses each aspect related to the corresponding development strategy. Choosing custom software development can be a big strategic step for a business. However, when to go for custom software development instead of choosing a COTS solution should be crystal clear. For example, a business may choose custom software development when:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>No COTS solution matches the business and functional requirements</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The custom software will contribute to the businesses’ secret sauce</li>
                                <li className='text-[24px] font-sans font-light mt-4'>The idea is unique, supports a PoC, and addresses the pain points</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Thumbnailss} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>MOBILE APP DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    Low-Code vs. No-Code Development: Which One Should You Choose?</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={boost} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    App Modernization Benefits To Boost Your ROI and Future-Proof Your Business </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Understanding} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    Understanding the API-first Approach for Product Development </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomSoftwareDevelopment