import {
  case2,
  BestOnlineFoodDeliveryApps,
  nativevs,
  shouldilaunch,
  edplacecasesmallpic,
  voylegalcasesmallpic,
  digitalcustomerexperiencestrategy,
  customplatformgraphic,
  personalmultidiagram,
  mobileappdevelopmentservicesnet,
  vzimg,
  trish,
  tabhi,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
const CrmDevelopement = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                EXPERT CUSTOM CRM DEVELOPMENT
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Digitally Transform Your Business With Bespoke CRM Solutions
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Leverage data-driven insights for revenue-driving customer
                relationships.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for CRM Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img
            src={mobileappdevelopmentservicesnet}
            className="w-full sm:h-[600px]"
          />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>How to Develop a CRM Software Application</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Customer Relationship Management (CRM) applications are the key
                to unlocking business growth, but too often, companies settle on
                CRMs with siloed data and systems that undercut their
                effectiveness. As a world-class custom CRM development company,
                we take a deep dive into your business objectives, data, and
                digital disconnects in your customer journey. From there, we
                create a bespoke solution from scratch or by customizing a
                leading platform like Microsoft Dynamics, Salesforce, or HubSpot
                that optimizes your organization’s productivity through
                automated workflows, enhanced sales cycles and marketing
                strategies, and data- and analytic-driven insights for
                unstoppable business growth.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[200px] max-sm:mt-[50px]">
          <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
            <b>Custom CRM Development Services</b>
          </p>
        </div>
        <div className="mt-[30px]">
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            We have extensive experience developing, implementing, migrating,
            and supporting scalable and custom CRM development services that
            empower businesses to focus on growth opportunities. Our customer
            CRM development services include:
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Connected Business Applications
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                The more you can streamline your relationship management, the
                better. Our custom CRM development specialists have expertise in
                designing, developing, and implementing robust omnichannel CRMs.
                While the business applications we develop can stand solidly on
                their own, they are also designed to work brilliantly together.
              </p>
              <ul className="list-disc mt-5 ml-5">
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Sales: Relationship selling, modernized sale productivity,
                  personalized sales
                </li>
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Customer Service: Modernized cross-channel customer service,
                  customer service insights
                </li>
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Field Service: Connected field service, remote assist, service
                  automation
                </li>
                <li className="mt-4 text-2xl max-sm:text-[20px]">
                  Marketing: Customer journey transformation, connected sales
                  and marketing
                </li>
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Talent: Hiring great talent, self-service and collaboration
                </li>
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Marketing: Customer journey transformation, connected sales
                  and marketing
                </li>
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Finance and operations: Connected, optimized finance, back
                  office, retail, supply chain operations
                </li>
                <li className="mt-4 sm:text-2xl max-sm:text-[20px]">
                  Mixed reality: Real-time collaboration, remote assist
                </li>
              </ul>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Our experience runs the gamut, from retail app development and
                eCommerce development solutions to a variety of service business
                CRM solutions. Contact us if you're wondering if custom CRM
                development is right for your business.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px] sm:mt-[300px] max-sm:mt-[50px]"
                ></img>
                 <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Social CRM
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Billions of people worldwide use social media daily, so it makes sense that socially inspired, conversion-driven (vs. sales-driven) CRM has become essential. We build social CRM solutions that enable your customers to interact with your brand through their preferred channels — empowering your business to continuously improve customer service and gain greater marketing insight to evolve existing products and services, identify upsells, and develop new products and services.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">

                AI-Driven Insights
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Just because there's unlimited data at your fingertips doesn't mean you have the answers you need to grow and scale your business. Our custom CRM development services, which include chatbot and virtual assistant development, help you tame the firehouse of information and surface actionable insights and predictive guidance from firsthand customer experience. With the right AI for your business, you’ll make better decisions and see clearer paths to improvement and innovation.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={vzimg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img src={trish} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={tabhi}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <div className="mt-[50px]">
            <p className="text-5xl sm:ml-5 max-sm:text-[20px] max-sm:text-center">
              <b>FAQs About Rapidsofts’ Custom CRM Development Services</b>
            </p>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">What is the implementation timeframe when developing a custom CRM solution?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        With all that a custom CRM can do, you might think it would take a long time to develop the best solution for your business. But that’s not the case with a custom CRM development company like Rapidsofts – in many cases, you’re looking at just a few weeks to a few months. We use an Agile methodology and have a DevOps culture, which helps us move quickly and efficiently.
                      </div>
                      <div></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How to develop CRM software in Java, PHP, and other technologies?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      The short answer is: hire an experienced CRM development company! The CRM development is complex, as outlined above. Our seasoned team of CRM software developers is experienced in all leading-edge technologies. But, as importantly, they are focused on building software with human-centric design. That means that the core promise of your CRM – building and nurturing your customer relationships — is fully realized.
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      What are the benefits of developing a custom CRM system versus an existing CRM service?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light max-sm:text-[20px] max-sm:text-center">
                      While existing CRMs theoretically make sharpening your competitive edge simple, the truth is that creating a CRM with just the right features and functions for your business isn’t simple. It’s not a “one-size-fits-all” proposition. While robust functionality might seem great, you might not need all the bells and whistles of an out-of-the-box solution. Conversely, it also might not come with exactly what you need to optimize your business workflows.
                    </p>
                    <ul className="mt-5 list-disc sm:ml-[100px]">
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Greater efficiency
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Better integration of sales and marketing teams
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Customized reporting with insightful KPIs
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Leading-edge data security
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Flexible, user-friendly functionality
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Improved scalability
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Abundant integrations
                      </li>
                      <li className="sm:text-2xl mt-4 max-sm:text-[20px]">
                        Less expensive than ready-made/off-the-shelf CRM
                      </li>
                      <p className="mt-3 sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                        And more! We’re here to answer any questions you have about working with our custom CRM development company.
                      </p>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>
                  Success Stories
                </b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={edplacecasesmallpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Build Bespoke CRMs for Visionary Companies
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we built an innovative, compliant, cross-platform CRM for a leading athletic recruitment software company that revolutionized and streamlined how coaches build winning teams.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={case2}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Upgrade Customer Experiences for Global Companies
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how we helped human resources and payroll giant ADP evolve aging software and limited capacity to an upgraded CRM that expanded customer access to talent and improved the end-user experience.
                  </p>
                  <img
                    src={voylegalcasesmallpic}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Up-level Your Customer Experience by Re-engineering
                      Existing Products
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how we engineered a mobile app for a professional
                    coaching and training company from their flagship web-based
                    product for more data efficiency, versatility, and
                    scalability.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={shouldilaunch} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={nativevs} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={BestOnlineFoodDeliveryApps}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CrmDevelopement;
