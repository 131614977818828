import React from 'react'
import { Link } from "react-router-dom";
import { digital_commerce_banner, black1, black2, black3, black4, black5, project_3, project_4, project_5, Digital_tra2, ChatGPT, Digital_tra1 } from '../../assets';


const DigitalMarketing = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[32px]'>
                            Digital Commerce and Marketing
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            We engage customers with attractive results-oriented campaigns that create the right digital presence for your brand.
                        </p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-32 h-10 text-[12px] font-extrabold mt-9'>Know more</button></Link>
                    </div>
                    <div className='w-[45%] mt-5'>
                        <img src={digital_commerce_banner} />
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>

                            Deliver a consistent brand experience globally
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>

                            Digital commerce is in the midst of (yet another) revolution and things are moving faster than ever. While all this excitement means new possibilities, it also comes with the challenge of figuring out just how to proceed. Customer expectations can shift in an instant and businesses’ ability to adapt to market trends will determine their level of success. Digital commerce (D-commerce) is the buying and selling of goods and services using digital channels such as the Internet, mobile networks, and commerce infrastructure.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4'>
                        <h5 className='font-bold text-[25px]'>Our Blockchain Technologies</h5>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5]'>
                            <Link to={""}><img src={black1} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Design Studio</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black2} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">CMS Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black3} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Platform Modernization</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black4} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Stacks Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black5} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Mobility Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 mt-3 bg-yellow-300'>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal ">See More Technologies…</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#093D77] w-full h-40 mt-5'>
                <div className='container mx-auto px-44'>
                    <h5 className='text-center font-extrabold text-[33px] text-white pt-14'>Rapidsofts has the Digital Commerce and Marketing Solutions for whatever business problem you have</h5>
                </div>
            </div>
            <div className='container mx-auto'>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Avail efficient Digital Commerce and Marketing for your business</h5>
                <div className='flex flex-row gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={project_3} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>START SMALL</h5>
                            <p className='font-light text-center text-[22px] mt-3'>As with all things, you will need to get started somewhere. And starting small is always much easier. Don’t go for perfection immediately — be flexible and willing to learn.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse '>
                        <div className='px-10 py-11'>
                            <img src={project_4} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>THINK SHORT-TERM AND LONG-TERM</h5>
                            <p className='font-light text-center text-[22px] mt-3'>You should not be afraid to experiment and try out alternative solutions. The main lesson to keep in mind here is that nothing is ever finished.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={project_5} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>PICK THE MOST VALUABLE AUDIENCE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Just because you could personalize everything on your site for everyone in the universe does not mean you should. It’s always helpful to try to weigh out the costs versus the benefits.</p>
                        </div>
                    </div>
                </div>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Rapidsofts Key Digital Commerce and Marketing Solutions</h5>
                <div className='flex flex-row flex-wrap gap-5 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>DIGITALLY-INTEGRATED MARKETING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>By working together to adopt digital solutions, we can analyze your customers’ choices on an unprecedented and uninterrupted basis. That way, we can start to provide personalized, interactive, and unbroken experiences for them.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>B2B AND B2C OMNICHANNEL</h5>
                            <p className='font-light text-center text-[22px] mt-3'>By working with you we can provide you with integrated, omnichannel solutions that are based on cutting-edge customer data and analysis. We can help you transform the speed and performance of your technologies.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>INTERACTIVE MARKETING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our highly experienced team of marketing specialists can help you design, develop and deploy a full suite of interactive marketing services. Together, we’ll maximize your conversion rates, engagement levels, and overall customer loyalty, while minimizing your costs.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>ENTERPRISE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our high-quality, agile, and cost-effective enterprise solutions facilitate seamless integration with your front-end and back-end systems. This enables you to continuously innovate and that delivers brand-defining experiences for your customers.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>E-COMMERCE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our large team of highly experienced E-commerce developers works with you to design and deliver customized, high-quality, and user-friendly E-commerce websites. They seamlessly integrate end-to-end solutions that allow you to use your new E-commerce site data anywhere, any time.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>
                            Connecting Your Business with the Digital Real
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Our digital integration services are designed for helping businesses looking to grow big on the web. It may be a dormant website, a dysfunctional platform, or a low-productivity product, our experts possess extensive knowledge on the subject to help you snap out of it. Develop comprehensive strategies with us and reach your goals regardless of their scale and term. Accelerate your digital growth with RevInfotech and improve your online presence as well.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 pl-2 pr-32 py-4 bg-[#F6F6F6]'>
                        <h5 className='text-[20px]'>
                            Why choose Rapidsofts for Digital Commerce and Marketing?
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            The Commerce Shop digital marketing services,
                            Ensure quality SEO standards
                            Develop and manage successful PPC campaigns
                            Expand the influencer community
                            Improve customer engagement
                            Content management</p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9'>Help My Business</button></Link>
                    </div>
                </div>
                <h5 className='text-[34px] font-extrabold text-center pt-20'>Insights</h5>
                <div className='flex flex-row gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={ChatGPT} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 23, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Making the Old New: Hiring Fresh
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Digital_tra1} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 24, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[30px] px-4 font-semibold leading-9 mb-12'>
                                Trends, Challenges, and Solutions for Healthcare’s Digital Transformation
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5 '>
                        <img src={Digital_tra2} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 27, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Here are some strategies, checklists, and FAQs about cloud migration
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalMarketing