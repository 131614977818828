import React, { useEffect } from 'react'
import { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";



const Sidebar7 = () => {
    const [open, setOpen] = useState("");
    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const [open1, setOpen1] = useState("");

    const handleOpen1 = (value) => {
        setOpen1(open1 === value ? 0 : value);
    };
    return (
        <>
            <div className='static'>
                <Fragment>
                    <Accordion open={open === 1}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(1)}>
                                <h5 className="sm:text-[21px] font-bold max-sm:text-[18px]">Digital Experience Platforms</h5>
                            </AccordionHeader>

                        </div>
                        <AccordionBody className=" bg-[#E6E9F0]">
                            <Fragment>
                                <Accordion open={open === 1 && open1 === 8}>
                                    <div className=''>
                                        <AccordionHeader onClick={() => handleOpen1(8)}>
                                            <div className='flex ml-5'>
                                                <i className="fa fa-angle-right !text-[20px]" aria-hidden="true"></i>
                                                <h5 className="sm:text-[19px] font-bold sm:w-[210px] sm:ml-3.5">Customer Relationship Management  (CRMs).</h5>
                                            </div>
                                        </AccordionHeader>
                                    </div>
                                    <AccordionBody className=" bg-[#E6E9F0]">
                                        <AccordionBody className=" bg-[]">
                                            <div className='pl-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is a CRM system?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Types of CRM systems & Choosing the right one</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Which CRM works in the real world?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">When does an organization need CRM?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What does CRM do for your business?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What happens when you don't adopt CRM?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What are the benefits of CRM?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is the future of CRM?</Link>
                                            </div>
                                            <div className='pl-4 mt-4'>
                                                <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">The latest trends to check out in your CRM system</Link>
                                            </div>

                                        </AccordionBody>
                                    </AccordionBody>
                                </Accordion>
                            </Fragment>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 2}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(2)}>
                                <h5 className="sm:text-[21px] font-bold  max-sm:text-[18px]">Experience Design</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">User Experience</Link></p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 3}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(3)}>
                                <h5 className="sm:text-[21px] font-bold  max-sm:text-[18px]">Digital Commerce</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#F8FBFF]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'>Magento</p>
                                    </li>
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <Accordion open={open === 4}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(4)}>
                                <h5 className="sm:text-[21px] font-bold  max-sm:text-[18px]">Product Development</h5>
                            </AccordionHeader>
                        </div>
                        <AccordionBody className=" bg-[#E6E9F0]">
                            <div>
                                <ul>
                                    <li className='flex pl-4 font-extrabold text-[16px] '>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3 '><Link to={""} className="hover:text-[#F13623]">MVP Development</Link></p>
                                    </li>
                                    <li className='flex pl-4 font-extrabold text-[16px] hover:text-[#F13623] mt-4'>
                                        <div className='text-[22px]'>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                        <p className='ml-3'><Link to={""} className="hover:text-[#F13623] ">Mobile App Development</Link></p>
                                    </li>
                                    
                                </ul>
                            </div>
                        </AccordionBody>
                    </Accordion>
                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="sm:text-[21px] font-bold  max-sm:text-[18px]"><Link to={""} className="hover:text-[#F13623]">Digital Transformation</Link></h5>
                    </div>
                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="sm:text-[21px] font-bold hover:text-[#F13623]  max-sm:text-[18px]"><Link to={""} className="hover:text-[#F13623]">Data Analytics & Insights</Link></h5>
                    </div>
                </Fragment>
            </div>
        </>
    )
}

export default Sidebar7