import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  blockchain_banner,
  Blockchain_Solutions1,
  Blockchain_sol2,
  Blockchain_sol3,
  Blockchain_sol4,
  Blockchain_sol5,
  Blockchain_sol6,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  ChatGPT,
  Digital_tra1,
  Hiring,
  defibanner,
  highlyrisky,
  zeroaccountability,
  highlyvolatile,
  slowtransactions,
  lackoftransparency,
  howdefiicchanging,
  FooterTwoBackgroundImage,
  highlysecured150,
  profitableInvestmentPlans,
  nullifiedriskfrauds,
  thebenifitsofdefi,
  Percentcontroll150x150,
  whychooserevinfotech,
} from "../../assets";
import { Link } from "react-router-dom";
const DefiDevelopement = () => {
  return (
    <>
   <div
        style={{ backgroundImage: `url(${defibanner})` }}
        className="h-[18rem] flow-root"
      >
        <div className="text-center mt-[80px]">
          <p className="text-white text-4xl">Decentralized Finance Development Company</p>
          <p className="text-white  mt-2">Lead the future of the global financial system with our world-class DeFi solutions.</p>
        </div>
      </div>
      <div className="container">
        <div className="mt-[50px]">
          <p className="text-center text-3xl"><b>Get DeFi Services</b></p>
          <p className="text-center mt-2">The old finance system is failing and we all have witnessed several financial crises in recent years. This is the reason we have started questioning its credibility. No matter how much experienced your banker is, there are fair chances they will miss the market hints while putting your asset at unprecedented risk. Putting global assets at such high risks leads to widespread losses. Also, not everyone can access traditional finance systems due to their complex working model.</p>
        </div>
        <div className="flex mt-5">
          <div className="w-[20%]">
          <div className="">
          <img src={highlyrisky} className="m-auto" ></img>
              <p className="text-center">Highly Risky</p>
            </div>
          </div>
          <div className="w-[20%]">
            <div className="">
            <img src={zeroaccountability} className="m-auto" ></img>
              <p className="text-center">Zero Accountability</p>
            </div>
          </div>
          <div className="w-[20%]">
          <div className="">
          <img src={slowtransactions} className="m-auto" ></img>
              <p className="text-center">Slow Transactions</p>
            </div>
          </div>
          <div className="w-[20%]">
          <div className="">
          <img src={highlyvolatile} className="m-auto" ></img>
              <p className="text-center">Highly Volatile</p>
            </div>
          </div>
          <div className="w-[20%]">
          <div className="">
              <img src={lackoftransparency} className="m-auto" ></img>
              <p className="text-center">Lack of Transparency</p>
            </div>
          </div>
        </div>
        <div className="flex mt-[80px]">
          <div className="w-[50%]">
          <img src={howdefiicchanging}></img>
          </div>
          <div className="w-[50%]">
          <div className="mt-[140px]">
              <p className="text-3xl">
                <b>How DeFi is changing the whole banking system?</b>
              </p>
              <p className="mt-2 mb-2">
              Now, imagine lending or borrowing money on a platform without involving any third party. Getting loans or repaying in just 30 seconds! Isn’t it amazing! Embracing DeFi can do wonders for future financial systems. It offers more security & transparency than the traditional banking system at very less cost. DeFi empowers your users to get complete control over their digital assets with transparency, security & trust. There is no doubt that DeFi will grow big and completely replace banks.
              </p>
            </div>
          </div>
        </div>
        </div>
        <div
        style={{ backgroundImage: `url(${FooterTwoBackgroundImage})` }}
        className="h-[30rem] flow-root"
      >
        <div className="container">
        <div className="text-center mt-[80px]">
          <p className="text-white text-4xl">With RevInfotech Get Premium Quality Financial dApps</p>
          <p className="text-white  mt-2">RevInfotech is a leading decentralized finance development company and has expertise in blockchain protocols, asset tokenization, wallet integration, and smart contract development. We provide affordable DeFi solutions to accelerate your business growth. Here are some reasons why our dApps services are unique and come with 100% quality assurance.</p>
        </div>
        <div className="flex mt-5">
        <div className="w-[25%]"><div className="">
          <img src={highlysecured150} className="m-auto"></img>
          <p className="text-center text-white">Highly Secured</p>
          </div>
        </div>
          <div className="w-[25%]"><div className="">
          <img src={profitableInvestmentPlans} className="m-auto"></img>
          <p className="text-center text-white">Profitable Investment Plans</p>
          </div>
        </div>
        <div className="w-[25%]"><div className="">
          <img src={nullifiedriskfrauds} className="m-auto"></img>
          <p className="text-center text-white">Nullified Risk & Frauds</p>
          </div>
        </div>
        <div className="w-[25%]"><div className="">
          <img src={Percentcontroll150x150} className="m-auto"></img>
          <p className="text-center text-white">100% control over your assets</p>
          </div>
        </div>
        </div>
      </div>
      </div>
      <div className="container">
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
        Services we Offer
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              MARKET-MAKING CONSULTING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Start trading value like a professional or open your trading service with RevInfotech. Get your business started with our blockchain solutions.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              DEFI SMART CONTRACT DEVELOPMENT
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              With our blockchain services, you do not have to falter about transparency. RevInfotech ensures that the quality standards remain to your expectations
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              DEFI WALLET DEVELOPMENT
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Now your users can be their banks with our robust DeFi wallet development services. These wallets help users to get access to their funds with complete control safely & securely without depending on a risky third party.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              DEFI FUND MANAGEMENT
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              Collaborating with fund managers will become more transparent for users with DeFi. Our DeFi solutions will help you to manage your crypto assets with high yield performance in DeFi through smart control & management without extra fees.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              DEFI TOKEN DEVELOPMENT
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
              DeFi token helps in boosting the growth of decentralized applications as they are higher in value than Bitcoins. RevInfotech provides assistance to guide you to convert underutilized crypto assets to productive investments to get higher returns.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
              DECENTRALIZED CRYPTO BANKING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                With our DeFi crypto banking solutions, you can ensure a smoother user experience in making transactions between two parties. We deploy a white-label payment mobile application that includes robust banking solutions like wallet creation, value transfer & detailed transactional analysis.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
           <img src={thebenifitsofdefi}></img>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[30px]">
            <b>The Benefits of DeFi in Future Banking System</b>
            </h5>
            <p className="font-light tracking-normal text-[20px] mt-2.5">
            You can see how DeFi has the potential to replace older financial organizations and how it can be beneficial in the Future Banking System. The best thing about DeFi is it can be easily accessed by everyone irrespective of their geography and wealth. The user can get complete control over their funds. The whole platform is based on automation and helps in making the transactions process much smoother as compared to older banking systems. There is no need for 3rd parties while making transactions. You can secure your funds with the lowest fees possible.
            </p>
          </div>
        </div>
        <div className="flex mt-[80px]">
          <div className="w-[50%] mr-3">
            <div className="mt-[10px]">
              <p className="text-3xl">
                <b>Why choose RevInfotech for your DeFi Idea?</b>
              </p>
              <p className="mt-2 mb-2">
              Being a top-notch DeFi development company, we have unparalleled experience in the Blockchain development industry to deliver the best DeFi solutions to our clients worldwide. RevInfotech has been leading Blockchain-based product development for more than 10 years now. Our highly experienced team has expertise in Blockchain protocols, Crypto-economics, dApps development, etc. We continue to invest in advanced emerging technologies to deliver the best to our clients. We understand the requirements of our client’s as we have proven records in delivering projects within the given time deadline.
              </p>
            </div>
          </div>
          <div className="w-[50%]">
          <img src={whychooserevinfotech}></img>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
            March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
              Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra1} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DefiDevelopement;
