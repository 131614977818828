import React from 'react'
import { Link } from "react-router-dom";
import { outsourceBanner, black_imageicon159, black_imageicon160, black_imageicon161, black_imageicon162, black_imageicon163, low_level, full_name, small_time, Hiring, Google_Cloud, Digital_tra1 } from '../../assets';

const ProductDevlopment = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[32px]'>
                            Outsourced Product Development
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            We manage your entire project cycle from advisory and architecture to implementation and deployment
                        </p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-32 h-10 text-[12px] font-extrabold mt-9'>Know more</button></Link>
                    </div>
                    <div className='w-[45%] mt-5'>
                        <img src={outsourceBanner} />
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>

                        Building bespoke solutions through responsible outsourcing


                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>

                        Being all the rage these days, Outsourcing is increasingly more difficult to find an area of business that has proven impervious to its lure and advances. Customer service, legal and accounting needs, administrative activities, financial, HR, and IT operations, — you name it, — are all outsourced by many businesses, — large and small alike. Only a custom software provider in possession of several mission-critical technical and analytical capabilities can help you with your product development needs if you want to build a large-scale and complex software application.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4'>
                        <h5 className='font-bold text-[25px]'>More Services</h5>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5]'>
                            <Link to={""}><img src={black_imageicon159} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">AI & Machine Learning</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon160} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal"> Technology Consulting</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon161} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Digital Integration Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon162} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Platform Modernization</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon163} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Mobility Solutions</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 mt-3 bg-yellow-300'>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal ">See More Services…</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#093D77] w-full h-40 mt-5'>
                <div className='container mx-auto px-44'>
                    <h5 className='text-center font-extrabold text-[33px] text-white pt-14'>Product Outsourcing Requires an Approach Different from that to Other Software Outsourcing Projects</h5>
                </div>
            </div>
            <div className='container mx-auto'>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Avail efficient Outsourced Product Development for your business</h5>
                <div className='flex flex-row gap-5 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={low_level} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>LOW-LEVEL SOFTWARE OUTSOURCING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Many companies uphold the belief that their core development should best be handled in-house for reasons, such as better control over the project’s implementation and better confidentiality.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse '>
                        <div className='px-10 py-11'>
                            <img src={small_time} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>SMALL-TIME PRODUCT OUTSOURCING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Not all products are meant to be innovative or sophisticated. Lots of apps, whose development is outsourced, are just small-time, conventional affairs that don’t require too much skill or effort to develop.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={full_name} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>FULL-VALUE PRODUCT OUTSOURCING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Often, both startups and well-established businesses need to develop complex and sophisticated software applications from the ground up.</p>
                        </div>
                    </div>
                </div>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Rapidsofts Outsourced Products Development Solutions</h5>
                <div className='flex flex-row flex-wrap gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>TECHNOLOGY CONSULTING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our innovation advisors can help you build the right technology strategies, after analyzing your business and IT landscape. They can provide a roadmap for optimal implementation and then enable a seamless transition to mobilize vertical growth for your business.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>PRODUCT ENGINEERING SERVICE</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Product engineering service can be defined as an engineering consulting activity, which uses various hardware, embedded, software and IT services solutions for the designing and development of products.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>QA TESTING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Thorough testing is indispensable to ensure that your websites and apps work properly on all devices. With RevInfotech, you avoid the risk of giving your users a bad user experience.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>PRODUCT USER INTERFACE DESIGN</h5>
                            <p className='font-light text-center text-[22px] mt-3'>We provide User Interface design combining the needs of user experience, pleasing aesthetics, and pragmatic functionality – we support the entire range of your product UI design needs from layout schema to icon designs to Web 2.0.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>PRODUCT BRANDING</h5>
                            <p className='font-light text-center text-[22px] mt-3'>We enhance the marketability of the product through our creative branding and design solutions that cover Product Logo creation, e-brochure, PowerPoint, and Multimedia Product Demonstrations for training and self-service.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>
                        End-to-end Product Development Services
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                        With ever-increasing business complexities and rapid market changes, there is an increasing urgency for all companies to leverage technology solutions while improving focus on their core businesses. Rapidsofts aims to be your reliable IT partner providing innovative products, generating value with custom solutions, architecting your online roadmap or mobile application platforms while ensuring delivery of the highest quality.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]'>
                        <h5 className='text-[20px]'>
                        Why choose Rapidsofts for Outsourced Product Development?
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            We’re trusted worldwide for our reliable approach to your business needs and for delivering outsourcing solutions that meet your unique business objectives. You can choose from a range of engagement models to suit your exact requirements including time and material, fixed-price/time, dedicated development facility, or milestone-based billing. We’ve used our unique fit-to-purpose methodologies to perform collaborative way-finding across industries.</p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9'>Help My Business</button></Link>
                    </div>
                </div>
                <h5 className='text-[34px] font-extrabold text-center pt-20'>Insights</h5>
                <div className='flex flex-row gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Hiring} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 23, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Making the Old New: Hiring Fresh
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Google_Cloud} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 24, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[30px] px-4 font-semibold leading-9 mb-12'>
                                Everything you need to know about Google Cloud SQL
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5 '>
                        <img src={Digital_tra1} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 27, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Trends, Challenges, and Solutions for Healthcare’s Digital Transformation
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductDevlopment