import React from 'react'
import { sports_fitness, watch_wrist, mobile_device, streaming_mobile, application_mobile, fantasy_betting, yimg, zimg, ttimg, ximg, zxu, Run_Beat, fourfourtwo_Image, frontrush_cs, epl_cs, Img_brt, zgbms, vvimg } from '../../assets';
import { useState } from "react";

const SportsFitness = () => {
  return (
    <>
      <div className='container mx-auto'>
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[170px] max-sm:mt-[80px]">
              <h1 className='sm:text-[22px] text-[#F13623] tracking-widest font-extrabold max-sm:text-[20px] max-sm:text-center'>SPORTS & FITNESS APP DEVELOPMENT COMPANY</h1>
              <h1 className='sm:text-[77px] font-black max-sm:text-[20px] max-sm:text-center'>Deliver a Game-Changing Digital Experience with Expert Sports Software Solutions</h1>
              <p className='sm:text-[33px] font-extralight max-sm:text-[20px] max-sm:text-center'>Transform your business with state-of-the-art technology and an unbeatable user experience</p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[50px] sm:w-[450px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Need Help with Sports & Fitness App Development?</h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='bg-[#F9D94E] sm:w-[95%] max-sm:w-[100%] ml-auto sm:px-32 sm:pt-24 mt-5 flow-root'>
        <div>
          <img src={sports_fitness} />
        </div>
        <div>
          <div className='border-l-8 border-sky-400 relative top-[-69px] ml-5'>
            <div className='sm:pl-8'>
              <p className='pt-28 font-black sm:text-[20px] text-[#F13623] tracking-widest max-sm:text-[20px] max-sm:text-center'>THE Rapidsofts APPROACH</p>
            </div>
          </div>
          <div className='sm:px-16 max-sm:px-8 top-[-64px] sm:w-[60%]'>
            <h5 className='sm:text-[46px] font-extrabold mt-[-60px] max-sm:text-[20px] max-sm:text-center'>UX-driven, Innovative Sports and Fitness Software Solutions</h5>
            <p className='sm:text-[25px] font-light max-sm:text-[20px] max-sm:text-center'>In today’s digital world, users expect a lightning-fast experience tailored to their needs and desires. For more than two decades, we’ve been at the forefront of fitness and sports app development, delivering customer-first strategy and engineering smart, results-driven fitness software solutions for a competitive edge and business value you can count on.</p>
            <div className='max-sm:text-center'>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] mt-5 hover:bg-[#F13623] hover:text-white"
              >
                Explore Services
              </button>
              <span className="ml-7 mt-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white mb-16"
                >
                  Request a Quote
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='container mx-auto mt-5'>
        <div className='sm:pt-4'>
          <h5 className='font-bold sm:text-[55px] max-sm:text-[20px] max-sm:text-center'>Fitness App Development Services</h5>
        </div>
        <div className='sm:flex flex-row sm:pt-20 max-sm:pt-5'>
          <div className='sm:w-[40%] max-sm:w-[100%]'>
            <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Fitness Apps & Products</h5>
            <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Today’s fitness enthusiasts, from the casual consumer to the elite athlete, rely on technology to reach their personal best. Our seasoned fitness app developers are dedicated to creating engaging digital experiences for apps and wearables with features including gamification, augmented reality (AR), fitness and geolocation tracking, and data visualizations to support sustained use by consumers and better results.</p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
            <img src={watch_wrist} className="sm:h-[502px] max-sm:mt-5" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className='sm:flex flex-row pt-20'>
          <div className='sm:w-[50%] max-sm:w-[100%]'>
            <img src={mobile_device} className="h-[495px]" />
          </div>
          <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
            <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>eCommerce & Monetization Solutions</h5>
            <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>With fans hungry for your content and merchandise, you need a high-performing, mobile-first software solution that seamlessly converts buyers to browsers. We help you build your B2B, B2C, or DTC business by customizing one of the leading eCommerce platforms, including Shopify, Magento, and Drupal, or by engineering a bespoke product or platform aligned with your business objectives and audience needs.</p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className='sm:flex flex-row pt-20'>
          <div className='sm:w-[40%] max-sm:w-[100%] mt-4'>
            <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Multimedia Streaming & Distribution</h5>
            <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Nowadays, customers judge your business on your delivery system's quality as on the content itself. Our seasoned fitness app developers have expertise in devising multi-channel, enterprise publishing platforms and apps that leverage leading-edge tools for video content delivery — both OTT and on-demand — and seamlessly build in revenue opportunities.</p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
            <img src={streaming_mobile} className="sm:h-[502px] max-sm:mt-5" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className='sm:flex flex-row pt-20'>
          <div className='sm:w-[50%] max-sm:w-[100%]'>
            <img src={application_mobile} className="h-[495px]" />
          </div>
          <div className='sm:w-[40%] max-sm:w-[100%] ml-auto'>
            <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Community Building Software</h5>
            <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Don’t miss an opportunity to engage and grow your vital fanbase. Our expert sports app development team is here to help you devise and implement strategic community-based initiatives and digital experience platforms that win attention, engagement, and customer loyalty.</p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className='sm:flex flex-row pt-20'>
          <div className='sm:w-[40%] max-sm:w-[100%]'>
            <h5 className='font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center'>Fantasy Sports App Development</h5>
            <p className='font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center'>Fantasy sports fans live for the thrill of their favorite sports, teams, and players. Optimize and monetize your customers' interest and excitement with custom sports software solutions that include features like data feed integrations and game management tools for business-building results.</p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%] ml-auto'>
            <img src={fantasy_betting} className="sm:h-[502px] max-sm:mt-5" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
      </div>
      <div className='sm:flex flex-row pt-20 w-[79%] mx-auto'>
        <div className="sm:ml-3">
          <div className="">
            <img
              src={zgbms}
              className="w-full object-cover"
            ></img>
          </div>
        </div>
        <div className="sm:ml-3">
          <div className="">
            <img src={yimg} className=" w-full object-cover"></img>
          </div>
        </div>
        <div className="sm:ml-3">
          <div className="">
            <img
              src={vvimg}
              className="w-full object-cover"
            ></img>
          </div>
        </div>
      </div>
      <div className='container mx-auto mt-5'>
        <h5 className='sm:text-[56px] font-bold max-sm:text-[20px] max-sm:text-center'>Success Stories</h5>
        <div className='sm:px-16 max-sm:px-8'>
          <div className='sm:flex flex-row mt-5'>
            <div className='mt-5 sm:w-[45%] max-sm:w-[100%]'>
              <div>
                <img src={zxu} />
              </div>
              <div className='mt-4 sm:w-[500px]'>
                <h5 className='sm:text-[43px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Develop and Scale eCommerce Apps for Global Sports Brands</h5>
                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Discover how we engineered an enhanced online retail experience for an Australian sportswear company, supporting international expansion with multiple sites that reflect local currency/taxes — and a single view for platform administrators.</p>
              </div>
              <div className='mt-5'>
                <img src={Run_Beat} />
              </div>
              <div className='mt-4 sm:w-[500px]'>
                <h5 className='sm:text-[43px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Create Award-winning Digital Experiences for Global Brands</h5>
                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Explore how we helped IMG Worldwide win the Kentico Site of the Year Award for the “Run to the Beat” website by leveraging Kentico’s best features, including friendly UX, .NET compatibility, and fast development.</p>
              </div>
              <div className='mt-5'>
                <img src={fourfourtwo_Image} />
              </div>
              <div className='mt-4 sm:w-[500px]'>
                <h5 className='sm:text-[43px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Future-proof Your Business with Innovations that Win Fans — and Awards</h5>
                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Learn how we developed a multiple award-winning, data-rich, real-time Android app that took an existing iOS football stats app to the next level for a leading sports and entertainment company.</p>
              </div>
            </div>
            <div className='mt-5 sm:w-[45%] ml-auto'>
              <div className=''>
                <img src={frontrush_cs} />
              </div>
              <div className='mt-4 sm:w-[500px]'>
                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Help Sports Startups Win out the Gates with Customized Software</h5>
                <p className='text-[20px] font-light mt-4'>See how we built a powerful, intuitive, cross-platform app that empowers college coaches to streamline their recruitment process, ensure compliance with NCAA rules, and facilitate anywhere/anytime access.</p>
              </div>
              <div className='mt-5'>
                <img src={epl_cs} />
              </div>
              <div className='mt-5 sm:w-[500px]'>
                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Provide Game-changing Sports App Development that Thrills Fans</h5>
                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Explore how we built a live TV app for a leading global sports talent agency that empowers football fans to watch matches from any device, access schedules, and stream past videos.</p>
              </div>
              <div className='mt-5'>
                <img src={Img_brt} />
              </div>
              <div className='mt-5 sm:w-[500px]'>
                <h5 className='sm:text-[30px] font-semibold hover:text-[#F13623] max-sm:text-[20px] max-sm:text-center'>We Engineer Exceptional User Experiences for Global Brands</h5>
                <p className='sm:text-[20px] font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Learn how we employed an Agile process to create a robust B2B platform that automates merchandising and licensing deals with vendors for a top international sports talent agency.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <h5 className='sm:text-[44px] font-extralight text-center max-sm:text-[20px] max-sm:text-center'>Have a Sports or Fitness App in Mind?</h5>
          <div className='w-[160px] mx-auto'>
            <button
              type="button"
              className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
            >
              Let's Talk
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SportsFitness