
import {
  casestudyreact1,
  softwaredevelopment,
  EMR,
  WooClubm,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment6,
  Hiring,
  GoogleCloudSQL,
  Digital_tra2,
  Pythonwebdevelopmentimage,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const ReactJs = () => {

  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                EXPERT REACT NATIVE DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Accelerate Your App Releases With Customized React Native
                Development
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Leverage the power of a top developers’ tool to deliver a
                seamless cross-platform experience.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for React Native?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={Pythonwebdevelopmentimage} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Our Approach to React Native App Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Since React Native’s inception in 2015, our seasoned team of app
                development experts has harnessed the power of this innovative
                open-source solution to accelerate time to market and ensure a
                consistent cross-platform experience. When combined with our
                Agile, DevOps-driven culture, we’re able to optimize all aspects
                of the app creation process, from concept and UX/UI road mapping
                through prototyping, testing, release, and maintenance. The
                result is fast, flawless mobile app development cycles on iOS
                and Android platforms.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Services
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Explore Technology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
            <b>
              Transform Your Business with Our Custom React Native Development
              Services
            </b>
          </p>
          <p className="sm:text-3xl mt-5 max-sm:text-[20px] max-sm:text-center">
            While it’s true that this robust JavaScript framework simplifies the
            development process, not all React Native development pros are
            created equal. Our team has extensive experience across industries
            and verticals in providing top-tier React Native web and mobile app
            development services. The combination of our developers and the
            vast, vibrant React Native community allows us to keep pace with
            trending technologies and deliver cutting-edge solutions that
            satisfy the ever-evolving needs of global customers.
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                React Native App Development
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                As a world-class React Native development company, we offer
                full-spectrum React Native app development services, from
                creating innovative UI to developing customized cross-platform
                mobile apps that artfully achieve your business objectives while
                delighting your users. Thanks to an in-depth knowledge of the
                React Native API library, our experienced developers
                simultaneously provide iOS development services and Android app
                development services, sharpening your competitive edge and
                helping future-proof your business.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment1} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  React Native Consulting
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  React Native is the tool, but the best-case utilization of
                  this robust open-source framework has to be expertly planned
                  and executed to create the optimal user experience. As a
                  leading custom mobile app development company, once we
                  understand your business priorities and goals, we create a
                  strategic roadmap for how to best leverage the power of React
                  Native to build cross-platform apps with a native look and
                  feel.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                React Native Migration/ Legacy App Migration
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Thanks to React Native’s core benefits of using a single
                codebase for multiple platforms and a dynamic, active community
                of developers, there are countless reasons migrating to the
                framework is a smart business move. From lower development costs
                to smooth, error-free cross-platform UX, reusable code,
                extensive third-party plug-ins, and native app compatibility,
                there’s a reason that today’s leading apps, like Instagram,
                Skype, Shopify, SoundCloud, Microsoft, Uber, and Salesforce,
                among others, use React Native.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment2} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment3} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  React Native Team Augmentation
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  As a React Native app development company, we take pride in
                  providing flexible services to our clients. If you just need
                  to enhance your in-house capabilities, we’re happy to serve as
                  an extended arm, providing a team of React Native experts who
                  operate in our offshore development model/dedicated
                  development team model. This makes it easy for you to scale
                  your teams up or down to meet your evolving needs.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                At a Glance: Why Choose Rapidsofts as Your React Native
                Development Partner?
              </h4>
              <ul className="list-disc mt-4">
                <li>
                  With more than 15 years of mobile development experience, we
                  provide an array of services from initial app audits/analysis
                  to strategic modernization planning and all aspects of
                  designing and developing mobile apps from scratch.
                </li>
                <li className="mt-3">
                  We don't simply code the application; we engineer it to run
                  for years without redesigning the system.
                </li>
                <li className="mt-3">
                  We follow a 12-factor app methodology for engineering needs.
                </li>
                <li className="mt-3">
                  Our design-led engineering ensures that the mobile interfaces
                  we develop provide a seamless experience for on-the-go users.
                </li>
                <li className="mt-3">
                  Our expert developers use app optimization techniques to
                  ensure optimal performance and increase conversion rates.
                </li>
                <li className="mt-3">
                  We have worked with innovative startups (EdPlace) and thriving
                  enterprises (Haymarket Consumer Media) to publish
                  award-winning apps on iOS and Android app stores.
                </li>
                <li className="mt-3">
                  We can make your app as fabulous as your favorites. Whether
                  you want an e-commerce app, a loyalty app, or a SaaS product,
                  we're excited to collaborate with you to create an app you and
                  your audience will love.
                </li>
              </ul>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment6} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <p className="sm:text-7xl w-[90%] m-auto max-sm:text-[20px] max-sm:text-center">
              <b>FAQs About Rapidsofts’ React Native Development Services</b>
            </p>
            <div className=" mt-[50px]">
              <div className="sm:w-[80%] m-auto">
                <Accordion className="mb-5 max-sm:mt-[-100px]">
                  <div>
                    <Accordion.Item eventKey="0" className="">
                      <Accordion.Header>
                        <h5 className="sm:text-[26px] max-sm:text-[20px]">
                          What is React development?
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="text-[20px] font-light text-3xl ">
                          React is a popular open-source JavaScript-based UI
                          development library introduced by Facebook in 2015.
                          Today it is widely used in web and mobile app
                          development, appreciated by developers for how it
                          simplifies building hybrid apps that operate as
                          smoothly and seamlessly on multiple platforms,
                          including iOS, Android, Windows, and more.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <Accordion.Item eventKey="1" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        Is React Native good for web development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        Yes! React Native for Web ensures developers can use a
                        single React Native application to create apps that run
                        natively on Android, iOS, and any web browser.
                      </div>
                      <div className="text-[20px] font-light text-3xl">
                        This is critical for a React Native development company
                        like Rapidsofts, as we’re both an Android and iOS app
                        development company and a developer of web-based apps.
                        While mobile app development uses HTML5 elements (i.e.,
                        div, span) to build layouts, web app development
                        utilizes custom APIs (i.e., view, text). React Native
                        for Web is the rare technology that empowers developers
                        to use a single codebase to engineer authentic
                        cross-platform applications.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        How do I hire React developers?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-3xl font-light">
                        When hiring React developers, there are numerous skills
                        and attributes you should consider, including:
                      </p>
                      <ul className="mt-4 list-disc ml-5">
                        <li className="text-3xl mt-3">
                          Experience with ReactJS and an understanding of its
                          essential concepts
                        </li>
                        <li className="text-3xl  mt-3">
                          Expertise with JavaScript, HTML, and CSS
                        </li>
                        <li className="text-3xl  mt-3">
                          First-hand working experience with ReactJS workflows
                          (i.e., Redux, Flux, etc.)
                        </li>
                        <li className="text-3xl  mt-3">
                          Competencies in working with Rest API, data structure
                          libraries, and JavaScript testing framework
                        </li>
                        <li className="text-3xl  mt-3">
                          Solid mobile and web app development experience (i.e.,
                          Android, iOS)
                        </li>
                        <li className="text-3xl  mt-3">
                          Background in IT, computer science, or a related field
                        </li>
                        <li className="text-3xl  mt-3">
                          collaborative, team-oriented perspective
                        </li>
                        <li className="text-3xl  mt-3">
                          Strong communication skills
                        </li>
                        <li className="text-3xl  mt-3">
                          Commitment to writing clean code according to best
                          practices
                        </li>
                        <li className="text-3xl  mt-3">
                          Critical thinker and problem-solver
                        </li>
                        <li className="text-3xl  mt-3">
                          Time, project, and organizational management skills
                        </li>
                      </ul>
                      <p className="mt-2 text-3xl">
                        So, how can you be sure about your React development
                        hires? Whether you’re working with an agency or
                        individual programmers, pay close attention to the React
                        Native app development services offered. And be sure to
                        consider if your source has the flexibility, talent, and
                        resources you need to deliver your project on time and
                        on budget.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="sm:w-[90%] m-auto">
              <div className="sm:mt-[100px] max-sm:mt-[50px]">
                <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                  <b>Success Stories</b>
                </p>
              </div>
              <div className="sm:flex">
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={casestudyreact1}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        casestudy-hubpixcasestudy-hubpix We Develop Strategic
                        B2B Cross-Platform Apps for Future-Forward Companies
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Learn how our team of researchers and engineers created a
                      mobile solution to connect brands with retailers,
                      eliminating print waste and improving ROI across the board
                    </p>
                  </div>
                </div>
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={WooClubm}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        casestudy-avancecasestudy-avance We Help Ambitious
                        Entrepreneurs Build World-Class Apps on a Start-Up
                        Budget
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Discover how we took an innovative video training app from
                      concept to reality with a core features-only Minimum
                      Viable Product (MVP) that minimized time to market and
                      cost and optimized the app’s successful launch.
                    </p>
                    <img
                      src={EMR}
                      className="mt-5 w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>We Build Hassle-Free Apps for Social Networks</b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      See how we helped a novel social networking platform
                      develop a mobile experience powered by a unique algorithm
                      to make it easier for users to find nearby events based on
                      their interests, locations, and availability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Hiring} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      A Guide To Cloud-Native Application Development
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={GoogleCloudSQL} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Digital_tra2} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactJs;
