import React from 'react'
import { xAbout, y_approch, y_corporate, z_flexbility, y_passion, y_inegritys, z_quality, xhonesty, zteam, z_social,z_governance,z_ux,z_kanban,z_Business} from "../../assets";
import { Link } from "react-router-dom";


const AboutUs = () => {
  return (
    <>
      <div style={{ backgroundImage: `url(${xAbout})` }} className="w-full sm:h-[334px] max-sm:h-[224px] bg-center bg-cover opacity-75">
        <div>
          <h5 className='font-black sm:text-[50px] pt-32 text-center !opacity-100 max-sm:text-[20px] max-sm:text-center'>About Us</h5>
          <p className='sm:text-[20px] text-center font-bold !opacity-100 max-sm:text-[15px] max-sm:text-center'>Our vision defines our excellence</p>
          <p className='sm:text-[20px] text-center font-bold !opacity-100 max-sm:text-[15px] max-sm:text-center max-sm:mb-[50px]'>we are committed to digitizing your business</p>
        </div>
      </div>
      <div className='container sm:w-[70%] mx-auto'>
        <div className='sm:flex'>
          <div className='sm:w-[50%] max-sm:w-[100%]'>
            <img src={y_approch} className="sm:h-[386px] sm:w-[625px] hover:animate-pulse" />
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%]  pl-3 pt-10'>
            <h5 className='font-black sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Rapidsofts approach</h5>
            <p className='font-normal sm:text-[17px] mt-3 tracking-wider max-sm:text-[15px] max-sm:text-center'>Our approach is to combine our understanding of user behavior with a commitment to meeting the business goals of our clients. We are proactive in our aftercare service and will alert our customers of any malicious behavior that we find.</p>
            <p className='font-normal sm:text-[17px] mt-4 tracking-wider max-sm:text-[15px] max-sm:text-center'>Our staff is diverse and talented in all aspects of website technology and marketing, including content creation. We love what we do and it is visible in the solutions we produce.</p>
          </div>
        </div>
      </div>
      <div className='bg-[#093D77] mt-10 flow-root'>
        <div className='container  mx-auto'>
          <div className='sm:py-10'>
            <h5 className='sm:text-[40px] font-black text-center text-white max-sm:text-[20px] max-sm:text-center max-sm:mt-3'>Corporate Facts</h5>
          </div>
          <div className='sm:flex sm:mt-5 max-sm:mt-2 sm:py-4'>
            <div className='sm:w-[25%] max-sm:w-[100%]'>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>PROVEN</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>15+</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>Years of serving the worlds leading CSPs</p>
            </div>
            <div className='sm:w-[25%] max-sm:w-[100%]'>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>CUSTOMER</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>30+</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>Globally</p>
            </div>
            <div className='sm:w-[25%] max-sm:w-[100%]'>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>IMPACT</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>300M</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>Globally Impacted</p>
            </div>
            <div className='sm:w-[25%] max-sm:w-[100%]'>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>EXPERTISE</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center'>CTO</p>
              <p className='text-white font-black text-center sm:text-[21px] max-sm:text-[15px] max-sm:text-center max-sm:mb-3'>Of the year - TM Forum 2018 Excellence Awards</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container sm:w-[70%] mx-auto mt-5'>
        <div className='sm:flex'>
          <div className='sm:w-[50%] max-sm:w-[100%]'>
            <img src={y_corporate} className="sm:h-[386px] w-[625px] hover:animate-pulse" />
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%]  pl-3 pt-10'>
            <h5 className='font-black sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Social responsibility</h5>
            <p className='font-normal sm:text-[17px] mt-3 tracking-wider max-sm:text-[15px] max-sm:text-center'>According to the concept of social responsibility, the objective of our managers for taking business decisions is not merely to maximize profits or shareholder’s value but also to serve and protect the interests of other members of society such as workers, consumers, and the community as a whole. Therefore, we work in a socially responsible manner to promote the interests of society.</p>
          </div>
        </div>
        <p className='font-black text-center sm:pt-36 max-sm:pt-[30px] sm:text-[42px] max-sm:text-[20px] max-sm:text-center'>Our values</p>
        <div className='sm:flex flex-wrap gap-4 sm:mt-5'>
          <div className='sm:w-[30%] max-sm:w-[100%] px-4 py-8 shadow-2xl hover:animate-pulse'>
            <div className='w-[37%] mx-auto'>
              <img src={z_flexbility} className="" />
            </div>
            <h5 className='font-black sm:text-[27px] text-center mt-4 max-sm:text-[20px] max-sm:text-center'>FLEXIBILITY</h5>
            <p className='text-center sm:text-[18px] tracking-wider sm:mt-4 max-sm:mt-2 max-sm:text-[15px] max-sm:text-center'>We are always ready to adapt to new trends as per the client’s requirements. If any unexpected event occurs, our team is ready to change its commitment, stance, and outlook.</p>
          </div>
          <div className='sm:w-[30%] max-sm:w-[100%] px-4 py-8 shadow-2xl hover:animate-pulse'>
            <div className='w-[37%] mx-auto'>
              <img src={y_passion} className="" />
            </div>
            <h5 className='font-black sm:text-[27px] text-center mt-4 max-sm:text-[20px] max-sm:text-center'>PASSION</h5>
            <p className='text-center sm:text-[18px] tracking-wider sm:mt-4 max-sm:mt-2 max-sm:text-[15px] max-sm:text-center'>We are always passionate about finding the right solution for each customer's requirements. This passion for achieving something great makes us different from others.</p>
          </div>
          <div className='sm:w-[30%] max-sm:w-[100%] px-4 py-8 shadow-2xl hover:animate-pulse'>
            <div className='w-[37%] mx-auto'>
              <img src={y_inegritys} className="" />
            </div>
            <h5 className='font-black sm:text-[27px] text-center mt-4 max-sm:text-[20px] max-sm:text-center'>INTEGRITY</h5>
            <p className='text-center sm:text-[18px] tracking-wider sm:mt-4 max-sm:mt-2 max-sm:text-[15px] max-sm:text-center'>We work with full integrity and dignity. We follow all the technical values which are important while working on any project development.</p>
          </div>
          <div className='sm:w-[30%] max-sm:w-[100%] px-4 py-8 shadow-2xl hover:animate-pulse'>
            <div className='w-[37%] mx-auto'>
              <img src={z_quality} className="" />
            </div>
            <h5 className='font-black sm:text-[27px] text-center mt-4 max-sm:text-[20px] max-sm:text-center'>QUALITY</h5>
            <p className='text-center sm:text-[18px] tracking-wider sm:mt-4 max-sm:mt-2 max-sm:text-[15px] max-sm:text-center'>We are bound to deliver high-quality products and services. We ensure that we provide you with a superior level of customer satisfaction with an innovative software solution.</p>
          </div>
          <div className='sm:w-[30%] max-sm:w-[100%] px-4 py-8 shadow-2xl hover:animate-pulse'>
            <div className='w-[37%] mx-auto'>
              <img src={xhonesty} className="" />
            </div>
            <h5 className='font-black sm:text-[27px] text-center mt-4 max-sm:text-[20px] max-sm:text-center'>OPENNESS & HONESTY</h5>
            <p className='text-center sm:text-[18px] tracking-wider sm:mt-4 max-sm:mt-2 max-sm:text-[15px] max-sm:text-center'>We believe that openness and honesty are an integral part of our working relationship. We always remain open and honest about our work. We always maintain a transparent relationship with our clients.</p>
          </div>
          <div className='sm:w-[30%] max-sm:w-[100%] px-4 py-8 shadow-2xl hover:animate-pulse'>
            <div className='w-[37%] mx-auto'>
              <img src={zteam} className="" />
            </div>
            <h5 className='font-black sm:text-[27px] text-center mt-4 max-sm:text-[20px] max-sm:text-center'>TEAMWORK</h5>
            <p className='text-center sm:text-[18px] tracking-wider sm:mt-4  max-sm:mt-2 max-sm:text-[15px] max-sm:text-center'>We believe that teamwork empowers our strengths and enables us to achieve extraordinary things. Because of our teamwork, we are achieving success in every project.</p>
          </div>
        </div>
        <div className='sm:flex flex-wrap sm:mt-48 max-sm:mt-10'>
          <div className='sm:w-[50%] max-sm:w-[100%]'>
            <img src={z_social} className=" hover:animate-pulse" />
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%]  sm:pl-3 '>
            <h5 className='font-black sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Corporate social responsibility</h5>
            <p className='font-normal sm:text-[17px] mt-3 tracking-wider max-sm:text-[15px] max-sm:text-center'>Corporate governance is about making your business work better while abiding by the rules. The most basic function of corporate governance is to see that a business strategy is made effective by the company’s executives and workers. Rapidsofts has a strong legacy of transparent, fair, and ethical governance practices. The company’s doctrine on Corporate Governance manages the business strategies and assures the finances, ethical corporative behavior, accountability, and fairness to the entire stakeholders, customers, vendors, employees, and investors.</p>
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%] sm:mt-32 max-sm:mt-5'>
            <h5 className='font-black sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Corporate Governance</h5>
            <p className='font-normal sm:text-[17px] mt-3 tracking-wider max-sm:text-[15px] max-sm:text-center'>Corporate governance is about making your business work better while abiding by the rules. The most basic function of corporate governance is to see that a business strategy is made effective by the company’s executives and workers. Rapidsofts has a strong legacy of transparent, fair, and ethical governance practices. The company’s doctrine on Corporate Governance manages the business strategies and assures the finances, ethical corporative behavior, accountability, and fairness to the entire stakeholders, customers, vendors, employees, and investors.</p>
          </div>
          <div className='sm:w-[50%] max-sm:w-[100%] sm:pl-3 sm:mt-32 max-sm:mt-10'>
            <img src={z_governance} className=" hover:animate-pulse" />
          </div>
        </div>
        <h5 className='font-black sm:text-[48px] sm:mt-32 max-sm:mt-10 text-center max-sm:text-[20px] max-sm:text-center'>Insights</h5>
                <div className='sm:flex gap-5'>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse'>
                        <img src={z_kanban} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2022</p>
                            </div>
                            <div className='mt-4'>
                                <div className='mt-4'>
                                    <Link to={""} className='sm:text-[21px] hover:text-[#F13623] font-bold max-sm:text-[20px]'>
                                    Agile Method Choice: Kanban vs Scrum</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse'>
                        <img src={z_Business} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2022</p>
                            </div>
                            <div className='mt-4'>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold max-sm:text-[15px]'>
                                    Keeping Users Engaged with the 13 Best Mobile Apps for 2022</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse'>
                        <img src={z_ux} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2022</p>
                            </div>
                            <div className='mt-4'>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold max-sm:text-[15px]'>

                                    UI Design Trends for 2022 – What You Need to Know to Make Your Product Stand Out</Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    </>
  )
}

export default AboutUs