import React, { Fragment, useEffect, useState } from "react";
import {
  net_solutions,
  customer_service,
  const2,
  oilGasindustry,
  logo_web_white,
  logoweb,
} from "../assets";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";
import logo from "../assets/image/logo-white.jpg";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
const Header = () => {
  const [HeaderMenu, setHeaderMenu] = useState(false);
  const [expertiseMenu, setExpertiseMenu] = useState(false);
  const [successStories, setSuccessStories] = useState(false);
  const [insight, setInsight] = useState(false);
  const [moreMenu, setMoreMenu] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [DigitalShown, setDigitalShown] = useState(false);
  const [mobileShown, setMobileShown] = useState(false);
  const [dataShown, setDataShown] = useState(false);
  const [digital, setDigital1Shown] = useState(false);
  const [offshore, setOffshoreShown] = useState(false);
  const [dedicated, setDedicatedShown] = useState(false);
  const [experience, setExperienceShown] = useState(false);
  const [product, setProductShown] = useState(false);
  const [application, setApplicationShown] = useState(false);
  const [software, setSoftwareShown] = useState(false);
  const [it, setItShown] = useState(false);
  const [headerMobile, setHeaderMobile] = useState(false);
  const [menuActive, setMenuActive] = useState(1);
  const [openSidebar, SetOpensidebar] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <div className="">
      <div class="header-2">
        <nav
          className={
            scroll
              ? "bg-white shadow-[0_35px_60px_-15px_rgba(0,0,0,0.2)] py-2 md:py-4 fixed w-full z-[999]  !top-0"
              : "bg-white py-2 md:py-4 fixed w-full z-[999]  !top-0"
          }
        >
          <div className="container  mx-auto md:flex md:items-center w-[90%]">
            <div className="flex justify-between items-center">
              <Link to="home">
                <img
                  src={logoweb}
                  className={
                    scroll
                      ? "h-[50px] duration-500"
                      : "h-[60px] w-[200px] duration-500"
                  }
                />
              </Link>
              <div className="sm:hidden">
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                  onClick={() => SetOpensidebar(!openSidebar)}
                ></i>
              </div>
            </div>
            <div
              class="max-sm:hidden md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0"
              id="navbar-collapse"
            >
              <a
                href="#"
                className={
                  HeaderMenu
                    ? "p-2 lg:px-4 md:mx-2 text-gray-600  hover:text-gray-700 transition-colors duration-300 border-b-2 border-[#D4386C]"
                    : "p-2 lg:px-4 md:mx-2 text-gray-600   hover:text-gray-700 transition-colors duration-300"
                }
                onMouseEnter={() => {
                  setHeaderMenu(true);
                  setExpertiseMenu(false);
                  setMoreMenu(false);
                }}
              >
                <b className="xl:text-[16px] sm:text-[12px]">Services</b>
              </a>
              <a
                href="#"
                className={
                  expertiseMenu
                    ? "p-2 lg:px-4 md:mx-2 text-gray-600   hover:text-gray-700 transition-colors duration-300 border-b-2 border-[#D4386C]"
                    : "p-2 lg:px-4 md:mx-2 text-gray-600  hover:text-gray-700 transition-colors duration-300"
                }
                onMouseEnter={() => {
                  setExpertiseMenu(true);
                  setHeaderMenu(false);
                  setMoreMenu(false);
                }}
              >
                <b className="xl:text-[16px] sm:text-[12px]">Expertise</b>
              </a>
              <Link
                to={"success-stories"}
                className="p-2 lg:px-4 md:mx-2 text-gray-600 rounded  hover:text-gray-700 transition-colors duration-300"
                onMouseEnter={() => {
                  setHeaderMenu(false);
                  setExpertiseMenu(false);
                  setMoreMenu(false);
                }}
              >
                <b className="xl:text-[16px] sm:text-[12px]">Success Stories</b>
              </Link>
              <Link
                to={"insights"}
                className="p-2 lg:px-4 md:mx-2 text-gray-600 rounded  hover:text-gray-700 transition-colors duration-300"
                onMouseEnter={() => {
                  setHeaderMenu(false);
                  setExpertiseMenu(false);
                  setMoreMenu(false);
                }}
              >
                <b className="xl:text-[16px] sm:text-[12px]">Insights</b>
              </Link>
              <a
                href="#"
                className={
                  moreMenu
                    ? "p-2 lg:px-4 md:mx-2 text-gray-600   hover:text-gray-700 transition-colors duration-300 border-b-2 border-[#D4386C]"
                    : "p-2 lg:px-4 md:mx-2 text-gray-600  hover:bg-gray-200 hover:text-gray-700 transition-colors duration-300"
                }
                onMouseEnter={() => {
                  setHeaderMenu(false);
                  setExpertiseMenu(false);
                  setMoreMenu(true);
                }}
              >
                <b className="xl:text-[16px] sm:text-[12px]">More</b>
              </a>
              <a
                href="#"
                className="p-2 lg:px-4  text-dark text-indigo-600 text-center hover:bg-[#FFFF] bg-[#D4386C] border-[#D4386C]  rounded hover:text-dark transition-colors duration-300  md:mt-0 md:ml-1"
                onMouseEnter={() => {
                  setHeaderMenu(false);
                  setExpertiseMenu(false);
                }}
              >
                <Link to="contact-us">
                  <b className="xl:text-[16px] sm:text-[12px]">Contact Us</b>
                </Link>
              </a>
            </div>
          </div>
          {/* <div
            className={headerMobile ? "bg-[#FAFAFA] sm:hidden grid" : "hidden"}
          >
            <div className="w-[100%]">
              <Link
                to="software-development-company"
                onClick={() => setMenuActive(1)}
              >
                {" "}
                <p
                  className={
                    menuActive == 1
                      ? "font-bold text-center  mt-[5px]"
                      : "text-center  mt-[5px]"
                  }
                >
                  Services
                </p>
              </Link>
              <Link to="aws-development" onClick={() => setMenuActive(2)}>
                <p
                  className={
                    menuActive == 2
                      ? "font-bold text-center mt-[5px]"
                      : "text-center mt-[5px]"
                  }
                >
                  Expertise
                </p>
              </Link>
              <Link to="success-stories" onClick={() => setMenuActive(3)}>
                <p
                  className={
                    menuActive == 3
                      ? "font-bold text-center mt-[5px]"
                      : "text-center mt-[5px]"
                  }
                >
                  Success Stories
                </p>
              </Link>
              <Link to="insights" onClick={() => setMenuActive(4)}>
                <p
                  className={
                    menuActive == 4
                      ? "font-bold text-center mt-[5px]"
                      : "text-center mt-[5px]"
                  }
                >
                  Insights
                </p>
              </Link>
              <Link to="contact-us" onClick={() => setMenuActive(5)}>
                <p
                  className={
                    menuActive == 5
                      ? "font-bold text-center mt-[5px] mb-[5px]"
                      : "text-center mt-[5px]  mb-[5px]"
                  }
                  p
                >
                  Contact Us
                </p>
              </Link>
            </div>
          </div> */}
          <div
            className={HeaderMenu ? "bg-[#FAFAFA]" : "d-none"}
            onMouseLeave={() => setHeaderMenu(false)}
          >
            <div className="container w-[90%]">
              <div className="flex">
                <div className="w-[30%]">
                  <div className="mt-[60px]">
                    <p className="text-center text-3xl">
                      Services
                      <span className="mt-2">
                        <i
                          className="fa fa-long-arrow-right ml-2 text-[#D4386C] absolute mt-2"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="software-development-company">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setIsShown(true)}
                          onMouseLeave={() => setIsShown(false)}
                        >
                          <p className="text-lg">Custom Software</p>
                          <div
                            className={
                              isShown
                                ? "border-[#D4386C] w-[140px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              isShown
                                ? "border-[#D4386C] w-[110px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="digital-experience-platform">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setDigitalShown(true)}
                          onMouseLeave={() => setDigitalShown(false)}
                        >
                          <p className="text-lg">Digital Experience</p>
                          <div
                            className={
                              DigitalShown
                                ? "border-[#D4386C] w-[150px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Platform</p>
                          <div
                            className={
                              DigitalShown
                                ? "border-[#D4386C] w-[70px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="mobile-app-consulting">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setMobileShown(true)}
                          onMouseLeave={() => setMobileShown(false)}
                        >
                          <p className="text-lg">Mobile App</p>
                          <div
                            className={
                              mobileShown
                                ? "border-[#D4386C] w-[95px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Consulting</p>
                          <div
                            className={
                              mobileShown
                                ? "border-[#D4386C] w-[89px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="data-analytics">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setDataShown(true)}
                          onMouseLeave={() => setDataShown(false)}
                        >
                          <p className="text-lg">Data</p>
                          <div
                            className={
                              dataShown
                                ? "border-[#D4386C] w-[40px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Analytics</p>
                          <div
                            className={
                              dataShown
                                ? "border-[#D4386C] w-[75px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="digital-transformations">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setDigital1Shown(true)}
                          onMouseLeave={() => setDigital1Shown(false)}
                        >
                          <p className="text-lg">Digital</p>
                          <div
                            className={
                              digital
                                ? "border-[#D4386C] w-[55px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Transformation</p>
                          <div
                            className={
                              digital
                                ? "border-[#D4386C] w-[125px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="offshore-development-services">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setOffshoreShown(true)}
                          onMouseLeave={() => setOffshoreShown(false)}
                        >
                          <p className="text-lg">Offshore</p>
                          <div
                            className={
                              offshore
                                ? "border-[#D4386C] w-[55px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development Services</p>
                          <div
                            className={
                              offshore
                                ? "border-[#D4386C] w-[180px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="dedicated-development-team">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setDedicatedShown(true)}
                          onMouseLeave={() => setDedicatedShown(false)}
                        >
                          <p className="text-lg">Dedicated</p>
                          <div
                            className={
                              dedicated
                                ? "border-[#D4386C] w-[83px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development Team</p>
                          <div
                            className={
                              dedicated
                                ? "border-[#D4386C] w-[160px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="experience-design">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setExperienceShown(true)}
                          onMouseLeave={() => setExperienceShown(false)}
                        >
                          <p className="text-lg">Experience</p>
                          <div
                            className={
                              experience
                                ? "border-[#D4386C] w-[90px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Design</p>
                          <div
                            className={
                              experience
                                ? "border-[#D4386C] w-[60px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="product-developments">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setProductShown(true)}
                          onMouseLeave={() => setProductShown(false)}
                        >
                          <p className="text-lg">Product</p>
                          <div
                            className={
                              product
                                ? "border-[#D4386C] w-[68px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              product
                                ? "border-[#D4386C] w-[110px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="application-modernization-services">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setApplicationShown(true)}
                          onMouseLeave={() => setApplicationShown(false)}
                        >
                          <p className="text-lg">Application</p>
                          <div
                            className={
                              application
                                ? "border-[#D4386C] w-[100px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Modernization</p>
                          <div
                            className={
                              application
                                ? "border-[#D4386C] w-[120px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="it-outsourcing-services">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setItShown(true)}
                          onMouseLeave={() => setItShown(false)}
                        >
                          <p className="text-lg">IT Outsourcing</p>
                          <div
                            className={
                              it
                                ? "border-[#D4386C] w-[120px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Services</p>
                          <div
                            className={
                              it
                                ? "border-[#D4386C] w-[70px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="software-consulting-services">
                        <div
                          className="mb-5  cursor-pointer"
                          onMouseEnter={() => setSoftwareShown(true)}
                          onMouseLeave={() => setSoftwareShown(false)}
                        >
                          <p className="text-lg">Software Consulting</p>
                          <div
                            className={
                              software
                                ? "border-[#D4386C] w-[160px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Services</p>
                          <div
                            className={
                              software
                                ? "border-[#D4386C] w-[65px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={expertiseMenu ? "bg-[#FAFAFA]" : "d-none"}
            onMouseLeave={() => setExpertiseMenu(false)}
          >
            <div className="container w-[90%]">
              <div className="flex">
                <div className="w-[30%]">
                  <div className="mt-[60px]">
                    <p className="text-center text-3xl">
                      Expertise
                      <span className="mt-2">
                        <i
                          className="fa fa-long-arrow-right ml-2 text-[#D4386C] absolute mt-2"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="web-development-company">
                        <div
                          className="mb-3  cursor-pointer"
                          onMouseEnter={() => setIsShown(true)}
                          onMouseLeave={() => setIsShown(false)}
                        >
                          <p className="text-lg">Web App</p>
                          <div
                            className={
                              isShown
                                ? "border-[#D4386C] w-[75px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              isShown
                                ? "border-[#D4386C] w-[110px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <div className="">
                        <Fragment>
                          <Accordion open={open === 1}>
                            <AccordionHeader
                              className="p-0 hover:text-[#D4386C]"
                              onClick={() => handleOpen(1)}
                            >
                              <Link
                                to="php-development"
                                className="text-[13px] font-bold tracking-wide"
                              >
                                PHP
                              </Link>
                            </AccordionHeader>
                            <AccordionBody className="p-1">
                              <p className="text-lg mt-2 hover:text-[#D4386C] cursor-pointer">
                                <Link to="laravel-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    Laravel
                                  </b>
                                </Link>
                              </p>
                            </AccordionBody>
                            <p className="hover:text-[#D4386C] cursor-pointer mt-1">
                              <Link to="asp-.net-development">
                                <b className="text-[13px] font-bold tracking-wide">
                                  .NET
                                </b>
                              </Link>
                            </p>
                          </Accordion>
                          <Accordion open={open === 2}>
                            <AccordionHeader
                              className="p-0 mt-2 hover:text-[#D4386C]"
                              onClick={() => handleOpen(2)}
                            >
                              <Link
                                to="python-web-development"
                                className="text-[13px] font-bold"
                              >
                                PYTHON
                              </Link>
                            </AccordionHeader>
                            <AccordionBody className="p-1">
                              <p className="text-lg mt-1 hover:text-[#D4386C] cursor-pointer">
                                <Link to="django-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    Django
                                  </b>
                                </Link>
                              </p>
                            </AccordionBody>
                          </Accordion>
                          <Accordion open={open === 3}>
                            <AccordionHeader
                              className="p-0 mt-2 hover:text-[#D4386C]"
                              onClick={() => handleOpen(3)}
                            >
                              <Link
                                to="javascript-development-company"
                                className="text-[13px] font-bold tracking-wide"
                              >
                                JAVASACRIPT
                              </Link>
                            </AccordionHeader>
                            <AccordionBody className="p-0 ">
                              <p className="text-lg mt-1 hover:text-[#D4386C] cursor-pointer">
                                <Link to="angular-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    Angular
                                  </b>
                                </Link>
                              </p>
                              <p className="text-lg mt-1 hover:text-[#D4386C] cursor-pointer">
                                <Link to="react-native-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    React.js
                                  </b>
                                </Link>
                              </p>
                              <p className="text-lg mt-1 hover:text-[#D4386C] cursor-pointer">
                                <Link to="vue-js-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    Vue.js
                                  </b>
                                </Link>
                              </p>
                            </AccordionBody>
                          </Accordion>
                        </Fragment>
                      </div>
                      <div
                        className="mb-3  cursor-pointer mt-4"
                        onMouseEnter={() => setDigitalShown(true)}
                        onMouseLeave={() => setDigitalShown(false)}
                      >
                        <Link to="devops-solutions-services">
                          <p className="text-lg">DevOps</p>

                          <div
                            className={
                              DigitalShown
                                ? "border-[#D4386C] w-[65px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">& Cloud</p>
                        </Link>
                        <div
                          className={
                            DigitalShown
                              ? "border-[#D4386C] w-[70px] duration-500  border-b-2 mt-[-1px]"
                              : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                          }
                        ></div>
                      </div>
                      <div className=" mb-5">
                        <p className="hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer">
                          <Link to="aws-development">
                            <b className="text-[13px] tracking-wide">
                              AWS Consulting
                            </b>
                          </Link>
                        </p>
                        <p className=" mt-2 hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer">
                          <Link to="cloud-migration-services">
                            <b className="text-[13px] tracking-wide">
                              Cloud Migrations Services
                            </b>
                          </Link>
                        </p>
                        <Link to="aws-development">
                          <p className="mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide">
                              AWS Consulting
                            </b>
                          </p>
                        </Link>
                        <Link to="it-consulting-services">
                          <p className="mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide">
                              It Consulting
                            </b>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="mobile-app-development">
                        <div
                          className="mb-3  cursor-pointer"
                          onMouseEnter={() => setDataShown(true)}
                          onMouseLeave={() => setDataShown(false)}
                        >
                          <p className="text-lg">Mobile App</p>
                          <div
                            className={
                              dataShown
                                ? "border-[#D4386C] w-[40px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              dataShown
                                ? "border-[#D4386C] w-[75px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <Link to="android-development">
                        <div className="">
                          <p className="mb-2 text-[13px] tracking-wide">
                            <b>Android</b>
                          </p>
                        </div>
                      </Link>
                      <div className="">
                        <Fragment>
                          <Accordion open={open === 55}>
                            <AccordionHeader
                              className="p-0 hover:text-[#D4386C]"
                              onClick={() => handleOpen(55)}
                            >
                              <Link
                                to="cross-platform-app-development"
                                className="text-[13px] font-bold tracking-wide"
                              >
                                Cross-Platform / Hybrid
                              </Link>
                            </AccordionHeader>
                            <AccordionBody className="p-1">
                              <p className="text-lg mt-1 hover:text-[#D4386C] cursor-pointer">
                                <Link to="flutter-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    Flutter
                                  </b>
                                </Link>
                              </p>
                              <p className="text-lg mt-1 hover:text-[#D4386C] cursor-pointer">
                                <Link to="react-native-development-company">
                                  <b className="text-[13px] tracking-wide">
                                    React Native
                                  </b>
                                </Link>
                              </p>
                            </AccordionBody>
                            <p className="hover:text-[#D4386C] cursor-pointer mt-1">
                              <Link to="ios-development">
                                <b className="text-[13px] tracking-wide">iOS</b>
                              </Link>
                            </p>
                          </Accordion>
                        </Fragment>
                      </div>
                      <Link to="software-consulting-service">
                        <div
                          className="mb-5  cursor-pointer h-[230px] mt-[70px]"
                          onMouseEnter={() => setDigital1Shown(true)}
                          onMouseLeave={() => setDigital1Shown(false)}
                        >
                          <p className="text-lg">Software QA</p>
                          <div
                            className={
                              digital
                                ? "border-[#D4386C] w-[55px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">& Testing</p>
                          <div
                            className={
                              digital
                                ? "border-[#D4386C] w-[125px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <div className="">
                        <p className="text-lg">UI/UX</p>
                      </div>
                      <div className="mt-4">
                        <p className=" hover:text-[#D4386C] cursor-pointer">
                          <Link to="experience-designs">
                            <b className="text-[13px] font-bold tracking-wide">
                              User Experience Design
                            </b>
                          </Link>
                        </p>
                      </div>
                      <div className="mt-2">
                        <p className=" hover:text-[#D4386C] cursor-pointer">
                          <b className="text-[13px] font-bold tracking-wide">
                            UI Design
                          </b>
                        </p>
                      </div>
                      <Link to="crm-development-services">
                        <div
                          className="mb-2  cursor-pointer mt-[60px]"
                          onMouseEnter={() => setDedicatedShown(true)}
                          onMouseLeave={() => setDedicatedShown(false)}
                        >
                          <p className="text-lg">CRM</p>
                          <div
                            className={
                              dedicated
                                ? "border-[#D4386C] w-[83px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              dedicated
                                ? "border-[#D4386C] w-[160px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <div className="h-[224px]">
                        <p className="text-lg hover:text-[#D4386C] cursor-pointer">
                          <Link to="hubspot-consulting">
                            <b className="text-[13px] font-bold tracking-wide">
                              Hubspot Consulting
                            </b>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4">
                      <Link to="digital-commerces">
                        <div
                          className="mb-3  cursor-pointer"
                          onMouseEnter={() => setApplicationShown(true)}
                          onMouseLeave={() => setApplicationShown(false)}
                        >
                          <p className="text-lg">eCommerce</p>
                          <div
                            className={
                              application
                                ? "border-[#D4386C] w-[100px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              application
                                ? "border-[#D4386C] w-[120px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                      <div className="mt-2">
                        <p className=" hover:text-[#D4386C] cursor-pointer">
                          <Link to="magento-development">
                            <b className="text-[13px] font-bold tracking-wide">
                              BigCommerce
                            </b>
                          </Link>
                        </p>
                      </div>
                      <div className="mt-2">
                        <p className=" hover:text-[#D4386C] cursor-pointer">
                          <Link to="magento-development">
                            <b className="text-[13px] font-bold tracking-wide">
                              Magento & Adobe
                            </b>
                            <p className="text-[13px] font-bold tracking-wide">
                              Commerce
                            </p>
                          </Link>
                        </p>
                      </div>
                      <Link to="database-development-services">
                        <div
                          className="mb-5  cursor-pointer mt-[80px] h-[220px]"
                          onMouseEnter={() => setItShown(true)}
                          onMouseLeave={() => setItShown(false)}
                        >
                          <p className="text-lg">Database</p>
                          <div
                            className={
                              it
                                ? "border-[#D4386C] w-[120px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                          <p className="text-lg">Development</p>
                          <div
                            className={
                              it
                                ? "border-[#D4386C] w-[70px] duration-500  border-b-2 mt-[-1px]"
                                : "border-[#D4386C] w-[0px] duration-500  border-b-2 mt-[-1px]"
                            }
                          ></div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={moreMenu ? "bg-[#FAFAFA] overflow-hidden" : "d-none"}
            onMouseLeave={() => setMoreMenu(false)}
          >
            <div className="container w-[90%]">
              <div className="flex">
                <div className="w-[30%]">
                  <div className="mt-[60px]">
                    <p className="text-center text-3xl">
                      More
                      <span className="mt-2">
                        <i
                          className="fa fa-long-arrow-right ml-2 text-[#D4386C] absolute mt-2"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4 h-[480px]">
                      <div className=" mb-5">
                        <p className="text-2xl">Company</p>
                        <Link to={"about-us"}>
                          <p className=" hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer mt-2">
                            <b className="text-[13px] tracking-wide font-bold">
                              About Us
                            </b>
                          </p>
                        </Link>
                        <Link to={"contact-us"}>
                          <p className=" mt-1 hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Contact Us
                            </b>
                          </p>
                        </Link>
                        <Link to={"careers"}>
                          <p className=" mt-1 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Careers
                            </b>
                          </p>
                        </Link>
                        <Link to={"process"}>
                          <p className=" mt-1 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Process
                            </b>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4 h-[480px]">
                      <div className=" mb-5">
                        <p className="text-2xl">Industries</p>
                        {/* <p className="text-lg hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer mt-3">
                          <b>Automotive</b>
                        </p> */}
                        <Link to={"education-training"}>
                          <p className=" mt-2 hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Education and<br></br>
                              Training
                            </b>
                          </p>
                        </Link>
                        <Link to={"food-beverage"}>
                          <p className="mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Food and<br></br>
                              Beverage
                            </b>
                          </p>
                        </Link>
                        <Link to={"healthcare-apps-solutions"}>
                          <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Healthcare
                            </b>
                          </p>
                        </Link>
                        <Link to={"media-publishing-entertainment"}>
                          <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Media,<br></br>
                              Publishing &<br></br>
                              Entertainment
                            </b>
                          </p>
                        </Link>
                        <Link to={"retail-ecommerce"}>
                          <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Retail and E-<br></br>
                              Commerce
                            </b>
                          </p>
                        </Link>
                        <Link to={"sports-fitness"}>
                          <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                            <b className="text-[13px] tracking-wide font-bold">
                              Sports & Fitness
                            </b>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[17%] ">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4 h-[480px]">
                      <div className=" mb-5">
                        <p className="text-2xl">Knowledge Hub</p>
                        <Link to="data-analytics-insights">
                          <p className=" hover:text-[#D4386C] hover:text-[#D4386C] cursor-pointer mt-3">
                            <b className="text-[13px] tracking-wide font-bold">
                              Data Analytics & <br></br>
                              Insights
                            </b>
                          </p>
                        </Link>
                        <Link to="digital-experience-platforms">
                          <Fragment>
                            <Accordion open={open === 1}>
                              <AccordionHeader
                                className="p-0 hover:text-[#D4386C] mt-3 "
                                onClick={() => handleOpen(1)}
                              >
                                <b className="text-[13px] tracking-wide font-bold">
                                  Digital Experience
                                  <p className="mr-[55px]">Platforms</p>
                                </b>
                              </AccordionHeader>
                              <AccordionBody className="p-1">
                                <Link to={"customer-relationship-management"}>
                                  <p className="mt-2 hover:text-[#D4386C] cursor-pointer">
                                    <b className="text-[13px] tracking-wide font-bold">
                                      Customer Relationship Management (CRM)
                                    </b>
                                  </p>
                                </Link>
                              </AccordionBody>
                            </Accordion>
                          </Fragment>
                        </Link>
                        <Link to="digital-transformation">
                          <p className="hover:text-[#D4386C] cursor-pointer mt-3">
                            <b className="text-[13px] tracking-wide font-bold">
                              Digital Transformation
                            </b>
                          </p>
                        </Link>
                        <Link to="digital-commerce">
                          <Fragment>
                            <Accordion open={open === 2}>
                              <AccordionHeader
                                className="p-0 hover:text-[#D4386C] text-[13px] tracking-wide font-bold mt-3"
                                onClick={() => handleOpen(2)}
                              >
                                Digital Commerce
                              </AccordionHeader>
                              <AccordionBody className="p-1">
                                <Link to={"b2b-ecommerce"}>
                                  <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                    <b className="text-[13px] tracking-wide font-bold">
                                      B2B eCommerce
                                    </b>
                                  </p>
                                </Link>
                                <Link to={"magento"}>
                                  <p className="text-lg mt-2 hover:text-[#D4386C] cursor-pointer">
                                    <b className="text-[13px] tracking-wide font-bold">
                                      Magento
                                    </b>
                                  </p>
                                </Link>
                              </AccordionBody>
                            </Accordion>
                          </Fragment>
                        </Link>
                        <Link to="digital-experience-design">
                          <Fragment>
                            <Accordion open={open === 3}>
                              <AccordionHeader
                                className="p-0 hover:text-[#D4386C] text-[13px] tracking-wide font-bold mt-3"
                                onClick={() => handleOpen(3)}
                              >
                                Experience Design
                              </AccordionHeader>
                              <AccordionBody className="p-1">
                                <Link to={"experience-designa"}>
                                  <p className="text-lg mt-2 hover:text-[#D4386C] cursor-pointer">
                                    <b className="text-[13px] tracking-wide">
                                      User Experience
                                    </b>
                                  </p>
                                </Link>
                              </AccordionBody>
                            </Accordion>
                          </Fragment>
                        </Link>
                        <Fragment>
                          <Accordion open={open === 4}>
                            <Link to="product-development">
                              <AccordionHeader
                                className="p-0 hover:text-[#D4386C] text-[13px] tracking-wide font-bold mt-3"
                                onClick={() => handleOpen(4)}
                              >
                                Product Development
                              </AccordionHeader>
                            </Link>
                            <AccordionBody className="p-1">
                              <Link to={"agile-software-development"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Agile Software<br></br>
                                    Development
                                  </b>
                                </p>
                              </Link>
                              <Link to={"cloud-computing"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Cloud Computing
                                  </b>
                                </p>
                              </Link>
                              <Link to={"custom-software-development"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Custom Software Development
                                  </b>
                                </p>
                              </Link>
                              <Link to={"devoops"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    DevOps
                                  </b>
                                </p>
                              </Link>
                              <Link to={"mobile-app-developments"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Mobile App Development
                                  </b>
                                </p>
                              </Link>
                              <Link to={"mvp-developments"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Minimum Viable<br></br>
                                    Product
                                  </b>
                                </p>
                              </Link>
                              <Link to={"outsourcing"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Outsourcing<br></br>
                                  </b>
                                </p>
                              </Link>
                              <Link to={"saas"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    SaaS
                                  </b>
                                </p>
                              </Link>
                              <Link to={"software-testing"}>
                                <p className=" mt-2 hover:text-[#D4386C] cursor-pointer">
                                  <b className="text-[13px] tracking-wide">
                                    Software Testing<br></br>
                                  </b>
                                </p>
                              </Link>
                            </AccordionBody>
                          </Accordion>
                        </Fragment>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[17%]">
                  <div className="mt-[60px] border-l-2 border-border_color">
                    <div className="ml-4 h-[480px]">
                      <div className=" mb-5">
                        <p className="text-2xl">Newsletter</p>
                        <p className="mt-3">
                          Get forward-thinking digital insights shaping the tech
                          industry in your Inbox.
                        </p>
                        <div className="mb-6">
                          <input
                            type="email"
                            id="email"
                            className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Enter your Email"
                            required
                          />
                        </div>
                        <button className="bg-[#D4386C]  text-white font-bold py-2 px-4 rounded mt-1">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <aside
        id="sidebar-multi-level-sidebar"
        className={
          openSidebar
            ? "fixed top-0 left-0 z-40 w-64 h-screen transition-transform  sm:translate-x-0 bg-grey-color right-shadow 1h-[100%] right-shadow sm:hidden"
            : "fixed top-0 left-[-15px] z-40 w-[270px] h-screen transition-transform -translate-x-full sm:translate-x-0 bg-grey-color right-shadow !h-[100%] sm:hidden"
        }
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
        <div className="mt-[60px]">
        {/* <div className="w-full">
          <i className="fa fa-times float-right" aria-hidden="true"></i>
          </div> */}
            <Link to="/" onClick={()=> {setMenuActive(1);SetOpensidebar(false)}}>
              <div className={menuActive == 1 ? "mb-1  text-bg_colors w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer" : "mb-1  w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer"}>
                <span className=" mt-[9px] ml-3 font-semibold">Home</span>
              </div>
            </Link>
          </div>
          <div className="mt-[12px]">
            <Link to="software-development-company" onClick={()=> {setMenuActive(2);SetOpensidebar(false)}}>
              <div className={menuActive == 2 ? "mb-1  text-bg_colors w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer" : "mb-1  w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer"}>
                <span className=" mt-[9px] ml-3 font-semibold">Services</span>
              </div>
            </Link>
          </div>
          <div className="mt-[15px]">
            <Link to="aws-development"  onClick={()=> {setMenuActive(3);SetOpensidebar(false)}}>
            <div className={menuActive == 3 ? "mb-1 text-bg_colors w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer" : "mb-1 w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer"}>
                <span className=" mt-[9px] ml-3 font-semibold">Expertise</span>
              </div>
            </Link>
          </div>
          <div className="mt-[15px]">
            <Link to="success-stories"  onClick={()=> {setMenuActive(4);SetOpensidebar(false)}}>
            <div className={menuActive == 4 ? "mb-1  text-bg_colors w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer" : "mb-1  w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer"}>
                <span className=" mt-[9px] ml-3 font-semibold">Success Stories</span>
              </div>
            </Link>
          </div>
          <div className="mt-[15px]">
            <Link to="insights"  onClick={()=> {setMenuActive(5);SetOpensidebar(false)}}>
            <div className={menuActive == 5 ? "mb-1 text-bg_colors w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer" : "mb-1 w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer"}>
                <span className=" mt-[9px] ml-3 font-semibold">Insights</span>
              </div>
            </Link>
          </div>
          <div className="mt-[15px]">
            <Link to="contact-us"  onClick={()=> {setMenuActive(6);SetOpensidebar(false)}}>
            <div className={menuActive == 6 ? "mb-1  text-bg_colors w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer" : "mb-1  w-[205px] mx-auto h-[43px] rounded-[8px] grid cursor-pointer"}>
                <span className=" mt-[9px] ml-3 font-semibold">Contact us</span>
              </div>
            </Link>
          </div>
        </div>
      </aside>
      <Outlet />
      <Footer />
    </div>
  );
};

export default Header;
