
import {
  Img1,
  frontrushm,
  GWA1m,
  acomplete,
  Thumbnail,
  Thumbnail1,
  tabhi,
  trish,
  vzimg,
} from "../../assets";
const DataBaseDevelopement = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                CUSTOM DATABASE DEVELOPMENT SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Power Better Business Outcomes with Custom Database Development.
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Transform your digital business by partnering with our experienced database design and development company.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with Databases?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
          <div className="sm:ml-3">
            <div className="">
              <img
                src={vzimg}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img src={trish} className=" w-full object-cover"></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img
                src={tabhi}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
            THE Rapidsofts APPROACH
          </h1>
          <div className="container">
            <div className="sm:mt-[70px] max-sm:mt-[30px]">
              <p className="text-dark  sm:text-6xl mb-5 max-sm:text-[20px] max-sm:text-center">
                <b>Your Partner in Optimal Database-driven Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Behind every successful digital product or platform is a smart database programmed to align business objectives with user needs. For more than 20 years, our custom database development company has been a leader in adopting new technologies, paradigms, and approaches to innovate high-performing applications. Our expert database designers and developers include AWS and Microsoft-certified specialists who engineer cloud databases and other customized systems that streamline business processes, boost operational and administrative performance, and optimize efficiencies.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[20px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Database Consulting Services
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  An effective database is not a cookie-cutter data container; it’s a critical system component that drives your entire organization forward. This is why you need database development solutions tailored to your company’s specific objectives. Our expert database developers take a deep dive into your business needs to come up with the best solution, from straightforward migration to devising architecture for a bespoke system. From NoSQL databases to hierarchical, network, object-oriented, and relational databases (RDBMS), our database consultants bring firsthand knowledge to your unique project.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Database Optimization
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Get the most out of your software development services with expert database optimization and upgradation services that speed up operations, lower costs by optimizing the underlying infrastructure, remove bottlenecks, and ensure your database is ready to handle scale and load. Our database performance-tuning specialists have expertise in optimization strategies from database indexing to server tuning, identifying and implementing waste-cutting measures across your digital properties for improved functionality, efficiency, and ROI.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Database Upgradation & Migration
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Data is the lifeblood of your digital business, so keeping your systems up-to-date is critical. Our database solution experts facilitate a broad range of migrations: from servers to the cloud, across database systems, or to a completely different architecture. Throughout the process of implementing your custom database development solutions, we ensure your workflow is uninterrupted and your data integrity is protected for your peace of mind.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center  max-sm:mt-[50px]">
                  Ongoing Support & Maintenance
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Your database is like a car’s engine: keep it well-oiled and maintained, and enjoy better performance and lower costs over time. By partnering with our seasoned custom database development company, you’re putting expert DBAs behind the wheel, dedicated to ensuring seamless software performance with continuous upgrades and updates that support business continuity and success.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Testing Services
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Effective database testing is both an art and a science — and it’s an area in which our database development company shines. Our team of seasoned, independent database professionals look at your database scalability, security, durability, and consistency. We help ensure your data stability and security with leading-edge testing services designed to reveal flaws and vulnerabilities and identify opportunities for optimization.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center  max-sm:mt-[50px]">
                  Data Preparation
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  The rise of data analytics as a critical business growth strategy shines a spotlight on the need for accurate, accessible data. You can count on our expert team to engineer database development solutions to cleanse, transform, and consolidate data from disparate sources. This helps you accelerate time to insight data for better decisions, products, and customer satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Custom Database Development Services
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  In designing and building a database to suit your software needs, it’s not just about getting the engineering right — it’s also about engineering the right database solution for your unique software product. We provide custom database development services for data-driven software — web, mobile, internal, and more — ranging from small projects to enterprise-grade systems and big-data solutions that require rock-solid database architecture to become reliable solutions
                </p>
              </div>
            </div>
          </div>

          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={Img1} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      IMGIMG Case Study We Build Innovative Web Portals for
                      Global Brands
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how we helped a leading international sports talent
                    agency boost its staff efficiency and vendor engagement by
                    automating its merchandising and licensing deals with a
                    custom-designed B2B platform.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={frontrushm}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      FrontRushFrontRush Case Study We Engineer Universal Apps
                      to Streamline Business Objectives
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we were able to build a bespoke CRM for an
                    athletic recruitment software company that rolled out across
                    native apps, including Apple and Microsoft products. The
                    resulting platform provided a winningly consistent
                    cross-platform experience for our partner.
                  </p>
                  <img
                    src={GWA1m}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Build Cloud-based Enterprise Products that Deliver
                      Flawless Experiences
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Explore how we helped an ambitious video visionary build an
                    intelligent video platform that demonstrates the power video
                    can bring to the workplace.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND T
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DataBaseDevelopement;
