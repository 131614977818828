// import React from 'react'
import {
  softwaredevelopment,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment4,
  softwaredevelopment5,
  softwaredevelopment6,
  softwaredevelopment7,
  softwaredevelopment8,
  softwaredevelopment9,
  softwaredevelopment10,
  softwaredevelopment11,
  softwaredevelopment12,
  softwaredevelopment13,
  softwaredevelopment14,
  softwaredevelopment16,
  softwaredevelopment17,
  softwaredevelopment18,
  softwaredevelopment19,
  digitalexpplatform1,
  digitalexpplatform3,
  digitalexpplatform4,
  edplacecasesmallpic,
  pageantbigcasestudpic,
  voylegalcasesmallpic,
  Thumbnail3,
  howtoprioritizefeaturesforyourmvp,
  Thumbnail4,
  ascend1,
  Hiring,
  GoogleCloudSQL,
  digitalcustomerexperiencestrategy,
  Digital_tra2,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  digitalexpplatform,
  itconsultbanner,
  flimskillm,
  acomplete,
  Thumbnail,
  Thumbnail1,
  dtdiagram,
  bannerods,
  netsolutions,
  mobilebanner,
  netsolutions1,
  dxp,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const ReactNative = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="flex">
          <div className="w-[60%]">
            <div className="mt-[200px]">
              <h1 className="text-[23px] font-black text-[#D4386C]">
              EXPERT REACT NATIVE DEVELOPMENT COMPANY
              </h1>
              <h1 className="text-[40px] font-black w-[75%]">
              Accelerate Your App Releases With Customized React Native Development
              </h1>
              <p className="text-[23px] italic mt-4 w-[80%]">
              Leverage the power of a top developers’ tool to deliver a seamless cross-platform experience.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="w-[40%]">
            <form className="bg-[#F9D94E] shadow-2xl px-16 pt-9 pb-8 mb-4 w-[450px] float-right mt-[150px]">
              <h1 className="text-[30px] font-black">
              Looking for React Native?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-[150px]">
        <div className="bg-[#F9D94E] p-[100px] w-[85%] m-auto">
          <img src={itconsultbanner} className="w-full h-[600px]" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  text-2xl">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="text-5xl mt-2">
                <b>Our Approach to React Native App Development</b>
              </p>
              <p className="text-3xl mt-4">
              Since React Native’s inception in 2015, our seasoned team of app development experts has harnessed the power of this innovative open-source solution to accelerate time to market and ensure a consistent cross-platform experience. When combined with our Agile, DevOps-driven culture, we’re able to optimize all aspects of the app creation process, from concept and UX/UI road mapping through prototyping, testing, release, and maintenance. The result is fast, flawless mobile app development cycles on iOS and Android platforms.
              </p>
              <div className="flex mt-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mt-[100px]">
          <p className="text-7xl">
            <b>Transform Your Business with Our Custom React Native Development Services</b>
          </p>
          <p className="text-2xl mt-3 w-[90%]">
          While it’s true that this robust JavaScript framework simplifies the development process, not all React Native development pros are created equal. Our team has extensive experience across industries and verticals in providing top-tier React Native web and mobile app development services. The combination of our developers and the vast, vibrant React Native community allows us to keep pace with trending technologies and deliver cutting-edge solutions that satisfy the ever-evolving needs of global customers.
          </p>
          <p className="text-2xl mt-3 w-[90%]">We offer the following Flutter app development services:

</p>
          <div className="flex mt-[150px]">
            <div className="w-[50%]">
              <h4 className="font-extrabold text-[52px] tracking-widest w-[600px]">
              React Native App Development
              </h4>
              <p className="text-[22px] font-light pt-4">
              As a world-class React Native development company, we offer full-spectrum React Native app development services, from creating innovative UI to developing customized cross-platform mobile apps that artfully achieve your business objectives while delighting your users. Thanks to an in-depth knowledge of the React Native API library, our experienced developers simultaneously provide iOS development services and Android app development services, sharpening your competitive edge and helping future-proof your business.
              </p>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="w-[50%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="ml-[100px]"
                ></img>
              </div>
            </div>
          </div>
          <div className="flex mt-[150px]">
            <div className="w-[50%]">
              <div className="">
                <img src={customplatformgraphic} className="mt-[100px]"></img>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="">
                <h4 className="font-extrabold text-[52px] tracking-widest w-[600px]">
                React Native Consulting
                </h4>
                <p className="text-[22px] font-light pt-4">
                React Native is the tool, but the best-case utilization of this robust open-source framework has to be expertly planned and executed to create the optimal user experience. As a leading custom mobile app development company, once we understand your business priorities and goals, we create a strategic roadmap for how to best leverage the power of React Native to build cross-platform apps with a native look and feel.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="flex mt-[150px]">
            <div className="w-[50%]">
              <h4 className="font-extrabold text-[52px] tracking-widest w-[600px]">
              React Native Migration/ Legacy App Migration
              </h4>
              <p className="text-[22px] font-light pt-4">
              Thanks to React Native’s core benefits of using a single codebase for multiple platforms and a dynamic, active community of developers, there are countless reasons migrating to the framework is a smart business move. From lower development costs to smooth, error-free cross-platform UX, reusable code, extensive third-party plug-ins, and native app compatibility, there’s a reason that today’s leading apps, like Instagram, Skype, Shopify, SoundCloud, Microsoft, Uber, and Salesforce, among others, use React Native.
              </p>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="w-[50%]">
              <div className="">
                <img src={personalmultidiagram} className="ml-[100px]"></img>
              </div>
            </div>
          </div>
          <div className="flex mt-[150px]">
            <div className="w-[50%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="w-[50%]">
              <div className="">
                <h4 className="font-extrabold text-[52px] tracking-widest w-[600px]">
                Migration to Flutter
                </h4>
                <p className="text-[22px] font-light pt-4">
                An important part of our iOS app development services and Android app development services includes ensuring that app migration is seamless and pain-free. As a leading Flutter app development company, we can ensure the same UI and business logic is used across all platforms while accelerating your time to market with a product you — and your customers — can count on.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="flex mt-[150px]">
            <div className="w-[50%]">
              <h4 className="font-extrabold text-[52px] tracking-widest w-[600px]">
              React Native Team Augmentation
              </h4>
              <p className="text-[22px] font-light pt-4">
              As a React Native app development company, we take pride in providing flexible services to our clients. If you just need to enhance your in-house capabilities, we’re happy to serve as an extended arm, providing a team of React Native experts who operate in our offshore development model/dedicated development team model. This makes it easy for you to scale your teams up or down to meet your evolving needs.
              </p>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="w-[50%]">
              <div className="">
                <img src={personalmultidiagram} className="ml-[100px]"></img>
              </div>
            </div>
          </div>
    
          <div className="w-[90%] m-auto">
            <div className="mt-[100px]">
              <p className="text-6xl">
                <b>
                  Success Stories: Our Track Record of IT Consulting Success
                  Across Several Industries and Business Verticals
                </b>
              </p>
            </div>
          </div>

          <div className="mt-[100px]">
            <div className="">
              <h5 className="text-[34px] font-extrabold text-center pt-20">
                Insights
              </h5>
              <div className="flex flex-row gap-5">
                <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={howtoprioritizefeaturesforyourmvp}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail4} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail3} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReactNative;
