// import React from 'react'
import {

  mainstreetm,
  ecp1,
  GWA1m,
  acomplete,
  Thumbnail,
  Thumbnail1,
  zsoft,
  vimg,
  gaimg,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const Php = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                CUSTOM PHP WEB DEVELOPMENT SERVICES & SOLUTIONS
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Build a Successful Digital Business with a Custom Product or
                Platform
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Transform your business by partnering with our expert PHP
                development company.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with PHP Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
          <div className="sm:ml-3">
            <div className="">
              <img
                src={zsoft}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img src={vimg} className=" w-full object-cover"></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img
                src={gaimg}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          {/* <img src={bannerods} className="w-full h-[600px]" /> */}
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-dark  sm:text-6xl mb-5 max-sm:text-[20px] max-sm:text-center">
                <b>Expert, End-to-End PHP Web Development Services</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                When it comes to delivering a robust digital experience that
                strengthens the brand-customer relationship and sharpens your
                competitive edge, PHP offers unparalleled development
                opportunities. Since our inception 20 years ago, we’ve
                established ourselves as a leading PHP development company. Our
                experienced team of core PHP and CMS specialists have designed
                and delivered customized websites, eCommerce stores, apps, and
                portals that help businesses scale fast and stay future-proof.
              </p>
              <div className="flex mt-5 max-sm:mb-[10px]">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Custom PHP Development Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Web Application Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Because your customers demand and deserve a fast, intuitive,
                  feature-rich experience, you need a PHP development partner
                  you can trust. Using the top PHP frameworks such as Laravel,
                  we offer full-spectrum development services for innovative
                  B2B, B2C, and B2B2C solutions, including custom websites and
                  apps, enterprise CMS (WordPress, Drupal), and engaging digital
                  experience platforms.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  E-commerce Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  In the competitive eCommerce landscape, details like load
                  time, smooth scrolling, and memory management can make or
                  break your customer experience. Our seasoned PHP development
                  team has the expertise to innovate feature-rich,
                  high-performance online storefronts either built with Magento
                  or custom-engineered for better engagement and retention — for
                  results you can count on.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Upgrade/Modernize PHP Applications
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  PHP has one of the richest ecosystems in the web development
                  world, with endless opportunities to modernize and upgrade
                  legacy products and platforms. Our expert PHP development
                  services include all you need to up-level your application.
                  And we also provide advanced testing to ensure your
                  applications are running at peak performance.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Cloud-based Web Application (SaaS) Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  One of today’s fastest-growing digital business models is
                  Software-as-a-Service (SaaS), and we have more than a decade
                  of experience designing, developing, implementing, and
                  maintaining custom-engineered solutions for businesses of all
                  sizes and scopes, from startups through enterprise. As a
                  leading PHP web development company, we specialize in a
                  variety of custom SaaS solutions including, CRM, ERP,
                  collaboration, entertainment, or education, among others.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Migration to PHP
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Your data is your lifeblood, as is your customer experience,
                  so you can’t just hire a PHP developer and trust that your
                  migration will be seamless and worry-free. As a top-tier PHP
                  web development company, we provide reliable migration from
                  your existing tech stack to PHP with an unparalleled level of
                  transparency and accountability.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  eCommerce Platform Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  An intuitive shopping experience and easy, secure checkout are
                  essential for today’s online customers. Rapidsofts is a
                  certified Adobe Solution Partner for Magento—Adobe’s leading
                  eCommerce platform. Our online store specialists can provide
                  your team with fully customized eCommerce development
                  solutions. We engineer the right solution for your brand — one
                  that optimizes traffic and boosts your sales by turning
                  browsers into buyers.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Support & Maintenance
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Make your life easier with expert support and maintenance
                  handled by our PHP team. We keep your website or application
                  running fast, safely, and smoothly with proactive
                  troubleshooting. More than just a “housekeeping” service, our
                  expert PHP developers keep your competitive edge sharp and
                  market advantage secure.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Web Services & API Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Supercharge your PHP web development project with robust
                  integrations. We’re experienced in creating user-friendly,
                  secure back-end systems and data platforms with robust,
                  scalable, enterprise web services (including CRMs and ERPs)
                  and APIs.
                </p>
              </div>
            </div>
          </div>

          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={ecp1} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Design and Build Extensive Multi-channel eCommerce Platforms
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    We Design and Build Extensive Multi-channel eCommerce Platforms
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={GWA1m} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Help Improve Digital Experiences with Strategic Legacy System Upgrades
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we helped a trusted name in home improvement seamlessly reinvent and
                    improve the customer experience across multiple platforms with a strategic
                    Drupal upgrade and storefront redesign.
                  </p>
                  <img
                    src={mainstreetm}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Help Customer-centric Brands Deliver High-quality Experiences in Real-time
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how we developed a beautifully designed, dynamic desktop and mobile app for a Canadian real estate company that delivers on the brand promise of up-to-date listings end users can count on.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:pt-8 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT,  PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT,  QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Php;
