import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  roboticsbanner,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  netImage2048x429,
  Digital_tra1,
  Hiring,
} from "../../assets";
import { Link } from "react-router-dom";
const Robotic = () => {
  return (
    <>
      <div className="container">
        <div className="flex mt-4">
          <div className="w-[50%]">
            <div className="mt-[160px]">
              <p className="text-3xl">
                <b>Robotic Process Automation</b>
              </p>
              <p className="mt-2 mb-2">
                Revolutionize your venture with our effective robotic process
                automation services. Witness the potency of automation with
                RevInfotech.
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <img src={roboticsbanner}></img>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <p className="text-4xl">
              <b>Offering Top-Notch RPA Solutions</b>
            </p>
            <p className="mt-4 mr-4">
              Technology has revolutionized the market and it continues to do
              so. Companies have started to leave the standard industrial
              practices for the modern automated approaches. Many factors play a
              role in the success of an organization, and RPA is also an
              integral element. With the boom in technology growth, RPA has also
              seen an increase in usage. RPA integration is a vital step in the
              market strategy of many companies, and you can avail of it as
              well. With RevInfotech, you can equip your business with the best
              RPA solutions. Reach us now and experience the brilliance of RPA
              firsthand.
            </p>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AR and VR</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                    Enterprise Mobility
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-5">
        <div className="text-center text-white p-5">
          <p className="text-3xl">
            Complete Everything Quickly, Easily, and Cheaply
          </p>
        </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-[70px]">
          Avail Quality Benefits
        </h5>
        <div className="mt-[100px]">
          <img src={netImage2048x429}></img>
        </div>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                PROCESS AUTOMATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Automate your procedures and get everything wrapped up quickly.
                With our automation help, you can get everything done
                efficiently.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                PROCESS UNIFICATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Streamline every task and carry out everything in unison.
                Improve your overall functionality and work in coordination.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                ISSUES IDENTIFICATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Identify the hurdles restricting your business from achieving
                optimal efficiency. Eradicate the issues with our help.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Additional Advantages
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                EASY INTEGRATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                RPA proposes simple integration without invading any business
                model or structure. Do not bode with any unwanted threats, and
                integrate the latest establishment algorithm ensures for growth
                of your company.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                WORKFLOW ENHANCEMENT
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                With efficient robotic price automation, you can easily enhance
                your workflow. Unify your proceedings and get everything on
                track. Avail of the best RPA solutions on the web with
                RevInoftech’s help.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                REFRESH TASKS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                Robotic process automation helps your business by carrying out
                routine and repetitive tasks comprehensively. Provide your
                employees a new sense of refreshment as they carry out tasks
                more effectively.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVED ACCURACY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                With repetition and routineness comes dullness and inefficiency.
                Performing the same task over and over is bound to decrease the
                accuracy of anyone. Our RPA solutions eradicate such issues,
                improving your accuracy.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BETTER PROCESSING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Integrate your processes better with our expertise. As you
                integrate RPA into your organization, the collaboration within
                your venture increases. Experience better processing and get
                added advantages with our help
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
              How RPA Solutions can Help Your Business?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              RPA is a viable solution in many difficult situations faced by
              businesses. Our team of experts specializes in crafting the best
              possible RPA strategy for your business, regardless of its nature
              and size. Premium benefits such as continuous deployment,
              construction, and integration are a norm in our strategies. With
              our help, your company can focus on vital aspects where we break
              down communication barriers within your site. Let us open the
              doors of growth for your firm.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
              Why Pick RevInfotech for Your RPA Integration?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              RPA also emerged as the ideal solution for many modern market
              requirements, and companies are more than eager to implement it
              within their system. However, choosing the ideal approach to doing
              it is difficult. Therefore, you need to choose the party that can
              do so in an effective manner. RevInfotech packs the ability to do
              this and has also offered on many occasions. Our specialists can
              ensure that your company follows the right path to ride the
              success wagon.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra1} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Robotic;
