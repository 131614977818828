import React from 'react'
import { Design_thinking, Initiation, xprocess, iterative, devops, xcomparison, Yx_Thumbnail, Z_Development, zScreenshots } from "../../assets";
import { Link } from "react-router-dom";

const Process = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className="sm:flex">
                    <div className="sm:w-[60%] max-sm:w-[100%]">
                        <div className="sm:mt-[200px] max-sm:mt-[80px] sm:w-[600px] mx-auto">
                            <h1 className="sm:text-[78px] font-black max-sm:text-[20px] max-sm:text-center">How We Think, Create & Iterate.</h1>
                            <p className="sm:text-[33px] tracking-wider sm:mt-4 max-sm:mt-2 max-sm:text-[20px] max-sm:text-center">
                                Our customer-centric, agile approach gets your products to market faster for a competitive edge.
                            </p>
                        </div>
                    </div>
                    <div className="sm:w-[40%] max-sm:w-[100%]">
                        <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[30px] sm:w-[450px]">
                            <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Need Help with</h1>
                            <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Development?</h1>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                                    id="username"
                                    type="text"
                                    placeholder="Name*"
                                />
                            </div>
                            <div className="">
                                <input
                                    className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder="Email*"
                                />
                            </div>
                            <div className="flex mt-2">
                                <div className="w-35%">
                                    <select
                                        id="countries"
                                        className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    >
                                        <option selected>Choose a country</option>
                                        <option value="US">India</option>
                                        <option value="CA">Canada</option>
                                        <option value="FR">France</option>
                                        <option value="DE">Germany</option>
                                    </select>
                                </div>
                                <div className="w-[65%] ml-3">
                                    <input
                                        className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                        id="phone"
                                        type="phone"
                                        placeholder="+91"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <textarea
                                    id=""
                                    row="5"
                                    className="w-full px-3 h-20 py-2"
                                    placeholder="Message*"
                                ></textarea>
                            </div>
                            <div className="mt-3 mb-16">
                                <button
                                    type="button"
                                    className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                                >
                                    Get In Touch
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='sm:w-[88%] max-sm:w-[100%] bg-[#FDDF57] ml-auto mt-32 flow-root'>
                <div className='sm:px-32 py-8  '>
                    <h5 className='font-bold sm:text-[33px] max-sm:text-[20px] max-sm:text-center'>Our Process</h5>
                    <div className="sm:mt-5">
                        <img src={Design_thinking} className="w-full sm:h-[500px] max-sm:p-2" />
                    </div>
                    <div className='sm:flex mt-10 gap-5'>
                        <div className='sm:w-[33%] max-sm:w-[100%]'>
                            <h5 className='font-bold sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Discover & Define</h5>
                            <p className='sm:text-[22px] mt-4 max-sm:text-[20px] max-sm:text-center'>Using Design Thinking to empathize with and understand your target audience, we take a deep dive into the mobile needs and challenges of your business, customers, and desired technology.</p>
                        </div>
                        <div className='sm:w-[33%] max-sm:w-[100%] max-sm:hidden'>
                            <h5 className='font-bold sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Discover & Define</h5>
                            <p className='sm:text-[22px] mt-4 max-sm:text-[20px] max-sm:text-center'>Using Design Thinking to empathize with and understand your target audience, we take a deep dive into the mobile needs and challenges of your business, customers, and desired technology.</p>
                        </div>
                        <div className='sm:w-[33%] max-sm:w-[100%] max-sm:hidden'>
                            <h5 className='font-bold sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Discover & Define</h5>
                            <p className='sm:text-[22px] mt-4 max-sm:text-[20px] max-sm:text-center'>Using Design Thinking to empathize with and understand your target audience, we take a deep dive into the mobile needs and challenges of your business, customers, and desired technology.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container sm:w-[60%] mx-auto'>
                <div>
                    <div className='border-l-8 border-sky-400'>
                        <div className='sm:pl-16 sm:pt-20 max-sm:pt-10'>
                            <p className='font-black sm:text-[20px] text-[#F13623] max-sm:text-[20px] max-sm:text-center'>DESIGN THINKING</p>
                        </div>
                    </div>
                    <div className='sm:pl-16'>
                        <h5 className='font-bold sm:text-[33px] ml-2 mt-2 max-sm:text-[20px] max-sm:text-center'>It Starts with Your Users. (Spoiler Alert: It Ends There, Too.)</h5>
                    </div>
                </div>
            </div>
            <div className='container sm:w-[50%] mx-auto sm:mt-5 max-sm:mt-2'>
                <div className='sm:flex'>
                    <div className='sm:w-[60%] max-sm:w-[100%] sm:px-20'>
                        <p className='sm:text-[20px] max-sm:text-[20px] max-sm:text-center'>Our co-creation process kicks off with a fun, informative and interactive Ideation Workshop where take a deep dive into the needs and challenges of your business, customers and technology. This critical exploration driven by Design Thinking allows us to deeply understand and empathize with your core audience.</p>
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <p className='sm:text-[20px] max-sm:text-[20px] max-sm:text-center'>A dedicated team of our experienced designers and developers then takes these insights and begins to conceptualize and design user-centric solutions. We work closely with you to set priorities and ensure that the wireframes, architecture, interfaces and/or prototypes we produce have maximum impact for your brand or business.</p>
                    </div>
                </div>
                <div className="mt-5">
                    <img src={Initiation} className="sm:w-[1400px] sm:h-[500px]"/>
                </div>
            </div>
            <div className='sm:w-[88%] bg-[#F8FBFF] ml-auto sm:mt-32 max-sm:mt-10'>
                <div className='sm:px-32 py-8'>
                    <div className="sm:mt-5">
                        <img src={zScreenshots} className="" />
                    </div>
                    <div className='sm:pl-20 relative top-[-40px]'>
                        <div className='border-l-8 border-sky-400'>
                            <div className='sm:pl-16 pt-24'>
                                <p className='font-black sm:text-[30px] max-sm:text-[20px] max-sm:text-center'>From Viable Options to an Optimal Solution</p>
                            </div>
                        </div>
                        <div className='sm:pl-24'>
                            <p className='font-light sm:text-[25px] ml-2 mt-2 max-sm:text-[20px] max-sm:text-center'>Our battle-tested and ever-evolving, Scrum-based Agile Development methodology drives a dynamic iterative development process that allows us to measure, test, and scale your solution in the markets, mediums and channels that move your business forward.</p>
                            <p className='font-light sm:text-[25px] ml-2 mt-4 max-sm:text-[20px] max-sm:text-center'>For agile aficionados, our process looks like this:</p>
                            <div className="mt-5">
                                <img src={iterative} className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' container sm:w-[60%] mx-auto'>
                <div>
                    <div className='border-l-8 border-sky-400'>
                        <div className='sm:pl-16 sm:pt-20 max-sm:pt-10'>
                            <p className='font-black sm:text-[20px] text-[#F13623] max-sm:text-[20px] max-sm:text-center'>DRIVEN BY DEVOPS</p>
                        </div>
                    </div>
                    <div className='sm:pl-16'>
                        <h5 className='font-bold sm:text-[33px] ml-2 mt-2 max-sm:text-[20px] max-sm:text-center'>Build, Test, Release, Repeat </h5>
                    </div>
                </div>
            </div>
            <div className='container sm:w-[50%] mx-auto mt-5'>
                <div className='sm:flex'>
                    <div className='sm:w-[60%] max-sm:w-[100%] sm:px-20 '>
                        <p className='sm:text-[20px] max-sm:text-[20px] max-sm:text-center'>Our DevOps-defined culture brings you the benefit of seamless collaboration and rock-solid trust between our operations and development teams: faster, automated, reliable software releases.</p>
                    </div>
                    <div className='sm:w-[40%] max-sm:w-[100%]'>
                        <p className='sm:text-[20px] max-sm:text-[20px] max-sm:text-center'>You’ll enjoy the value of continuous delivery, ongoing innovation and increased opportunities for better ROI and bottom-line growth.</p>
                    </div>
                </div>
                <div className="mt-16">
                    <img src={devops} className="" />
                </div>
            </div>
            <div className='container sm:w-[70%] mx-auto sm:mt-36 max-sm:mt-10'>
                <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Insights</h5>
                <div className='sm:flex gap-5'>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={xcomparison} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  WEB DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold '>
                                        PHP vs Python: Features & Comparison in 2023</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Yx_Thumbnail} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>MOBILE APP DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                        Low-Code vs. No-Code Development: Which One Should You Choose?</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Z_Development} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        Understanding the API-first Approach for Product Development</Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <h5 className='text-[44px] font-extralight text-center max-sm:text-[20px]'>Got a Project in Mind? Get Started Now.</h5>
                    <div className='sm:w-[160px] mx-auto max-sm:text-center'>
                        <button
                            type="button"
                            className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Process