import React from "react";
import { NavLink } from "react-router-dom";
import dmca from "../assets/image/dmca.png";
import { logo_web_white, Clutch_1, g21 } from "../assets";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer relative">
      <div className="bg-[#0C0C0C] mt-3 flow-root mb-1">
        <div className="container sm:w-[90%] max-sm:w-[100%]">
          <div className="sm:flex sm:mt-5 max-sm:mt-4">
            <div className="sm:w-[25%] max-sm:w-[100%] max-sm:text-center">
              <Link to={"/"}>
                <img
                  src={logo_web_white}
                  className="sm-w-56 max-sm:w-[150px] max-sm:m-auto"
                />
              </Link>
              <p className="text-white sm:mt-4 max-sm:mt-2  max-sm:text-center  sm:mr-5 max-sm:text-[10px]">
                Our approach is to combine our understanding of user behavior
                with a commitment to meeting the business goals of our clients.
                We are proactive in our aftercare service and will alert our
                customers of any malicious behavior that we find.
              </p>
              <button
                type="button"
                className="bg-[#D4386C] px-3 py-2 font-bold mt-3 text-white max-sm:text-[10px]"
              >
                Learn More
              </button>
            </div>
            <div className="w-[25%] max-sm:hidden">
              <div className="float-right">
                <h5 className="text-white font-bold text-3xl max-sm:text-[10px] max-sm:text-center">
                  service
                </h5>
                <ul className="sm:mt-4 list-disc text-white ">
                  <li>
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Platform Modernization
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Agile Software Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Digital Commerce Marketing
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Research {"&"} Development Labs
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Technology Consulting
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Automation Testing
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Professional Service
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Outsourced Product Development
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-[25%] max-sm:hidden">
              <div className="float-right">
                <h5 className="text-white font-bold text-3xl max-sm:text-[10px] max-sm:text-center">
                  Expertise
                </h5>
                <ul className="sm:mt-4 list-disc text-white ">
                  <li>
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Artificial Intelligence
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      IOT Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Blockchain Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Web Design
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Web Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Mobile Application Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      Digital Marketing
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink to="" className="text-white max-sm:text-[5px]">
                      FinTech
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-[25%] max-sm:hidden">
              <div className="float-right">
                <div className="">
                  <h5 className="text-white font-bold text-3xl max-sm:text-[10px] max-sm:text-center">
                    Knowledge Hub
                  </h5>
                </div>
                <ul className="sm:mt-4 list-disc text-white max-sm:text-center">
                  <li className="">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      Agile Software Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      Agile Software Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      Minimum Viable Product
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      Mobile App Development
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      Software as a Service
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      Software Testing
                    </NavLink>
                  </li>
                  <li className="sm:py-2">
                    <NavLink
                      to=""
                      className="text-white max-sm:text-[5px] max-sm:text-center"
                    >
                      User Experience
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="sm:flex mb-5 sm:mt-5 max-sm:mt-2">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h5 className="text-white font-bold text-lg max-sm:text-[10px] max-sm:text-center">
                  Stay current with our latest insights
                </h5>
                <div className="flex">
                  <input
                    className="sm:w-[300px] max-sm:w-[100%] py-2 px-1.5 mt-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-[13px]"
                    id="username"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <button
                    type="button"
                    className="bg-[#D4386C] px-2.5 py-2 font-bold mt-3 ml-3 text-white max-sm:text-[10px]"
                  >
                    Submit
                  </button>
                </div>
                <p className="text-white mt-3 max-sm:text-[10px] max-sm:text-center">
                  We respect your
                  <span className="font-bold ml-2 max-sm:text-[10px] max-sm:text-center">
                    privacy & term-condition
                  </span>
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <p className="text-white font-bold text-lg max-sm:text-[10px] max-sm:text-center">
                Let's Connect
              </p>
              <div className="sm:flex mt-3 max-sm:text-center">
                <i
                  className="fa fa-facebook text-white text-[100px] "
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-twitter text-white ml-5 cursor-pointer text-lg"
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-linkedin text-white ml-5 cursor-pointer text-lg"
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-instagram text-white ml-5 cursor-pointer text-lg"
                  aria-hidden="true"
                ></i>
                <i
                  className="fa fa-spotify text-white ml-5 cursor-pointer text-lg"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-bg_footer p-6 text-center dark:bg-neutral-700">
          <div className="container w-[90%]">
            <div className="flex">
              <div className="w-[50%]">
                <p className="text-left max-sm:text-[5px]">
                  © 2023 Rapidsofts. All Rights Reserved.
                </p>
                <p className="text-left mt-2 max-sm:text-[5px]">
                  Rated <b>4.3/5</b> for Rapidsofts based on 249 reviews at
                  Google.
                </p>
              </div>
              <div className="w-[50%]">
                <div className="flex float-right">
                  <img
                    src={Clutch_1}
                    className="sm:w-[70px] max-sm:w-[40px]"
                  ></img>
                  <img
                    src={g21}
                    className="ml-5 sm:w-[70px] max-sm:w-[40px]"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
