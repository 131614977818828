import {
  softwaredevelopment,
  EMR,
  FMCGGiant,
  WooClubm,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment4,
  softwaredevelopment5,
  softwaredevelopment6,
  Hiring,
  GoogleCloudSQL,
  Digital_tra2,
  Pythonwebdevelopmentimage,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const Angular = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                CUSTOM ANGULAR DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Quickly Build and Scale Apps That Delight Your Customers
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Accelerate your app development with our all-pro team of Angular
                experts.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for Angular Web Development Services?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={Pythonwebdevelopmentimage} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Our Approach to Angular Web Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Angular has been a leading open-source JavaScript framework
                developed by Google since 2010, and our experienced team has
                been at the forefront of development with this feature-rich
                tool. Our Agile approach and DevOps-driven culture optimize our
                comprehensive, end-to-end Angular development services. The
                result is an efficient, seamless experience at all phases of the
                development process, from concept creation and ideation through
                UX/UI strategy, prototyping, testing, launch, and through to
                ongoing maintenance.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Services
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Explore Technology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
            <b>
              Transform Your Business with Bespoke Angular Development Services
            </b>
          </p>
          <p className="sm:text-3xl mt-5 max-sm:text-[20px] max-sm:text-center">
            When you’re looking for custom mobile app development, it pays to
            partner with an Angular app development company with the experience
            and know-how necessary to optimize the technology and provide
            results tied to critical KPIs.
          </p>
          <p className="sm:text-3xl mt-5 max-sm:text-[20px] max-sm:text-center">
            We offer the following Angular web development services:
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Angular Development
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                One of the biggest benefits of Angular is how it simplifies and
                accelerates the front-end development process. Our team of
                seasoned analysts, developers, and testing pros is experienced
                in the art and science of designing and building dynamic, modern
                apps that delight users and stand the test of time to help
                future-proof your digitally-driven business.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment1} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  API Development and Integration
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  As a top Angular app development company, we are invested in
                  building custom applications with feature-rich architecture,
                  airtight security, and built-in scalability. The apps we
                  create feature code maintainability and integrate seamlessly
                  for a beautifully responsive user experience.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Custom UI/UX using Angular
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Thanks to Angular’s simplified Model-View-Controller (MVC)
                pattern, modular structure, reusable and consistent code,
                HTML-defined UI, and built-in validation, our developers have
                all they need to devise a seamless, robust user experience that
                better engages, connects, and converts customers for your
                business.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment2} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment3} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center  max-sm:mt-[50px]">
                  Web App Modernization
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  One of the best ways to up-level your digital experience and
                  boost your bottom line is to modernize legacy applications by
                  migrating or upgrading to Angular with our seasoned team of
                  developers. Using Typescript to streamline codes into modules,
                  we’re able to improve inline documentation while minimizing
                  errors in build time.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Angular Maintenance Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Launch is just a starting point; to keep your application up to
                date and continuously improving, we’re your partner in providing
                on-point, custom Angular maintenance. And it’s not just about
                your application's technical aspects; we integrate ongoing
                Angular-powered upgrades to meet your ever-evolving business
                needs for better efficiency, consistency, and revenue-driving
                solutions.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment4} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment5} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Angular Development Team Augmentation
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Deciding to outsource Angular development is much easier when
                  you know you’re working with an experienced provider. Thanks
                  to our offshore development model/ dedicated development team
                  model, we have all you need to quickly scale up (or down) your
                  Angular development team to meet your current needs and
                  maximize time, budget, and resources.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                At a Glance: Why Choose Rapidsofts as Your Angular
                Development Partner?
              </h4>
              <ul className="list-disc mt-4">
                <li>
                  We are a full-spectrum Angular development company that has
                  worked with the technology since its inception and has
                  extensive experience working with companies from startups to
                  scale-ups and enterprises.
                </li>
                <li className="mt-3">
                  We specialize in delivering fast, high-performance
                  cross-platform applications designed and built from scratch.
                </li>
                <li className="mt-3">
                  Although a robust framework, Angular is just the starting
                  point — we don't just build apps for today. We engineer them
                  to future-proof your business for years to come.
                </li>
                <li className="mt-3">
                  We’re an android app development company and also specialize
                  in iOS mobile app development — no matter the platform, we
                  have your on-the-go needs covered.
                </li>
                <li className="mt-3">
                  We use the latest Angular features, including dependency
                  injection, data binding, and routing, to develop innovative,
                  interactive single-page applications.
                </li>
              </ul>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment6} className="sm:ml-[100px]"></img>
              </div>
            </div>
          </div>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <p className="sm:text-7xl sm:w-[90%] m-auto max-sm:text-[20px] max-sm:text-center">
              <b>FAQs About Rapidsofts’ Angular Web Development Services</b>
            </p>
            <div className=" mt-[50px]">
              <div className="sm:w-[80%] m-auto">
                <Accordion className="mb-5 max-sm:mt-[-100px]">
                  <div>
                    <Accordion.Item eventKey="0" className="">
                      <Accordion.Header>
                        <h5 className="sm:text-[26px] max-sm:text-[20px]">
                          What is Angular software development?
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="text-[20px] font-light text-3xl">
                          Angular software development uses the uber-popular
                          framework developed by Google to support dynamic user
                          experiences, lightning-fast responsiveness, and easy
                          code maintainability. The framework uses the
                          JavaScript-based TypeScript programming language,
                          which removes any dispensable code for a more
                          lightweight app design.
                        </div>
                        <div className="mt-3">
                          When you work with an Angular app development company,
                          you can expect a quicker, more efficient, and
                          cost-effective software development cycle. That’s
                          because Angular is component-based, which makes it
                          simpler for skilled developers to build and scale
                          applications.
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <Accordion.Item eventKey="1" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        Can Angular be used for mobile app development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        Absolutely! In fact, Angular is an excellent choice for
                        mobile app development because of its built-in code
                        reutilization between web and mobile. This is why
                        Angular is a popular choice for cross-platform app
                        development. Also, Angular combines well with other
                        mobile app development frameworks, like NativeScript and
                        Ionic, for stunning, feature-rich hybrid apps.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        What is Angular in web development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        In the world of web development, Angular is one of the
                        most-used frameworks for a variety of reasons. First and
                        most notably, it’s a free, open-source tool from Google
                        that has all the features, support, and functions that
                        developers love, from a variety of features to solid
                        documentation, a declarative, intuitive UI, simplified
                        development, and a modular structure that makes it easy
                        for an Angular web development team to divide and
                        conquer (among other benefits).
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="sm:w-[90%] m-auto">
              <div className="sm:mt-[100px] max-sm:mt-[50px]">
                <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                  <b>Success Stories</b>
                </p>
              </div>
              <div className="sm:flex">
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={FMCGGiant}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        We Build Complex Platforms That Make it Easy to Scale
                        and Grow
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Discover how we designed and built a novel, modular
                      platform that connected both ends of the convenience store
                      industry — brands and retailers — streamlining ad
                      activations and making it fast and easy to evolve the
                      application based on Voice of the Consumer (VoC) feedback
                    </p>
                  </div>
                </div>
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={WooClubm}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        We Partner With Clients to Continuously Upgrade IT &
                        Sharpen Their Competitive Edge
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Learn how we became industry giant ADP’s go-to UX and
                      development resource who, over time, has upgraded its
                      cloud-based platform capabilities, CRM, and
                      customer-facing automation for an efficient,
                      cost-effective, long-term, ROI-driving partnership.
                    </p>
                    <img
                      src={EMR}
                      className="mt-5 w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        We Create Strategic, Agile Responses to Industry Shifts
                        for Visionary Companies
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Explore how we helped an innovative online betting
                      platform respond mid-design when US betting regulations
                      unexpectedly loosened with restructured workflows,
                      intuitive UX, and smooth use of gamification that swiftly
                      earned the platform glowing reviews and a loyal customer
                      base.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Hiring} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      A Guide To Cloud-Native Application Development
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={GoogleCloudSQL} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Digital_tra2} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Angular;
