// import React from 'react'
import {
  edplacecasesmallpic,
  pageantbigcasestudpic,
  voylegalcasesmallpic,
  Thumbnail3,
  howtoprioritizefeaturesforyourmvp,
  Thumbnail4,
  digitalcustomerexperiencestrategy,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  zimg,
  trish,
  vnimg,
  ximg,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useEffect, useState } from "react";
const Laravel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C]  max-sm:text-[20px] max-sm:text-center">
                CUSTOM LARAVEL DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%]  max-sm:text-[20px] max-sm:text-center">
                Leverage the Power of Developers’ Favorite PHP Framework for Building Scalable Applications
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%]  max-sm:text-[20px] max-sm:text-center">
                Effortlessly build and scale Your digital business With fast, secure and elegant apps.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right mt-[150px]">
              <h1 className="sm:text-[30px] font-black  max-sm:text-[20px] max-sm:text-center">
                Looking for Laravel Web Development Services?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-[150px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={trish} className="w-full sm:h-[600px] object-cover" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl  max-sm:text-[20px] max-sm:text-center">
                <b>OUR APPROACH TO OFFSHORE DEVELOPMENT</b>
              </p>
              <p className="sm:text-5xl mt-2  max-sm:text-[20px] max-sm:text-center">
                <b>Our Approach to Laravel Web Development Services</b>
              </p>
              <p className="sm:text-3xl mt-4  max-sm:text-[20px] max-sm:text-center">
                Laravel, which bills itself as “the PHP web framework for artisans,” is built on the simple theory that the more expressive and elegant the code, the easier it is for skilled developers to make beautiful, state-of-the-art platforms and apps. Since its inception in 2011, Laravel has been a go-to framework for our team to swiftly create remarkable digital destinations, apps, and experiences that drive engagement and boost our clients’ bottom lines.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl  max-sm:text-[20px] max-sm:text-center">
            <b>Transform Your Business with Our Custom Laravel Development Services</b>
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%]  max-sm:text-[20px] max-sm:text-center">
            Thanks to the technology's flexibility, security, and ingenuity, our end-to-end Laravel web development services are swift, seamless, and cost-effective. Our expert engineers and developers love Laravel's easy modularity, which means creating, building, sustaining, and scaling world-class applications is a snap. From startups to scale-ups and enterprise applications, we offer custom web development services for businesses across industries and verticals, leveraging the creative power of Laravel to deliver tailor-made, innovative solutions.
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%]  max-sm:text-[20px] max-sm:text-center">
            We offer the following Laravel development services:
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Laravel Website Development Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                While the technology is designed specifically to ignite developers’ creativity, it takes a talented team of Laravel experts to optimize its features and functionality. Thanks to our Agile, DevOps culture, we can accelerate app creation while delivering exclusive designs, flawless UX, and highly scalable, responsive software solutions.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px]"
                ></img>
                  <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Restful APIs Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Artfully designed RESTful APIs are crucial to your business, making functions and processes run smoothly and ensuring secure, reliable connections between your internal system and third parties. As a top Laravel web development company, our engineers are experts in leveraging Laravel’s API generator packages for highly functional, RESTful solutions.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Third-party Integrations
              </h4>
              <p className="text-[22px] font-light pt-4">
                Optimizing applications' features and functionality can be a complicated undertaking — particularly for enterprise-level apps — unless you have the talent and the tech to make it quick, simple, and efficient. We have a team of pros with deep experience integrating third-party apps that expand secure capabilities and capacities, improving UX, and maximizing ROI.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Laravel Data Migration or Upgrade
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Whether upgrading from one Laravel platform to another or migrating your web application from different tech, our ingenious team of Laravel experts is skilled in the art of hassle-free migrations with no downtime.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center">
                Laravel Maintenance Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                Because launch is just a starting point, we offer ongoing Laravel maintenance services to ensure you get the most out of this flexible, leading-edge technology. From simply fine-tuning your existing app to adding new features and functions to keep up with the evolving demands of your consumers, we’re your partner in driving continuous business growth.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px]  max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Laravel Development Team Augmentation
                </h4>
                <p className="sm:text-[22px] font-light pt-4  max-sm:text-[20px] max-sm:text-center">
                  Your Laravel development needs will naturally expand and contract over time, and we’re here to help you make those transitional times simple and seamless. A top reason to hire a web development company like Rapidsofts is that we have the flexibility and resources to work as an extended arm of your company, optimizing your time, budget, and resources.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={vnimg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img src={zimg} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={ximg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <div className="w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl  max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={edplacecasesmallpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    <b>
                      casestudy-edplacecasestudy-edplace
                      We Create Scalable Infrastructure for Ambitious Startups
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    See how we helped a rapidly growing Edtech company meet customer demand by partnering with their back-end team to reinvent their architecture and extend the system that supports their mobile app for a seamless journey from MVP to an award-winning app.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    <b>
                      casestudy-pageant-planetcasestudy-pageant-planet
                      We Optimize Enterprise Platforms Quickly and Seamlessly
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    Learn how we helped the top beauty pageant platform, which boasts around a half-million daily visitors, deliver an innovative feature in just six weeks — leading to a long-term relationship in which we empower this industry leader to improve its UX, grow, and scale continuously.
                  </p>
                  <img
                    src={voylegalcasesmallpic}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    <b>
                      casestudy-edplacecasestudy-edplace
                      We Create Scalable Infrastructure for Ambitious Startups

                      See how we helped a rapidly growing Edtech company meet customer demand by partnering with their back-end team to reinvent their architecture and extend the system that supports their mobile app for a seamless journey from MVP to an award-winning app.

                      casestudy-voylegalcasestudy-voylegal
                      We Help Ambitious Founders Execute Their Vision and Create Disruptive Apps
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3  max-sm:text-[20px] max-sm:text-center">
                    Discover how we helped a long-time legal recruiter design, develop, deploy, and continuously refine an online recruiting firm that matches high-performing attorneys with top companies — including a Laravel migration that enabled the already-popular platform to become the gold standard in IT-based recruiting systems.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:pt-0  max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={howtoprioritizefeaturesforyourmvp} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT,  PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail4} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail3} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT,  QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Laravel;
