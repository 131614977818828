
import {
  case1,
  BestOnlineFoodDeliveryApps,
  nativevs,
  shouldilaunch,
  pageantbigcasestudpic,
  voylegalcasesmallpic,
  digitalcustomerexperiencestrategy,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  trish,
  vnimg,
  zimg,
  ximg,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const QaTesting = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                FULL-SERVICE SOFTWARE DEVELOPMENT CONSULTING SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                We Help You Engineer Business Growth With Tailored Solutions
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                We’re not just a software consulting company — we’re your
                partner in digital-driven success.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for Software Consulting?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={trish} className="w-full sm:h-[600px] object-cover" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>End-to-End Software Consulting Services</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                In today’s hypercompetitive world, brands need innovative
                software solutions and high-performing digital products and
                platforms to support continuous growth. With more than two
                decades of providing software development as a service, our
                experienced business analysts, designers, and developers know
                how to build the right products and platforms to delight
                customers and drive business demand. With a strong focus on UX
                to CloudOps, we design and deliver high-performing, accessible,
                scalable, and secure digital solutions. From enterprises seeking
                digital transformation to scale-ups looking to build remote
                teams on-demand, we are experienced working with businesses of
                all sizes and scopes.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mt-[30px]">
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Software Development Consulting Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                When you’re hiring a software consultant, you can’t just settle
                on any developer who simply knows how to code. Software
                development consulting services are specialized and require
                expert insight from unbiased IT professionals who are
                well-qualified to advise on technology-related business
                decisions.Our seasoned team includes industry and domain experts
                and software consultants with rich experience in their field and
                a deep understanding of their industry. By taking a deep dive
                into the client’s business objectives and IT needs, they provide
                expert opinion, analysis, and recommendations for
                performance-driving, results-oriented strategies and solutions.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px]"
                ></img>
                 <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  “As-is” Software Stack Assessment & “To-be” Stack
                  Recommendations
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  We thoroughly explore your business objectives and analyze how
                  your current software stack supports your performance goals.
                  We then make recommendations for deletions, additions, and
                  upgrades.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[700px] max-sm:text-[20px] max-sm:text-center">
                Software Architecture Assessment & Redesign | Enterprise
                Architecture Consulting, Advisory, and Redesign Service
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                As a premier software development company focusing on bespoke
                web, mobile, and desktop applications, we can handle projects of
                any size. We’re just as comfortable consulting on the
                construction of an enterprise mobile app as we are for
                grassroots development projects for SMBs.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[700px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Software modernization | Migration of Legacy Software |
                  Digital Transformation Consulting Services
                </h4>
                <p className="text-[22px] font-light pt-4">
                  In today’s world, where technology is constantly evolving, the
                  best way to maintain your competitive edge is through
                  continuous digital innovation. Our expert software consultants
                  advise you on the best ways to modernize your operations,
                  leverage analytics, innovate platforms and products, and
                  mobilize your business.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={vnimg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img src={zimg} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={ximg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <div className=" mt-[50px]">
            <p className="sm:text-5xl ml-5 max-sm:text-[20px] max-sm:text-center">
              <b>FAQs About Rapidsofts’ IT Outsourcing Services</b>
            </p>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">
                        What is a software consulting company?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        A software consulting firm partners with companies to
                        ensure that their technology choices best support their
                        business objectives. Often there are gaps in
                        functionality, features, and experiences that you need
                        expert insight to help close. From updating existing
                        tech stacks to recommending new leading-edge IT options,
                        our software development consulting services use
                        industry best practices and are designed to deliver
                        results-driving digital improvements and transformation.
                      </div>
                      <div></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      What are the benefits of partnering with a software
                      consulting firm?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      There are numerous benefits of working with an established
                      software consulting company, but these top the list:
                    </div>
                    <ul className="list-disc ml-[50px]">
                      <li className="mt-4 text-2xl">
                        Helps ensure you’re using the best software technologies
                        for your business
                      </li>
                      <li className="mt-4 text-2xl">
                        Saves time, resources, and money spent on a software
                        development project
                      </li>
                      <li className="mt-4 text-2xl">
                        Ensures a smooth transition and optimal usage of
                        cutting-edge technologies, including AI, Machine
                        Learning, the Internet of Things, and more
                      </li>
                      <li className="mt-4 text-2xl">
                        Keeps you apace of rapidly changing market demands,
                        technology, and customer preferences
                      </li>
                      <li className="mt-4 text-2xl">
                        Provides customized solutions that increase your
                        efficiency and ROI
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How do software consultants audit a company’s IT
                      environment?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      The audit process depends on the software consultant you
                      choose, but the general process is this:
                    </p>
                    <ul className="mt-5 list-disc sm:ml-[100px]">
                      <li className="mt-4  text-2xl">
                        {" "}
                        An audit is conducted of your company’s information
                        technology infrastructure, including data use and
                        management, applications, operational processes,
                        procedures, and policies.
                      </li>
                      <li className="mt-4  text-2xl">
                        The results are then measured and benchmarked against
                        your business goals, standards, policies, and industry
                        standards.
                      </li>
                      <li className="mt-4  text-2xl">
                        This information creates a roadmap to optimize your
                        company's IT environment and better align your business
                        objectives with the best possible technological
                        solutions.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={case1} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>We Help Industry-Leading Platforms Scale and Grow</b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how our experienced IT consultants helped a
                    fast-growing education app implement best practices and
                    create a scalable infrastructure—without disrupting the
                    existing customer experience.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Help Ambitious Businesses Build Game-Changing
                      Multi-Platform Experiences
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how we helped a leading athletic recruitment software
                    company design and build an intuitive, compliant,
                    consistent, cross-platform CRM solution for coaches.
                  </p>
                  <img
                    src={voylegalcasesmallpic}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Devise State-of-the-Art Workflows for Leading Global
                      Companies
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Explore how we took a poor-performing legacy brand licensing
                    system with outdated technology and completely redesigned it
                    with robust, scalable, modern architecture that helps
                    future-proof the business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={shouldilaunch} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT,  SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Understanding the API-first Approach for Product Development
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={nativevs} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={BestOnlineFoodDeliveryApps}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QaTesting;
