import React from 'react'
import { b2b_commerce, seller_intractions,report_piechart, piechart_main, piechart_main1, strategy_ebook, ecom_solution,platform,site,trends } from '../../assets';
import { Link } from "react-router-dom";
import Sidebar8 from '../../componets/sidebar/Sidebar8'

const B2bCommerce = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='w-[400px] h-[500px] mt-[68px] fixed overflow-auto'>
                    <Sidebar8 />
                </div>
                <div className='ml-[420px]'>
                    <ul className='flex pt-[85px]'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Digital Commerce</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">B2B eCommerce</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${b2b_commerce})` }} className="w-full h-[350px] bg-center bg-cover mt-5">
                        <div className='pl-10 pt-16 w-[43%]'>
                            <h5 className='font-extrabold text-[41px]'>Everything You Need to Know About B2B eCommerce</h5>
                        </div>
                    </div>
                    <div className='px-10 mt-5'>
                        <div>
                            <p className='text-[27px] font-sans font-light mt-5'>The <span className='font-bold border-b-[2px] border-[#F13623]'>digital commerce</span> business model is witnessing high adoption rates since COVID led to lockdowns and a temporary shutdown of brick and mortar stores. According to <span className='font-bold border-b-[2px] border-[#F13623]'>eMarketer</span>, worldwide eCommerce sales is expected to cross $1 trillion in 2022.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>The rise in B2C (Business to Consumer) eCommerce businesses is evident, but how quickly B2B (Business to Business) <span className='font-bold border-b-[2px] border-[#F13623]'>eCommerce solutions</span>are empowering the digital landscape is fascinating. The B2B buyers and sellers are gradually leaning towards digital platforms to self-evaluate products/services in a remote setup.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight text-[#F13623]'>According to Amazon’s B2B eCommerce Evolution report, 96% of buyers who shifted more procurement online during the pandemic, expect to continue e-procurement, even after normal operations resume.</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>What is B2B eCommerce?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>B2B stands for business-to-business eCommerce, and it implies the buying and selling process between two businesses. An example of B2B eCommerce is a wholesale website such as Alibaba that facilitates business-to-business commerce sales through its platform. In the overall picture of <span className='font-bold border-b-[2px] border-[#F13623]'>B2B strategies</span> framed for a dynamic growth in the present-day world, there is a significant emphasis on the eCommerce segment.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>Earlier, businesses avoided buying online as B2B involved heavy transactions. However, according to <span className='font-bold border-b-[2px] border-[#F13623]'> McKinsey</span>, 70% of B2B decision-makers say they are now open to making new, fully self-serve, or remote purchases in excess of $50,000, and 27% would spend more than $500,000.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>What is the Difference Between B2B and B2C eCommerce?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>B2C stands for business-to-consumer eCommerce. As the name implies, in a B2C business setup, the business is the seller, and the end-user, or the consumer, is the buyer. There are no intermediary parties in between.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>On the other hand, in a B2B business setup, the buyer and the seller are both business entities. The buyer may act as an intermediary, who can then sell the products/services to the end-users.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>Approaching B2B and B2C buyers requires separate strategies due to the distinct buyer personas and the differences in the target audience's mindset in each case.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Why Invest in a B2B eCommerce Business?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>If a business has an established B2C <span className='font-bold border-b-[2px] border-[#F13623]'>eCommerce model</span>, going B2B can be the next step forward to expand. On the other hand, if a business has an offline B2B setup, adopting the digital platform should be a priority to stay afloat (considering the <span className='font-bold border-b-[2px] border-[#F13623]'>impact of COVID-19 on businesses</span>). An <span className='font-bold border-b-[2px] border-[#F13623]'>ecommerce development company</span> can assist businesses in graduating through this transition.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-bold'>Why is B2B eCommerce Important?</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Maintain a competitive edge</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Improved brand visibility</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Expand B2B customer reach</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Fast-track <span className='font-bold border-b-[2px] border-[#F13623]'>order fulfillment</span></li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Take advantage of analytics for insights</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Trim down the supply chain</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Offer platform for remote interaction</li>
                            </ul>
                            <p className='mt-4'><strong className='text-[27px]'>Related blog: </strong><span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>Why Sellers Need a B2B Website?</span></p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Types of B2B eCommerce Businesses</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>The different types of B2B eCommerce businesses include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>B2B2B</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>B2B2B stands for “business to business to business” — an eCommerce model that adds additional parties in the B2B chain. In the standard B2B model, the two parties involved include the manufacturer and the retailer. On the other hand, a B2B2B model has other intermediaries such as distributors, sub-distributors, wholesalers, etc.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>B2B2C</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>B2B2C stands for “business to business to consumer” — an eCommerce model that bridges the gap between B2B and B2C businesses. In this model, the B2B business can directly sell to the individual consumers through B2B partnerships or by directly approaching consumers.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Wholesale</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>n a wholesale setup, a business buys bulk products from the manufacturer or a distributor at a much lower price. This is another B2B eCommerce type where the purchased products are then sold at the retail price to other businesses leveraging the eCommerce platform.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Manufacturers</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Manufacturers are the producers of the products that run large-scale productions at their end. These manufacturers are a part of the B2B network but can choose to run an exclusive eCommerce website too. They can sell products to other businesses and to consumers directly from the website or a dedicated B2B eCommerce app.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Common Myths Around B2B eCommerce (Plus Facts)</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Some of the common B2B eCommerce misconceptions and the corresponding facts include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Myth: B2B eCommerce is Uncommon</span></p>
                            <p className=' mt-4 ml-[40px]'><strong className='text-[24px]'>Fact:</strong><span className='text-[24px] font-sans	font-light'>There is a perception that B2B businesses cannot stand up to the competition that B2C businesses are giving. However, many brands are already running a B2B model alongside B2C.</span></p>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-bold'>Any brand that:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Is established as a Wholesale business</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Acts as a distributor to retailers</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Is selling to other businesses</li>
                            </ul>
                            <p className='text-[24px] font-sans	font-light mt-4'>— is a B2B eCommerce business! This implies that any B2C business can also operate as a B2B business. The most prominent example of this type of hybrid setup is Amazon. They have Amazon for B2C transactions and Amazon Business for B2B transactions.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Myth: There is no Demand for B2B in the Market</span></p>
                            <p className=' mt-4 ml-[40px]'><strong className='text-[24px]'>Fact:</strong><span className='text-[24px] font-sans	font-light'>Businesses have a perception that B2C is more popular than B2B in the market. However, with COVID-19 coming into the picture, B2B businesses are actively moving towards digital platforms.</span></p>
                            <p className='text-[27px] font-sans font-light mt-5'>According to McKinsey, 70-80% of B2B decision-makers prefer remote human interactions or digital self-service.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={seller_intractions} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Myth: eCommerce is Primarily for Small Orders</span></p>
                            <p className=' mt-4 ml-[40px]'><strong className='text-[24px]'>Fact:</strong><span className='text-[24px] font-sans	font-light'>The digital commerce market is changing for good. Initially, people preferred eCommerce for making small-ticket orders, but now B2B sales are also gaining traction.</span></p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight'>Notably, 70% of B2B decision-makers say they are open to making new, fully self-serve, or remote purchases in excess of $50,000, and 27% would spend more than $500,000. — McKinsey.</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Benefits of Starting a B2B eCommerce Business</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>The major benefits of supporting a B2B eCommerce business model include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Expand Customer Reach</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>If a business already has a localized B2B eCommerce business, going online will help them expand their reach across borders. If they offer a good catalog, affordable and custom pricing, and a delightful customer experience — the business is likely to broaden their audience of target customers.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Automated Sales Process</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>With the online platform, a business can easily automate the lengthy process involved in B2B sales. In addition, the communication among the sales team of respective suppliers, retailers, and distributors is synchronized and transparent, which further helps in streamlining the B2B transactions.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>What are Some of the Popular B2B eCommerce Trends?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>According to the <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>Rapidsofts’ B2B Commerce Report</span>, the top B2B eCommerce trends include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Improvement in Customer Experience</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>According to a Google survey, most of the B2B buyers are millennials, who expect a connected customer experience across platforms and touchpoints. A research process that requires contacting sales reps for more details is something that millennials tend to avoid. This, in turn, implies that B2B businesses need to ramp up their website and app to offer a streamlined customer experience.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={report_piechart} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Deliver Omnichannel Experience</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>B2B businesses are now planning to implement an omnichannel strategy to offer a unified customer experience. Most B2B eCommerce businesses plan to capture a 360-degree view of their customers to bridge the gaps between platforms and channels. In other words, omnichannel B2B eCommerce is expected to grow by leaps and bounds in the next two years.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={piechart_main} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Deliver Personalized Experiences</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A personalized experience is the most significant factor that attracts B2B customers. Nowadays, B2B buyers prefer conducting approximately12 online searches before finalizing a brand. If your business wants to stand out, personalization is a critical area to focus on.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Identifying data-supported ways to improve B2B eCommerce personalization can be accomplished by accumulating customer data through analytics and experimentation with AI and machine learning.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={piechart_main1} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>How to Choose the Best B2B eCommerce Platform?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>When setting up a B2B eCommerce business, the most critical decision narrows down to <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>choosing the best eCommerce platform</span>. This is similar to setting the foundation for a new home.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>Many eCommerce product owners provide eCommerce platforms, such as Magento, Salesforce Commerce Cloud, Shopify, BigCommerce, Wix, PrestaShop, etc. So, how should a business choose one of these options?</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>B2B eCommerce: Best Practices</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>The best practices for setting up and operating a B2B eCommerce platform include:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Focus on B2B marketing activities like posting how-to guides, live chats, videos, and blogs. This will help in educating the customers and keeping them informed. In fact, the revenue generated from video-related interactions has gone up by 69% since April 2020.</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Experiment with <span className='text-[27px] font-sans font-light  border-b-[2px] border-[#F13623]'>augmented reality</span> (AR), artificial intelligence (AI), and machine learning to advance the digital transformation of the business</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Work on improving customer experience by building a robust CX strategy based on surveys and customer reviews</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Use B2C strategies for B2B growth</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Maintain a single source of truth and post concise, clear, and relevant content</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Upscale the customer support team so that all inquiries are looked after</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Focus on going comprehensive with the product descriptions. The more details provided, the better the chances of winning over the customers.</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={strategy_ebook} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>List of B2B eCommerce KPIs</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>For an all-new B2B eCommerce business model setup, measuring how well the business is performing is challenging. To counter the challenge, several KPIs (Key Performance Indicators) can come in handy. Some of which include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Website/App Visitors</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Run regular data analytics activities for the business website and app. Record the number of visitors, click-through rate, and conversions stats. These numbers will help the business understand how well they are performing overall. If the numbers are less than the defined threshold value, the business should look up ways to improve traction across the sales funnel.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Retention Rate</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Are the customers returning after their first-time purchase? If the answer is affirmative, the B2B eCommerce business is heading in the right direction. Customer retention is the biggest KPI for measuring customer satisfaction. To improve the experience further, a business can choose to survey these returning customers for their suggestions regarding the overall customer experience.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Customer Lifetime Value (CTV)</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Customer Lifetime Value is the sum of the average transaction amount, annual buying frequency, and the years of expected relationship. The higher the CTV, the higher the probability of profits. However, to increase the CTV — the business will have to invest whole-heartedly in customer retention strategies.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Customer Acquisition Cost  Profit Made</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Customer Acquisition Cost (CAC) is the total cost that a B2B business invests in acquiring a customer. To ensure that the B2B eCommerce business is performing optimally, the business needs to minimize the CAC so that it does not exceed profits. This is possible by making efforts to increase sales funnel conversions, creating strong social media and SEO campaigns, and investing in referral marketing.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>FAQs Related to B2B eCommerce</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Some of the most commonly asked questions around B2B eCommerce include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>What is the Difference between B2B and EDI?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>EDI stands for “Electronic Data Interchange,” which allows the sharing of purchasing information between the buyer and the seller. This is a digital format copy of order details and invoice.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>EDI’s are common in B2B eCommerce due to the large order volumes involved. The data exchange helps mitigate the chances of any loopholes in order fulfillment.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>On the other hand, B2B transactions involve selling goods and services through an online platform. An EDI is shared between the buyer and the seller following the transaction.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Why do you need PIM for a B2B eCommerce Business?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>PIM stands for “Product Information Management” which is a system that synchronizes and manages product data in a unified location. In a B2B eCommerce business, the order volume is usually high, implying that the business owner needs to maintain a large product catalog.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>To better manage the B2B eCommerce catalog, investing in a PIM software is a smart solution. As an add-on, PIM software also helps integrate data with the ERP system.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>What are the Best Examples for B2B eCommerce Websites?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Some of the prominent B2B eCommerce examples include:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Amazon Business</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Alibaba</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>General Electronics</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Dupont</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Polycom</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>H&D Supply</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Grainger</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>How Much Does it Cost to Setup a B2B eCommerce Website?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>When setting up a B2B eCommerce website/app, the following costs need to be considered:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>B2B eCommerce platform cost</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Domain name registration</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>eCommerce hosting (in case, the eCommerce platform vendor does not offer it)</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>SSL certificate</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Payment processing at payment Gateway</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Add-ons, plugins, and extensions</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>eCommerce development cost (such as in the case of Magento)</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={ecom_solution} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Conclusion</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>With new businesses plunging into the eCommerce market and with existing ones looking for new opportunities to expand their business, B2B suits the bill. A B2B eCommerce model allows for buying and selling between businesses.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>With the prediction that the B2B market is expected to reach $1.8 trillion by 2023, businesses should consider making the shift or plan to go hybrid (i.e., both B2B and B2C).</p>
                            <p className='text-[27px] font-sans font-light mt-5'>To help get started, this article aimed to cover everything a business needs to know about B2B eCommerce. It is high time that businesses shift traditional B2B eCommerce online and embrace <span className='text-[27px] font-sans font-bold  border-b-[2px] border-[#F13623]'>digital transformation</span> to ensure they maintain a competitive edge.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={platform} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>B2B COMMERCE,  DIGITAL COMMERCE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>


                                    How to Choose the Right B2B eCommerce Platform   </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={site} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>B2B COMMERCE,  DIGITAL COMMERCE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>



                                    7 Reasons Why Sellers Need a B2B eCommerce Website   </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={trends} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className=''>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>B2B COMMERCE,  DIGITAL COMMERCE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>


                                    Top 10 B2B eCommerce trends to watch in 2023    </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-48'>
                    <h5 className=' text-[39px] font-sans font-extralight text-center'>Got a Project in Mind? Get Started Now.</h5>
                    <div className='w-[160px] mx-auto'>
                        <button
                            type="button"
                            className="border-[2px]  border-red-400 px-4 py-2 font-bold text-white text-[17px] mt-4 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default B2bCommerce