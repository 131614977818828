import React from 'react'
import { Link } from "react-router-dom";
import { digital_integration_img, black_imageicon1, black_imageicon11, black2, agile_blue, black_imageicon12, simple_setup, SME_icon, project_3, Hiring, Google_Cloud, Digital_tra1 } from '../../assets';


const DigitalSoluation = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-24'>
                        <h5 className='font-black text-[32px]'>
                            Digitizing Your Venture to Meet the Modern Market Needs
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Modernizing your venture has never been simpler. Our effective digital solutions help businesses get big on the internet in a limited time.
                        </p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-32 h-10 text-[12px] font-extrabold mt-9'>Know more</button></Link>
                    </div>
                    <div className='w-[45%] mt-5'>
                        <img src={digital_integration_img} />
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>

                            Best Digital Integration Solutions for Your Every Online Requirement

                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>

                            The software industry has continuously managed to grow and businesses had to cope with the latest trends to ensure market stability. Over the years the digital market has become more like a necessity than a convenience. Brands undergo numerous modifications to ensure they are up-to-date with the market and providing the best services. Managing to do so offers global audience reach and customer engagement, but it is not as easy as it sounds. Therefore, RevInfotech proposes quality digital and tech solutions to help your business stay ahead of the cult. Avail us and digitize your proceedings to meet modern standards.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-4'>
                        <h5 className='font-bold text-[25px]'>More Services</h5>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5]'>
                            <Link to={""}><img src={black_imageicon1} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Automation Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon11} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">Professional Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black2} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">CMS Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={agile_blue} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal"> Agile Software Development</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 bg-[#F5F5F5] mt-3'>
                            <Link to={""}><img src={black_imageicon12} className="w-6 h-6" /></Link>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal">IBM Services</Link>
                        </div>
                        <div className='flex w-full h-16 px-3 py-3.5 mt-3 bg-yellow-300'>
                            <Link to={""} className="pl-2 font-light text-[18px] tracking-normal ">See More Services…</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#093D77] w-full h-40 mt-5'>
                <div className='container mx-auto px-44'>
                    <h5 className='text-center font-extrabold text-[33px] text-white pt-14'>Equip Your Business with Every Modern Digital Mean</h5>
                </div>
            </div>
            <div className='container mx-auto'>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Who Do We Help?</h5>
                <div className='flex flex-row gap-4 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={simple_setup} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>SIMPLE STARTUP INTEGRATION</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our comprehensive digital solutions can be availed by an emerging startup as we have articulated custom strategies and business plans to best suit entrepreneurship.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse '>
                        <div className='px-10 py-11'>
                            <img src={SME_icon} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>IDEAL FOR SME</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Small-sized businesses looking to grow on the web or function a dead platform can avail of our esteemed services as we offer extensive solutions to facilitate the same.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <img src={project_3} className="w-16 text-center mx-auto" />
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>BEST MNC SUPPORT</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Large-scale organizations looking to cut on their digital ventures can also avail of comprehensive benefits from our well-rounded digital services package.Large-scale organizations looking to cut on their digital ventures can also avail of comprehensive benefits from our well-rounded digital services package.</p>
                        </div>
                    </div>
                </div>
                <h5 className='text-center font-extrabold text-[33px] mt-5'>Get Esteemed Online Benefits</h5>
                <div className='flex flex-row flex-wrap gap-5 mt-5'>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>INCREASED CONSUMER ENGAGEMENT</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Get a hold of a global consumer base and increase your customer engagement. Make a loyal user base online and offer them the best digital experience. Hire our services and prepare the ideal digital platform for showcasing your business.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>DIGITAL SOLUTION INTEGRATION</h5>
                            <p className='font-light text-center text-[22px] mt-3'>With Rapidsofts you can integrate services like ERP, TMS, WMS, and CMS without a hitch. Our experts offer the best integration regardless of your organization’s scale, nature, or approach. Get big on the web with us.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>BEST ONLINE SUPPORT</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Migrate to Business-to-Business cloud platforms with APIs and Agile DevOps support comprehensively with the digital industry’s leader. RevInfotech ensures you get the best digital services to offer your products ideally.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-5 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>REDUCED COSTS</h5>
                            <p className='font-light text-center text-[22px] mt-3'>We help your business incorporate services such as AI, RPA, blockchain, and IoT to keep up with the modern industry dynamics. Never stay behind the competition and build an online stature to turn your business into a brand.</p>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-lg hover:animate-pulse'>
                        <div className='px-10 py-11'>
                            <h5 className='text-[22px] font-bold text-center tracking-widest mt-7'>UTMOST EFFICIENCY</h5>
                            <p className='font-light text-center text-[22px] mt-3'>Our experts thoroughly analyze your venture to compute the most efficient approach. We help minimize wastes within your premise resulting in optimum utilization of available resources. Stabilize your online proceedings with us.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row gap-5'>
                    <div className='w-[45%] mt-14'>
                        <h5 className='font-black text-[32px]'>
                            Connecting Your Business with the Digital Realm
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Our digital integration services are designed for helping businesses looking to grow big on the web. It may be a dormant website, a dysfunctional platform, or a low-productivity product, our experts possess extensive knowledge on the subject to help you snap out of it. Develop comprehensive strategies with us and reach your goals regardless of their scale and term. Accelerate your digital growth with Rapidsofts and improve your online presence as well.
                        </p>
                    </div>
                    <div className='w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]'>
                        <h5 className='text-[20px]'>
                            Why Rapidsofts for Your Digital Ventures?
                        </h5>
                        <p className='font-light tracking-normal text-[21px] mt-2.5'>
                            Our experts have garnered years of accumulated knowledge and experience to compute the best digital strategies. Meet the shifting market dynamics head-on with our throughout the support. It does not matter whether you are a startup, an SME, or a well-established venture, our strategies are highly scalable and well-devised. Availing RevInfotech allows your firm to leave the digital arena in safe hands while you focus on other vital aspects. Get going with us and never look back.</p>
                        <Link to={""}><button type="button" className='px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9'>Help My Business</button></Link>
                    </div>
                </div>
                <h5 className='text-[34px] font-extrabold text-center pt-20'>Insights</h5>
                <div className='flex flex-row gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Hiring} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 23, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Making the Old New: Hiring Fresh
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Google_Cloud} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 24, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[30px] px-4 font-semibold leading-9 mb-12'>
                                Everything you need to know about Google Cloud SQL
                            </h5>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5 '>
                        <img src={Digital_tra1} />
                        <p className='bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold'>February 27, 2023 /</p>
                        <div className='mt-4'>
                            <h5 className='mt-4 text-[31px] px-4 font-semibold leading-9 mb-12'>
                                Trends, Challenges, and Solutions for Healthcare’s Digital Transformation
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalSoluation