import React from 'react'
import Sidebar2 from '../../componets/sidebar/Sidebar2'
import { digital_commerce,clients_quotes, Mario_maruffi,Digital_com_ofc3, diagram_today, dc_strategy, Lamine_pearlheart,digital_numbers,digita_metrics,what_is_product,omnichannel,B2B_eCommerce} from '../../assets';
import { Link } from "react-router-dom";

const DigitalCommerce = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='w-[400px] h-[500px] mt-[68px] fixed overflow-auto'>
                    <Sidebar2 />
                </div>
                <div className='ml-[420px]'>
                    <ul className='flex pt-[85px]'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Digital Commerce</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${digital_commerce})` }} className="w-full h-[400px] bg-center bg-cover mt-5">
                        <div className='relative left-[53px] top-[80px] w-[33%]'>
                            <h5 className='font-extrabold text-[41px] text-white'>Digital Commerce</h5>
                            <p className='text-[30px] font-sans	font-light mt-4 text-white'>Embracing Experience Led Commerce in the Industry 4.0 Era</p>
                        </div>
                    </div>
                    <div className='px-32 py-16'>
                        <h5 className='text-[57px] font-extralight'><span className='text-[#48DDEF]'>80%</span> of customers stop doing business with a company that delivers poor customer experience.</h5>
                    </div>
                    <div className='px-8 mt-5'>
                        <div>
                            <p className='text-[27px] font-sans font-light'>We are in the midst of a digital commerce revolution. Delivering a customer-friendly and seamless eCommerce experience is no longer a differentiator — it’s a necessity to survive for forward-thinking entrepreneurs.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>Digital Commerce, today, is not just a buy button towards the end of an online sales funnel, it’s an end-to-end experience that is anticipatory and pervasive, aiming towards meeting the customers’ expectations for a more fluid and memorable buying journey.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <h5 className='text-[31px] text-[#48DDEF]'>9 seconds:</h5>
                                <p className='text-[31px] font-extralight'>It’s an average time that a potential customer gives any brand before abandoning the site, thereby assuming more control over their buying journey.</p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-light'>Empowering customers with the experiences they desire means new possibilities. However, with new opportunities, the volume of <span className='font-bold border-b-[2px] border-[#F13623]'>ecommerce challenges</span> also increases and it is requires acumen to strike a balance. The lack of buy-in from the C-suite is a major barrier to customer-centricity.</p>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <div>
                            <img src={Digital_com_ofc3} />
                        </div>
                        <div className='px-10'>
                            <div className='border-l-8 border-sky-400 relative top-[-60px]'>
                                <p className='px-2 pt-28 font-black text-[58px]'>What is Digital Commerce?</p>
                            </div>
                            <div className='px-2.5'>
                                <p className='text-[27px] font-sans font-light mt-5'>Just a decade ago, brands relied upon limited solutions for their commerce needs. There was ‘Internet’ but the ‘Internet of Things’ was a term no one knew, there was ‘Intelligence’ but ‘Artificial Intelligence’ hadn’t been born, there was ‘data’ but ‘data-driven insights’ was still a Hollywood fantasy. In-store purchases dominated the sale compared to online sales.</p>
                                <p className='text-[27px] font-sans font-light mt-5'>In what felt like an instant, the entire landscape changed.</p>
                                <p className='text-[27px] font-sans font-light mt-5'>Technology evolved. The Internet exploded. Data started to drive innovative organizations to create amazing customer experiences, thus shifting the paradigm from eCommerce to digital commerce.</p>
                            </div>
                            <div className='px-32 mt-5'>
                                <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                    <h5 className='text-[31px] font-extralight'>Digital commerce, thus, is a highly interconnected process — involving the process, people, and technology — that enables consumers to buy goods and services via an interactive, self-service, and seamless experience at all touchpoints throughout their buying journey.</h5>
                                </div>
                            </div>
                            <div>
                                <p className='text-[27px] font-sans font-light mt-5'>Featuring products on an online platform and spending money to market them is not enough to attract ever-evolving customers. Leading businesses understand that in today’s digital era, strategies that worked in the past no longer work and thus, they have started to embrace digital commerce as a more holistic way of doing business online.</p>
                            </div>
                            <div className='px-16 mt-5'>
                                <img src={diagram_today} />
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>6 Ds: The Digital Commerce Strategy</h5>
                                <p className='text-[27px] font-sans font-light mt-5'>to keep pace with tomorrow’s customers and naturally progress towards digital business, organizations need to build a robust digital commerce strategy.</p>
                                <p className='text-[27px] font-sans font-light mt-5'>By building a strategy and aligning resources, tools, and capabilities, an informed organization can deliver revenue growth through digital channels with an agile path to purchase. However, not many organizations have embraced <span className='font-bold border-b-[2px] border-[#F13623]'>digital commerce services</span>, thus failing to meet the customers’ expectations.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'><b>57.7%</b> of businesses invested in Digital Commerce technology in 2019, however, most of the organizations often treat digital commerce as a one-time project and leave the application architecture out of sync with business requirements.</h5>
                                    </div>
                                </div>
                                <p className='text-[27px] font-sans font-light mt-5'>To succeed in today’s Darwinian era, it is important to build and continuously refine the digital commerce strategy to kickstart the virtuous cycle of attracting potential visitors and turning them into your customers.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={dc_strategy} />
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Develop Cross-Functional Collaborations</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Have you ever imagined what could happen if every unit in your organization — IT, Sales, Marketing, and Development — is working in a silo? You’ll surely be in a huge mess. Thus, while working upon a digital commerce strategy, it is critical to forming a cross-functional team consisting of business and technical stakeholders, embracing the concept of <span className='font-bold border-b-[2px] border-[#F13623]'>DevOps</span>.</p>
                                {/* <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A robust platform approach in the digital transformation journey allows your business to leverage a structured, scalable, and flexible foundation, which can eventually help speed up development time.</p> */}
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>A DevOps Culture can improve cooperation and collaboration by almost <b>55%</b> and reduce handling time by <b>60%</b>.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Define Business Requirements</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Launching digital commerce without defining the <span className='font-bold border-b-[2px] border-[#F13623]'>business requirements</span> is a waste of time and effort that can leave even the big brands in the dust. Thus, define the goals of your digital commerce, which mostly are: Improving customer experience, Increasing digital revenue, Minimizing customer churn, Reaching new markets, and Retaining the repeat customers. Based on these goals, define the essential KPIs.</p>
                                {/* <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A robust platform approach in the digital transformation journey allows your business to leverage a structured, scalable, and flexible foundation, which can eventually help speed up development time.</p> */}
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'><b>37%</b> of projects that fail do so because of poor requirements management.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Design an Omnichannel Experience</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Most organizations deploy the latest technologies to their digital commerce without studying the entire customer journey, thus treating customer experience in a piecemeal manner.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Just deploying the latest technology won’t boost customer experience: a customer, while shopping online, goes through multiple steps — research, evaluation, check-out, and order returns, thus, it is imperative for an organization to design for a unified and seamless experience throughout the customer journey, across all the channels.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Determine the Technical Architecture</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Once the customer experience is defined based upon the touchpoints, organizations should be able to choose an ideal digital commerce platform — CMS, DXP, DAM, or CRM — for their business that will be integrated with front-end customer touchpoints, back-end applications, and external services for the purpose of delivering optimum customer experience.</p>
                                {/* <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A robust platform approach in the digital transformation journey allows your business to leverage a structured, scalable, and flexible foundation, which can eventually help speed up development time.</p> */}
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'><b>47%</b> of respondents in the Sitecore survey ranked CRM, analytics, and BI as most important to their company’s customer experience strategy.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Drive a Solution</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Once the application and integration environment gets defined, the next step is to build the solution by working on the primary functionalities for digital commerce, which mostly involve customer care, digital store management, localization, merchandise management, reporting, and analytics.</p>
                                {/* <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A robust platform approach in the digital transformation journey allows your business to leverage a structured, scalable, and flexible foundation, which can eventually help speed up development time.</p> */}
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>Without comprehensive, accessible customer data, it’s impossible to deliver on the DX promises of personalization, predictive analytics, and machine learning.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>6. <span className='ml-3'>Deploy Continuously</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The first vital step is to go live with the selected digital commerce platform. However, to continuously improve revenue and meet customers’ expectations, speed and innovation are the two factors that separate the winners from losers.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>And to achieve that businesses need to constantly and seamlessly optimize the digital product to enable the continuous release of digital product improvements. This happens by embracing the concept of agile development along with DevSecOps.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>The deployment frequency of high performers is approximately <b>4%</b> higher than low performers.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>Digital Commerce Challenges</h5>
                                <p className='text-[24px] font-sans font-light mt-4'>Although the worldwide spending on digital commerce platform technology is projected to reach $8.544B by 2020, riding the digital commerce wave is not an easy task.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>It is a challenge for business leaders to strengthen their digital commerce strategy in today’s experience era, where you are surrounded by brands like Amazon, eBay, and Alibaba that leave no stone unturned to deliver a seamless experience to their customers.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Capitalization of Data</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Traditional data pipelines are breaking, and data quality is suffering. Just 22% of enterprise companies are currently seeing a significant return from data science expenditures.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Thus, to handle this data challenge, it is important for organizations to find new ways to turn this untapped data into useful insights to drive customer retention, and one such approach is DataOps.</p>
                            </div>
                            <div className='bg-[#FFF9EC] mt-5'>
                                <div className='px-24 py-4'>
                                    <img src={clients_quotes} />
                                    <h5 className='text-[25px] font-extralight mt-7'>Good data visualization reduces the users’ cognitive load while improving their decision-making.</h5>
                                    <div className='flex mt-5'>
                                        <div>
                                            <img src={Mario_maruffi} />
                                        </div>
                                        <div className='ml-6'>
                                            <h5 className='text-[21px] font-bold mt-3.5'>Mario Maruffi</h5>
                                            <span className='text-[16px] font-medium'>Experience Design, Consultant</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Customers’ Exploding Expectations</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>We are in the midst of an experience era, where big brands like Amazon are taking the online buying process to the proverbial ‘next level’ through its Anticipatory Shipping method. Thus, competing with them and fulfilling the ever-evolving customers’ demands is a huge challenge for retailers today.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The major issue with digital commerce players today is that they are investing a huge chunk of money in the problem. However, the winners will find their way to satisfy their customers’ high expectations smartly while keeping costs in check.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'><b>75%</b> of consumers expect shipping to be free, even on online orders under <b>$50</b>, up from <b>68%</b> a year earlier. And <b>39%</b> expect two-day shipping to be offered at no charge</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Being Consistent</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Analyzing and understanding customer’s interactions across all touchpoints and using it to build a consistent and seamless customer experience across all these touchpoints is a major challenge for retailers.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Many brands still have gaps in their cross-touchpoint experience that need to be plugged. For instance, picture the inconsistency to keep a customer engaged throughout their buying journey by not saving their recent searches and additions to shopping carts across devices and channels, even when a customer is logged on.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Personalization Approach</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Today, retailers have become obsessed with their customers and they try to cross all the limits just to provide over-personalized experience to them. However, the bitter truth is that customers are becoming increasingly frustrated with targeted online ads.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>To overcome this challenge, it is important for businesses to understand that they personalize their customers’ experience while maintaining privacy and respect.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'><b>91%</b> of consumers agree that online ads are more intrusive today than they were a few years ago.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Agility Challenge</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold border-b-[2px] border-[#F13623]'>Agile Transformation</span> is at the heart of Digital and scaling is important for any successful digital commerce. Even a study reveals that 68% of companies identified agility as one of their most important initiatives of digital commerce.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The parameters that play an indispensable role in laying the foundation for scaling agile capabilities and approach at the enterprise level are Agile teams, flexible architecture, and efficient release management.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'><b>96%</b> of agile transformation projects fail because of their inability to rapidly adapt to market changes in a productive and cost-efficient manner.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>Digital Commerce Trends</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>Technology is evolving. And it is changing customers’ expectations of the shopping experience, which in turn, impacts marketing and digital commerce. In order to meet customer expectations for a more relevant, streamlined, and convenient buying journey, it is critical for stakeholders to focus on digital commerce trends in terms of customer experience, <span className='font-bold border-b-[2px] border-[#F13623]'>business models</span> and emerging technologies.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>Gartner survey reveals that CMOs place digital commerce in the top three strategically vital capabilities, allocating <b>9.2%</b> of their marketing budget to digital commerce, up from <b>8.1%</b> in 2017.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Visual Commerce</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Visual commerce allows your potential customers to interact with your products in a visual and more immersive manner. For instance, there is a feature in Snapchat that allows users to click a photo of an object or persons’ outfit to buy the same on Amazon.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>With digital commerce getting more visual, it is becoming vital for businesses to analyze and understand customers’ behavior throughout the funnel and use that insight to build visual content, thereby influencing your customers’ purchase behavior.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>According to Deloitte, “<b>90%</b> of companies with annual revenues of <b>$100</b> million to $1 billion are leveraging AR/VR technology.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Personalization</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Personalization involves delivering relevant, targeted, and individualized interaction to optimize the customers’ experience. Any customer would consider a brand that gives importance to their preferences and tastes.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>By <span className='font-bold border-b-[2px] border-[#F13623]'>personalizing users’ experience</span>, you are offering something useful based on the users’ characteristics, behaviors, attributes and/or data analysis.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>An Accenture study mentions that <b>33%</b> of customers who abandon business relationships do so because personalization is lacking</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Omnichannel Commerce</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>73% of customers shop across multiple channels and a good <span className='font-bold border-b-[2px] border-[#F13623]'>omnichannel experience</span> connects customers across multiple devices and locations, thereby bridging the gap between online and offline channels.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>An omnichannel solution gives users the freedom to choose a variety of touchpoints. No matter what point of purchase they choose, a <span className='font-bold border-b-[2px] border-[#F13623]'>successful omnichannel strategy</span> eliminates silos to maintain a continuous and seamless experience that, in turn, helps optimize the customer journey.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>Companies with extremely strong omnichannel customer engagement retain on average <b>89%</b> of their customers, compared to <b>33% </b> for companies with weak omnichannel customer engagement.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Unified Commerce</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Today, customers make use of multiple channels throughout their buying journey. Thus, it is important to serve them with a seamless experience across these touchpoints.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Building a seamless customer journey across all the digital experience touchpoints requires the specific suite of elements that work in conjunction with each other. A unified digital experience platform is enterprise software that ties together these elements to deliver a seamless user experience.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>By the end of 2020, <b>81%</b> of retailers will deploy unified commerce platforms, to support commerce across the enterprise’s stores, mobile users and the web.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Cart Abandonment Rate</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Analyzing customers’ journeys with a range of analytics tools is vital for <span className='font-bold border-b-[2px] border-[#F13623]'>enterprises</span> to increase their market growth.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Analyzing the data paves the way for enterprises to understand what went right and what requires more attention. The absence of analytics for customer experience is like keeping your fingers crossed and hoping that your actions make a positive impact. And Hope is never a strategy.</p>
                                <div className='px-32 mt-5'>
                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                        <h5 className='text-[31px] font-extralight'>Organizations that make use of digital customer experience analytics across all business decisions, witness <b>131%</b> sales improvement over the companies that don’t.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h5 className='font-extrabold text-[41px]'>The ROI of Digital Commerce</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>Although it may sound overwhelming that visitors are landing on online stores from all directions, however, at the same time the digital commerce landscape is becoming extremely chaotic, thereby making it hard to measure the <span className='font-bold border-b-[2px] border-[#F13623]'>ROI of digital commerce</span>.</p>
                            </div>
                            <div className='bg-[#FFF9EC] mt-5'>
                                <div className='px-24 py-4'>
                                    <img src={clients_quotes} />
                                    <h5 className='text-[25px] font-extralight mt-7'>An investment is deemed an investment only through its returns on investment</h5>
                                    <div className='flex mt-5'>
                                        <div>
                                            <img src={Lamine_pearlheart} />
                                        </div>
                                        <div className='ml-6'>
                                            <h5 className='text-[21px] font-bold mt-3.5'>Lamine Pearlheart</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className='text-[24px] font-sans	font-light mt-4'>There are various tools available to measure, analyze, benchmark, and improve your digital experience across multiple channels by helping you track the following metrics:</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>1. <span className='ml-3'>Conversion Rate</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The conversion rate is the ratio between the number of visitors, visiting your digital property and those that turn into customers.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>2. <span className='ml-3'>Churn Rate</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>A business’s churn rate is the percentage of customers who would not like to make a repeat purchase with your brand.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>3. <span className='ml-3'>Return Customer Rate</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The Return Customer Rate is defined as the ratio of customers that have made more than one purchase to the total number of customers.</p>
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>4. <span className='ml-3'>Net Promoter Score</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Today, customers make use of multiple channels throughout their buying journey. Thus, it is important to serve them with a seamless experience across these touchpoints.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={digital_numbers} />
                            </div>
                            <div className='mt-5'>
                                <p className='font-bold text-[27px]'>5. <span className='ml-3'>Customer Analytics</span></p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Analyzing customers’ journeys with a range of analytics tools is vital for enterprises to increase their market growth.</p>
                                <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Analyzing the data paves the way for enterprises to understand what went right and what requires more attention. The absence of customer experience analytics is like keeping your fingers crossed and hoping that your actions make a positive impact. And Hope is never a strategy.</p>
                            </div>
                            <div className='mt-5'>
                                <img src={digita_metrics} />
                            </div>
                            <div className='mt-5'>
                                <h5 className='text-[50px] font-extrabold'>Parting Words</h5>
                                <p className='text-[24px] font-sans	font-light mt-4'>While nobody can predict the future of business and the wonders it will bring with complete accuracy, we can confidently lean into the trend towards fully connected cloud technology platforms as the primary means of getting there.</p>
                                <p className='text-[24px] font-sans	font-light mt-4'>But, of course, it’s not just the technology that leads to success. In the race to the top in digital commerce, the winners will undoubtedly be the ones that constantly look ahead, reimagine the possibilities, and keep their focus firmly on the true benefactors of their offerings: the end customer.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={what_is_product} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Digital commerce</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    What is PIM, its benefits, and the best PIM software for eCommerce?</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={omnichannel} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Digital commerce</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                    Omnichannel vs Multichannel Retailing: The Complete Guide </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={B2B_eCommerce} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>B2B COMMERCE,  DIGITAL COMMERCE</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    How to Choose the Right B2B eCommerce Platform </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalCommerce