import React from "react";
import {
  UXX,
  zDigital,
  Wsay,
  yzfeatured,
  yxfeatured,
  Where_Do,
  xheadles,
  yfeatured,
  zeatured,
  zfeatured,
  xcomparison,
  zThum,
  zxThumbnail,
  modernization,
  Z_Development,
  ySuccessful,
  znatives,
} from "../assets";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

const Insights = () => {
  let subtitle;
  let customStyles;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="sm:pt-[72px]">
        <div className="bg-[#F6F6F6] fixed w-full max-sm:hidden">
          <div className="container mx auto w-[75%]">
            <ul className="flex justify-between">
              <li className="py-3">
                <div className="px-2 border-[#C6C6C6] border-r-[2px]">
                  <Link to={""}>
                    <p className="font-black hover:text-black">All Topics</p>
                  </Link>
                </div>
              </li>
              <li className="py-3">
                <div className="px-2 border-[#C6C6C6] border-r-[2px]">
                  <Link to={""}>
                    <p className="font-black hover:text-black">
                      Digital Commerce
                    </p>
                  </Link>
                </div>
              </li>
              <li className="py-3">
                <div className="px-2 border-[#C6C6C6] border-r-[2px]">
                  <Link to={""}>
                    <p className="font-black hover:text-black">
                      Experience Design
                    </p>
                  </Link>
                </div>
              </li>
              <li className="py-3">
                <div className="px-2 border-[#C6C6C6] border-r-[2px]">
                  <Link to={""}>
                    <p className="font-black hover:text-black">
                      Product Development
                    </p>
                  </Link>
                </div>
              </li>
              <li className="py-3">
                <div className="px-2 border-[#C6C6C6] border-r-[2px]">
                  <p className="font-black hover:text-black">
                    Digital Transformation
                  </p>
                </div>
              </li>
              <li className="py-3">
                <div className="px-2 border-[#C6C6C6] border-r-[2px]">
                  <Link to={""}>
                    <p className="font-black hover:text-black">
                      Digital Experience Platforms
                    </p>
                  </Link>
                </div>
              </li>
              <li className="py-3 mt-[6px]">
                <div className="px-2 border-[#C6C6C6] border-r-[2px] nav ">
                  <Nav>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="More"
                      menuVariant=""
                    >
                      <NavDropdown.Item href="#action/3.1">
                        Data Analytics & Insights
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.1">
                        News and Events
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
              </li>
              <li className="py-3 mt-[6px]">
                <div className="px-2 border-[#C6C6C6] border-r-[2px] nav">
                  <Nav>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Filter"
                      menuVariant=""
                    >
                      <NavDropdown.Item href="#action/3.1">
                        Data Analytics & Insights
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.1">
                        News and Events
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-16">
          <div className="sm:flex">
            <div
              style={{ backgroundImage: `url(${zDigital})` }}
              className="sm:w-[50%] max-sm:w-[100%] sm:h-[500px]"
            >
              <div className="sm:w-[50%] max-sm:p-[10px] sm:ml-[50px]">
                <p className="px-2 bg-[#F9D94E] w-[60px] sm:mt-3 max-sm:mt-[40px]">
                  BLOG
                </p>
                <h5 className="max-sm:text-center text-white font-extrabold sm:text-[45px] max-sm:text-[20px] mt-4">
                  Digital Transformation in Healthcare: Trends, Challenges &
                  Solutions
                </h5>
                <div className="mt-10">
                  <button
                    type="button"
                    className="max-sm:mb-[10px] w-28 px-2 py-2  border-[2px] border-white text-white hover:bg-white hover:!text-black "
                  >
                    Read Blog
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundImage: `url(${Wsay})` }}
              className="sm:w-[25%] max-sm:w-[100%] sm:h-[500px] flow-root"
            >
              <div className="px-8">
                <p className="px-2 bg-[#F9D94E] w-[60px] sm:mt-3 max-sm:mt-[40px]">
                  BLOG
                </p>
                <h5 className="text-white font-extrabold text-[35px] mt-4 w-[330px] max-sm:text-[20px]">
                  The 8 Steps of UX Design Process – How to Do it the Right Way
                </h5>
                <div className="mt-40">
                  <button
                    type="button"
                    className="max-sm:mb-[10px] w-28 px-2 py-2 border-[2px] border-white text-white hover:bg-white hover:!text-black "
                  >
                    Read Blog
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundImage: `url(${UXX})` }}
              className="sm:w-[25%] max-sm:w-[100%] sm:h-[500px] flow-root"
            >
              <div className="px-8">
                <p className="px-2 bg-[#F9D94E] w-[60px] sm:mt-3 max-sm:mt-[40px]">
                  BLOG
                </p>
                <h5 className="text-white font-extrabold sm:text-[35px] mt-4 sm:w-[330px] max-sm:text-[20px]">
                  What is Personalization in UX?
                </h5>
                <div className="mt-48">
                  <button
                    type="button"
                    className="w-28 px-2 py-2 border-[2px] border-white text-white hover:bg-white hover:!text-black max-sm:mb-[10px]"
                  >
                    Read Blog
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container sm:w-[70%] mx-auto mt-10">
            <div className="sm:flex gap-5">
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={Where_Do} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        MOBILE APP DEVELOPMENT
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className="text-[21px] hover:text-[#F13623] font-bold"
                      >
                        The Ultimate Guide to Cross Platform App Development
                        Frameworks in 2023
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={xheadles} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-5">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        DIGITAL COMMERCE
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className=" text-[21px] hover:text-[#F13623] font-bold"
                      >
                        What is Headless Commerce? The Ultimate Guide
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <div className="px-4 py-4">
                  <h5 className="font-bold text-[24px]">Popular Posts</h5>
                  <div className="flex mt-4">
                    <img src={yfeatured} />
                    <p className="font-bold ml-5">
                      <spna className="border-b-[2px] border-[#F13623]">
                        What are the Seven Stages in the New Product Development
                        Process?
                      </spna>
                    </p>
                  </div>
                  <div className="flex mt-4">
                    <img src={yzfeatured} />
                    <p className="font-bold ml-5">
                      <spna className="border-b-[2px] border-[#F13623]">
                        Top 8 Agile Estimation Techniques (Explained with
                        Examples)
                      </spna>
                    </p>
                  </div>
                  <div className="flex mt-4">
                    <img src={yxfeatured} />
                    <p className="font-bold ml-5">
                      <spna className="border-b-[2px] border-[#F13623]">
                        Flask vs Django in 2023: Which Framework to Choose and
                        When?
                      </spna>
                    </p>
                  </div>
                  <div className="flex mt-4">
                    <img src={zeatured} />
                    <p className="font-bold ml-5">
                      <spna className="border-b-[2px] border-[#F13623]">
                        A Step-by-Step Guide to Build a Minimum Viable Product
                        (MVP)
                      </spna>
                    </p>
                  </div>
                  <div className="flex mt-4">
                    <img src={zfeatured} />
                    <p className="font-bold ml-5">
                      <spna className="border-b-[2px] border-[#F13623]">
                        What’s a Software Design Pattern? (+7 Most Popular
                        Patterns)
                      </spna>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container sm:w-[70%] mx-auto">
            <div className="sm:flex gap-5">
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={xcomparison} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        PRODUCT DEVELOPMENT, WEB DEVELOPMENT
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className="text-[21px] hover:text-[#F13623] font-bold"
                      >
                        PHP vs Python: Features & Comparison in 2023
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={xheadles} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        DIGITAL COMMERCE
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className=" text-[21px] hover:text-[#F13623] font-bold"
                      >
                        What is Headless Commerce? The Ultimate Guide
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container sm:w-[70%] mx-auto">
            <div className="sm:flex gap-5">
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={zThum} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        APPLICATION MODERNIZATION
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className="text-[21px] hover:text-[#F13623] font-bold"
                      >
                        13 App Modernization Trends That Can Support and
                        Optimize Your App Modernization Strategy
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={zxThumbnail} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        MOBILE APP DEVELOPMENT,PRODUCT DEVELOPM
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className=" text-[21px] hover:text-[#F13623] font-bold"
                      >
                        Low-Code vs. No-Code Development: Which One Should You
                        Choose?
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container sm:w-[70%] mx-auto">
            <div className="sm:flex gap-5">
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={modernization} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        APPLICATION MODERNIZATION
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className="text-[21px] hover:text-[#F13623] font-bold"
                      >
                        App Modernization Benefits To Boost Your ROI and
                        Future-Proof Your Business
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={Z_Development} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-5">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className=" text-[21px] hover:text-[#F13623] font-bold"
                      >
                        Understanding the API-first Approach for Product
                        Development
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container sm:w-[70%] mx-auto">
            <div className="sm:flex gap-5">
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={znatives} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        CLOUD SERVICES, PRODUCT DEVELOPMENT
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className="text-[21px] hover:text-[#F13623] font-bold"
                      >
                        A Guide To Cloud-Native Application Development
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
              <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
                <img src={ySuccessful} />
                <div className="px-4">
                  <div className="flex mt-3">
                    <p className="px-2 bg-[#F9D94E]">BLOG</p>
                    <p className="px-2 bg-[#F4F9FF] ml-3">February 23, 2023</p>
                  </div>
                  <div className="mt-5">
                    <div className="flex">
                      <Link
                        to={""}
                        className="text-[#F13623] tracking-widest text-[12px]"
                      >
                        CLOUD SERVICES, PRODUCT DEVELOPMENT
                      </Link>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={""}
                        className=" text-[21px] hover:text-[#F13623] font-bold"
                      >
                        Cloud Migration Checklist
                      </Link>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white "
                    >
                      Read Blog
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[43%] mx-auto mt-20">
              <button
                type="button"
                className="w-28 px-2 py-2 !text-[#F13623] border-[1px] border-black hover:bg-[#666666] hover:!text-white"
              >
                Read Blog
              </button>
            </div>
            <div className="mt-20">
              <h5 className="sm:text-[44px] font-extralight text-center">
                Got a Project in Mind? Get Started Now.
              </h5>
              <div className="w-[160px] mx-auto">
                <button
                  type="button"
                  className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                >
                  Let's Talk
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Insights;
