import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  iotbanner,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  ChatGPT,
  Digital_tra1,
  Hiring,
} from "../../assets";
import { Link } from "react-router-dom";
const IotDevelopement = () => {
  return (
    <>
      <div className="container">
        <div className="flex mt-4">
          <div className="w-[50%]">
            <div className="mt-[160px]">
              <p className="text-3xl">
                <b>Smarten Your Business Ventures for Better with IoT</b>
              </p>
              <p className="mt-2 mb-2">
                Enter the realm of smart devices with our smart IoT solutions.
                Install the latest software and experience unmatched brilliance
                with RevInfotech.
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <img src={iotbanner}></img>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <p className="text-4xl">
              <b>Offering the Best IoT Services on the Web</b>
            </p>
            <p className="mt-4">
              With technology proliferating at an unforeseen pace, organizations
              are pacing themselves to quick market shifts one after another.
              The digital domain is full of uncertainties and new findings,
              making it enormously tough for unintuitive ventures to grow.
              Amidst these growing techs, IoT has taken great leaps for the
              industry’s betterment. Your business can also enter the smart
              circle with our help. Our IoT expertise is available for different
              industries at affordable prices. Many experts speculate the IoT
              market to expand globally and your firm will have to equip it
              sooner or later. Make an early move to get ahead of the cult and
              capitalize on every opportunity with RevInfotech.
            </p>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AR and VR</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                    Enterprise Mobility
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-5">
        <div className="text-center text-white p-5">
          <p className="text-3xl">
            Avail Valid IoT Consultancy at the Best Prices
          </p>
        </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          IoT Services of RevInfotech
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                SOFTWARE DESIGN
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Let your ideas run wild and prepare the most intuitive software
                with us. Discuss your software ideas with us and turn your
                imagination into reality.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                INDUSTRY CONSULTANCY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Studying the market and devising viable strategies can consume
                valuable time. Let our experts do the work and get expert
                analysis on the market.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                SOFTWARE DEVELOPMENT
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Share your software ideas with us to build the most viable
                solutions. Allow RevInfotech to develop your desired software in
                the most efficient way.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Avail Esteemed Benefits
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVED CONNECTIVITY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Leave the hassles of manually operating multiple devices for
                different tasks. With our IoT solutions, multitasking becomes
                significantly easy. We can help you connect with different
                platforms at once to formulate a unified chain of communication.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                INCREASED EFFICIENCY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Better connectivity means better results and better
                communication within your premises. With better communication,
                the barriers come down, and the overall efficiency increases.
                Gain this and much more with our IoT solutions.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVED CONVENIENCE
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                Digitally smart has become the new normal in the current market
                and with our help, you can experience true convenience as well.
                Integrate smart devices within your system with our help and get
                your business in top shape.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                RESULT PREDICTIONS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Our AI solutions are capable of making calculated predictions
                for your business. With our AI algorithms, you can deduce the
                results and undergo viable shifts to make them desirable. Keep
                up with the market and grow optimally with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BETTER SAVINGS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                With smart devices installed within your system, the processes
                become smart as well. Conserve as many resources as possible and
                utilize them in better areas. With our help, you can produce
                improved results with limited resources.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
              Why is IoT Integral for Your Business?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              Evolution is the necessary ingredient for the recipe of growth,
              and IoT facilitates unmatched growth. Be it industry, healthcare,
              business, or household, IoT carries the potency to revolutionize
              them all. The mere optimal utilization of data can increase your
              productivity severalfold. Solving intricate tasks becomes routine
              for you, helping you save time and capital. IoT is bound to rule
              the upcoming market algorithms as well, so you need to integrate
              it ASAP to stay ahead of the competition.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
              Why Pick RevInfotech for Your IoT Integration?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              The IT landscape has drastically changed over the years, with new
              tech emerging and replacing the traditional ones. This cycle of
              shift has brought us to the latest catalyst of growth, IoT. IoT
              ruling the market is not a farfetched dream anymore. It is bound
              to happen, and RevInfotech takes pride in being at the forefront
              of the shift. Our experts pack the capability of revolutionizing
              your firm from within and bring out your optimal with sound IoT
              solutions. To reach us and get the best.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra1} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IotDevelopement;
