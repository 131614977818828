import {
  softwaredevelopment,
  Img1,
  GWA1m,
  ASPNETCoreSolutions,
  softwaredevelopment1,
  frontrushm,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment4,
  softwaredevelopment5,
  softwaredevelopment6,
  softwaredevelopment7,
  Hiring,
  GoogleCloudSQL,
  Digital_tra2,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const NetDevelopement = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                ASP.NET CORE WEB DEVELOPMENT SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Increase Productivity, Lower Costs and Improve Output with
                Microsoft Apps Developed by an Expert ASP Net Development
                Company
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Our .Net development specialists build the apps and platforms
                you need to expand your business and help transform it into an
                agile, high-performing competitor.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with .NET Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={ASPNETCoreSolutions} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Delivering Innovative ASP.NET Core Solutions</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                As a Microsoft Gold Application Development Partner, we have
                engineered enterprise-grade solutions for customers in many
                different industries, including for Microsoft's own hardware
                division. Though, let's understand one thing first - users often
                get overwhelmed with the .Net Core vs .Net Framework debate and
                related products. The base fact is that Microsoft supports both
                these frameworks - .NET Core and the .NET framework. While the
                latter is a traditional version, .NET Core is more aligned to
                technological advancements. And ASP.NET Core relies upon and
                extends .NET Core's platform functionalities. We use Microsoft's
                position at the forefront of digital innovation and its
                versatile ASP.NET and ASP.NET Core frameworks to power
                innovative but robust business software. We specialize in custom
                .Net projects that range from writing pristine code to building
                web applications for the Azure cloud. Our ASP Net development
                services are all you need to deliver an optimal experience to
                any device, every time.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  max-sm:text-[10px]   hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Services
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Explore Technology
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="">
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Developing Apps That Respond to Your Needs
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                While the .Net platform offers flexibility for both Windows
                desktop applications and cross-platform mobile apps, only an
                experienced ASP Net development company can use that flexibility
                to build the apps you need. Rapidsofts has almost 20 years of
                experience in digital products, platforms and software
                development. We can evaluate your business needs, develop the
                corresponding apps and help your business thrive. Our .Net and
                ASP.NET Core specialists can start with the features and
                functionality you require and build apps that are easy-to-use,
                scalable, and engagingly interactive. Our apps deliver the CX
                your customers want while giving you the functionality you need.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment} className="sm:ml-[100px]"></img>
                <button
                  type="button"
                  className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <dv className="">
            <p className="sm:text-6xl sm:mt-[100px] max-sm:mt-[50px] max-sm:text-[20px] max-sm:text-center">
              <b>Services</b>
            </p>
          </dv>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={softwaredevelopment1}
                  className="sm:mt-[100px] max-sm:mt-[50px]"
                ></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Using the ASP.NET Framework for Custom Solutions
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Our ASP.NET development team uses an agile process to build
                  custom solutions within a tight framework. We employ best
                  coding practices that leverage the ASP.NET platform's
                  lightweight features and functions. The result is robust
                  custom software and full-featured back ends for desktop,
                  mobile, web and IoT solutions. For customer-facing software,
                  we prioritize an engaging CX that helps bring in new users.
                </p>
                <p className="mt-4 sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                  Our overall development process emphasizes attention to
                  customer needs, thorough concept validation, fast app
                  creation, extensive testing and successful final rollout. Our
                  familiarity with the ASP.NET framework lets us execute our
                  custom software development process to deliver excellent
                  solutions that work for our customers at a reasonable cost.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Integrating Other Microsoft Platforms
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                In addition to working with custom software developed with the
                ASP.NET platform, Rapidsofts can integrate other platforms that
                deliver standard functions, such as enterprise resource planning
                or business management. Our expert ASP Net web development
                services empowers us to build your software ecosystem to include
                custom solutions as well as Microsoft Business Solutions
                platforms like Microsoft Dynamics CRM and the Sharepoint
                collaborative document management software.
              </p>
              <p className="sm:text-[22px] font-light mt-11 max-sm:text-[20px] max-sm:text-center">
                These platforms can be configured for many different business
                cases and we can make sure that they deliver the functionality
                you want and work with your other applications to share data as
                needed. We use powerful Microsoft tools and components to make
                sure your applications function as an integrated, streamlined
                software ecosystem.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment2} className="sm:ml-[100px]"></img>
                <button
                  type="button"
                  className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment3} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Modernizing Legacy Apps
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Legacy software and apps generate ever-increasing maintenance
                  costs and resist integration with modern software. As a
                  result, many can't deliver the digital experience customers
                  and employees expect. Companies maintain operations with
                  legacy software because they often can't find the essential
                  functions they need in standard, updated applications, and
                  out-of-date apps may not have an obvious upgrade path. When we
                  update such software to work on a .Net platform, we plan and
                  execute a smooth transition.
                </p>
                <p className="sm:text-[22px] font-light mt-11 max-sm:text-[20px] max-sm:text-center">
                  When you partner with an ASP Net web development company like
                  Rapidsofts, you upgrade your existing tech stack to deliver
                  improved performance — we make sure the updated software can
                  execute all the functions you classify as important. Typical
                  benefits include improved performance, increased speed, better
                  usability and lower costs.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Building and Managing Microsoft Azure Projects
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                As a leading ASP Net development company, Rapidsofts can help
                your company become a cloud computing powerhouse with the
                Microsoft Azure service and the .Net framework. We can design
                your Azure cloud computing projects to help with employee
                collaboration, remote work, computer backups and device
                management.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Our experienced .Net developers write, build and manage
                Microsoft Azure projects to make sure you get the results you
                need. Our leading-edge ASP Net development services include
                cloud-first software solutions that address common business
                challenges, such as data security, file availability,
                operational scalability and functional consistency. When we
                create cloud applications, we configure them so that your
                employees and customers can access the data and functions for
                which they have authorization from any device, anywhere. Your
                business becomes more flexible, more responsive to market
                demands and better at giving customers what they want.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment4} className="sm:ml-[100px]"></img>
                <button
                  type="button"
                  className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={softwaredevelopment5}
                  className="sm:mt-[100px] max-sm:mt-[50px]"
                ></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Migrating Existing Apps to ASP.NET Core
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  ASP.NET Core is the open-source, cross-platform version of
                  ASP.NET, and we at Rapidsofts can help you migrate existing
                  apps to the new framework. When you migrate apps to ASP.NET
                  Core, you can target Windows, Linux and Apple operating
                  systems, but converting your files can be challenging. You
                  need an experienced ASP.NET Core developer for a successful
                  migration. We identify data structures, files and dependencies
                  for your proposed migration and make sure that your data is
                  safe for a flawless transition.
                </p>
                <p className="sm:text-[22px] font-light mt-11 max-sm:text-[20px] max-sm:text-center">
                  We use Microsoft tools to analyze portability and create clear
                  steps for the conversion process. To make sure everything
                  works as planned, we create test projects that we run on the
                  migrated apps to show that they operate as expected. Net
                  Solutions can complete your transition to the latest version
                  of ASP.NET Core quickly and successfully.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Optimizing App Performance
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Users expect apps to run rapidly with an intuitive UI —
                excellent reliability and consistency are only prerequisites. In
                order to compete, your business needs apps that immediately
                communicate to users what they have to do and quickly give them
                the information and functionality they're looking for the same
                way every time.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                At Rapidsofts, we offer ASP Net core development services with
                these goals in mind — but there is always room for improvement
                via app optimization. Based on our extensive ASP.NET development
                experience, we review code and analyze crash data. We establish
                performance benchmarks and look for potential problem points.
                Rapidsofts identifies which aspects of app performance are
                lagging and works on those. As part of this process, when we
                come across bottlenecks, we resolve them to improve day-to-day
                performance. When the app optimization is complete, your app
                will be fast, intuitive and reliable.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="mt-5">
                <img src={softwaredevelopment6} className="sm:ml-[100px]"></img>
                <button
                  type="button"
                  className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={softwaredevelopment7} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Updating, Upgrading and Maintaining Your Software
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Rapidsofts is committed to your digital success and can
                  continue to support you after launch. We'll be here to handle
                  your updates, upgrades and maintenance and help you keep
                  everything running smoothly. Our ASP.NET maintenance team is
                  there for the support you need and to make sure you can serve
                  your customers and exceed their expectations — that way, you
                  can stay ahead of the competition and satisfy market demands.
                  With our specialists to back you up, you can focus on your
                  business and let us worry about your IT.
                </p>
                <p className="sm:text-[22px] font-light mt-11 max-sm:text-[20px] max-sm:text-center">
                  Rapidsofts will keep your current software ecosystem
                  performing at a high level, and when your business changes as
                  a result of evolving markets, we can help you adapt and
                  prosper
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:mt-[200px] max-sm:mt-[50px]">
            <p className="sm:text-7xl w-[90%] m-auto max-sm:text-[20px] max-sm:text-center">
              <b>
                Common FAQs about Working With Rapidsofts for ASP.NET
                Development
              </b>
            </p>
            <div className="mt-[50px]">
              <div className="sm:w-[80%] m-auto">
                <Accordion className="mb-5 max-sm:mt-[-100px]">
                  <div>
                    <Accordion.Item eventKey="0" className="">
                      <Accordion.Header>
                        <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                          What is ASP.NET?
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="text-[20px] font-light text-3xl">
                          ASP.NET is an open-source developer framework. It was
                          created by Microsoft and dates back to the 2000s,
                          though constant upgrades have kept it competitive as a
                          framework through the years.
                        </div>
                        <div></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <Accordion.Item eventKey="1" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                        What is ASP.NET used for?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        ASP.NET is used by developers to build dynamic websites
                        as well as web applications and services. While ASP.NET
                        runs only on Windows, Microsoft issued ASP.NET Core in
                        2016. It runs on Windows, Linux and MacOS, expanding the
                        accessibility of this free framework to more developers
                        and systems.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                        Why use ASP.NET?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        As an open-source solution, ASP.NET is free to use. That
                        can reduce the cost of web development for agencies and
                        businesses. It's built into the overall Windows
                        framework, which makes it easy and familiar to use for
                        most developers and requires less setup than some
                        frameworks.
                      </p>
                      <p className="text-[20px] font-light">
                        In some cases, ASP.NET also supports better speed. For
                        example, most of the time a site built with ASP.NET will
                        be faster than a site built with PHP, making ASP.NET
                        more attractive for businesses that are worried about
                        site functionality and user experience.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                        How much does an ASP.NET application cost?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        ASP.NET web apps are often more affordable than apps
                        developed with other solutions, due in part to the
                        open-source nature of the technology. That being said,
                        the total cost of your project depends on various
                        factors, including how big or complex it is, how fast
                        you need it and what integrations you might need. Reach
                        out to Rapidsofts, a trusted ASP Net web development
                        company for a quote on an ASP.NET project today.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                        How is ASP.NET used in web development?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light ">
                        ASP.NET is only one of many coding languages under the
                        .NET umbrella. Usually it's used to develop the back-end
                        applications and utilities of a website or web
                        application (in the same way Python is used for the same
                        thing). As part of our ASP Net web development services,
                        ASP.NET is paired with programming languages that may
                        also be under the .NET umbrella (such as VB.NET), and
                        those are used to code the front-end elements.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                        How do you find an ASP.NET development company?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        You can search online, ask a business partner if they've
                        worked with one they liked or check with your IT teams
                        to get some recommendations. Or, you can reach out to
                        Rapidsofts today. We're an ASP.NET development company
                        with plenty of technical resources and know-how to get
                        your project completed.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="a">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px] ">
                        Why hire ASP.NET developers?
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="text-[20px] font-light">
                        There are so many coding languages and web development
                        tools, and your IT teams can't know them all. Besides,
                        you hired those professionals to keep your business
                        applications running and support your other teams.
                        Hiring developers for ASP Net core development services
                        lets you build new web applications or sites without
                        burdening existing tech resources. It also ensures
                        you're working with people who are experts at the
                        technology, so they're likely to be faster and more
                        adept at using it. That's good news for your project
                        timeline and cost.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="sm:w-[90%] m-auto">
              <div className="sm:mt-[100px] max-sm:mt-[50px]">
                <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                  <b>Success Stories</b>
                </p>
              </div>
              <div className="sm:flex">
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img src={Img1} className="w-100 hover:animate-pulse"></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        We Empower Businesses with Bespoke Enterprise Solutions
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      See how we quickly and efficiently engineered a robust
                      enterprise web portal using .NET programming to enable a
                      global talent agency to manage their licensing and
                      merchandising process better.
                    </p>
                  </div>
                </div>
                <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                  <div className="mt-5">
                    <img
                      src={frontrushm}
                      className="w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        FrontRushFrontRush Case Study We Devise Seamless
                        Cross-platform Experiences for Strong Engagement &
                        Performance
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Explore how we seamlessly integrated multiple technologies
                      on a .NET framework to create a powerful CRM platform for
                      an ambitious athletic recruitment software startup.
                    </p>
                    <img
                      src={GWA1m}
                      className="mt-5 w-100 hover:animate-pulse"
                    ></img>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      <b>
                        We Design Award-winning Event-based Digital Experiences
                        for Global Brands
                      </b>
                    </p>
                    <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                      Discover how we developed an eye-grabbing responsive
                      website using Kentico, a .NET-based platform, for a
                      Nike-sponsored half-marathon that was a one-stop
                      destination for runners and a singularly easy to manage
                      for admin users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Hiring} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={GoogleCloudSQL} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px] ">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Digital_tra2} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND{" "}
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px] ">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NetDevelopement;
