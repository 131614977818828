// import React from 'react'
import {
  vvimg,
  zzimg,
  xaimg,
  Img1,
  frontrushm,
  xipm,
  webdevisotop1,
  ascend1,
  Hiring,
  GoogleCloudSQL,
  digitalcustomerexperiencestrategy,
  Digital_tra2,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  digitalexpplatform,
  GWA1m,
  flimskillm,
  acomplete,
  Thumbnail,
  Thumbnail1,
  dtdiagram,
  bannerods,
  netsolutions,
  mobilebanner,
  netsolutions1,
  dxp,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const WebDevelopement = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                CUSTOM WEB DEVELOPMENT SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                Transform Your Business With A Custom Web Development Company
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Agile, full-service engineering capabilities — everything you
                need for your next project under one roof.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with Web App Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mt-[200px]">
          <div className="sm:flex">
            <div className="sm:col-5">
              <div className="">
                <img src={vvimg} className="sm:h-[518px] w-full object-cover"></img>
              </div>
            </div>
            <div className="sm:col-3">
              <div className="sm:ml-2">
                <img src={xipm}></img>
                <img src={zzimg} className="sm:mt-2 w-full sm:h-[233px] object-cover"></img>
              </div>
            </div>
            <div className="sm:col-4">
              <div className="sm:ml-3">
                <img src={xaimg} className="sm:h-[518px] w-full object-cover "></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          {/* <img src={bannerods} className="w-full h-[600px]" /> */}
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-dark  sm:text-6xl mb-5 max-sm:text-[20px] max-sm:text-center">
                <b>Expert Full-stack Web Development Company</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Your business success depends on the solutions your brand can
                deliver in the digital world. For more than two decades,
                mid-size businesses and global brands continue to choose Net
                Solutions as their preferred custom web development company. Our
                certified web development specialists can provide various web
                development services ranging from progressive web apps (PWAs) to
                personalized customer experience platforms.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Web App Development Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Web Dev Strategy & Consultation
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  We strive to set ourselves apart as a premier web app
                  development company by providing specific development
                  solutions to meet each client’s needs. We feel the goal is not
                  just to build the product right — it’s also about building the
                  right product for your brand. Our web development team
                  includes UX specialists and experienced system architects who
                  engineer customized experiences with secure, scalable
                  infrastructure for sustainable growth and dependable ROI.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Custom Web App Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Listening to your customers and anticipating their needs is
                  vital to earning their business and loyalty. We provide smart
                  solutions to your most complex challenges by using the best in
                  enterprise web app development services. We’re more than just
                  a vendor. Consider us your partner for all of your single-page
                  applications (SPAs), progressive web apps (PWAs), portals,
                  intranets, and cross-platform experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  System Integrations
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Seamless system integrations are essential to maximizing
                  efficiency and minimizing the cost of the web development
                  process. We specialize in integrating third-party systems and
                  APIs such as CRMs and SaaS platforms into your app. As an
                  expert web app development company, we can increase your
                  offering’s versatility by incorporating your digital products
                  into a broader ecosystem of applications.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Modernization and Migrations
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Legacy applications need constant upgrading in order to
                  reimagine and reinvigorate your digital experience. Our custom
                  web development company experts can implement a strategic plan
                  to migrate and modernize your existing technology stack for
                  optimal, results-driven performance.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Your Rapidsofts team also prepares products and services
                  for changes in modern user trends. Our goal is to equip our
                  partners to keep pace with the ever-changing digital
                  landscape. For example, more users are accessing web apps from
                  their mobile devices than ever before. Since that trend
                  doesn’t appear to be slowing down, we support our partners by
                  offering iOS app development services, as well as Android
                  development services, in addition to our other offerings.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Digital Experience Platform Implementation
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Engaging your users with an interactive, personalized,
                  mobile-ready digital experience is a must in our on-the-go
                  world. This type of development requires a custom web
                  development company on your side. “Out-of-the-box” SaaS
                  solutions aren’t capable of adapting to your business’s unique
                  needs. Leverage our team’s knowledge of leading CMS platforms,
                  including AEM, Drupal, and Kentico, among others. We also
                  offer bespoke solutions that help you achieve maximum
                  efficiency.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  eCommerce Platform Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  An intuitive shopping experience and easy, secure checkout are
                  essential for today’s online customers. Rapidsofts is a
                  certified Adobe Solution Partner for Magento—Adobe’s leading
                  eCommerce platform. Our online store specialists can provide
                  your team with fully customized eCommerce development
                  solutions. We engineer the right solution for your brand — one
                  that optimizes traffic and boosts your sales by turning
                  browsers into buyers.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                  Cloud-based Web Application (SaaS) Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Software-as-a-Service (SaaS) is one of the fastest-growing
                  digital business models because of the ease, convenience, and
                  efficiency it delivers to both brands and consumers. Our
                  seasoned SaaS app development team has solidified our
                  reputation for providing premier web development services by
                  designing and building innovative cloud-based solutions with
                  award-winning results for over a decade.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Ongoing Maintenance and Support
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Getting your web development project launched and off to a
                  strong start is just the beginning. Our goal is to provide you
                  with end-to-end web app development services that see your
                  project to the finish line. Consider Rapidsofts a long-term
                  partner in your success. We’re here to provide everything your
                  business may need, from the initial launch to providing
                  regular updates and maintenance.
                </p>
              </div>
            </div>
          </div>

          <div className="w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img src={Img1} className="w-100 hover:animate-pulse"></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      IMGIMG Case Study We Build Innovative Web Portals for
                      Global Brands
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    See how we helped a leading international sports talent
                    agency boost its staff efficiency and vendor engagement by
                    automating its merchandising and licensing deals with a
                    custom-designed B2B platform.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={frontrushm}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      FrontRushFrontRush Case Study We Engineer Universal Apps
                      to Streamline Business Objectives
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we were able to build a bespoke CRM for an
                    athletic recruitment software company that rolled out across
                    native apps, including Apple and Microsoft products. The
                    resulting platform provided a winningly consistent
                    cross-platform experience for our partner.
                  </p>
                  <img
                    src={GWA1m}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Build Cloud-based Enterprise Products that Deliver
                      Flawless Experiences
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Explore how we helped an ambitious video visionary build an
                    intelligent video platform that demonstrates the power video
                    can bring to the workplace.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND T
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebDevelopement;
