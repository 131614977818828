import React from 'react'
import { digital_platform90, digital_platform_ofc, digital_platform_CMS_to_DXP, digital_platform_DXP3Pillars, CMS_Flywheel,  ebook_img, a_complete_CMS, Thumbnail_2, Crawl_Walk, dxp_gartner } from '../../assets';
import { Link } from "react-router-dom";
import Sidebar1 from '../../componets/sidebar/Sidebar1'

const DigitalExperiences = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='sm:w-[400px] sm:h-[500px] mt-[68px] sm:fixed overflow-auto'>
                    <Sidebar1 />
                </div>
                <div className='sm:ml-[420px]'>
                    <ul className='flex pt-[85px] max-sm:hidden'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]"> Data Analytics & Insights</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${digital_platform90})` }} className="w-full sm:h-[350px] max-sm:h-[200px] bg-center bg-cover mt-5">
                        <div className='relative sm:left-[53px] top-[50px] sm:w-[22%]'>
                            <h5 className='font-extrabold sm:text-[41px] text-white max-sm:text-[20px] max-sm:text-center'>Digital Experience Platforms</h5>
                        </div>
                    </div>
                    <div className='px-8 mt-5'>
                        <div className='sm:px-16 py-8 border-l-8 border-yellow-400'>
                            <h5 className='text-[#48DDEF] font-bold sm:text-[22px] max-sm:text-[20px] max-sm:text-center'>SUMMARY:</h5>
                            <h5 className='sm:text-[40px] font-extralight mt-4 max-sm:text-[20px] max-sm:text-center'>Digital Experience Platforms (DXPs) steer digital business. They act as a building block of an ecosystem with many capabilities that amalgamate to deliver to end-to-end digital experiences. This guide helps industry leaders understand the evolving definition of Digital Experience Platform, its importance, its capabilities, and other key concepts.</h5>
                        </div>
                    </div>
                    <div className='sm:px-10 mt-5'>
                        <h5 className='sm:text-[50px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Introduction</h5>
                        <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Transformative digital experiences are reshaping today’s technology landscape. Established enterprises are undergoing a digital transformation, and contender firms are embracing digital to disrupt traditional industries. The sole reason behind this change is that consumers have made it clear that they won’t expect anything less than engaging customer experiences with a brand.</p>
                        <div className='sm:px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <h5 className='text-[#48DDEF] font-bold sm:text-[22px] max-sm:text-[20px] max-sm:text-center'>SUMMARY:</h5>
                                <h5 className='sm:text-[40px] font-extralight mt-4 max-sm:text-[20px] max-sm:text-center'>75% of consumers expect consistent experiences across multiple channels (web, mobile, social, in-person).</h5>
                            </div>
                        </div>
                        <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Brands taking their digital presence seriously must understand that to build an interactive, personalized, and compelling experience, they need to embrace the Digital Experience Platform — a solution that stitches data across the organization — to deliver effectual digital experiences in real-time across multiple channels in B2C, B2B, and B2E use cases.</p>
                    </div>
                    <div className=''>
                        <div className='mt-5'>
                            <img src={digital_platform_ofc} />
                        </div>
                        <div className='sm:px-10'>
                            <div className='border-l-8 border-sky-400 relative top-[-75px]'>
                                <div className='px-2 pt-36'>
                                    <p className='font-black sm:text-[50px] max-sm:text-[20px] max-sm:text-center'>What is a Digital Experience Platform?</p>
                                </div>
                            </div>
                            <div className='px-2.5'>
                                <div>
                                    <div>
                                        <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Digital Experience Platforms act as a cross-section in today’s evolving, complex, and interconnected digital technology landscape. It has become important for organizations to make a paradigm shift beyond traditional websites and mobile apps towards building orchestrated experiences across all the channels and devices (web, mobile apps, kiosks, mobile web, IoT/smart devices, etc) available throughout the customer journey.</p>
                                        <div className='sm:px-32 mt-5'>
                                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                                <h5 className='sm:text-[40px] font-extralight mt-4 max-sm:text-[20px] max-sm:text-center'>Through 2024, organizations with an established multi-experience strategy will outperform competitors in customer experience satisfaction metrics.</h5>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <p className='sm:text-[27px] font-sans	font-light max-sm:text-[20px] max-sm:text-center'>Digital Experience Platform is a consolidated set of digital technologies that work together to enable the optimization and delivery of orchestrated digital experiences, focusing on cross-channel continuity throughout the customer journey.</p>
                                            <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The purpose of Digital Experience Platforms is to attract, engage, and delight target customers using an integrated set of tools/technologies.</p>
                                        </div>
                                    </div>
                                    <div className='bg-[#F8FBFF] mt-5 max-sm:hidden'>
                                        <table className="table-fixed">
                                            <thead>
                                                <tr className=''>
                                                    <th className='sm:text-[24px] px-10 py-4 !bg-[#F2F8FF] sm:w-[430px] max-sm:text-[20px] max-sm:text-center'>What a DXP Is</th>
                                                    <th className='sm:text-[24px] px-10 py-4 sm:w-[450px] max-sm:text-[20px] max-sm:text-center'>What a DXP Is Not</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='px-10 py-2 sm:text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>A central technological foundation to be built upon and to support the entire, continous customer life cycle across all digital channels.</td>
                                                    <td className='px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not just a mashing together of new or existing technologies. Not just a “bucket” of products</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-10 py-2 sm:text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>The multichannel delivery via APIs of digital interactions across all touchpoints, including IoT, AR/VR, digital assistants and kiosks.</td>
                                                    <td className='px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not just a website channel, a responsive/mobile web, or a mobile app.</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-10 py-2 sm:text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>A platfrom that is API-driven and supports headless, hybrid and head-optional delivery, microservices and DevOps. It allows for creation of single-page and progressive web apps.</td>
                                                    <td className='px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not a cofiguration tool. A DXP is not a development platform as much as it is a way of personalizing, contextualizing and orchestration experiences. It is not a development platform like integration/application platform as a service or multiexperience development platforms for multiexperience development.</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-10 py-2 text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>A new way of interacting with the customers - it enables a continous conversation or dialogue that, ultimately, will supplant the more-traditional, push-oriented channels.</td>
                                                    <td className='px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not a one-way communication vehicle to customers that requires a one-time installation without ongoing optimization and refinement.</td>
                                                </tr>
                                                <tr>
                                                    <td className='px-10 py-2 sm:text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>A unified and integrated platform on which an employee experience (among other experiences) can be deployed.</td>
                                                    <td className='px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not a stand-alone intranet package.</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-[px] px-10 py-2 sm:text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>A platform where business and IT with various skills and responsibilities work together toward the common goal of customer experience improvement.</td>
                                                    <td className='text-[px] px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not an IT system and not a marketing system. It is, however a way to manage experiences and that management is far from just a task for the IT organization.</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-[px] px-10 py-2 sm:text-[17px] !bg-[#F2F8FF] max-sm:text-[20px] max-sm:text-center'>It is build for change and can be easily changed as a response to changes in demand.</td>
                                                    <td className='text-[px] px-10 py-2 sm:text-[17px] max-sm:text-[20px] max-sm:text-center'>Not a monolithic system that doesn’t undergo constant evlolution, optimization and refinement.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='mt-5'>
                                        <div className='sm:pr-8 '>
                                            <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Evolution of Digital Experience Platform</h5>
                                            <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Building meaningful conversation with target prospects does not mean only delivering content to websites, mobile apps, IoT devices, emails, or social platforms; rather, it also implies stitching experiences on them.</p>
                                        </div>
                                        <div className='sm:pr-8 mt-5'>
                                            <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>What is Content Management System?</h5>
                                            <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>It all started with the Web Content Management system (WCM) that helps enterprises organize, manage, and create content, among other things, for their online presence. However, with the growth in customer’s expectations, websites were no longer seen as mere tools to serve as brands’ digital brochures and evolved into platforms for better interactions and engagements.</p>
                                            <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The Content Management System (CMS) has been around for over 20 years, and during this period, the customer experience has only grown — in size, expectations, and reach. In fact, the customer experience has become the new metric for businesses, giving rise to a new term called Return on Experience i.e., ROX.</p>
                                        </div>
                                        <div className="mt-5">
                                            <img src={CMS_Flywheel} />
                                        </div>
                                        <div className="mt-5">
                                            <p className='sm:text-[26px] font-sans	font-light max-sm:text-[20px] max-sm:text-center'>Although the CMS served us well, enterprises began to experience the following critical issues, paving the way for digital experience becoming a vital part of the customer journey.</p>
                                            <ul className='list-disc sm:ml-14 max-sm:p-3'>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Valuable information buried in unproductive silos</li>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Bloated technology stacks</li>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Lack of content personalization</li>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Poor marketing analytics</li>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>A barrier to omnichannel marketing</li>
                                            </ul>
                                        </div>
                                        <div style={{ backgroundImage: `url(${ebook_img})` }} className="w-full h-[350px] bg-center bg-cover mt-5">
                                            <div className='relative left-[53px] top-[50px] w-[56%]'>
                                                <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>Read this next: What is a Content Management System (CMS) and How Does it Work?</h5>
                                                <Link to={""}><button type="button" className='px-4 py-2 sm:text-[24px] text-white bg-[#F13623] font-extrabold sm:h-16 mt-3 sm:w-[270px]'>Explore Our blog</button></Link>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Digital Experience Platform: A Mutated Evolutionary Version of CMS</h5>
                                            <p className='sm:text-[26px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Building meaningful conversation with target prospects does not mean only delivering content to websites, mobile apps, IoT devices, emails, or social platforms; rather, it also implies stitching experiences on them.</p>
                                            <p className='sm:text-[26px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The issues, highlighted in the above section, set the stage for the birth of DXP (a mutated evolutionary version of CMS), responsible for delivering a true omnichannel content delivery that is flexible enough to support the channels of the future. The evolution from CMS to DXP is two-fold:</p>
                                            <ul className='list-disc sm:ml-14 max-sm:p-3'>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Firstly, it is steered by the push toward a fully connected experience, where customers’ every interaction with any touchpoint is fed back to optimize the next interaction.</li>
                                                <li className='sm:text-[24px] font-sans font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Secondly, DXP comes from the natural rise of emerging technologies becoming increasingly agile and capable of handling complex tasks.</li>
                                            </ul>
                                        </div>
                                        <div className='mt-5'>
                                            <img src={digital_platform_CMS_to_DXP} />
                                        </div>
                                        <div className='mt-5'>
                                            <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Capabilities of Digital Experience Platform</h5>
                                            <p className='sm:text-[27px] font-sans	font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The three key pillars of core capabilities for Digital Experience Platforms are Experience, Management, and Platform. By understanding the core elements of a digital experience platform and how they work in unison to form a platform, an enterprise can become well-equipped to make the apt decision for their customized needs.</p>
                                        </div>
                                        <div className='mt-5'>
                                            <Link to={""}><img src={digital_platform_DXP3Pillars} /></Link>
                                        </div>
                                        <div className='mt-5 max-sm:p-3'>
                                            <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Experience</span></p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>The first piece of the unified Digital Experience Platform puzzle is ‘Data.’ You will be unable to deliver a seamless flow of customer journey if the data in your systems are not connected.</p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>A Digital Experience Platform requires ‘data’ to work in cohesion with other elements to perform the following two major functions:</p>
                                            <div className=''>
                                                <ul className='list-disc'>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Analysis and correlation of data:</strong><span className='font-light'>It provides businesses and marketers the insight and intelligence to spot the consumer’s trends, thereby providing them access to the areas where new products, pricing or promotions can be tested and refined.</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Real-time complex event processing: </strong><span className='font-light'>It allows organizations to leverage insights and use machine learning to assemble content fragments in real-time, closely matched to the customer’s individual lifestyle and preferences.</span></li>
                                                </ul>
                                            </div>
                                            <div className='sm:pl-32 mt-5'>
                                                <div className='sm:px-32 max-sm:px-10 py-8 border-l-8 border-yellow-400'>
                                                    <h5 className='sm:text-[40px] font-extralight mt-4 max-sm:text-[20px] max-sm:text-center'>Without comprehensive, accessible customer data, it’s impossible to deliver on the DX promises of personalization, predictive analytics, and machine learning.</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>2. <span className='ml-3'>Content Management</span></p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[40px] max-sm:text-[20px] max-sm:text-center'>DXPs need Web Content Management (WCM) capabilities. Content management makes sure that an effective and rich content/information is accessible to DXP users.</p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[40px] max-sm:text-[20px] max-sm:text-center'>Content management is the process of monitoring and controlling different types of content (including rich media,text, content snippets, voice, immersive elements, and navigation and taxonomy elements, etc) across multiple digital channels, using tools based on a core repository.</p>
                                        </div>
                                        <div className='mt-5'>
                                            <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>3. <span className='ml-3'>Integration, Interoperability and Extensibility</span></p>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>Good DXPs come with three abilities — Integration, Interoperability, and Extensibility — that helps build, operate, and improve end-to-end digital experiences.</p>
                                            <div className=''>
                                                <ul className='list-disc'>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Integration </strong><span className='font-light'>is the ability of data employment and use of applications from third parties for an effective digital experience.</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Interoperability</strong><span className='font-light'>is the ability of an DXP to integrate with other systems for synergistic benefit.</span></li>
                                                    <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Extensibility</strong><span className='font-light'>implies a framework that deals with modules, plugins, extensions, and application integrations. Integrations usually happen within a Digital Experience Platform and with adjacent technologies.</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Benefits of Digital Experience Platform</h5>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Based on the capabilities — Experience, Content Management, and Interoperability — offered by Digital Experience Platforms (DXPs) the following are the key advantages of including DXP in your business strategy:</p>
                                            <div className='mt-5'>
                                                <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Touchpoint Optimization for Rich End-User Experience</span></p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>With a Digital Experience Platform, customization across every touchpoint becomes easily achievable. DXPs deploy the power of trend analysis and <span className='font-medium border-b-[2px] border-[#F13623]'>data analytics</span>to study customer behavior at every critical touchpoint, which further helps in construing the customer expectations in the truest manner.</p>
                                            </div>
                                            <div className='mt-5'>
                                                <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>2. <span className='ml-3'>Digital Transformation Enablement</span></p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>Digital Experience Platform architecture also plays a significant role in driving digital transformation by embracing microservices, a modern architectural style. The microservices architecture aids in the continuous plus successful deployment of large-sized & complex applications, thus giving an opportunity to evolve through technology integrations.</p>
                                            </div>
                                            <div className='mt-5'>
                                                <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>3. <span className='ml-3'>Better Personalization</span></p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>Today, consumers are peppered with a number of choices; thus, brands must know how to attract them so that they choose only your brand over others. DXPs integrate with other systems like CRMs and social media to connect with your consumers at every touchpoint, build a relationship with them, and identify their pain points that your brand can solve, thereby generating AI-driven deep insights.</p>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Digital Experience Platform — Use Cases</h5>
                                            <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Focusing on the factors that offer a customer-centric business value, this section covers the top use cases of Digital Experience Platform that your business can leverage to deliver on the promise of <span className='font-medium border-b-[2px] border-[#F13623]'>Customer Experience Management</span> (CXM).</p>
                                            <div className='mt-5'>
                                                <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>1. <span className='ml-3'>Multi-site Management</span></p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>‘Multiple sites with a single voice’ is the motto of most Digital Experience Platforms.</p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>When your organisation’s web presence surpasses geographies and targets several diverse regions with varied languages, multi-site management helps you to easily manage these multiple websites and micro-websites from a single location.</p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>You can build a microsite framework using DXP, which will allow you to manage the content of all your web properties from a single platform, thereby maintaining your brand identity and customer experience.</p>
                                            </div>
                                            <div className='mt-5'>
                                                <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>2. <span className='ml-3'>Digital Enrolment and Communication</span></p>
                                                <p className='text-[24px] font-sans	font-light mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'>A customer wants to get rid of complicated paper-based forms and be served with an experience that is refreshing, secure, and seamless — flexibility to start on a mobile device, switch to another device and then finish up wherever they are next — across all the channels.</p>
                                                <p className='text-[24px] font-sans	font-light mt-4 ml-[35px] max-sm:text-[20px] max-sm:text-center'>With a DXP integrated into your organization, you can make the enrollment process paperless, secure, and compliant and can automatically convert all your PDF forms to mobile-responsive, digital, adaptive forms. Going digital will enable you to save time, money and personalize enrollment to <span className='font-medium border-b-[2px] border-[#F13623]'>reduce abandonment</span>.</p>
                                            </div>
                                            <div className='mt-5'>
                                                <p className='font-bold sm:text-[27px] max-sm:text-[20px] max-sm:text-center'>3. <span className='ml-3'>Content Marketing and Velocity</span></p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 ml-[35px] max-sm:text-[20px] max-sm:text-center'>An enterprise deals with multiple campaigns across multiple channels; thus requires streamlined workflows between their creative and marketing teams. Finding an easy way to manage tons of creative assets — one place where every stakeholder can find what they need to deliver consistent experiences across channels — is not just a luxury but a necessity to satisfy an endless customer’s appetite for engaging and authentic content.</p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 ml-[35px] max-sm:text-[20px] max-sm:text-center'>A solution in the form of DXP helps connect workflows, thereby enabling your unified team — marketers, creatives and outside agencies — to easily create, manage and dynamically deliver engaging, channel-optimized, and <span className='font-medium border-b-[2px] border-[#F13623]'>personalized customer experiences</span> across all channels: web, mobile, social and more.</p>
                                                <div className='sm:px-32 mt-5'>
                                                    <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                                        <h5 className='sm:text-[40px] font-extralight mt-4 max-sm:text-[20px] max-sm:text-center'>Only 26% of US prople surveyed think that retailers are totally providing a consistent customer experience across all channels.</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Digital Experience Platform Examples</h5>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>While there are a lot of Digital Experience Platforms out there, and many have an impressive list of features, not every DXP suits the task at hand.</p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Here are top Digital Experience Platform examples that have proven their worth in managing and improving digital customer experience (based on <span className='font-medium border-b-[2px] border-[#F13623]'>Gartners’ Magic Quadrant for Digital Experience Platforms</span>).</p>
                                                <div className="mt-5">
                                                    <img src={dxp_gartner} />
                                                </div>
                                            </div>
                                            <div className='mt-5'>
                                                <h5 className='sm:text-[51px] font-extrabold max-sm:text-[20px] max-sm:text-center'>Conclusion</h5>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>Today’s experience-driven digital landscape is steered by customers, and the businesses that can deliver seamless and consistent experiences retain customers longer and gain new visitors faster. The channels are increasing and overlapping, making it almost impossible for yesterday’s software solutions to keep the customers happy and engaged.</p>
                                                <p className='sm:text-[24px] font-sans	font-light mt-4 max-sm:text-[20px] max-sm:text-center'>DXP is a perfect solution for businesses that are in need to unify and manage their disparate systems and channels. A robust Digital Experience Platform can help brands conquer the challenge of delivering a relevant experience to their customers across all the touchpoints and channels, resulting in more agile and frictionless business processes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Featured Blogs</h5>
                <div className='sm:flex gap-5'>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={a_complete_CMS} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL EXPERIENCE PLATFORMS</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>

                                        A Complete Guide To Content Management Systems in 2023  </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Thumbnail_2} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL EXPERIENCE PLATFORMS</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        Composable Architecture: Why It’s Important and How to Get Started  </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5'>
                        <img src={Crawl_Walk} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className=''>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Customer Experience Management</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        Digital Customer Experience Management: Strategy, Value to Business, and Best practices   </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalExperiences