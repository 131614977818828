import React from "react";
import {
  food,
  xtestimonials,
  xytestimonials,
  xyztestimonials,
  author_i4,
  authorj_i5,
  btech,
  adobe,
  clutch,
  forrester,
  gartner,
  goodfirms,
  W3,
  Group66110,
  logo223,
  eiysys1,
  sumsub3,
  Group66111,
  csharpcorner1,
  alchemy,
  GreyScale,
  mcn1,
  LH1,
  The15,
  Cloud_Computing,
  Howto,
  Clutch_1,
  g21,
  goodfirms1,
  xScreenshot,
  testimonials,
} from "../assets";
// import React from "react";
import Slider from "react-slick";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
const Main = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="">
        <div className="container w-[100%]">
          <div className="sm:flex">
            <div className="sm:w-[60%] max-sm:w-[100%]">
              <div className="sm:mt-[300px] max-sm:w-[100%] max-sm:px-2">
                <h1 className="sm:text-[43px] font-black max-sm:text-[20px] max-sm:text-center  max-sm:mt-[80px]">
                  REVOLUTION IS
                </h1>
                <h1 className="text-[40px] font-black max-sm:text-[20px] max-sm:text-center">
                  CALLED FOR.
                </h1>
                <p className="text-[23px] italic mt-4 max-sm:text-[10px] max-sm:text-center">
                  We are a client-focused business process company that has a
                  decade of experience and expertise in web, mobile, AI, and
                  blockchain technology, with many more. In order to provide a
                  quality product, we work with innovation and provide business
                  consultancy.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] sm:mt-5 max-sm:mt-4 hover:bg-[#F13623] hover:text-white"
                >
                  Explore Services
                </button>
                <span className="ml-7 sm:mt-5">
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white"
                  >
                    Explore Technology
                  </button>
                </span>
                <div className="max-sm:px-2">
                  <ul className="flex mt-5 sm:gap-4 max-sm:gap-3">
                    <li>
                      <img src={Clutch_1} className="max-sm:w-[100px]" />
                    </li>
                    <li>
                      <img src={g21} className="max-sm:w-[100px]" />
                    </li>
                    <li>
                      <img src={goodfirms1} className="max-sm:w-[100px]" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sm:w-[40%] max-sm:w-[100%]">
              <form className="bg-[#F9D94E] shadow-2xl sm:px-8 xl:px-16 max-sm:px-5 pt-9 pb-8 mb-4 float-right sm:mt-[150px]  max-sm:mt-[50px] xl:w-[450px] max-sm:w-[100%] sm:w-[300px]">
                <h1 className="xl:text-[30px] font-black max-sm:text-[20px] sm:text-[20px]">
                  Get in touch with
                </h1>
                <h1 className="xl:text-[30px] font-black  max-sm:text-[20px] sm:text-[20px]">
                  our experts.
                </h1>
                <div className="">
                  <input
                    className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    placeholder="Name*"
                  />
                </div>
                <div className="">
                  <input
                    className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    placeholder="Email*"
                  />
                </div>
                <div className="flex mt-2">
                  <div className="w-35%">
                    <select
                      id="countries"
                      className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Choose a country</option>
                      <option value="US">India</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select>
                  </div>
                  <div className="w-[65%] ml-3">
                    <input
                      className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      id="phone"
                      type="phone"
                      placeholder="+91"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <textarea
                    id=""
                    row="5"
                    className="w-full px-3 h-20 py-2"
                    placeholder="Message*"
                  ></textarea>
                </div>
                <div className="mt-3 mb-16">
                  <button
                    type="button"
                    className="bg-[#F13623] py-2 w-full text-white font-extrabold text-[23px] max-sm:text-[15px]"
                  >
                    Get In Touch
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-20 max-sm:w-[100%]">
            <img src={xScreenshot} className="w-full object-cover" />
          </div>
          <div className="sm:flex">
            <div className="sm:w-[50%] max-sm:w-[100%] max-sm:w-[100%]">
              <div className="sm:px-20 max-sm:px-10 bg-[#F9D94E] sm:h-[500px] max-sm:pb-[1px]">
                <h1 className="font-extrabold text-[16px] tracking-widest sm:pt-28 max-sm:pt-10">
                  DEVELOP SOFTWARE AND APPS THAT MEET THE NEEDS OF YOUR
                  CUSTOMERS
                </h1>
                <p className="text-[26px] max-sm:text-[15px] tracking-widest sm:pt-5 max-sm:pt-5 mb-24">
                  Partner with a software company that develops products &
                  platforms that earn the loyalty of your customers and grow
                  your business.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-[50px]">
              <div className="">
                <div className="max-sm:w-[100%]">
                  <h5 className="sm:text-[33px] max-sm:text-[15px] text-[#4E4E4E] font-light sm:pt-48 max-sm:mt-[50px]">
                    A traditional software company is being transformed by
                    Rapidsofts. We’re flipping the conventional developer
                    “design and build” model.
                  </h5>
                  <p className="sm:pt-11 max-sm:pt-5 sm:text-[24px] font-light max-sm:text-[15px]">
                    Almost no custom software development company offers
                    end-to-end agile development. We create transformative
                    solutions that drive revenue while reducing your development
                    headaches.
                  </p>
                  <div className="pt-16">
                    <button
                      type="button"
                      className="border-[2px] border-[#F13623] px-2 py-2.5 w-full font-semibold sm-text-[20px] max-sm:text-[15px] text-[#F13623] tracking-widest"
                    >
                      Learn About Our Capabilities & Culture
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-[80%] m-auto max-sm:w-[100%] max-sm:px-2">
            <div className="sm:mt-[150px] max-sm:mt-[20px]">
              <h4 className="font-extrabold sm:text-[52px] max-sm:text-[20px] tracking-widest max-sm:text-center">
                Customers
              </h4>
              <p className="sm:text-[22px] max-sm:text-[15px] font-light pt-4 max-sm:text-center">
                As a result of our work, we have had the pleasure of growing
                brands in:
              </p>
              <img src={food} className="w-full mt-16" />
              <p className="sm:text-[22px] font-light mt-11 max-sm:text-[15px]  max-sm:text-center">
                Since 2018, trusted by the world's leading startups and
                enterprises.
              </p>
            </div>
          </div>
          <div className="">
            <div className="sm:w-[80%] m-auto max-sm:w-[100%] max-sm:px-2">
              <h5 className="sm:text-[57px] max-sm:text-[20px] font-extrabold sm:mt-32 max-sm:mt-[10px] max-sm:text-center ">
                Our software development agency has been trusted by innovative
                business leaders since 2018.
              </h5>
              <p className="sm:text-[21px] font-light tracking-wider sm:mt-5 max-sm:mt-5 max-sm:text-[15px]  max-sm:text-center">
                A web development agency is only as good as its reputation. The
                Rapidsofts team prides itself on consistently providing clients
                with top-tier design and customer service.
              </p>
              <p className="text-[21px] font-light tracking-wider sm:mt-5  max-sm:mt-3  max-sm:text-[15px]  max-sm:text-center">
                Here are some of our clients’ comments:
              </p>

              <Slider {...settings} className="">
                <div className="item">
                  <div className="sm:flex sm:flex-row gap-5 mt-20">
                    <div className="max-sm:w-[100%]">
                      <img
                        src={testimonials}
                        className="mt-6 rounded-full sm:h-[160px] max-sm:w-[80px] max-sm:mx-auto"
                      />
                    </div>
                    <div className="sm:w-4/6 px-8">
                      <h5 className="sm:text-[25px] font-semibold max-sm:text-[17px] max-sm:text-center max-sm:mt-1">
                        Dawson Norrish
                      </h5>
                      <p className="text-[13px] font-semibold mt-1  max-sm:text-center">
                        Arctic Shelf & Founder
                      </p>
                      <p className="sm:text-[28px] sm:text-[15px] font-light sm:mt-14 max-sm:mt-[3px]  max-sm:text-center">
                        Jatin and Ravi were great. They were always available
                        for phone calls and were always willing to try new
                        things. Very knowledgeable in IoT products and AWS
                        integration. They were also always willing to try new
                        things and deliver what we were asking for. Very happy
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="sm:flex sm:flex-row gap-5 mt-20">
                    <div className="max-sm:w-[100%]">
                      <img
                        src={xtestimonials}
                        className="mt-6 rounded-full sm:h-[160px]  max-sm:w-[80px] max-sm:mx-auto"
                      />
                    </div>
                    <div className="sm:w-4/6 px-8">
                      <h5 className="text-[25px] font-semibold max-sm:text-[17px] max-sm:text-center max-sm:mt-1">
                        Mohhamamd Asraf{" "}
                      </h5>
                      <p className="sm:text-[16px] font-semibold mt-1 tracking-wider max-sm:text-[15px] max-sm:text-center">
                        BoloTalk & Founder
                      </p>
                      <p className="sm:text-[28px] sm:text-[15px] font-light sm:mt-14 max-sm:mt-[3px]">
                        Rapidsofts team has done many projects for me.. and they
                        never failed to deliver on time a world class product.
                        Select him to do your work....you will never regret it
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="sm:flex sm:flex-row gap-5 mt-20">
                    <div className="max-sm:w-[100%]">
                      <img
                        src={xytestimonials}
                        className="mt-6 rounded-full sm:h-[160px] max-sm:w-[80px] max-sm:mx-auto"
                      />
                    </div>
                    <div className="sm:w-4/6 px-8">
                      <h5 className="text-[25px] font-semibold max-sm:text-[17px] max-sm:text-center max-sm:mt-1">Jay Amlesh</h5>
                      <p className="text-[16px] font-semibold mt-1 tracking-wider max-sm:text-[15px] max-sm:text-center">
                        Mapbuzz & Founder
                      </p>
                      <p className="sm:text-[28px] sm:text-[15px] font-light sm:mt-14 max-sm:mt-[3px] max-sm:text-[15px] max-sm:text-center">
                        Job well done. They have delivered the product as I
                        wished and I recommend them to other clients. Thank you
                        guys and I look forward to doing more tasks with you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="sm:flex sm:flex-row gap-5 mt-20">
                    <div className="max-sm:w-[100%]">
                      <img
                        src={xyztestimonials}
                        className="mt-6 rounded-full sm:h-[160px]  max-sm:w-[80px] max-sm:mx-auto"
                      />
                    </div>
                    <div className="sm:w-4/6 px-8">
                      <h5 className="text-[25px] font-semibold max-sm:text-[17px] max-sm:text-center max-sm:mt-1">
                        Sondra Conner
                      </h5>
                      <p className="text-[16px] font-semibold mt-1 tracking-wider max-sm:text-[15px] max-sm:text-center">
                        Sync Bus Lines &CEO
                      </p>
                      <p className="sm:text-[28px] sm:text-[15px] font-light sm:mt-14 max-sm:mt-[3px] max-sm:text-[15px] max-sm:text-center">
                        I Rapidsofts team are by far the best team i've worked
                        with in the past 5 years. Patient, understanding,
                        creative and always ready to help.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="sm:flex sm:flex-row gap-5 mt-20">
                    <div className="max-sm:w-[100%]">
                      <img src={author_i4} className="mt-6 max-sm:w-[80px] max-sm:mx-auto" />
                    </div>
                    <div className="sm:w-4/6 px-8">
                      <h5 className="text-[25px] font-semibold max-sm:text-[17px] max-sm:text-center">
                        Jakub Fundowicz{" "}
                      </h5>
                      <p className="text-[16px] font-semibold mt-1 tracking-wider max-sm:text-[15px] max-sm:text-center">
                        MastBit CEO
                      </p>
                      <p className="sm:text-[28px] sm:text-[15px] font-light sm:mt-14 max-sm:mt-[3px] max-sm:text-[15px] max-sm:text-center">
                        I have been a satisfied client of Rapidsofts. I am
                        impressed with their quality work and responsiveness.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="site-content">
          <div className="bg-[#FDDF57] sm:w-[94%] max-sm:w-[100%] sm:flex gap-3 float-right max-sm:mb-[20px]">
            <div className="sm:w-[44%] max-sm:w-[100%] sm:mt-36 max-sm:mt-[40px]">
              <h5 className="sm:text-[50px] font-bold sm:pl-40 max-sm:text-[20px] max-sm:text-center">
                Software Services offered by agencies
              </h5>
            </div>
            <div className="sm:w-[40%] max-sm:w-[100%]">
              <Accordion className="mb-5 max-sm:p-[10px] max-sm:mt-[-70px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[16px]">Designing an experience</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light accordion-collapse.collapse.show">
                        Turn your company’s UX into your X-factor with the
                        Rapidsofts experience design process. Our people-first
                        philosophy results in finished products that strike the
                        perfect balance of emotion and function, leaving your
                        customers to experience more rewarding interactions with
                        your products and services. Each experience design
                        project is unique. You can rest assured that Rapidsofts
                        will deliver the finished product, regardless of your
                        goals.
                      </div>
                      <div>
                        <button className="w-28 px-2 py-2  mt-5 border-[2px] border-[#F13623] text-[#F13623] font-medium">
                          Learn More
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[16px]">Commerce digital</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <p className="text-[20px] font-light">
                        Elevate the nature of your eCommerce store beyond simply
                        being responsive. Rapidsofts can help you design a
                        platform to keep your customers engaged and anticipate
                        their needs.
                      </p>
                      <p className="text-[20px] font-light mt-3">
                        You only have seconds to gain your customers’ attention.
                        We will help you win the hearts, minds, and wallets of
                        your store’s customers with our eCommerce expertise.
                      </p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[16px]">Development of products</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Rapidsofts is a premier website development agency that
                      can guide you through your product’s entire development
                      life cycle. Our goal is to leverage cutting-edge
                      technology to provide you with engaging, fulfilling
                      digital products for your customers. We can help you
                      rejuvenate your existing digital presence regardless of
                      the size of your business.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[16px]">
                      Platforms for digital experiences
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      To survive in today’s business world, you need to grab
                      your audience’s attention on mobile. Rapidsofts offers
                      first-class app development agency services to do just
                      that.
                    </p>
                    <p className="text-[20px] font-light mt-3">
                      Our team factors your business goals and users’ desires
                      into building a dynamic pathway to bridge the gap between
                      you and your audience. While still meeting your customers’
                      needs and challenges, the results are simple, powerful,
                      and elegant.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[16px]">Analyzing data</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Part of premier web development services is mastering the
                      art of data analytics. Any software development agency can
                      design a platform once and hand it off to you. However,
                      they’re missing out on the gold mine of information to be
                      found by analyzing your data.
                    </p>
                    <p className="text-[20px] font-light mt-3">
                      Rapidsofts can analyze your data through a lens of design
                      thinking to create new and improved ways to satisfy and
                      retain your customers. Monitor your company’s key metrics,
                      discover patterns, and gain new levels of insight to boost
                      revenue through user experience optimization.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[16px]">
                      Transformation of the digital economy
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Most companies struggle to design digital experiences that
                      embrace future trends. The digital landscape is constantly
                      changing, and businesses need to adapt along with it to
                      best serve their customers.
                    </p>
                    <p className="text-[20px] font-light mt-3">
                      As a digital transformation agency, Rapidsofts offers
                      enterprise mobile app development services so your digital
                      experiences can grow along with your business. Rapidsofts
                      leaves you free to evolve, modernize, and move forward
                      with confidence as your company’s future grows.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[68%] max-sm:w-[100%] mx-auto sm:flex max-sm:mt-[10px]">
            <h5 className="sm:text-[45px] max-sm:text-[20px] font-bold sm:mt-32 max-sm:mt-[30px] max-sm:text-center">
              Recognition & Awards
            </h5>
          </div>
          <div className="w-[62%] mx-auto sm:pt-16 max-sm:pt-10">
            <ul className="flex-wrap flex">
              <li className="w-[25%]">
                <div>
                  <img src={btech} className="" />
                </div>
              </li>
              <li className="w-[25%]">
                <div>
                  <img src={adobe} />
                </div>
              </li>
              <li className="w-[25%]">
                <div>
                  <img src={clutch} />
                </div>
              </li>
              <li className="w-[25%]">
                <div>
                  <img src={forrester} />
                </div>
              </li>
              <li className="w-[25%] sm:mt-24 max-sm:mt-10">
                <div>
                  <img src={gartner} className="" />
                </div>
              </li>
              <li className="w-[25%] sm:mt-24 max-sm:mt-10">
                <div>
                  <img src={goodfirms} />
                </div>
              </li>
              <li className="w-[25%] sm:mt-24 max-sm:mt-10">
                <div>
                  <img src={W3} />
                </div>
              </li>
            </ul>
          </div>
          <div className="sm:w-[68%] max-sm:w-[100%] mx-auto sm:flex">
            <h5 className="sm:text-[45px] max-sm:text-[20px] font-bold sm:mt-32 max-sm:mt-[20px] max-sm:text-center">
              Insights
            </h5>
          </div>
          <div className="sm:w-[68%] max-sm:w-[100%] mx-auto sm:flex gap-5 max-sm:p-[10px]">
            <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
              <img src={The15} />
              <div className="px-4">
                <div className="flex mt-3">
                  <p className="px-2 bg-[#F9D94E] max-sm:text-[7px]">
                    INSIGHTS
                  </p>
                  <p className="px-2 bg-[#F4F9FF] ml-3 max-sm:text-[7px]">
                    February 23, 2023
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-web text-sm tracking-wider ">
                    WEB APP BUILDING IN 15 STEPS
                  </p>
                  <h5 className="mt-4 text-base">
                    Here is a 15-step guide with tips to help you understand how
                    web apps are developed, so you can create a web app that
                    engages users and meets your business needs. In today's
                    world, consumers expect{" "}
                  </h5>
                  <button
                    type="button"
                    className="sm:w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white max-sm:text-[15px]"
                  >
                    Read Blog
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
              <img src={Cloud_Computing} />
              <div className="px-4">
                <div className="flex mt-3">
                  <p className="px-2 bg-[#F9D94E] max-sm:text-[7px]">
                    INSIGHTS
                  </p>
                  <p className="px-2 bg-[#F4F9FF] ml-3 max-sm:text-[7px]">
                    February 23, 2023
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-web text-sm tracking-wider max-sm:text-[15px]">
                    WEB APP BUILDING IN 15 STEPS
                  </p>
                  <h5 className="mt-4 text-base max-sm:text-[15px]">
                    Here is a 15-step guide with tips to help you understand how
                    web apps are developed, so you can create a web app that
                    engages users and meets your business needs. In today's
                    world, consumers expect{" "}
                  </h5>
                  <button
                    type="button"
                    className="sm:w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white max-sm:text-[15px]"
                  >
                    Read Blog
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5">
              <img src={Howto} />
              <div className="px-4">
                <div className="flex mt-3">
                  <p className="px-2 bg-[#F9D94E] max-sm:text-[7px]">
                    INSIGHTS
                  </p>
                  <p className="px-2 bg-[#F4F9FF] ml-3 max-sm:text-[7px]">
                    February 23, 2023
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-web text-sm tracking-wider max-sm:text-[15px]">
                    WEB APP BUILDING IN 15 STEPS
                  </p>
                  <h5 className="mt-4 text-base max-sm:text-[15px]">
                    Here is a 15-step guide with tips to help you understand how
                    web apps are developed, so you can create a web app that
                    engages users and meets your business needs. In today's
                    world, consumers expect{" "}
                  </h5>
                  <button
                    type="button"
                    className="sm:w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white max-sm:text-[15px]"
                  >
                    Read Blog
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center sm:mt-32 max-sm:mt-[20px]">
            <p className="sm:text-[30px] max-sm:text-[15px] font-extralight">
              Got a Software or App Development Project in Mind?
            </p>
            <p className="sm:text-[30px] font-extralight max-sm:text-[15px]">Get Started Now.</p>
            <button
              type="button"
              className="w-32 px-2 py-2 mb-8 mt-11 font-bold text-white border-[2px] border-[#F13623] bg-[#F13623]  hover:bg-white hover:!text-[#F13623] max-sm:text-[15px]"
            >
              let's Talk
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
