import React from 'react'
import { crm_banner, crm_flow, systematic_flow, strategy_customer, over_years, operationnal_analytical, right_crm, your_business, benefits_crm, software_trends, digital_engineering, determining_roi, technology_solve } from '../../assets';
import { Link } from "react-router-dom";
import Sidebar7 from '../../componets/sidebar/Sidebar7'

const CustomerRelationship = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='sm:w-[400px] sm:h-[500px] mt-[68px] sm:fixed overflow-auto'>
                    <Sidebar7 />
                </div>
                <div className='sm:ml-[420px]'>
                    <ul className='flex pt-[85px] max-sm:hidden'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">  Digital Experience Platforms</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium sm:text-[18px] max-sm:text-[20px] max-sm:text-center">Customer Relationship Management (CRM)</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${crm_banner})` }} className="w-full sm:h-[350px] max-sm:h-[200px] bg-center bg-cover mt-5">
                        <div className='pl-10 pt-16 sm:w-[22%]'>
                            <h5 className='font-extrabold sm:text-[41px] text-white max-sm:text-[20px] max-sm:text-center'>Customer Relationship Management</h5>
                        </div>
                    </div>
                    <div className='sm:px-10 mt-5'>
                        <div>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>People determine sales - and always will. Customer building and nurturing aren’t just about individual intuition, experience, or maintaining extensive spreadsheets. Growth-oriented organizations have evolved and use research, data analytics, & tech inputs to retain customers, tap potential leads, and foster customer relationships. We call this CRM or Customer Relationship Management - the process of using technology and data analytics to create a new customer base, retain the present, build customer relationships, and generate growth.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>“I don’t know who you are, but do you want to buy a car? Or maybe a pizza? Could we also arrange the latest Loius Vuitton? We can get you anything!”</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>A business organization can start each day like this by posing random questions to existing/potential customers and sound like lost sheep trying to please customers at any cost.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Or, it could simply adopt CRM & show the foresight that behooves ambitious businesses. CRM is the tool that gives a business the competitive edge it needs to be the industry's superstar.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>What is a CRM system?</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Brand Empathy is the driving force for <span className='font-bold border-b-[2px] border-[#F13623]'>B2B or B2C</span> sales & marketing. How often have you been awed by a brand’s ability to predict your preferences, align your purchase accordingly and be responsive to your queries & needs in the post-purchase period?</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Did you know - the invisible thread that makes all this possible and efficient is CRM? Customer <span className='font-bold border-b-[2px] border-[#F13623]'>Relationship Management</span> or CRM is a complete system, an assortment of approaches, processes, and strategies organized in a software program, desktop or cloud-based, that a company follows to build & enhance its customer relationships and retain its customer base. By providing an improved user experience, a business gives a push to customer loyalty - the net result is that your customer stays with you.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={crm_flow} />
                        </div>
                        <div className='mt-5'>
                            <p className='sm:text-[27px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>When there is a systematic flow of data collection, linkages, and analyses, users can utilize that data to understand what is happening at every ‘touchpoint’ from the customer end. This results in a precise, clear “customer profile” - and the beginning of a long-lasting business-customer relationship.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={systematic_flow} />
                        </div>
                        <div className='mt-5'>
                            <p className='sm:text-[27px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>CRM isn't a one-point magic wand; it is a sensible, well-thought assimilation of <span className='font-bold border-b-[2px] border-[#F13623]'>cloud solutions</span> that make a complete system. And rightly so! To address the customer requirements at every touchpoint, you would need a series of tools that work together to enhance sales & productivity, support marketing, and build customer relationships.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5'>Given how comprehensive CRM is, it loops together cloud solutions for sales, e-commerce, marketing, database accumulation & other external sources for a constant customer evaluation.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={strategy_customer} />
                        </div>
                        <div className='mt-5'>
                            <p className='sm:text-[27px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>Have you ever wondered how CRM evolved? Take a look at this timeline:</p>
                        </div>
                        <div className='mt-5'>
                            <img src={over_years} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>Types of CRM & choosing the right one</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Research firm Gartner has aptly quoted that unless businesses make a well-planned blueprint for their CRM implementation, they will be part of the 50% failed implementations of CRM systems. The number of businesses relying on CRM systems for expansion & customer retention has been on the <span className='font-bold border-b-[2px] border-[#F13623]'>increase over the past few years</span>.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>To make the correct blueprint for the CRM implementation, one needs to know what types of Customer Relationship Management systems one can choose from.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Customer Relationship Management systems have both a philosophical intent and a functional aspect. The former denotes the company's strategy, culture, processes, workforce, and vision. The latter involves actual initiatives a company would take to build its customer relationship: research, data management & analytics, direct marketing, customer base reports, and communications.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Combining these two gives a holistic picture of the customer profiles, which further pushes better relationships & business. The needs of companies would vary from industry to industry and within the same industry depending on their product base & geographical locations. Broadly, a company can decide to operate one of the following Customer Relationship Management systems:</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Collaborative CRM</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>As the name suggests, this is about bringing together customer data for all relevant teams to view & utilize. Customer communication, purchase history, service requests, and other details are within access for all teams.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>So say, a support team gets some information then shared with the marketing team, enabling them to target the suitable customer profiles. When shared with the technical team, the same information gets them to address all issues faced by customers. The service department would know too when to approach a customer for various services needed and expedite the same.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>While doing this, the various teams together would handle the entire situation at the interaction and analysis levels. A track record of what was communicated to a customer and how it was communicated (email, phone, social media, etc.) helps build a base for analysis to decide which medium will be used for which customer.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Suppose that an organization wants to provide the best & most appropriate customer experience as a business. In that case, it will need to be on as many - and all- channels that existing & potential customers can use. Along with collaborating within the organization for your CRM system, it is vital to consider the distributors, wholesalers, retailers, and other agencies that partner with you in business expansion. The data you would get regarding leads for you to close deals and deliver results.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[25px] max-sm:text-[20px] max-sm:text-center'>Analytical CRM</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>With data analysis as the focus here, analytical CRM helps the top brass - management, sales, and other support staff - chalk out various ways of better customer service. Data from multiple sources is gathered for meaningful analysis of customers to gain better overviews and perspectives about how an organization functions.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The rippling effect is better decision making, better campaign management & effectiveness, improved sales & support, and an overall stronger customer relationship.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The perceptive analysis brings forward even the most subtle customer behavior patterns, thus helping a business base its future policies in the form of converting leads & forecast sales.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>When an organization draws the linkages between customer behavior and intent, it becomes easier to provide personalized customer experiences. Therefore, whatever the placement of the customer in the entire buying cycle, the organization would be ready to meet their expectations.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={operationnal_analytical} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>Which CRM type works in the real world?</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>In practice, most businesses depend on more than one type of CRM. For example, the analytical Customer Relationship Management process could provide insights integrated into operations CRM. The result would be well-targeted communication to customers based on their conditions.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Eventually, an organization will list its objectives, focus, goals, budget, and long-term strategy and then finalize its choice of CRM. A better approach would be to make a careful choice based on all this and adopt solutions that do not make you pay for features you are not using. A balanced mix of CRM strategies will work for most companies. A more prudent approach would be to take care of B2B and B2C ecosystems. No futuristic & ambitious organization can afford to neglect AI, machine learning abilities, and the database created. One needs to imbibe outsourced or mobile staff to handle this wide range of requirements.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>For starters, there could be just an efficient interaction, leading to better tracking of potential customers. Over time, as the customer base grows, there would have to be a system to automate both sales & service. The next step will be to analyze data, build accurate profiles, avoid duplication and keep correcting the previous records.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>While it may look tough, the reality is that systems need to be coordinated and synchronized between the marketing, sales, and service departments. This can happen when the database and go-to points are the same; there is active sharing of insights, leads, concerns, and purchase/interaction history. The net result is escalating productivity & efficiency, eliminating data gaps, and the path to working in an organization functional on efficient data management & insightful analysis. The customer stays on priority both in strategy & execution.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>When does an organization need CRM?</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Customer Relationship Management processes are problem-solving software systems. Are the sales figures showing a downward slide? Is there a considerable decline in the customer base? Does the growth graph reflect stagnancy? Time for the apt CRM to enter the show!</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Specifically, an organization will know it needs CRM when:</p>
                            <ul className='list-disc'>
                                <li className='sm:text-[27px] font-sans font-light mt-5 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>It is a struggle to maintain the business being generated, and potential, lucrative opportunities need to be tapped</li>
                                <li className='sm:text-[27px] font-sans font-light mt-5 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Data seems scattered, and it is tough to build customer profiles - details are incomplete, valuable profiles get lost, and follow-ups are shaky</li>
                                <li className='sm:text-[27px] font-sans font-light mt-5 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>There is a lack of coordination between different departments - and a subsequent discord between aspirations & plans</li>
                                <li className='sm:text-[27px] font-sans font-light mt-5 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>It seems complicated to build automated flawless reports, thus resulting in a lack of real-time, uncomplicated forecasting - time is being wasted on elaborate administrative tasks rather than the actual role of increasing sales</li>
                            </ul>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Over the years, several organizations have realized the value and importance of CRM and chosen to adopt the systems into their processes. Some organizations already working on an on-premise data management system have opted to switch over to efficient CRMs. This implies ‘picking up’ all data from existing systems and placing it over in the new one without losing data integrity or security. Such a shift is called CRM data migration.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={right_crm} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>What does CRM do for your business?</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Customer Relationship Management systems have brought about fundamental changes in the way businesses strategize and create structures. Earlier, managing performance was about checking documented records and analyzing them. With time, it became about digital empowerment - having the information, the data on the valuable & high-priority opportunities, the strategic planning on how to capitalize on specific leads, and the subsequent translation into the sales channel.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={your_business} />
                        </div>
                        <div className='mt-5'>
                            <p className='sm:text-[27px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>CRM:</p>
                            <ul className='list-disc'>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Answers all questions about customer data</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Organizes, manages, and interprets the available data to give a clear road map of the customer life cycle, including the vital impact points - leads are listed out more clearly</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Uses AI and analytical models to push the customer to the next step, personalize all campaigns and also encourage referrals from the existing customer base</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Automates the strategizing process of the sales, marketing, and services teams - and then, cross-functional teams drive the transformation of the business</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Creates transparency into performances - so adjustment, realignment of goals, and targeted training become possible, and the performance culture is more action-oriented</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='sm:text-[27px] font-sans font-light max-sm:text-[20px] max-sm:text-center'>In particular, as CRM turned to Cloud, there has been:</p>
                            <ul className='list-disc'>
                                <li className='sm:text-[27px] font-sans font-light mt-4 ml-[55px] sm:max-sm:text-[20px] max-sm:text-center'>A surge in cost-effective working through lesser hardware or storage costs</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 ml-[55px] sm:max-sm:text-[20px] max-sm:text-center'>Greater ease of scaling</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 ml-[55px] sm:max-sm:text-[20px] max-sm:text-center'>Enhanced collaboration, coordination & insightful futuristic planning</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 ml-[55px] sm:max-sm:text-[20px] max-sm:text-center'>The convenience of working from anywhere and having access to all data anytime, anywhere</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center' >What are the benefits of Customer Relationship Management?</h5>
                            <ul className='list-disc'>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Analytics helps develop a dynamic sales target</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>Data gets organized into systematic dashboards for assessment</li>
                                <li className='sm:text-[27px] font-sans font-light mt-4 sm:ml-[55px] max-sm:text-[20px] max-sm:text-center'>The work culture becomes more action-oriented</li>
                            </ul>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Organizations that have adopted data-driven growth can identify substantial growth opportunities from across the customer life cycle and plan more specific target-based campaigns. They follow an <span className='font-bold border-b-[2px] border-[#F13623]'>omnichannel path</span> where customer data gets aligned more precisely across all channels to attain maximum sales & conversion. Insights are more relevant; the capacity building is more robust and rigorously monitors progress across channels. The system improves holistically.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={benefits_crm} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold sm:text-[41px] max-sm:text-[20px] max-sm:text-center'>The latest trends to check out in your CRM system</h5>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>While opting for CRM, keep in mind these future trends will set one CRM system apart from others:</p>
                            <ul className='list-disc'>
                                <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Business Intelligence (BI) -</strong><span className='font-light'>for better data analysis and smarter decisions; new trends like customer persona, buying behavior, and market influences, thus facilitating multi-channel marketing campaigns.</span></li>
                                <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Cloud CRM-</strong><span className='font-light'>is not only affordable, has flexible solutions, is low maintenance, offers good integration & real-time analytics, and has greater efficiency.</span></li>
                                <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Multiple integration options -</strong><span className='font-light'>Modern CRMs that can provide you with a more significant number of integration options make your business workflows smooth. Multi-channel options aimed at refined compatibility & responsiveness add to the value of the CRM systems.</span></li>
                                <li className='sm:text-[23px] font-sans mt-4 sm:ml-[35px] max-sm:text-[20px] max-sm:text-center'><strong>Responsive content strategy - </strong><span className='font-light'>The in-depth analysis from CRM will be increasingly used to define Content strategy especially landing pages, emails, and blogs, creating a higher number of leads & sales. Social Media, content marketing, and mobile being integrated enhance productivity.</span></li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={software_trends} />
                        </div>
                        <div className='mt-5'>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>What works for you is also suitable for you at the end of the day. Choosing a CRM system is no exception to this rule of life.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>Bottom Line? A CRM must have agility and scope for continuous improvement. It should be able to keep data updated. Besides investing in complementary assets, it should integrate with other enterprise information systems.</p>
                            <p className='sm:text-[27px] font-sans font-light mt-5 max-sm:text-[20px] max-sm:text-center'>The underlying base behind all CRM is marketing psychology - the art of understanding how customers feel, think, and make decisions. It gives an organization an edge over its competition. And your choice of CRM will become your tool to walk towards that competitive edge in a more systematic & planned manner.</p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black sm:text-[48px] mt-5 max-sm:text-[20px] max-sm:text-center'>Featured Blogs</h5>
                <div className='sm:flex gap-5'>
                    <div className='sm:w-[31%] max-sm:[100%] shadow-2xl mt-5'>
                        <img src={determining_roi} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL TRANSFORMATION</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                        Maximizing Your Digital Dividends: How to Measure the ROI of Your Digital Transformation   </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:[100%] shadow-2xl mt-5'>
                        <img src={technology_solve} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL TRANSFORMATION</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        10 Problems that Healthcare Technology can Solve for a Healthier World   </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='sm:w-[31%] max-sm:[100%] shadow-2xl mt-5'>
                        <img src={digital_engineering} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className=''>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL TRANSFORMATION</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                        Understanding Digital Engineering: What it is, Implementation, Strategies, and Best Practices    </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerRelationship