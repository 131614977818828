import React from 'react'
import { Fragment, useState } from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

const Sidebar12 = () => {
    const [open, setOpen] = useState("");

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const [open2, setOpen2] = useState("");

    const handleOpen2 = (value) => {
        setOpen2(open2 === value ? 0 : value);
    };
    return (
        <>
            <div className='static'>
                <Fragment>
                    <Accordion open={open === 1}>
                        <div className='bg-[#F8FBFF] px-4 mt-2'>
                            <AccordionHeader onClick={() => handleOpen(1)}>
                                <h5 className="text-[21px] font-bold">Product Development</h5>
                            </AccordionHeader>

                        </div>
                        <AccordionBody className="mt-[-24px]">
                            <Fragment>
                                <Accordion open={open === 1 && open2 === 9}>
                                    <div className='bg-[#E6E9F0]'>
                                        <AccordionHeader onClick={() => handleOpen2(9)}>
                                            <div className='flex ml-5'>
                                                <i className="fa fa-angle-right !text-[20px]" aria-hidden="true"></i>
                                                <h5 className="text-[19px] font-bold  ml-3.5 hover:text-[#F13623]">Custom Software Development</h5>
                                            </div>
                                        </AccordionHeader>
                                    </div>
                                    <AccordionBody className=" bg-[#F8FBFF]">
                                        <div className='pl-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">What is Custom Software?</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Difference Between Custom Software and COTS</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Reasons for Choosing Custom Software Development</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Factors to Consider When Building Custom Software</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Custom Software Development Process</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Advantages of Custom Software Development</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Custom Software Development Best Practices</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Custom Software Development FAQs</Link>
                                        </div>
                                        <div className='pl-4 mt-4'>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623]">Conclusion</Link>
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                                <div className='bg-[#E6E9F0]'>
                                    <ul>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Agile Software Development </Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Cloud Computing</Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">DevOps</Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Minimum Viable Product</Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Mobile App Development</Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Outsourcing</Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Software as a Service (SaaS)</Link></p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-4'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'><Link to={""} className="hover:text-[#F13623]">Software Testing</Link></p>
                                        </li>
                                    </ul>
                                </div>
                            </Fragment>
                        </AccordionBody>
                        <Accordion open={open === 2}>
                            <div className='bg-[#F8FBFF] px-4 mt-2'>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    <h5 className="text-[21px] font-bold">Digital Commerce</h5>
                                </AccordionHeader>
                            </div>
                            <AccordionBody className=" bg-[#F8FBFF]">
                                <div>
                                    <ul>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'>B2B eCommerce</p>
                                        </li>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623] mt-3'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'>Magento</p>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionBody>
                        </Accordion>
                        <Accordion open={open === 3}>
                            <div className='bg-[#F8FBFF] px-4 mt-2'>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    <h5 className="text-[21px] font-bold">Experience Design</h5>
                                </AccordionHeader>
                            </div>
                            <AccordionBody className=" bg-[#F8FBFF]">
                                <div>
                                    <ul>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <p className='ml-3'>User Experience</p>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionBody>
                        </Accordion>
                        <Accordion open={open === 4}>
                            <div className='bg-[#F8FBFF] px-4 mt-2'>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    <h5 className="text-[21px] font-bold">Digital Experience Platforms</h5>
                                </AccordionHeader>
                            </div>
                            <AccordionBody>
                                <div>
                                    <ul>
                                        <li className='flex pl-4 font-semibold text-[16px] hover:text-[#F13623]'>
                                            <div className='text-[22px]'>
                                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                                            </div>
                                            <Link to={""} className="font-semibold text-[16px] hover:text-[#F13623] ml-3">Customer Relationship Management (CRM)</Link>
                                        </li>
                                    </ul>
                                </div>
                            </AccordionBody>
                        </Accordion>
                    </Accordion>

                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="text-[21px] font-bold "><Link to={""} className="hover:text-[#F13623]">Digital Transformation</Link></h5>
                    </div>
                    <div className='bg-[#F8FBFF] px-4 py-4 mt-2'>
                        <h5 className="text-[21px] font-bold hover:text-[#F13623]"><Link to={""} className="hover:text-[#F13623]">Data Analytics & Insights</Link></h5>
                    </div>
                </Fragment>
            </div>
        </>
    )
}

export default Sidebar12