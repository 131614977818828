import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  blockchain_banner,
  Blockchain_Solutions1,
  Blockchain_sol2,
  Blockchain_sol3,
  Blockchain_sol4,
  Blockchain_sol5,
  Blockchain_sol6,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  ChatGPT,
  Digital_tra1,
  Hiring,
} from "../../assets";
import { Link } from "react-router-dom";
const MachineLearning = () => {
  return (
    <>
      <div className="container">
        <div className="flex mt-4">
          <div className="w-[50%]">
            <div className="mt-[160px]">
              <p className="text-3xl">
                <b>Offering Quality AI & Machine Learning Solutions</b>
              </p>
              <p className="mt-2 mb-2">
                Evolve your venture and experience true advancement through our
                esteemed AI and machine learning solutions.
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <img src={aimlimg}></img>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <p className="text-4xl">
              <b>Get Equipped with Latest Technologies</b>
            </p>
            <p className="mt-4">
              The modern market has many complex facets, which are equally
              integral to stay ahead of the cult. While integrating the latest
              technologies is a beneficial step, not comprehending its full
              potential is severely dangerous. AI and machine learning have come
              a long way in recent years, and you can implement these
              advancements within your system as well. Just contact RevInfotech
              and make a breakthrough in the industry of tech. With our
              automation solutions, your business can save vital resources and
              time. Face the unpredictable future with confidence and stay ready
              for any market shift. Avail RevInfotech and truly enhance the
              capabilities of your venture.
            </p>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AR and VR</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                    Enterprise Mobility
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-5">
        <div className="text-center text-white p-5">
          <p className="text-3xl">
            Take Off Unnecessary Burden and distribute it evenly with the
            Machines
          </p>
        </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Quality Services & Benefits
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BEST STRATEGIES
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Merely equipping AI in your proceedings won’t do your business
                any good. Make optimal use of the technology to devise the best
                strategies with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BUSINESS PLANNING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Do not take unwarranted steps on a whim. Let us research the
                market and prepare thorough plans for your next big business
                step.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                DATA SECURITY
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Secure your consumer data with the latest blockchain technology
                and win the trust of your consumers. Never fall back with
                Rapidsofts.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Get Premium Benefits
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                DATA OPTIMIZATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                As your organization grows, the wastage of data and valuable
                resources increases as well. With our AI solutions you can
                minimize your wastage and optimally use the dormant data.
                Improve your productivity with our data optimization services.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                EXTENSIVE BUSINESS INSIGHTS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Not understanding your business capabilities fully can render
                your resources useless. Allow us to research your venture and
                know how it works. With RevInfotech, you can get an extensive
                knowledge of your business insights.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVED CX
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                Customer experience is pivotal for an organization’s success. An
                unhappy customer means your system has failed to perform its
                job. Do not let such failure ruin your reputation and allow our
                AI solutions to improve your CX to its fullest potential.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                RESULT PREDICTIONS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Our AI solutions are capable of making calculated predictions
                for your business. With our AI algorithms, you can deduce the
                results and undergo viable shifts to make them desirable. Keep
                up with the market and grow optimally with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                FACILITATE AUTOMATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                You can only go so far with manual labor and assistance. Allow
                us to introduce the magic of automation to your system. We can
                integrate seamless automation within your proceedings to carry
                out tedious tasks easily and securely.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
              How AI and Machine Learning can Help You?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              The times where mere human resources were able to assure a firm’s
              growth are gone. With changing times, the need for automation has
              massively increased. This need has led to the emergence of aspects
              like AI and ML. Both AI and ML paves the path of advancement and
              automation for an organization. When you integrate smart
              automation within your venture, you experience numerous business
              advantages you never knew existed. So avail the benefits today
              with RevInfotech and reach the top.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
              Why Pick RevInfotech for AI and ML Solutions?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              Only knowing the benefits of AI and ML won’t do your business any
              good. You need to make the most out of the tech and RevInfotech
              can help you achieve the same with ease. Our experts have years of
              knowledge and experience under their belt, allowing them to
              perceive the market from a different perspective. Their shrewd
              skill set allows them to understand every current and upcoming
              trend. Avail RevInfotech to witness the marvel of advancement. Let
              us bring all your worries to a halt.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
            March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
              Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra1} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full"/>
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MachineLearning;
