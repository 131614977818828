import React from "react";
import {
  food_beverage,
  ordering_mobile,
  store_employee,
  track_produce,
  assisting_customer,
  boxing_produce,
  timg,
  scoff,
  scoff_mobile,
  scoff_mobile_2,
  yimg,
  zgbms,
} from "../../assets";

const FoodBeverage = () => {
  return (
    <>
      <div className="container mx-auto">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[170px] max-sm:mt-[80px]">
              <h1 className="sm:text-[22px] text-[#F13623] tracking-widest font-extrabold max-sm:text-[20px] max-sm:text-center">
                FOOD APP DEVELOPMENT COMPANY
              </h1>
              <h1 className="sm:text-[77px] font-black max-sm:text-[20px] max-sm:text-center">
                Engage Audiences Hungry for a Better Digital Experience with
                Expert Food & Beverage App Solutions
              </h1>
              <p className="sm:text-[33px] font-extralight max-sm:text-[20px] max-sm:text-center">
                Transform your business with customer-focused food app
                development.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 float-right sm:mt-[150px] max-sm:mt-[50px] sm:w-[450px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with Food & Beverage App Development?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500  h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-[#F9D94E] sm:w-[95%] max-sm:w-[100%] ml-auto sm:px-32 sm:pt-24 mt-5 flow-root">
        <div>
          <img src={food_beverage} />
        </div>
        <div>
          <div className="border-l-8 border-sky-400 relative top-[-69px] ml-5">
            <div className="sm:pl-8">
              <p className="pt-28 font-black text-[20px] text-[#F13623] tracking-widest max-sm:text-[20px] max-sm:text-center">
                THE Rapidsofts APPROACH
              </p>
            </div>
          </div>
          <div className="sm:px-16 top-[-64px] sm:w-[60%] max-sm:p-2">
            <h5 className="sm:text-[46px] font-extrabold mt-[-60px] max-sm:text-[20px] max-sm:text-center">
              UX-driven Digital Software Solutions for the Food & Beverage
              Industry
            </h5>
            <p className="sm:text-[25px] font-light max-sm:text-[20px] max-sm:text-center">
              To achieve business success in the ultra-competitive, strictly
              regulated food and beverage industry, leading-edge technology must
              be on the menu. For more than two decades, we’ve provided
              world-class software development services for all types of
              businesses, from restaurants to distributors, manufacturers, and
              vendors that prioritize efficiency, quality, and compliance while
              satisfying ever-changing consumer needs.
            </p>
            <div className="max-sm:text-center">
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
              >
                Explore Services
              </button>
              <span className="sm:ml-7 mt-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white mb-16 max-sm:ml-5"
                >
                  Request a Quote
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-5">
        <div className="sm:pt-4">
          <h5 className="font-bold sm:text-[55px] max-sm:text-[20px] max-sm:text-center">
            Food App Development Services
          </h5>
        </div>
        <div className="sm:flex flex-row sm:pt-20 max-sm:pt-5">
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Custom Food Service Software
            </h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              The food and beverage industry has unique constraints, from safety
              regulations to tight time frames. Our experienced food app
              development team, including expert business analysts, UX
              specialists, designers, and developers, has expertise in building
              custom food service management software that streamlines
              operations, eliminates waste, and integrates essential functions,
              including Point of Sale (POS) and IoT.
            </p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className="sm:w-[50%] max-sm:w-[100%] ml-auto">
            <img src={ordering_mobile} className="h-[502px]" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[50%] max-sm:w-[100%]">
            <img src={store_employee} className="h-[495px]" />
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%] ml-auto">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              Food Distribution Software
            </h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              Whether you’re a B2B or a B2C vendor, every point in your food
              distribution chain is critical. As a leading food delivery app
              development company, we’ll help you safeguard your inventory and
              reputation with a custom, purpose-built distribution software
              system featuring advanced tools specially designed to help you
              manage and scale your business.
            </p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[40%] max-sm:w-[100%] mt-4">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              ERP Development & Integration
            </h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              Meeting your business objectives in the multi-faceted food and
              beverage industry can be complicated — but a robust ERP system can
              simplify your operations and illuminate new opportunities. Our
              expert engineers develop customized ERP systems that deliver
              critical, real-time insights into pricing, quality, and more to
              help your team optimize operations and make better decisions.
            </p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className="sm:w-[50%] max-sm:w-[100%] ml-auto">
            <img src={track_produce} className="h-[502px]" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[50%] max-sm:w-[100%]">
            <img src={assisting_customer} className="h-[495px]" />
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%] ml-auto">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">Customized CRM</h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              To survive and thrive in one of the world’s most competitive
              industries, relationships are the lifeblood of any food and
              beverage business. Our food app development company employs
              state-of-the-art tools, including AI and automation, to create a
              customized CRM that helps increase customer retention and loyalty
              and supports enhanced relationships with vendors and suppliers.
            </p>
            <button
              type="button"
              className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
        <div className="sm:flex flex-row pt-20">
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">eCommerce Solutions</h5>
            <p className="font-light sm:text-[21px] mt-4 max-sm:text-[20px] max-sm:text-center">
              Empower your customers to order meals or groceries with a click,
              and win their loyalty with a flawless customer experience,
              including speedy delivery of the products they want and need the
              most. We specialize in on-demand food delivery app development and
              food ordering platform development, featuring multi-channel
              support (B2B, B2C, DTC), personalization, and smart POS
              integrations that encourage ongoing product innovation.
            </p>
            <button
              type="button"
              className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
          <div className="sm:w-[50%] ml-auto">
            <img src={boxing_produce} className="h-[502px]" />
            <button
              type="button"
              className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] sm:mt-5 hover:bg-[#F13623] hover:text-white"
            >
              Request a Quote
            </button>
          </div>
        </div>
      </div>
      <div className="sm:flex flex-row pt-20 w-[79%] mx-auto">
        <div className="sm:ml-3">
          <div className="">
            <img src={yimg} className="w-full object-cover"></img>
          </div>
        </div>
        <div className="sm:ml-3">
          <div className="">
            <img src={zgbms} className=" w-full object-cover"></img>
          </div>
        </div>
        <div className="sm:ml-3">
          <div className="">
            <img src={timg} className="w-full object-cover"></img>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-5">
        <h5 className="sm:text-[56px] font-bold max-sm:text-[20px] max-sm:text-center">Success Stories</h5>
        <div className="sm:px-16">
          <div className="mt-5">
            <img src={scoff} />
          </div>
          <div className="mt-5 sm:w-[65%]">
            <h5 className="font-semibold sm:text-[32px] max-sm:text-[20px] max-sm:text-center">
              We Build Innovative Mobile Platforms for the Food Industry
            </h5>
            <p className="font-light text-[21px] mt-4">
              See how we built a unique on-demand food delivery app that
              generates equal opportunities for both food sellers and buyers and
              seamlessly handles logistics for both ends of the equation.
            </p>
          </div>
          <div className="mt-5 w-[30%] mx-auto">
            <img src={scoff_mobile} />
          </div>
          <div className="mt-5 w-[30%] mx-auto">
            <img src={scoff_mobile_2} />
          </div>
        </div>
        <div className="mt-5">
          <h5 className="sm:text-[44px] font-extralight text-center max-sm:text-[20px] max-sm:text-center">
            Have a Food & Beverage App Project in Mind?
          </h5>
          <div className="w-[160px] mx-auto">
            <button
              type="button"
              className="border-[2px]  border-red-400 px-3 py-2 font-bold text-white text-[17px] mt-5 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
            >
              Let's Talk
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FoodBeverage;
