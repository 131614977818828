// import React from 'react'
import {
  digitalexpplatform3,
  xaimg,
  ascend1,
  ttimg,
  GoogleCloudSQL,
  digitalcustomerexperiencestrategy,
  Digital_tra2,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  xbimg,
  GWA1m,
  flimskillm,
  acomplete,
  Thumbnail,
  Thumbnail1,
  netsolutions,
  dxp,
  trish,
  zimg,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const DigitalExperience = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[90px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                DIGITAL EXPERIENCE DEVELOPMENT: PLATFORM SOLUTIONS & SERVICES
              </h1>
              <h1 className="xl:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center max-sm:mt-2">
                The Right Platform Delivers the Right Experience for the Results
                You Need.
              </h1>
              <p className="xl:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                Win higher engagement, conversion & retention with relentlessly
                user-focused digital platform solutions.
              </p>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Need Help with a Digital Platform?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px] max-sm:text-[15px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={dxp} className="w-full sm:h-[600px]" />
          <div className="container">
            <div className="sm:mt-[70px] max-sm:mt-[30px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>We Make Your Customer the Hero of Your Brand’s Story</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                The distance between your brand and your audience is bridged by
                your digital experience platform — a dynamic pathway we design
                and build to deliver invaluable interactions. With advanced
                digital experience development expertise, we create mobile-first
                platforms that equally prioritize your business goals and your
                users’ desires, habits and preferences.
              </p>
              <p className="sm:text-3xl sm:mt-5 max-sm:mt-3 max-sm:text-[20px] max-sm:text-center">
                The resulting solutions are both elegant in their simplicity and
                powerful in their ability to respond to your audience’s needs
                and challenges.
              </p>
              <div className="flex mt-5 max-sm:mb-2">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  hover:bg-[#F13623] hover:text-white h-[50px] max-sm:text-[10px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4 max-sm:text-[10px]"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[30px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[10px]">
            <div className="sm:w-[50%] w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Digital Customer Experience Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                From detailed persona development and empathetic journey mapping
                to a deep knowledge of which platforms and approaches are best
                to power your portals, websites and apps for best-case customer
                and employee engagement, we plot precise strategies to capture
                user attention and convert it into action.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px] max-sm:mt-[40px]"
                ></img>
                 <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[100px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[20px]">
                  Customized Digital Customer Experience Platforms
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Today’s technology has infinite potential, but it takes a
                  seasoned experience commerce digital agency to maximize every
                  stage of development and deployment. We’re experts in
                  designing a full-spectrum of digital platform solutions,
                  including enterprise portal development, digital publishing,
                  social intranets, third-party app integration and more.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-center max-sm:mt-[20px]">
                Digital Customer Experience Services
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-center max-sm:mt-[20px]">
                From detailed persona development and empathetic journey mapping
                to a deep knowledge of which platforms and approaches are best
                to power your portals, websites and apps for best-case customer
                and employee engagement, we plot precise strategies to capture
                user attention and convert it into action.
              </p>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
              </div>
            </div>
          </div>
          <div className="sm:flexsm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-center max-sm:mt-[20px]">
                  Ongoing Improvements
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-center max-sm:mt-[20px]">
                  It’s called “launch” for a reason — making your platform live
                  is just the starting point. To get the maximum mileage from
                  your digital customer experience platform(s), we’re here to
                  help you continually maintain, monitor and measure
                  performance, so you don’t miss an opportunity to further
                  optimize the customer journey.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="flex mt-[100px]">
            <div className="ml-3">
              <div className="">
                <img
                  src={ttimg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="ml-3">
              <div className="">
                <img src={zimg} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="ml-3">
              <div className="">
                <img
                  src={trish}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <div className="w-[90%] m-auto">
            <div className="mt-[100px]">
              <p className="sm:text-6xl max-sm:text-center max-sm:mt-[20px]">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] sm:ml-5 max-sm:w-[100%]">
                <div className="mt-5">
                  <img
                    src={ascend1}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-center max-sm:mt-[20px]">
                    <b>
                      HBRHBR Case Study We Innovate Smart Technological
                      Solutions for Engaging User Experiences
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-center max-sm:mt-[20px]">
                    Learn how we helped Harvard Business Review launch a
                    mobile-first, internationally focused platform for young
                    managers in just six weeks.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] sm:ml-5 max-sm:w-[100%]">
                <div className="mt-5">
                  <img
                    src={flimskillm}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-center max-sm:mt-[20px]">
                    <b>
                      FilmSkillsFilmSkills Case Study We Develop Intuitive
                      Interactive Experiences for Successful Training
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-center max-sm:mt-[20px]">
                    See how we rescued an online-course platform and developed
                    it into a fun, engaging interactive destination for a
                    prominent film-industry training program.
                  </p>
                  <img
                    src={GWA1m}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-center max-sm:mt-[20px]">
                    <b>
                      GWA Group Case StudyRead Case Study We Create Seamless
                      Multi-platform Digital Experiences
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-center max-sm:mt-[20px]">
                    Explore how our Agile Scrum methodology helped us
                    effectively upgrade, streamline and optimize several legacy
                    websites for a leading construction company.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[100px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center pt-20 max-sm:text-center max-sm:mt-[20px]">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      DIGITAL EXPERIENCE PLATFORMS
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:mt-[20px]">
                      A Complete Guide To Content Management Systems in 2023
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      DIGITAL EXPERIENCE PLATFORMS
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:mt-[20px]">
                      Composable Architecture: Why It’s Important and How to Get
                      Started
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, REMOTE
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:mt-[20px]">
                      Digital Customer Experience Management: Strategy, Value to
                      Business, and Best practices
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalExperience;
