// import React from 'react'
import {

  zgaga,
  zzimg,
  imgs,
  ascend1,
  Hiring,
  GoogleCloudSQL,
  digitalcustomerexperiencestrategy,
  Digital_tra2,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  digitalexpplatform,
  GWA1m,
  ready,
  acomplete,
  Thumbnail,
  Thumbnail1,
  arete,
  lauren,
  fitnes,
  zScreenshots,
  vnimg,
  zrpid,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const MobileApp = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[90px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                LEADING-EDGE MOBILE APP CONSULTING
              </h1>
              <h1 className="xl:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center max-sm:mt-2">
                Accelerate Your Success With a Customized Mobile Application
              </h1>
              <p className="xl:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                We design and deploy transformative mobile app solutions that
                future-proof your business.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking for Mobile App Consulting?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-500 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px] max-sm:text-[15px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img src={zScreenshots} className="w-full object-cover" />
          <div className="container">
            <div className="sm:mt-[70px] max-sm:mt-[30px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Full-Spectrum Mobile App Consulting</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                In our digital age, every on-the-go touchpoint factors into your
                business success. As a leading app development consultant, we
                specialize in designing and deploying enterprise mobile
                applications. Our team of analysts, UX specialists, designers,
                and developers have extensive industry and vertical expertise
                that they bring to the creation of your custom digital strategy
                and mobile app roadmap. The result is a next-level mobile
                experience that delights users, ignites opportunities, and
                supports results-driving digital transformation.
              </p>
              <div className="flex mt-5 max-sm:mb-2">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px]  hover:bg-[#F13623] hover:text-white h-[50px] max-sm:text-[10px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4 max-sm:text-[10px]"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[30px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Our Mobile App Development Consulting Services</b>
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%]  max-sm:text-center">
            With more than 15 years of mobile development consulting, we provide
            an array of services from initial app audits/analysis to strategic
            modernization planning and all aspects of designing and developing
            mobile apps from scratch. With the help of our expert mobile app
            consulting team, we empower our clients with a custom strategy that
            earns engagement and loyalty — and sharpens their competitive edge.
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-center">
                Mobile App Strategy Design
              </h4>
              <p className="text-[22px] font-light pt-4">
                Plotting out the app itself is just one part of the solution;
                ensuring your mobile app is integrated into your overall
                business and enterprise strategy is critical. We help you
                analyze and evaluate all mobile uses of your app or platform to
                ensure that it’s cost-effective, efficient, and user-friendly.
              </p>
              <button
                type="button"
                className=" max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px]"
                ></img>
                 <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[100px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Mobile App Audit & Optimization
                </h4>
                <p className="text-[22px] font-light pt-4">
                  Your business performance is tightly aligned with your mobile
                  app performance. Our app development consultants are experts
                  in a variety of niche audits, including:
                </p>
                <ul className="mt-4 list-disc">
                  <li>UX/UI</li>
                  <li>Code</li>
                  <li>Security</li>
                  <li>Compliance</li>
                  <li>Compatibility (device, platform)</li>
                </ul>
                <p className="text-[22px] font-light pt-4">
                  Once we’ve determined obstacles to progress and opportunities
                  for improvements, we create and execute a custom plan to
                  optimize your apps for better outcomes and ROI.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-5">
                Mobile App Development
              </h4>
              <p className="text-[22px] font-light pt-4">
                Having been at the forefront of mobile app development since the
                dawn of the mobile app (2007), we have experience and expertise
                in designing and deploying a bespoke solution that supports your
                company’s needs in innovative ways. And we ensure that every
                angle — from the back end to the front end and every point in
                between — is optimized for speed, usability, conversion rate,
                and performance.
              </p>
              <button
                type="button"
                className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-5">
                  Ongoing Improvements
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-center max-sm:text-[15px]">
                  It’s called “launch” for a reason — making your platform live
                  is just the starting point. To get the maximum mileage from
                  your digital customer experience platform(s), we’re here to
                  help you continually maintain, monitor and measure
                  performance, so you don’t miss an opportunity to further
                  optimize the customer journey.
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="ml-3">
              <div className="">
                <img
                  src={vnimg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="ml-3">
              <div className="">
                <img src={zrpid} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="ml-3">
              <div className="">
                <img
                  src={zgaga}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          {/* <div className="mt-16 w-[50%] mx-auto">
            <img src={xomi} className="h-[700px]" />
          </div> */}
          <div className="w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex flex-wrap gap-5">
              <div className="sm:w-[48%] max-sm:w-[100%] drop-shadow-lg">
                <div className="mt-5 hover:animate-pulse">
                  <img src={fitnes} className=" object-cover sm:w-[500px] sm:h-[550px]"></img>
                  <div className="bg-[#EAEBEC] grid sm:h-[250px] max-sm:block">
                    <div className="ml-5 mr-5">
                      <p className="mt-3">
                        <b>Grown Strong App</b>
                      </p>
                      <p className="text-sm">wealth of healthy
                        recipes, meal plans, and nutritional</p>
                      <p className="text-lg mt-4">
                        <b>Fueling your body with the right nutrition is essential
                          for overall well-being</b>
                      </p>
                      <button
                        type="button"
                        className="max-sm:mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-4 mb-5"
                      >
                        Read Case Study
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-[48%] max-sm:w-[100%] drop-shadow-lg max-sm:mt-[50px]">
                <div className="mt-5 hover:animate-pulse">
                  <img src={lauren} className=" sm:w-[500px] sm:h-[550px]"></img>
                  <div className="bg-[#EAEBEC] grid sm:h-[250px max-sm:block]">
                    <div className="ml-5 mr-5">
                      <p className="mt-3">
                        <b>Lauren App</b>
                      </p>
                      <p className="text-sm">Fitness & Training</p>
                      <p className="text-lg mt-4">
                        <b>Lauren App provides expert to
                          helping you make informed choices to
                          support your fitness goals</b>
                      </p>
                      <button
                        type="button"
                        className="max-sm:mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[23px]"
                      >
                        Read Case Study
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-[48%] max-sm:w-[100%] drop-shadow-lg  max-sm:mt-[50px]">
                <div className="mt-1 hover:animate-pulse">
                  <img src={arete} className="w-100 sm:h-[550px] object-cover"></img>
                  <div className="bg-[#EAEBEC] grid sm:h-[250px] max-sm:block">
                    <div className="ml-5 mr-5">
                      <p className="mt-3">
                        <b>Arate</b>
                      </p>
                      <p className="text-sm">AUTOMOTIVE</p>
                      <p className="text-lg mt-4">
                        <b>
                          Multi-channel eCommerce Solution for One of IRUK'S Top 500
                          Retailers
                        </b>
                      </p>
                      <button
                        type="button"
                        className="max-sm:mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[53px] mb-5"
                      >
                        Read Case Study
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:w-[48%] max-sm:w-[100%] drop-shadow-lg  max-sm:mt-[50px]">
                <div className="mt-1 hover:animate-pulse">
                  <img src={ready} className="w-100 sm:h-[555px] object-cover"></img>
                  <div className="bg-[#EAEBEC] grid sm:h-[243px] max-sm:block">
                    <div className="ml-5 mr-5">
                      <p className="mt-3">
                        <b>THE READY STATE</b>
                      </p>
                      <p className="text-sm">OPTIMIZING YOUR MOVEMENT</p>
                      <p className="text-lg mt-4">
                        <b>
                          This app is designed to help you achieve a
                          state of readiness and physical condition.
                        </b>
                      </p>
                      <button
                        type="button"
                        className="max-sm:mb-3 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-[25px]"
                      >
                        Read Case Study
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px]">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      DIGITAL EXPERIENCE PLATFORMS
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      A Complete Guide To Content Management Systems in 2023
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      DIGITAL EXPERIENCE PLATFORMS
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      Composable Architecture: Why It’s Important and How to Get
                      Started
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, REMOTE
                    </p>
                    <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                      Digital Customer Experience Management: Strategy, Value to
                      Business, and Best practices
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileApp;
