import React from 'react'
import { magento_banner, quadrant, popular, magento_benefits, pricing, choice,variants,Thumb,developers} from '../../assets';
import { Link } from "react-router-dom";
import Sidebar9 from '../../componets/sidebar/Sidebar9'

const Magento = () => {
    return (
        <>
            <div className='container mx-auto'>
                <div className='w-[400px] h-[500px] mt-[68px] fixed overflow-auto'>
                    <Sidebar9 />
                </div>
                <div className='ml-[420px]'>
                    <ul className='flex pt-[85px]'>
                        <li>
                            <Link to={""} className="font-medium text-[18px]" >Home</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div >
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Home Knowledge Hub</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Digital Commerce</Link>
                        </li>
                        <li className='mt-0.5 ml-3'>
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className='ml-3'>
                            <Link to={""} className="font-medium text-[18px]">Magento</Link>
                        </li>
                    </ul>
                    <div style={{ backgroundImage: `url(${magento_banner})` }} className="w-full h-[350px] bg-center bg-cover mt-5">
                        <div className='pl-10 pt-16 w-[37%]'>
                            <h5 className='font-extrabold text-[41px] text-white'>The Ultimate Guide to Magento eCommerce Platform</h5>
                        </div>
                    </div>
                    <div className='px-10 mt-5'>
                        <div>
                            <p className='text-[27px] font-sans font-light mt-5'>An eCommerce platform is a foundation software that offers a medium to buy and sell products online. <span className='font-bold border-b-[2px] border-[#F13623]'>Choosing an eCommerce platform</span> is the first step towards kick-starting an online business. There are many eCommerce platforms to choose from, but if the business would benefit from a flexible eCommerce platform that offers control over the look and functionality of the online store, Magento is the right choice.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight'>Adobe Analytics data shows that, in March, overall eCommerce in the U.S. was up 25% At an aggregate level, we have seen site traffic for Magento Commerce merchants globally increase by an average of 30% during that same period. <span className='text-[#48DDEF] font-bold'> Magento</span></p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <img src={quadrant} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>What is Magento?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Magento, developed by Varien Inc., a US private company, is an open-source digital commerce platform written in PHP. The software solution was first publicly released on March 31, 2008, and was later acquired by Adobe for $1.65 billion USD in 2018.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>A CMS (<span className='font-bold border-b-[2px] border-[#F13623]'>Content Management System</span>) platform is a “build it yourself” solution where the user gets the leverage to customize the platform’s look and functionality to offer a unique customer experience. Magento is hosted on-premise or through cloud hosting services such as <span className='font-bold border-b-[2px] border-[#F13623]'>AWS, Azure, or Google Cloud</span>.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight'>On average, merchants using Magento grow 3X faster than those on competitive platforms. They also realize a 50% reduction in Total Cost of Ownership. <span className='text-[#48DDEF] font-bold'> Magento</span></p>
                            </div>
                        </div>
                        <div>
                            <p className='text-[27px] font-sans font-light mt-5'>To set- up and maintain a Magento eCommerce store, a business needs to hire skilled developers to help them through the process. It would help to know how to hire a Magento developer too. This means that associated costs will be higher, however, it will help introduce the required level of customizations while offering access to many out-of-the-box features.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>The code is made available universally and developers can make modifications to suit the business needs. Furthermore, to ease personalized implementations, Magento uses PHP frameworks such as Symphony and Laminas to provide code libraries for commonly used functions.</p>
                        </div>
                        <div className='mt-5'>
                            <img src={popular} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>What is Magento 2?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Magento 2 is the latest operating version of Magento in the market, launched on November 17, 2015. Adobe ended support for its predecessor (i.e., Magento 1) on June 30, 2020. All platforms operating on Magento 1 were suggested to <span className='font-bold border-b-[2px] border-[#F13623]'>migrate to Magento 2</span> which offers newer technologies and more advanced functionalities to experiment with.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>The <span className='font-bold border-b-[2px] border-[#F13623]'>migration from Magento 1 to Magento 2 </span> is a step-by-step process that the brands need to follow to enjoy the benefits that the latest version brings in. Brands such as Land Rover, Pino’s, and Tile Giant have successfully made the shift to avoid the vulnerabilities of the Magento 1.</p>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight'>According to BuiltWith, 165,530 websites are Magento 2 customers. <span className='text-[#48DDEF] font-bold'>BuiltWith</span></p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[27px] font-sans font-light mt-5'>Some of the noteworthy benefits of shifting to Magento 2 include:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Improved Performance and Scalability</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Enhanced Security</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Improved Checkout Process</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Use B2C strategies for B2B growth</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Advanced Reporting</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Mobile-friendly and responsive design</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <img src={magento_benefits} />
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Magento: Service Levels</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>There are two service levels offered by Magento, including:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Magento Open Source</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Magento Open Source is a self-hosted eCommerce platform that offers basic and essential features for free. This service level suits both small businesses with basic needs and large-scale eCommerce businesses looking forward to creating a prototype for testing innovative ideas.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Magento Commerce</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>On the other hand, Magento Commerce is an enterprise-level eCommerce platform that offers advanced features and functionalities. It is considered an all-in-one PaaS <span className='font-bold border-b-[2px] border-[#F13623]'>cloud solutions</span> that enables customizations and third-party integrations.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>This service level is suitable for <span className='font-bold border-b-[2px] border-[#F13623]'> B2B and B2C eCommerce businesses</span> and supports selling across geographies while supporting multiple currencies. The latest version of Magento Commerce (i.e., Magento Commerce 2.4.2) was released in May of 2021.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>What is Adobe Commerce Cloud?</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Adobe Commerce Cloud is a fully-managed cloud service model that is part of Magento. It offers the best digital commerce capabilities and Adobe tools integration (Analytics Cloud, Marketing Cloud, and Advertising Cloud).</p>
                            <p className='text-[27px] font-sans font-light mt-5'>In this new service model, Adobe will take care of the operational part of the eCommerce platform allowing the business owners the ability to focus on their core business entirely.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>The best part about Adobe Commerce Cloud is its Amazon integration ability. The business will be able to cherry-pick the products they want to be displayed on the Amazon platform along with customized pricing.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Magento: Service Levels</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Magento is a perfect choice for <span className='font-bold border-b-[2px] border-[#F13623]'>digital commerce</span> businesses looking forward to introducing out-of-the-box customizations as well as having access to a Magento solution and technology partner.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>The noteworthy Magento benefits include:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>Abundant Customizations Through Magento Marketplace</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Magento offers a diverse collection of features and customizations to its Magento Commerce customers. The demand to expand and scale a Magento store increases with growing business and access to the extensive feature list aids in this process.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>For instance, Magento Marketplace supports numerous extensions (some are free) for the store owners. The business can simply buy and install the needed extension from the Magento Marketplace.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>Customer Support</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>For Magento open source, the business has access to the Magento community that supports developers from across the globe. On the other hand, Magento Commerce provides direct customer service. In this service level, a business can raise tickets, call, or even email their queries. Magento customer support offers quick and meaningful responses.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>Headless Commerce Capability</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'> <span className='font-bold border-b-[2px] border-[#F13623]'>Headless commerce</span> is the separation of the backend and frontend layers of the eCommerce platform. This implies that changing the application's front-end does not affect the backend, thus making it easy to introduce customizations.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Magento offers headless commerce support in the form of:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Decoupled storefront in PWA studio</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Robust REST and GraphQL APIs to build custom touchpoints</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Store integrations with Adobe Experience Manager</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Better Security</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'> Magento provides two-factor authentication (2FA) for improving the security of the Magento Admin portals across devices. However, the 2FA support is only available for Magento admin control and not for customer accounts.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The multiple authenticators supported by Magento include:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Google Authenticator</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Authy</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Duo</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>U2F Keys</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Magento vs Other eCommerce Platforms</h5>
                        </div>
                        <div className='px-32 mt-5'>
                            <div className='px-16 py-8 border-l-8 border-yellow-400'>
                                <p className='text-[31px] font-extralight'>The global retail eCommerce sales are projected to grow by about 85% from USD 3.53 trillion in 2019 to as high as USD 6.54 trillion in 2022. <span className='text-[#48DDEF] font-bold'>Statista</span></p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <p className='text-[24px] font-sans	font-light mt-4 '>Apart from Magento, there are other eCommerce platforms in the market such as Shopify, BigCommerce, WooCommerce, PrestaShop, and Wix.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 '>So, how does a business choose the right eCommerce platform for them?</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>How to Hire a Magento Developer?</h5>
                            <p className='text-[24px] font-sans	font-light mt-4 '>Here are some key factors to consider before <span className='font-bold border-b-[2px] border-[#F13623]'>hiring a Magento Developer:</span></p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Analyze the Business’ Requirements</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Learn about Magento Certifications</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Analyze the Solution Partner Company’s Portfolio</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Evaluate the Developer Experience</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Read through Online Ratings and Reviews</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Interview Developers for their Technical Expertise</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Understand their Level of Understanding around Magento 2</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Understand the Level of the Post-Development Support</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Magento: Frequently Asked Questions</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Here are some most commonly asked questions revolving around Magento:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>1. <span className='ml-3'>What is the Magento Community?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold border-b-[2px] border-[#F13623]'>Magento Community</span> is a platform that supports a vast community of Magento developers, contributors, and tech experts to share ideas, resources for learning, and tips and tricks to design Magento stores.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>2. <span className='ml-3'>What is the Total Cost of Ownership of a Magento Store?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The cost of building and maintaining a Magento store depends on the business size and its specific needs. If the company goes for Magento Open-source, they can get access to the code for free. However, they will have to pay for the developer’s working hours and for cloud hosting (i.e.,renting servers from <span className='font-bold border-b-[2px] border-[#F13623]'>Amazon Web Services</span>).</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold'>Magento Cost of Open Source</span> = Development Team + Cloud hosting</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>The costs will rise when a business chooses Magento Commerce as their go-to option. The total cost of ownership will depend heavily on the store size, order volume, and annual revenues. These costs range between $22,000/year to $125,000/year.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'><span className='font-bold'>Cost of Magento Commerce</span> = Licensing Cost + Infrastructure Cost + Cloud Hosting + Development Team</p>
                        </div>
                        <div className='mt-5'>
                            <img src={pricing} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>3. <span className='ml-3'>How does Magento help with PCI Compliance?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Magento is a PCI-compliant Level 1 Solution Provider that provides complete security towards credit card payments. The Magento solution partner ensures that the payment system of the store is secure and built in accordance with the PCI standards.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[27px]'>4. <span className='ml-3'>Does Magento Provide Omnichannel Support?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[40px]'>Adobe Commerce and Magento Commerce support Omnichannel commerce capabilities. Some of these added functionalities that promote omnichannel experience include:</p>
                            <ul className='list-disc'>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Buy-online-Pick-up-in-Store (BOPIS)</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Centralized global inventory, intelligently sourcing items for fulfillment</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Omnichannel extensibility with API integration and Magento Marketplace</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>OMS Integrations</li>
                                <li className='text-[27px] font-sans font-light mt-4 ml-[55px]'>Better fulfillment options that allow stores, partners, and suppliers to turn their offline stores into mini distribution centers</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h5 className='font-extrabold text-[41px]'>Conclusion</h5>
                            <p className='text-[27px] font-sans font-light mt-5'>Magento is an eCommerce platform to help small, medium, and large-scale <span className='font-bold border-b-[2px] border-[#F13623]'>eCommerce businesses</span> set up and maintain their online stores. Magento is the best choice for businesses looking forward to customizing their stores and getting ahead of the curve.</p>
                            <p className='text-[27px] font-sans font-light mt-5'>With everything going online, Magento is a decent platform to begin an eCommerce journey. This write-up provides a comprehensive description of Magento, the best eCommerce platform.</p>
                        </div>
                        <div style={{ backgroundImage: `url(${choice})` }} className="w-full h-[350px] bg-center bg-cover mt-5">
                            <div className='pl-10 pt-4 w-[50%]'>
                                <h5 className='font-extrabold text-[41px]'>Hire Magento Developers for Building your eCommerce Platform</h5>
                                <button type="button" className='px-4 py-2 bg-[#F13623] text-white mt-5 font-black text-[30px]'>Talk to our Experts</button>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={developers} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL COMMERCE,  MAGENTO DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>


                                    A 12-point Cheat Sheet to Hire a Magento Developer   </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Thumb} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL COMMERCE,  MAGENTO DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>



                                    Magento 2 Migration – The 2023 Guide + Case Study   </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={variants} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className=''>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>DIGITAL COMMERCE,  MAGENTO DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>


                                    Magento eCommerce – Variants, Benefits & Fit for your Online Store    </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-48'>
                    <h5 className=' text-[39px] font-sans font-extralight text-center'>Got a Project in Mind? Get Started Now.</h5>
                    <div className='w-[160px] mx-auto'>
                        <button
                            type="button"
                            className="border-[2px]  border-red-400 px-4 py-2 font-bold text-white text-[17px] mt-4 bg-[#F13623] hover:bg-white hover:!text-[#F13623]"
                        >
                            Let's Talk
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Magento