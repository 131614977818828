// import React from 'react'
import {
  softwaredevelopment,
  softwaredevelopment1,
  softwaredevelopment2,
  softwaredevelopment3,
  softwaredevelopment4,
  softwaredevelopment5,
  softwaredevelopment6,
  softwaredevelopment7,
  softwaredevelopment8,
  softwaredevelopment9,
  softwaredevelopment10,
  softwaredevelopment11,
  softwaredevelopment12,
  softwaredevelopment13,
  softwaredevelopment14,
  softwaredevelopment16,
  softwaredevelopment17,
  softwaredevelopment18,
  softwaredevelopment19,
  digitalexpplatform1,
  WebcastMyEvent,
  webdevisotop2,
  webdevisotop3,
  webdevisotop4,
  Img1,
  frontrushm,
  digitalexpplatform4,
  webdevisotop1,
  ascend1,
  Hiring,
  GoogleCloudSQL,
  digitalcustomerexperiencestrategy,
  Digital_tra2,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  digitalexpplatform,
  GWA1m,
  flimskillm,
  acomplete,
  Thumbnail,
  Thumbnail1,
  WooClub,
  bannerods,
  netsolutions,
  mobilebanner,
  netsolutions1,
  dxp,
  yimg,
  zzimg,
  vzimg,
  zgaga,
  timg,
  zgbms,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const Aws = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[15px] max-sm:text-center">
                AWS PROFESSIONAL SERVICES & CONSULTING SOLUTIONS
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[13px] max-sm:text-center max-sm:mt-2">
                Build Your Business Success with Customized AWS Consulting
                Services
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[15px] max-sm:text-center">
                Fast, scalable, reliable digital solutions engineered by our
                Amazon Web Services experts.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">Need Help with DevOps?</h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm-text-[23px] sm:px-16 max-sm:text-[15px] max-sm:text-center"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="sm:flex mt-[100px]">
          <div className="sm:ml-3">
            <div className="">
              <img
                src={yimg}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
          <div className="sm:sm:ml-3">
            <div className="">
              <img src={zgbms} className=" w-full object-cover"></img>
            </div>
          </div>
          <div className="sm:ml-3">
            <div className="">
              <img
                src={timg}
                className="w-full object-cover"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] max-sm:p-[10px] sm:w-[85%] m-auto">
          {/* <img src={bannerods} className="w-full h-[600px]" /> */}
          <div className="container">
            <div className="sm:mt-[70px] max-sm:mt-[30px]">
              <p className="text-dark  sm:text-6xl sm:mb-5 max-sm:text-[20px] max-sm:text-center">
                <b>Agile Cloud Development, AWS Solutions</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[15px] max-sm:text-center">
                When it comes to empowering digital businesses with agile,
                innovation-driving, cost-lowering cloud computing solutions,
                Amazon Web Services (AWS) is the global leader. Back in 2012, we
                were among the first Amazon Web Services development partners in
                the world to be designated as a Standard Consulting Partner.
                Today we offer comprehensive AWS consulting services — our team
                features certified AWS developers who provide world-class
                infrastructure design and execution for businesses of all sizes
                and scopes, from startup to enterprise. We’re your partner in
                AWS-powered success.
              </p>
              <div className="flex mt-5">
                <button
                  type="button"
                  className="max-sm:w-[150px] border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm-text-[17px]  hover:bg-[#F13623] hover:text-white h-[50px] max-sm:text-[10px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="max-sm:w-[150px] border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4 max-sm:text-[10px]"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[100px] max-sm:mt-[50px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>AWS Development Services</b>
          </p>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[18px] max-sm:text-center">
                  Strategy
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px]">
                  AWS is “the world’s most comprehensive and broadly adopted
                  cloud platform,” which means it offers both significant
                  opportunities and challenges. Our seasoned, certified AWS
                  developers help you fully realize the power and the promise of
                  Amazon’s cloud computing services with strategic cloud-native
                  application design. As your AWS consulting partner, you can
                  count on us to engineer ingenious infrastructure for projects
                  from a single application to enterprise SaaS that uses
                  multiple AWS services.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[18px] max-sm:text-center max-sm:mt-[35px]">
                  AWS App Development
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  When it comes to building and deploying native applications
                  through the cloud, you can’t just hire an AWS developer and
                  hope it works out. Our AWS solutions team has expertise in
                  designing and scaling flexible architecture to build robust,
                  agile, secure applications from scratch or bring legacy
                  applications to the cloud
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[18px] max-sm:text-center">
                  Cloud Security & Risk Management
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  AWS is known for its rock-solid core infrastructure, which is
                  structured to provide stringent security for high-profile
                  clients including military and global banks. Our AWS
                  specialists are experts in the vast array of AWS security
                  tools and are well-versed in devising custom cloud security
                  plans. We offer custom AWS security enterprise integration
                  solutions, AWS security audits, risk and identity management,
                  and AWS technical security architecture consulting for your
                  better business — and peace of mind.
                </p>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:ml-[50px]">
                <h4 className="font-extrabold text-[52px] tracking-widest sm:w-[600px] max-sm:text-[18px] max-sm:text-center max-sm:mt-[35px]">
                  Monitoring, Management, and Support
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  Leveraging Amazon Web Services platform to deliver an
                  exceptional digital experience while optimizing efficiencies
                  in time, resources, and budget is an ever-expanding
                  opportunity. Leave the ongoing details of configuring,
                  managing, and troubleshooting to our AWS Managed Services team
                  and free up your team to focus on other areas of business
                  growth. Our full-spectrum AWS consulting services include AWS
                  efficiency audits, SLA management, and comprehensive
                  monitoring of all aspects of your AWS cloud-based business.
                </p>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="sm:mr-[50px]">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[18px] max-sm:text-center">
                  Implementation and Migration
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[15px] max-sm:text-center">
                  Migrating your business to the AWS platform is smart for your
                  business — and even wiser is choosing an AWS professional
                  services partner who can ensure a smooth process of migration
                  and implementation. Our dedicated team of Amazon AWS solutions
                  experts delivers seamless transitions that improve user
                  experience and support business objectives like enterprise
                  cloud adoption and CloudOps using AWS’ technology stack for
                  reliable, secure solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-[50px]">
            <div className="">
              <p className="sm:text-6xl mt-5 max-sm:text-[20px] max-sm:text-center">
                <b>
                  FAQs about Working With Rapidsofts as Your AWS consulting
                  partner for AWS Development
                </b>
              </p>
            </div>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[15px]">What is AWS?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="sm:text-[20px] max-sm:text-[15px] font-light sm:text-3xl">
                        AWS stands for Amazon Web Services, which is a cloud
                        platform that includes packaged software solutions,
                        infrastructure as a service, database storage solutions
                        and a wide range of other features. AWS developers are
                        professionals that work within this platform to build
                        and customize solutions that will run in the
                        environment. Though Amazon provides these services, the
                        AWS professional services solutions provided by your
                        development team don't have to be related to Amazon or
                        even ecommerce.
                      </div>
                      <div></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">Why choose AWS development?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="sm:text-[20px]  max-sm:text-[15px] font-light text-3xl">
                      AWS is widely used and popular, so you may find it easier
                      to find AWS consulting pros and developers willing and
                      able to work on your project. Among technical solutions,
                      it's also considered easier to work with, which can pave
                      the way for smoother (and potentially less costly)
                      development in some cases.
                    </div>
                    <div className="sm:text-[20px] font-light sm:text-3xl  max-sm:text-[15px]">
                      Because the platform is backed by the seemingly infinite
                      resources of Amazon, server capacity is practically
                      unlimited and developers and clients can also rely on
                      features such as reliable encryption. It's also easy to
                      scale up or down when using cloud services, and especially
                      AWS, which makes this a versatile choice.
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">Is Django in high demand?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      Even though it’s been around since 2005, Django remains a
                      top in-demand web development framework. (And we’re not
                      just saying that because we’re a Django development
                      company — it’s true!) It’s been reported that Django has
                      had a market share of over 28% since 2021. Plus, if you
                      Google, “hire Django developer,” you’ll see how popular it
                      is as a search term!
                    </p>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      (That said, it’s not so simple to just type in “hire
                      Django developers” or “hire Django programmer” and find
                      vendors who will deliver consistent, high-quality work.
                      That’s why we believe a full-service Django development
                      company is a better choice.)
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">
                      How to use AWS for development
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      One of the best ways to implement AWS solutions is to work
                      with IT professionals who specialize in providing AWS
                      consulting services and are experienced with AWS projects.
                      While you don't always need to know coding to use AWS
                      solutions, you do need coding and other development skills
                      if you want to build new solutions on this platform.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">
                      What do your AWS consulting and development services
                      include?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      Our AWS development services cover everything from initial
                      strategy to ongoing support. The knowledgeable project
                      teams meet with businesses to determine requirements and
                      goals for projects. They then do the work to bring those
                      visions to life while ensuring risk management and
                      security of your data. Finally, once the solution is
                      deployed, the teams can continue to provide monitoring,
                      management and support.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">
                      What are the benefits of AWS development services?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      When you work with an AWS development service, you get the
                      benefit of expert knowledge and skill sets — without
                      having to hire permanent staff or invest in in-house
                      training.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">What do AWS developers do?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      AWS developers build and deploy AWS solutions in the AWS
                      environment. They work with clients to determine business
                      requirements and build solutions to meet those needs. They
                      may also work on ensuring security, setting up and running
                      databases, debugging solutions and helping to maintain and
                      troubleshoot solutions after they're deployed.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">
                      How much experience do your developers have?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px]  max-sm:text-[15px] font-light">
                      Our team is extremely experienced in AWS development and
                      the use of technology including Amazon EC2, DynamoDB,
                      Lamda, Simple Storage Service, Route 53 and many others.
                      Our experience with AWS dates back almost to the beginning
                      of the platform, and we were awarded the Standard
                      Consulting Partner by Amazon in 2012.
                    </p>
                    <p className="sm:text-[20px]  max-sm:text-[15px] font-light">
                      The best thing to do is put together your project wish
                      list and contact us for more information on pricing.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px]  max-sm:text-[15px]">
                      How much does AWS development cost?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sm:text-[20px] font-light  max-sm:text-[15px]">
                      Cost for any development project varies according to
                      factors such as the size and complexity of the project,
                      how fast you need it and whether there are integrations
                      and other external factors. While AWS development can be
                      cheaper than some other options because of the robust
                      platform that's already built, the total cost of your
                      project is based on your requirements. Reach out to Net
                      Solutions with information about your project to get an
                      estimate on services.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] max-w-[100%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl  max-sm:text-[20px] max-sm:text-center">
                <b>Success Stories</b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={WooClub}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 sm:text-[15px] max-sm:text-center">
                    <b>We Develop Flagship Products using AWS Features</b>
                  </p>
                  <p className="sm:text-3xl mt-3 sm:text-[15px] max-sm:text-center">
                    Learn how we helped an ambitious startup build a corporate
                    video-on-Demand platform by using AWS transcoders to make
                    videos securely accessible from anywhere, anytime.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={WebcastMyEvent}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 sm:text-[20px] max-sm:text-center ">
                    <b>
                      We Provide Seamless Migration to the AWS Cloud for Better
                      CX
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 sm:text-[15px] max-sm:text-center">
                    See how we used an iterative process of continuous
                    integration and resource deployment to facilitate a
                    hassle-free, multi-site migration from legacy hosting to AWS
                    cloud infrastructure.
                  </p>
                  <img
                    src={GWA1m}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 sm:text-[20px] max-sm:text-center">
                    <b>
                      We Create Unique Customer Experiences to Support Rapid
                      Growth
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 sm:text-[15px] max-sm:text-center">
                    Explore how we saved significant time and expense using
                    multiple AWS services to accelerate the launch of a unique
                    shopping video platform that won over 1,800+ active users in
                    the first two months.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="sm:mt-[100px] max-sm:[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center pt-20 sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={acomplete} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="sm:mt-4 max-sm:mt-1">
                    <p className="text-[#D4386C] ml-5 max-sm:text-[15px]">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="sm:mt-4 max-sm:mt-2 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[15px]">
                      A Guide To Cloud-Native Application Development
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="sm:mt-4 max-sm:mt-1">
                    <p className="text-[#D4386C] ml-5  max-sm:text-[15px]">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="sm:mt-4 max-sm:mt-2 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[15px]">
                      What is the Difference Between AWS, Azure, and Google
                      Cloud?
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={Thumbnail1} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="sm:mt-4 max-sm:mt-1">
                    <p className="text-[#D4386C] ml-5  max-sm:text-[15px]">
                      CLOUD SERVICES, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="sm:mt-4 max-sm:mt-2 sm:text-[25px] px-4 font-semibold leading-9 mb-12  max-sm:text-[15px]">
                      How to Choose the Right Cloud Service Provider?
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Aws;
