import {
  Contactbanner,
  Navdeep1,
  businessbusiness,
  petersburg,
  winnipeg,
  marrone,
  champion,
  userpdf,
  handimg,
} from "../assets";
import React, { Component } from "react";
const Contact = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${Contactbanner})` }}
        className="h-[32rem] flow-root"
      >
        <div className="text-center">
          <h1 className="text-white sm:text-4xl max-sm:text-[20px] mt-[220px] font-black">
            Good Talks Make Good Projects
          </h1>
          <p className="text-white font-black max-sm:text-[15px]">
            While we are good at sign language, there are various other simpler
            ways to connect with us. Talk to us in person.
          </p>
        </div>
        <div className="flex mt-3">
          <div className="w-[50%]">
            <div className="float-right">
              <button class="max-sm:text-[10px] mr-3 bg-transparent hover:!bg-blue-500 text-black font-semibold hover:!text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                CALL US NOW!
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <div className="">
              <button class="max-sm:text-[10px] ml-3 bg-white hover:bg-blue-500 text-black font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                DROP AN EMAIL
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="sm:flex sm:mt-[50px]">
          <div className="sm:w-[50%] max-sm:w-[100%] max-sm:p-2">
            <div className="h-[570px]">
              <iframe
                className="h-[570px] w-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d350.29732525454256!2d76.69188858920131!3d30.71104999701224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef54f5c4fe7d%3A0xbd13af48731f5321!2sRapidsofts!5e0!3m2!1sen!2sin!4v1677821603419!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5 mr-5 max-sm:p-2">
            <div className="card">
              <div className="text-center bg-[#093D77] flow-root">
                <p className="text-3xl text-white mt-2 mb-2 max-sm:text-[20px]">Contact Us</p>
              </div>

              <form>
                <div className="grid gap-6 mb-6 md:grid-cols-2 ml-2 mr-2 mt-4">
                  <div>
                    <label
                      for="first_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="last_name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Doe"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="company"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="company"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Flowbite"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="123-45-678"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      required
                    />
                  </div>
                  <div className="">
                    <label
                      for="phone"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your Budget
                    </label>
                    <select
                      id="countries"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Choose Budget</option>
                      <option value="US">UNDER USD 10K</option>
                      <option value="CA">USD 10K - 25K</option>
                      <option value="FR">USD 25K - 50K</option>
                      <option value="DE">USD 50K+</option>
                    </select>
                  </div>
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      for="file_input"
                    >
                      Attachment if any
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 p-2"
                      id="file_input"
                      type="file"
                    />
                  </div>
                  <div>
                    <label
                      for="message"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your message
                    </label>
                    <textarea
                      id="message"
                      rows="4"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="How can we help you? *"
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  className="max-sm:w-[200px] max-sm:m-auto max-sm:block mb-3 sm:ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-10 grid">
        <div className="container">
          <div className="sm:flex mt-10 mb-5">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="card flow-root sm:mt-[120px] max-sm:mt-[50px] sm:h-[505px] rounded-none">
                <p className="sm:text-3xl sm:mt-[210px] text-center max-sm:text-[15px] max-sm:mt-3">
                  Want to be a part of our team?
                </p>
                <p className="text-center mt-2 max-sm:text-[10px]">
                  If you have got powerful skills, we will pay your bills.
                  Contact our HR at:
                </p>
                <div className="sm:flex mt-3 max-sm:mb-3">
                  <div className="sm:w-[50%] max-sm:w-[100%] max-sm:text-center">
                    {" "}
                    <button class="text-white sm:float-right sm:ml-3 bg-[#093D77] hover:bg-blue-500 text-black font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded max-sm:text-[10px]">
                      Rapidsofts.com
                    </button>
                  </div>
                  <div className="sm:w-[50%] max-sm:w-[100%] max-sm:text-center">
                    <button class="text-white max-sm:mt-3 sm:ml-3 bg-[#093D77] hover:bg-blue-500 text-black font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded max-sm:text-[10px]">
                      +9100000000000
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={businessbusiness} className="sm:mt-[120px] max-sm:mt-[50px]"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="m-auto w-[40%]">
          <p className="sm:text-4xl max-sm:text-[20px] text-center">
            Delivering excellence, collaborating across time zones.
          </p>
          <p className="text-center mt-3 max-sm:text-[10px]">
            Take a look at our global hideouts.
          </p>
        </div>
        <div className="flex sm:mt-[80px] max-sm:mt-[20px]">
          <div className="w-[33%]">
            <img src={petersburg} className="rounded-xl max-sm:h-[80px]"/>
            <p className="text-center sm:text-2xl mt-2 max-sm:text-[10px]">United States:</p>
            <p className="text-center max-sm:text-[10px]">
              7901 4th St Nste 300 St Petersburg, Fl 33702
            </p>
          </div>
          <div className="w-[33%] ml-4">
            {" "}
            <img src={winnipeg} className="rounded-xl max-sm:h-[80px]" />
            <p className="text-center sm:text-2xl mt-2 max-sm:text-[10px]">Canada:</p>
            <p className="text-center max-sm:text-[10px]">
              11, Whitewood Avenue, Winnipeg, MB R3Y 0B8, Canada
            </p>
          </div>
          <div className="w-[33%] ml-4">
            <img src={marrone} className="rounded-xl max-sm:h-[80px]" />
            <p className="text-center sm:text-2xl mt-2 max-sm:text-[10px]">Australia::</p>
            <p className="text-center max-sm:text-[10px]">
              19 Marrone Bvd, Cranbourne East VIC 3977, Australia
            </p>
          </div>
        </div>
        <div className="flex sm:mt-5 max-sm:mt-3">
          <div className="w-[33%]">
            <img src={champion} className="rounded-xl max-sm:h-[80px]" />
            <p className="text-center sm:text-2xl mt-2 max-sm:text-[10px]">United Kingdom:</p>
            <p className="text-center max-sm:text-[10px]">
              15 The Hamlet, Champion Hill London SE5 8AW, UK
            </p>
          </div>
          <div className="w-[33%] ml-4">
            <img src={userpdf} className="rounded-xl sm:h-[190px] max-sm:h-[80px] w-full" />
            <p className="text-center sm:text-2xl mt-2 max-sm:text-[10px]">India::</p>
            <p className="text-center max-sm:text-[10px]">
              1105, Welldone Tech Park, Sohna Rd, Sector 48, Gurugram, Haryana
              122002
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
