import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  Hiring,
  GoogleCloudSQL,
  designstudiobanner,
} from "../../assets";
import { Link } from "react-router-dom";
const DesignStudio = () => {
  return (
    <>
      <div className="container">
        <div className="flex mt-4">
          <div className="w-[50%]">
            <div className="mt-[160px]">
              <p className="text-3xl">
                <b>Design Studio</b>
              </p>
              <p className="mt-2 mb-2">
                Combine the prowess of exquisite designs in your proceedings and
                master the UX domain. Avail us and get the best design studio
                services for your business.
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <img src={designstudiobanner}></img>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <p className="text-4xl">
              <b>Associate Aesthetic with Your Brand Image</b>
            </p>
            <p className="mt-4">
              Merely integrating design for formality within your website and
              business, in general, is one of the worst things you can do. The
              design has the potency to enhance your business proceedings
              several folds. It can attract new users to your business and it
              can also establish a distinct brand image within the eyes of your
              customer base. Seeing how it serves as a competent growth
              stimulant, it is high time, you pay attention to your designs.
              RevInfotech packs capable designers and developers to help you
              solve the issues of money and time shortage. Avail of our experts
              and let them prepare the best designs for your business.
            </p>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AR and VR</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                    Enterprise Mobility
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-5">
        <div className="text-center text-white p-5">
          <p className="text-3xl">Adding Exquisiteness to Your Brand Image</p>
        </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Class Design Services
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                UX DESIGNS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Get premium UX designs for your venture and offer an unrivaled
                user experience. Get our help and garner an online user base.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                PRODUCT DESIGNS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Offering the best product designs for your existing and upcoming
                projects. Showcase your products under premium light with us.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BRANDING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Evolve your business into a brand and earn prestige for your
                proceedings. Get the best design branding for your business.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Additional Benefits
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                CUSTOMER-FOCUSED DESIGNS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Designing your website according to customer needs to improve
                the customer experience. Adapting to every shift in the market
                and preparing customer-focused designs for improving your
                overall standing.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                EFFECTIVE CUSTOMIZATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Simplicity is vital, but there are times when you need to add a
                special touch to the procedures. Compress your content depending
                on users' tastes and preferences. Provide content targeted
                towards your consumers.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                QUICK FIXES
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                You can't always trust technology for being smooth. There will
                be times when you will face a roadblock due to some technical
                glitch. In these times, you need to be prepared to resolve
                issues quickly.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                INCREASED CONSUMER PARTICIPATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Regardless if it's negative or positive, you need to interact
                regularly with your audience. You can do so with our effective
                CX. Study your market and fix the problems for increased
                visiting queries and faith in your brand.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                DIGITAL TRANSITION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Designs go a long way for any business. Associate premium
                designs with your business and rides the wave of the digital
                transition. With RevInfotech’e help, you can get to the top of
                any industry within a limited time.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
              Why Pick Design Studio for Your Business?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              Proposing half-baked designs and messed-up final products can lead
              to a bad reputation for your business. Design and look is the
              first thing your audience sees on your website. Make sure you
              leave a memorable experience in that regard. On the other hand,
              merely aesthetic designs can also provide an undesirable customer
              experience. That is why RevInfotech focuses on both aspects
              individually and offers only what is best.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
              Why Pick RevInfotech for Designing Jobs?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
              Customer experience is one of the most valuable factors in
              business growth. Over the years, the market has seen brands
              emphasize customers and products more than absolute revenue.
              Companies are choosing on the value of the customer experience, so
              they offer value more than just materials. You can make use of the
              same with our design solutions. RevInfotech packs designing
              experts capable of enhancing the looks of a business regardless of
              its scale. Reach us today and get the best designs for your
              business.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={GoogleCloudSQL} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DesignStudio;
