
import {
  BestOnlineFoodDeliveryApps,
  nativevs,
  shouldilaunch,
  mobileappdevelopment12,
  mobileappdevelopment,
  edplacecasesmallpic,
  pageantbigcasestudpic,
  voylegalcasesmallpic,
  digitalcustomerexperiencestrategy,
  customplatformgraphic,
  personalmultidiagram,
  ongoingdiagram,
  mobileappdevelopmentservicesnet,
  mobileappdevelopmenservicesapplelogoline,
  ttimg,
  zimg,
  trish,
} from "../../assets";
import Accordion from "react-bootstrap/Accordion";
import React, { useState } from "react";
const MobileAppDevelopement = () => {
  return (
    <>
      <div className="container">
        <div className="sm:flex">
          <div className="sm:w-[60%] max-sm:w-[100%]">
            <div className="sm:mt-[200px] max-sm:mt-[80px]">
              <h1 className="sm:text-[23px] font-black text-[#D4386C] max-sm:text-[20px] max-sm:text-center">
                MOBILE APP DEVELOPMENT SERVICES
              </h1>
              <h1 className="sm:text-[40px] font-black sm:w-[75%] max-sm:text-[20px] max-sm:text-center">
                We Make Your Mobile App Vision a Reality
              </h1>
              <p className="sm:text-[23px] italic mt-4 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                When you trust our custom mobile app development services, you
                get modern apps that can transform your business and help it
                grow. Rapidsofts is a mobile app development company—also
                offering many other services, like web app development—that's
                ready to take on any challenge you throw our way.
              </p>
              <div className="flex"></div>
            </div>
          </div>
          <div className="sm:w-[40%] max-sm:w-[100%]">
            <form className="bg-[#F9D94E] shadow-2xl sm:px-16 max-sm:px-8 pt-9 pb-8 mb-4 sm:w-[450px] float-right sm:mt-[150px] max-sm:mt-[50px]">
              <h1 className="sm:text-[30px] font-black max-sm:text-[20px] max-sm:text-center">
                Looking to Build a Mobile App?
              </h1>
              <div className="">
                <input
                  className="shadow appearance-none border-0 w-full h-10 py-2 px-3 text-gray-700 mt-4 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="">
                <input
                  className="shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3 mt-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Email*"
                />
              </div>
              <div className="flex mt-2">
                <div className="w-35%">
                  <select
                    id="countries"
                    className=" bg-gray-50 border border-gray-300 text-gray-400 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected>Choose a country</option>
                    <option value="US">India</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                </div>
                <div className="w-[65%] ml-3">
                  <input
                    className="w-[100%] shadow appearance-none border-0 border-red-500 w-full h-10 py-2 px-3  text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="phone"
                    type="phone"
                    placeholder="+91"
                  />
                </div>
              </div>
              <div className="mt-3">
                <textarea
                  id=""
                  row="5"
                  className="w-full px-3 h-20 py-2"
                  placeholder="Message*"
                ></textarea>
              </div>
              <div className="mt-3 mb-16">
                <button
                  type="button"
                  className="bg-[#F13623] py-2 w-full text-white font-extrabold sm:text-[23px]"
                >
                  Get In Touch
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="sm:mt-[150px] max-sm:mt-[50px]">
        <div className="bg-[#F9D94E] sm:p-[100px] sm:w-[85%] max-sm:w-[100%] m-auto max-sm:flow-root">
          <img
            src={mobileappdevelopmentservicesnet}
            className="w-full sm:h-[600px]"
          />
          <div className="container">
            <div className="mt-[70px]">
              <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                <b>THE Rapidsofts APPROACH</b>
              </p>
              <p className="sm:text-5xl mt-2 max-sm:text-[20px] max-sm:text-center">
                <b>Full-service Custom Mobile App Development</b>
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                From the moment your app idea enters your mind to the end of
                development and beyond, Rapidsofts is here to provide
                revolutionary and full-service app services. You don't need to
                be an expert in mobile apps to have an excellent one—leave that
                type of work to our mobile app development company.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                Consumers expect instant access to the brands they care about.
                Mobile apps offer a simple and effective way of communicating
                with customers and keeping your brand at the forefront of their
                minds.
              </p>
              <p className="sm:text-3xl mt-4 max-sm:text-[20px] max-sm:text-center">
                At Rapidsofts, we have more than two decades of experience in
                digital development. We've helped many top brands build
                strategies and design user journeys to engage target audiences
                and expand reach.
              </p>
              <div className="flex mt-5 max-sm:mb-5">
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px]  hover:bg-[#F13623] hover:text-white h-[50px]"
                >
                  Explore Our Process
                </button>

                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] ml-4"
                >
                  Request a Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="sm:mt-[200px] max-sm:mt-[50px]">
          <p className="text-[#D4386C]  sm:text-2xl max-sm:text-[20px] max-sm:text-center">
            <b>HOW WE CAN HELP YOU</b>
          </p>
        </div>
        <div className="mt-[30px]">
          <p className="sm:text-7xl max-sm:text-[20px] max-sm:text-center">
            <b>Full-service App Development for Your Brand</b>
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            You won't get a cookie-cutter app with Rapidsofts. When we create
            and provide ongoing support for your brand's app, we are focused
            entirely on your company and its needs.
          </p>
          <p className="text-2xl mt-3 w-[90%] max-sm:text-[20px] max-sm:text-center">
            Whether you need help designing your first app, planning a digital
            transformation, or crafting the back- and front-ends of the
            application, Rapidsofts has the expertise to handle the job.
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%]">
            Further, Rapidsofts' expert developers use app optimization
            techniques to ensure optimal performance and increase conversion
            rates.
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            Finally, we use independent testing to ensure your apps are
            bug-free, pleasant to use, and produce results.s
          </p>
          <p className="sm:text-2xl mt-3 sm:w-[90%] max-sm:text-[20px] max-sm:text-center">
            The team's expertise in developing B2B, B2C, B2B2C, and custom
            mobile applications includes:
          </p>
          <div className="sm:flex">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <ul className="list-disc sm:ml-[100px] mt-5">
                <li className="sm:text-3xl max-sm:text-[20px]">MVP app development for startups</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">
                  Custom consumer enterprise app development
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">
                  Bullet point Product development
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">SaaS development</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">
                  Native Android and iOS development
                </li>
              </ul>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <ul className="list-disc sm:ml-[100px] mt-5">
                <li className="sm:text-3xl max-sm:text-[20px]">
                  Migration of existing apps to hybrid frameworks
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">Optimization</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">Redesign</li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">
                  Ongoing support and maintenance
                </li>
                <li className="sm:text-3xl mt-4 max-sm:text-[20px]">Independent testing</li>
              </ul>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Experienced Usability Experts
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                It's no longer enough to make a website mobile-friendly. Our
                application development team is well-versed in usability best
                practices. We create apps that work smoothly and intuitively,
                ensuring maximum customer satisfaction regardless of the device.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Our UI/UX design services teams carefully craft smooth and
                pleasant user experiences, sending customers down conversion
                funnels using tried and tested methods.
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img
                  src={digitalcustomerexperiencestrategy}
                  className="sm:ml-[100px]"
                ></img>
                 <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={customplatformgraphic} className="sm:mt-[100px] max-sm:mt-[50px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Custom Mobile App Development for Every Purpose
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  No matter what your company does, we can create an app that
                  suits your needs. Industries we've previously worked with
                  include:
                </p>
                <ul className="list-disc mt-5 ml-5 max-sm:text-[20px]">
                  <li className="mt-4 sm:text-2xl">Automotive</li>
                  <li className="mt-4 sm:text-2xl">Education and Training</li>
                  <li className="mt-4 sm:text-2xl">Food and Beverage</li>
                  <li className="mt-4 sm:text-2xl">Healthcare</li>
                  <li className="mt-4 sm:text-2xl">
                    Media, Publishing & Entertainment
                  </li>
                  <li className="mt-4 sm:text-2xl">Retail and Ecommerce</li>
                  <li className="mt-4 sm:text-2xl">Sports and Fitness</li>
                </ul>
                <p className="mt-5 sm:text-2xl max-sm:text-[20px] max-sm:text-center">
                  For these industries, we've created everything from loyalty
                  program apps to games to seamless shopping experiences. If you
                  can dream it, we can build it!
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:mt-[300px] max-sm:mt-[50px] bg-[#D2EDFC] grid max-sm:px-2">
            <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
              <div className="sm:w-[40%] max-sm:w-[100%]">
                <img
                  src={mobileappdevelopmenservicesapplelogoline}
                  className="mx-auto"
                ></img>
              </div>
              <div className="sm:w-[60%] max-sm:w-[100%]">
                <div className="">
                  <p className="sm:text-3xl max-sm:text-[20px] max-sm:text-center">
                    <b>iOS</b>
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    We can help you deploy effective and user-friendly apps
                    designed for Apple products like iPhones and iPads. We even
                    create features for the Apple Watch! By providing polished
                    experiences for Apple users, your business can build loyalty
                    among an expansive user base.
                  </p>
                  <button
                    type="button"
                    className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] max-sm:w-full hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                  >
                    Learn More About IOS Development
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
              <div className="sm:w-[40%] max-sm:w-[100%] sm:mt-[400px] max-sm:mt-[50px]">
                <img src={mobileappdevelopment} className="mx-auto"></img>
              </div>
              <div className="sm:w-[60%] max-sm:w-[100%]">
                <div className="max-sm:px-2">
                  <p className="sm:text-3xl max-sm:text-[20px] max-sm:text-center">
                    <b>Cross-platform</b>
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    Have you ever noticed that some popular apps are only
                    available on Android or iOS, not both? That's often because
                    they chose one or the other rather than opting for
                    cross-platform app development. This type of app creation
                    allows your app to work on multiple operating systems.
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    These are different from native apps, which are created
                    strictly for a specific operating system. By contrast, these
                    apps use non-OS-specific programming
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    While we commonly create packages for Android and Apple
                    devices alone, we can work with you to see if we can make
                    one for a different operating system.
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    When you use a cross-platform development method, you save
                    time and money. Creating native apps for Android and Apple
                    is done separately—we program for Android OR Apple and
                    charge accordingly. But, if you do both simultaneously, you
                    pay one price, and we make one app for both platforms.
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    In short, Rapidsofts' cross-platform development helps
                    you get more out of your app budget and enter the mobile
                    market quickly by creating an app that works on multiple
                    operating systems. Take this chance to respond to the
                    ever-changing marketplace and get ahead of your competition.
                  </p>
                  <button
                    type="button"
                    className=" max-sm:w-full border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                  >
                    Learn More About Cross-platform Development
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:mt-[200px] max-sm:mt-[50px] max-sm:px-2">
              <div className="sm:w-[40%] max-sm:w-[100%]">
                <img src={mobileappdevelopment12} className="mx-auto"></img>
              </div>
              <div className="sm:w-[60%] max-sm:w-[100%]">
                <div className="">
                  <p className="sm:text-3xl max-sm:text-[20px] max-sm:text-center">
                    <b>Android</b>
                  </p>
                  <p className="sm:text-2xl mt-3 sm:w-[80%] max-sm:text-[20px] max-sm:text-center">
                    Android Our Android team understands the complexities of
                    developing for a marketplace accessed by a vast range of
                    devices from varying manufacturers. As a result, they build
                    user-friendly, intuitive, and polished applications that
                    look and feel great on any Android device.
                  </p>
                  <button
                    type="button"
                    className="max-sm:w-full max-sm:mb-2 border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 sm:text-[17px] max-sm:text-[10px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                  >
                    Learn More About Android Development
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center">
                Future-proof Your Brand
              </h4>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Empower customers to engage with your brand with our
                interactive, data-rich, and eye-catching apps that give
                real-time analytics.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Let customers buy from you or manage their accounts on their
                terms, any time day or night.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                Consumers across the B2B and B2C spaces expect fluid and
                innovative products that put them in control. So, we provide our
                clients with the web apps and mobile tools they need to grow an
                engaged user base.
              </p>
              <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                We also provide ongoing support for your app, so if you need
                updates or if something happens to go wrong, we're here to help
              </p>
              <button
                type="button"
                className="max-sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={personalmultidiagram} className="sm:ml-[100px]"></img>
                <button
                type="button"
                className="sm:hidden border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
              >
                Request to Quote
              </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[150px] max-sm:mt-[50px]">
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <img src={ongoingdiagram} className="mt-[20px]"></img>
              </div>
            </div>
            <div className="sm:w-[50%] max-sm:w-[100%]">
              <div className="">
                <h4 className="font-extrabold sm:text-[52px] tracking-widest sm:w-[600px] max-sm:text-[20px] max-sm:text-center max-sm:mt-[50px]">
                  Award-winning Apps
                </h4>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Rapidsofts has worked with innovative startups and thriving
                  enterprises to publish award-winning apps on iOS and Android
                  app stores. One of our success stories is FourFourTwo, a
                  football stats app developed by Rapidsofts for Haymarket
                  Consumer Media.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  Built using HTML5 and Java, this slick and visually appealing
                  app gives football fans the statistics and information they
                  need to fully engage with each game, managing insights for the
                  real world and fantasy teams.
                </p>
                <p className="sm:text-[22px] font-light pt-4 max-sm:text-[20px] max-sm:text-center">
                  The app uses native canvas drawings for animations to ensure
                  optimum performance and was named App of the Year by PPA
                  Digital Publishing. It also won the Innovation Award, Grand
                  Prix Awards from AOP Digital Publishing, and many other
                  accolades
                </p>
                <button
                  type="button"
                  className="border-[2px] border-red-400 px-3 py-2 font-bold text-red-500 text-[17px] hover:bg-[#F13623] hover:text-white  h-[50px] mt-5"
                >
                  Request to Quote
                </button>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:mt-[100px] max-sm:mt-[50px]">
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={ttimg}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img src={zimg} className=" w-full object-cover"></img>
              </div>
            </div>
            <div className="sm:ml-3">
              <div className="">
                <img
                  src={trish}
                  className="w-full object-cover"
                ></img>
              </div>
            </div>
          </div>
          <div className=" mt-[50px]">
            <p className="sm:text-5xl ml-5 max-sm:text-[20px] max-sm:text-center">
              <b>Rapidsofts' Mobile App Development: FAQs</b>
            </p>
            <div className="sm:w-[80%] m-auto">
              <Accordion className="mb-5 max-sm:mt-[-50px]">
                <div>
                  <Accordion.Item eventKey="0" className="">
                    <Accordion.Header>
                      <h5 className="sm:text-[26px] max-sm:text-[20px]">What is a mobile app?</h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="text-[20px] font-light text-3xl">
                        A mobile application, or mobile app, is software
                        designed to run on a device like a phone or tablet. Its
                        functionality is limited when compared to a company
                        website. For instance, an eCommerce site may have a blog
                        and pop-up newsletter sign-ups, but the app for the same
                        site could strictly make sales.
                      </div>
                      <div></div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <Accordion.Item eventKey="1" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How many mobile apps are there?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="text-[20px] font-light text-3xl">
                      Apps are becoming an increasingly important part of
                      digital commerce. There are roughly five million apps as
                      of 2021. Nearly three million of those are on the Google
                      Play Store (Android), and over two million are on the
                      Apple app store
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How do you create a mobile app?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      The basic steps to creating a mobile app are developing an
                      idea, doing relevant research, fleshing out the idea, and,
                      finally, developing the app.
                    </p>
                    <ul className="mt-5 list-disc sm:ml-[100px]">
                      <li className="text-2xl">
                        {" "}
                        Have the idea: What does your company need?
                      </li>
                      <li className="text-2xl">
                        Research your idea and audience: Ask yourself what your
                        audience wants and what the app needs to look like.
                      </li>
                      <li className="text-2xl">
                        Flesh out your idea: Use your research to detail
                        precisely what you want your app to do and look like.
                      </li>
                      <li className="text-2xl">
                        Develop the app: App development includes wireframing,
                        assessing the app’s feasibility, and developing a
                        prototype, among other tasks.
                      </li>
                      <p className="mt-3 text-2xl">
                        After the interface is designed and coded, test the app
                        to ensure it works as expected and fits the customers’
                        needs.
                      </p>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">What is mobile development?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Mobile development is an umbrella term for software
                      development for programs that work across mobile devices
                      and computers.
                    </p>
                    <p className="text-[20px] font-light">
                      As smartphones and tablets continue to grow in popularity,
                      more software development comes with a mobile component to
                      ensure users can access their favorite websites and
                      programs on their favorite devices.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">How do mobile apps work?</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      A mobile app allows users to perform specific tasks on
                      their phone, tablet, or another mobile device. For
                      instance, some apps may only allow you to browse and shop
                      a store's products, while their full website may also
                      include a blog.
                    </p>
                    <p className="text-[20px] font-light">
                      This precision is part of the appeal, with consumers able
                      to download apps offering the exact functionality they’re
                      looking for without having to sift through any other
                      "noise."
                    </p>
                    <p className="text-[20px] font-light">
                      Additionally, apps can integrate location-based functions,
                      which can help users make purchases more easily. For
                      instance, if you own a chain of coffee shops and have an
                      app, your app's location-based services can help them find
                      the nearest location.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How do you hire a mobile app developer?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Your chosen mobile app development company or individual
                      developer is a critical component of your project’s
                      success, so it’s essential to take the time to find the
                      right person or group. Assess their portfolio and check
                      their references to see if they’ve completed a similar
                      project in the past.
                    </p>
                    <p className="text-[20px] font-light">
                      You’ll spend a lot of time with your contact to work on
                      your custom mobile app development, so choose someone with
                      whom you can build a relationship. Ask about how they
                      work, the process they use, and how you, the customer, fit
                      into this process. Make sure you’ll be updated on all
                      crucial aspects.
                    </p>
                    <p className="text-[20px] font-light">
                      While budget is an important consideration for any
                      business, basing your decision entirely on cost could
                      result in a lower-quality product. Instead, look at your
                      budget and see where you can make adjustments to ensure
                      you can afford a top-quality app developer's work.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="a">
                  <Accordion.Header>
                    <h5 className="sm:text-[26px] max-sm:text-[20px]">
                      How much does it cost to develop a custom mobile app?
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="text-[20px] font-light">
                      Many factors determine the cost of app development,
                      including the platform, project complexity, necessary
                      features, and level of customization. Company size may
                      also pay a role in cost. Large businesses may need the
                      assistance of an enterprise mobile app company—which is
                      one of the sizes Rapidsofts can support. The larger the
                      company, though, the more the app may cost.
                    </p>
                    <p className="text-[20px] font-light">
                      No use beating around the bush: App development can be
                      costly. GoodFirms found a simple app averages around
                      $24,000, while a highly complex one can run nearly
                      $140,000.
                    </p>
                    <p className="text-[20px] font-light">
                      However, these numbers are not our numbers—they're just
                      averages. The best way to find out how much your app will
                      cost is to talk to an app developer and ask them to
                      provide a quote.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="sm:w-[90%] m-auto">
            <div className="sm:mt-[100px] max-sm:mt-[50px]">
              <p className="sm:text-6xl max-sm:text-[20px] max-sm:text-center">
                <b>
                  Success Stories: Our Track Record of IT Consulting Success
                  Across Several Industries and Business Verticals
                </b>
              </p>
            </div>
            <div className="sm:flex">
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={edplacecasesmallpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Future-proof Your Business with Innovations that Win
                      Fans — and Awards
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how developed a multiple award-winning, data-rich,
                    real-time Android app that took an existing iOS football
                    stats app to the next level for a leading sports and
                    entertainment company.
                  </p>
                </div>
              </div>
              <div className="sm:w-[50%] max-sm:w-[100%] sm:ml-5">
                <div className="mt-5">
                  <img
                    src={pageantbigcasestudpic}
                    className="w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      frontrushfrontrush We Help Ambitious Businesses Design &
                      Execute Their Vision
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Learn how we helped a scrappy sports start-up bring its
                    unique concept for athlete recruiting software to life with
                    a strategy that delighted end-users and exceeded the
                    company’s wildest dreams.
                  </p>
                  <img
                    src={voylegalcasesmallpic}
                    className="mt-5 w-100 hover:animate-pulse"
                  ></img>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    <b>
                      We Up-level Your Customer Experience by Re-engineering
                      Existing Products
                    </b>
                  </p>
                  <p className="sm:text-3xl mt-3 max-sm:text-[20px] max-sm:text-center">
                    Discover how we engineered a mobile app for a professional
                    coaching and training company from their flagship web-based
                    product for more data efficiency, versatility, and
                    scalability.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-[100px] max-sm:mt-[50px]">
            <div className="">
              <h5 className="sm:text-[34px] font-extrabold text-center sm:pt-20 max-sm:text-[20px] max-sm:text-center">
                Insights
              </h5>
              <div className="sm:flex flex-row gap-5">
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img
                    src={shouldilaunch}
                    className="h-[300px] w-full"
                  />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    March 2, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      MVP DEVELOPMENT, PRODUCT DEVELOPMENT
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      How to Prioritize Features for Your Minimum Viable Product
                      (MVP)
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={nativevs} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 24, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, SOFTWARE ENGINEERING
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      7 Steps to Build an Effective Application Modernization
                      Roadmap
                    </h5>
                  </div>
                </div>
                <div className="sm:w-[31%] max-sm:w-[100%] shadow-2xl mt-5 hover:animate-pulse">
                  <img src={BestOnlineFoodDeliveryApps} className="h-[300px] w-full" />
                  <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
                    February 27, 2023 /
                  </p>
                  <div className="mt-4">
                    <p className="text-[#D4386C] ml-5">
                      PRODUCT DEVELOPMENT, QUALITY ASSURANCE AND
                    </p>
                    <h5 className="mt-4 sm:text-[25px] px-4 font-semibold leading-9 mb-12 max-sm:text-[20px]">
                      Test Driven Development: How it works, Pros and Cons
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileAppDevelopement;
