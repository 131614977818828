import {
  aimlimg,
  blackimageicon,
  blackimageicon2,
  blackimageicon3,
  blackimageicon4,
  blackimageicon5,
  blockchain_banner,
  Blockchain_Solutions1,
  Blockchain_sol2,
  Blockchain_sol3,
  Blockchain_sol4,
  Blockchain_sol5,
  Blockchain_sol6,
  value_trade,
  internet_of,
  increased_transparency,
  Digital_tra2,
  ChatGPT,
  Digital_tra1,
  Hiring,
  datasciencebannerscaled,
  GoogleCloudSQL,
} from "../../assets";
import { Link } from "react-router-dom";
const DataScience = () => {
  return (
    <>
      <div className="container">
        <div className="flex mt-4">
          <div className="w-[50%]">
            <div className="mt-[160px]">
              <p className="text-3xl">
                <b>Data Science and Analytics</b>
              </p>
              <p className="mt-2 mb-2">
                Draw out your full potential with our data science and analytics
                solutions. Know the power of numbers with RevInfotech and grow
                globally.
              </p>
              <button
                type="submit"
                className="mb-3 mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Know More
              </button>
            </div>
          </div>
          <div className="w-[50%]">
            <img src={datasciencebannerscaled}></img>
          </div>
        </div>
        <div className="flex mt-[100px]">
          <div className="w-[50%]">
            <p className="text-4xl">
              <b>Helping Businesses Get Future Proofed</b>
            </p>
            <p className="mt-4">
              The modern market has many complex aspects, which are both
              integral to survival before worship. While integrating
              state-of-the-art technology is a useful step, not understanding
              its full potential is extremely dangerous. Data science and
              analytics have come a long way in recent years, and you can
              implement these developments within your system as well. Just
              contact RevInfotech and make a breakthrough in the technology
              industry. With our premium solutions, your company can save vital
              resources and time. Face an unpredictable future with confidence
              and stay ready for any market shift. Take advantage of RevInfotech
              and enhance your project capabilities.
            </p>
          </div>
          <div className="w-[50%]">
            <p className="text-3xl">
              <b>Our Other Digital Transformation</b>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">AR and VR</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon2}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Blockchain Development Services
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon3}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">
                    Data Science and Analytics
                  </p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon4}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Design Studio</p>
                </div>
              </div>
              <div className="card mt-4">
                <div className="flex mt-3 mb-3">
                  <img
                    src={blackimageicon5}
                    className="ml-2 w-[25px] h-[25px] mt-2"
                  ></img>
                  <p className="ml-3 p-1  text-lg mt-1">Enterprise Mobility</p>
                </div>
              </div>
              <div className="card mt-4 bg-[#F2E04C]">
                <div className="flex mt-3 mb-3">
                  <p className="ml-3 p-1  text-lg mt-1 text-white">
                    Enterprise Mobility
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#093D77] mt-5">
        <div className="text-center text-white p-5">
          <p className="text-3xl">
            Implement Effective Data Science and Analytics Solutions in Your
            System
          </p>
        </div>
      </div>
      <div className="container mx-auto">
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Quality Services
        </h5>
        <div className="flex flex-row gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={value_trade}
                className="w-16 text-center mx-auto hover:animate-[wiggle_1s_ease-in-out_infinite]"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                BIG DATA INTEGRATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Integrate advanced technologies within your venture and unleash
                your true potential. Get the best big data integration with
                RevInfotech.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img
                src={increased_transparency}
                className="w-16 text-center mx-auto"
              />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                ANALYTICS AS A SERVICE
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                AaaS has emerged as one of the most pivotal technology in the
                industry. Get it instilled in your organization with our help.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <img src={internet_of} className="w-16 text-center mx-auto" />
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                DATA CONSULTING
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Thoroughly researching your data and analytics is a routine job
                for us. Analyze your stats and devise functional strategies.
              </p>
            </div>
          </div>
        </div>
        <h5 className="text-center font-extrabold text-[33px] mt-5">
          Premium Business Benefits
        </h5>
        <div className="flex flex-row flex-wrap gap-5 mt-5">
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVING DATA
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                As your organization grows, valuable data and resources might be
                wasted. With our data science solutions, you can reduce waste
                and optimize insert data. Improve your productivity through our
                data improvement services.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                EXTENSIVE BUSINESS INSIGHTS
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Not understanding your business capabilities fully can render
                your resources useless. Allow us to research your venture and
                know how it works. With RevInfotech, you can get an extensive
                knowledge of your business insights.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                IMPROVED CI
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                {" "}
                The customer interface is central to your organization's
                success. An unhappy client means that your system has failed to
                perform its function. Don't let this failure ruin your
                reputation and let our data solutions improve your CI to its
                fullest potential.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-5 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                FACILITATE AUTOMATION
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                You can only go so far with manual labor, so let us introduce
                the magic of automation into your system. We can integrate
                seamless automation into your procedures to perform boring tasks
                easily and safely.
              </p>
            </div>
          </div>
          <div className="w-[31%] shadow-lg hover:animate-pulse ">
            <div className="px-10 py-11">
              <h5 className="text-[22px] font-bold text-center tracking-widest mt-7">
                RESULTS FORECAST
              </h5>
              <p className="font-light text-center text-[22px] mt-3">
                Our analytics solutions can make calculated predictions for your
                business. With our data analytics algorithms, you can infer
                results and undergo viable transformations to make them
                desirable. Keep up with the market and grow with us optimally.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-5">
          <div className="w-[45%] mt-14 hover:animate-pulse ">
            <h5 className="font-black text-[32px]">
            How Data Science and Analytics can Help You?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
            Gone are the times when only human resources were able to ensure the growth of the company. As time changes, the need for data science and analytics has increased dramatically. Both data science and analytics pave the way for the progress and automation of an organization. When you integrate smart automation into your project, you experience many business advantages that you didn’t know existed. So take advantage of today’s benefits with RevInfotech and reach the top.
            </p>
          </div>
          <div className="w-[45%] mt-14 px-2 py-4 bg-[#F6F6F6]">
            <h5 className="text-[20px]">
            Why Pick RevInfotech for Your Data Science and Analytics Endeavors?
            </h5>
            <p className="font-light tracking-normal text-[21px] mt-2.5">
            Just knowing the benefits of data science and analytics will not do you any good. You need to make the most of the technology and RevInfotech can help you achieve the same thing with ease. Our experts have years of knowledge and experience under their belt, allowing them to perceive the market from a different perspective. Shrewd skillset allows them to understand each current and upcoming trend. Allow us to unleash your true potential and take your business to new heights.
            </p>
            <Link to={""}>
              <button
                type="button"
                className="px-2 py-1.5 bg-yellow-300 w-36 h-10 text-[12px] font-extrabold mt-9"
              >
                Help My Business
              </button>
            </Link>
          </div>
        </div>
        <h5 className="text-[34px] font-extrabold text-center pt-20">
          Insights
        </h5>
        <div className="flex flex-row gap-5">
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Hiring} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              March 2, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Making the Old New: Hiring Fresh
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={GoogleCloudSQL} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 24, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Trends, Challenges, and Solutions for Healthcare’s Digital
                Transformation
              </h5>
            </div>
          </div>
          <div className="w-[31%] shadow-2xl mt-5 hover:animate-pulse">
            <img src={Digital_tra2} className="h-[300px] w-full" />
            <p className="bg-[#F4F9FF] pt-1.5 px-1 w-40 mt-8 ml-5 text-[13px] font-extrabold">
              February 27, 2023 /
            </p>
            <div className="mt-4">
              <h5 className="mt-4 text-[25px] px-4 font-semibold leading-9 mb-12">
                Here are some strategies, checklists, and FAQs about cloud
                migration
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DataScience;
