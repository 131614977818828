import React from 'react'
import { error } from '../../assets';
import { Link } from "react-router-dom";

const ErrorDesign = () => {
  return (
    <>
      <div className='container mx-auto'>
        <div className='flex flex-row pt-[80px] gap-5'>
          <div className='w-[40%] pl-24'>
            <h5 className='text-8xl font-extrabold'>Oops!</h5>
            <p className='text-[27px] font-sans font-light mt-5 '>We can't seem to find the page you're looking for.</p>
            <p className='text-[20px] font-sans font-light mt-3 '>Here are some helpful links instead:</p>
            <div className='flex mt-2'>
              <Link to={""}>
                <p className='text-[#F13623]'>Services</p>
              </Link>
              <Link to={""}>
                <p className='ml-32 text-[#F13623]'>About</p>
              </Link>
            </div>
            <div className='flex mt-2'>
              <Link to={""}>
                <p className='text-[#F13623]'>Works</p>
              </Link>
              <Link to={""}>
                <p className='ml-36 text-[#F13623]'>Insights</p>
              </Link>
            </div>
          </div>
          <div className='w-[40%]'>
            <img src={error} />
          </div>
        </div>
      </div>
    </>

  )
}

export default ErrorDesign