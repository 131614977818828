import React from "react";
import {
    agile_hub,
    pie_chart,
    report,
    principles,
    state,
    Scrum_Meetings,
    dsdm,
    values_xp,
    Lifecycle,
    methodology,
    guide,
    Know,
    Thumbnail,
} from "../../assets";
import { Link } from "react-router-dom";
import Sidebar10 from "../../componets/sidebar/Sidebar10";

const Agile = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="w-[400px] h-[500px] mt-[68px] fixed overflow-auto">
                    <Sidebar10 />
                </div>
                <div className="ml-[420px]">
                    <ul className="flex pt-[85px]">
                        <li>
                            <Link to={""} className="font-medium text-[18px]">
                                Home
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Home Knowledge Hub
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                Product Development
                            </Link>
                        </li>
                        <li className="mt-0.5 ml-3">
                            <div>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li className="ml-3">
                            <Link to={""} className="font-medium text-[18px]">
                                {" "}
                                Agile Software Development
                            </Link>
                        </li>
                    </ul>
                    <div
                        style={{ backgroundImage: `url(${agile_hub})` }}
                        className="w-full h-[400px] bg-center bg-cover mt-5"
                    >
                        <div className="relative left-[53px] top-[80px] w-[33%]">
                            <h5 className="font-extrabold text-[41px] text-white">
                                What is Agile in Software Development?
                            </h5>
                        </div>
                    </div>
                    <div className="px-10">
                        <div className="mt-5">
                            <p className="text-[27px] font-sans	font-light">
                                The pace of technological disruption is massive. By the time the
                                development team is still working along the software development
                                lifecycle, a requirement changes. In this case, the challenge is
                                to incorporate the new changes while also ensuring faster time
                                to market. This is where Agile comes into play because of its
                                iterative and the incremental nature that allows quick response
                                to change.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                The origins of Agile date back to 2001 when 17 software
                                developers met at a resort in Snowbird, Utah, to develop a
                                better method for developing software.
                            </p>
                            <p className="text-[27px] font-sans	font-light mt-4">
                                Since the advent of{" "}
                                <span className="font-medium border-b-[2px] border-[#F13623]">
                                    className='text-[27px] font-sans font-light'
                                </span>
                                , many organizations have been embracing Agile transformation.
                            </p>
                        </div>
                        <div className="mt-5">
                            <img src={pie_chart} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is Agile Methodology?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Agile is an iterative and incremental approach to software
                                development that helps deliver quality software while ensuring
                                faster time to market. The hero characteristic of Agile is its
                                adaptiveness to change.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Agile promotes:
                            </p>
                            <ul className="list-disc">
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Collaboration among teams (with cross-functional setups)
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Open communication
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Better change management
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Iterative development (ability to step back in the SDLC
                                    process)
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Incremental development (delivery in small increments)
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <img src={report} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is the Agile Manifesto?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                In 2001, seventeen software developers published the{" "}
                                <span className="font-medium border-b-[2px] border-[#F13623]">
                                    Agile Manifesto
                                </span>{" "}
                                which highlights the four values and twelve Agile principles.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                The goal of the Agile Manifesto is to —{" "}
                                <span className="font-bold">
                                    uncover better ways of developing software by doing it and
                                    helping others do it.
                                </span>
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                The four values of Agile methodology include:
                            </p>
                            <ul className="list-disc">
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Individual and Interactions over Processes and Tools
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Working Software over Comprehensive Documentation
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Customer Collaboration over Contract Negotiation
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Responding to Change over Following a Plan
                                </li>
                            </ul>
                            <p className="text-[27px] font-sans font-light mt-5">
                                And the 12 principles of Agile methodology include:
                            </p>
                        </div>
                        <div className="mt-5">
                            <img src={principles} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">Why Agile?</h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Agile is a better way to develop software as it ensures due
                                consideration to changing requirements without hampering time to
                                market. According to{" "}
                                <span className="font-medium border-b-[2px] border-[#F13623]">
                                    the 14th State of Agile report
                                </span>
                                , the top reasons for adopting Agile include.
                            </p>
                        </div>
                        <div className="mt-5">
                            <img src={state} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is Agile Transformation?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Many organizations are still following the traditional software
                                development methodologies (owing to the golden hammer bias),
                                which, in turn, leads to failure to adapt and respond to the
                                changing technological landscape. With Agile transformation, the
                                traditionally set organizations can plan and initiate the move
                                toward adopting and implementing Agile{" "}
                                <span className="font-medium border-b-[2px] border-[#F13623]">
                                    development solutions
                                </span>
                                .
                            </p>
                            <p className="text-[27px] font-sans font-bold mt-5">
                                Agile transformation introduces a step-by-step approach to going
                                fully Agile. An organization is fully Agile when the different
                                teams naturally and consciously follow the Agile practices to
                                complete a task at hand.
                            </p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                What is an Agile Framework?
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                The agile framework defines the different approaches that Agile
                                follows to develop software.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Some of the popular Agile Frameworks include:
                            </p>
                        </div>
                        <div className="mt-5">
                            <p className="font-bold text-[27px]">
                                1. <span className="ml-3">Scrum</span>
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                According to The{" "}
                                <span className="font-medium border-b-[2px] border-[#F13623]">
                                    Scrum Guide
                                </span>
                                , Scrum is a lightweight framework that helps people, teams, and
                                organizations generate value through adaptive solutions for
                                complex problems. The Scrum Guide mentions the standards and
                                practices used in the scrum process which were created by Ken
                                Schwaber and Jeff Sutherland in 2010.
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                According to the{" "}
                                <span className="font-medium border-b-[2px] border-[#F13623]">
                                    14th State of Agile Report
                                </span>
                                , around 75% of the respondents already practice Agile Scrum
                                methodology.
                            </p>
                            <ul className="list-disc">
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    <strong className="font-bold">Scrum Master:</strong>
                                    <span>
                                        Individual and Interactions over Processes and Tools
                                    </span>
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    <strong className="font-bold">Product Owner:</strong>
                                    <span>Working Software over Comprehensive Documentation</span>
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    <strong className="font-bold">Scrum Team</strong>
                                    <span>Customer Collaboration over Contract Negotiation</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <img src={Scrum_Meetings} />
                        </div>
                        <div className="mt-5">
                            <p className="font-bold text-[27px]">
                                2. <span className="ml-3">Crystal Methodologies</span>
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                Crystal Methodology focuses on addressing the first value of the
                                Agile Manifesto (i.e., “Individuals and Interactions over
                                Processes and Tools”). Alistair Cockburn introduced this
                                framework at IBM in 1991.
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                The prominent features of Crystal methodology include:
                            </p>
                            <ul className="list-disc">
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    <strong className="font-bold">Scrum Master:</strong>
                                    <span>
                                        The software development process will vary according to the
                                        project’s requirements and the team has the freedom to
                                        choose the right strategy going forward
                                    </span>
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    <strong className="font-bold">Product Owner:</strong>
                                    <span>Working Software over Comprehensive Documentation</span>
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    <strong className="font-bold">Scrum Team:</strong>
                                    <span>Customer Collaboration over Contract Negotiation</span>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <p className="font-bold text-[27px]">
                                3.{" "}
                                <span className="ml-3">
                                    Dynamic Software Development Method (DSDM)
                                </span>
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                DSDM evolved as an Agile framework (previously popular as a
                                software development methodology) that addresses cost, time, and
                                quality concerns. DSDM uses the MoSCoW rule of prioritization
                                (based on must-haves, should-haves, nice-to-have, and
                                won’t-haves) for finalizing requirements.
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                DSDM works on solving people’s problems as it is based on the
                                belief that projects fail due to a lack of collaboration among
                                teams rather than due to technology. Moreover, DSDM is
                                independent of tools and techniques — making it a flexible Agile
                                framework to work with.
                            </p>
                        </div>
                        <div className="mt-5">
                            <img src={dsdm} />
                        </div>
                        <div className="mt-5">
                            <p className="font-bold text-[27px]">
                                4. <span className="ml-3">Lean Software Development (LSD)</span>
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                Lean Software Development (LSD) is an Agile framework that
                                focuses on optimizing development time and resources while
                                eliminating waste and maximizing value delivery. Toyota
                                developed LSD, therefore, it was initially known as the Toyota
                                Production System.
                            </p>
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">
                                The LSD methodology is similar to the minimum viable product
                                (MVP) strategy. The basic version of the product is launched in
                                the market and changes are made based on feedback received.
                            </p>
                        </div>
                        <div className="mt-5">
                            <p className="text-[24px] font-sans	font-light mt-4 ml-[40px]">5.<span>eXtreme Programming (XP) is an Agile framework that focuses on developing high-quality software while also ensuring a stress-free life for the development team. Kent Beck introduced XP in 1996 to support:</span></p>
                            <ul className="list-disc">
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Changing software requirements
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Changing software requirements
                                </li>
                                <li className="text-[27px] font-sans font-light mt-4 ml-[55px]">
                                    Automated unit and functional tests
                                </li>
                            </ul>
                        </div>
                        <div className="mt-5">
                            <img src={values_xp} />
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">The Agile Glossary</h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                The common terms that are associated with Agile include:
                            </p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>1. <span className='ml-3'>User Story</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>A user story is a natural language description of a particular software feature. It is written from the perspective of the end-user.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The format of a user story is — <span className="font-bold">“As a [role], I want [goal] so that [benefit]”</span>.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>2. <span className='ml-3'>Epic</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>An epic is a large user story that can be further broken down into smaller, manageable tasks.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>3. <span className='ml-3'>Sprint</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>A sprint is a short and predetermined time-box period where the scrum team works on completing the assigned tasks. Sprint planning helps in picking up tasks from the product backlog that, in turn, help define the sprint goals.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>4. <span className='ml-3'>Daily Standups</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>Daily standups are everyday meetings among the Agile development team where they discuss — what they did yesterday, what they plan to do today, and any challenges they faced while completing the tasks at hand.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>5. <span className='ml-3'>Product Backlog</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>A product backlog is a prioritized list of user stories. The most important tasks are listed at the top of the product backlog so that they can be picked up first.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>6. <span className='ml-3'>Backlog Grooming</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>A backlog grooming session occurs at the end of every sprint to reprioritize tasks as new realities emerge. The goal of backlog grooming is to ensure the most valuable tasks reside at the top of the product backlog.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className="font-extrabold text-[41px]">How to Estimate an Agile Project?</h5>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>Agile estimation is a technique that helps calculate the effort necessary to complete a prioritized item in the product backlog. Estimation in Agile is also done based on story points (i.e., measuring the difficulty of completing a given user story).</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>T-Shirt sizing</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Planning poker</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Analogy</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Dot voting</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Affinity mapping</li>
                                <li className='text-[24px] font-sans font-light mt-4'>Bucket system</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <h5 className="font-extrabold text-[41px]">What are the Stages of Agile Development Lifecycle [Agile-Scrum]?</h5>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The six stages of the Agile development lifecycle are:</p>
                        </div>
                        <div className="mt-5">
                            <img src={Lifecycle} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>1. <span className='ml-3 font-bold'>Requirements Gathering</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>This stage aims to undergo an initial requirements analysis. In Agile, this phase is known as “identification of user stories.” The user story is a natural language description of the particular functionality of the software.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>This stage helps in understanding:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'>Who is the target audience ( i.e., the users of the software)</li>
                                <li className='text-[24px] font-sans font-light mt-4'>What problems will the software solve?</li>
                                <li className='text-[24px] font-sans font-light mt-4'>What are the different user stories that will go into building the MVP?</li>
                            </ul>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>Stage 2: <span className='ml-3'>Design</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The objective of this stage is to create a prototype that helps visualize the UI/UX flow of the software under development. The design architects, designers, and developers work collaboratively through this stage to enhance the overall experience design of the product.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>Stage 3: <span className='ml-3'>Development</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The Agile development team works on coding within time-boxed sprints. The developers pick the user stories from the prioritized product backlog and start working to complete the small, manageable tasks.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>Stage 4: <span className='ml-3'>Testing</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The Agile testing team checks the code for bugs, inconsistencies, and other issues. The identified issues are reported back to the development team. The team then conducts backlog grooming and re-prioritizes tasks to accommodate the bug fixing tasks.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>Stage 5: <span className='ml-3'>Deployment</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>This stage aims to release the developed user stories in the market (i.e., executing the launch process).</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>Stage 6: <span className='ml-3'>Maintenance</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The software is maintained throughout its lifetime. At this stage, the team works on introducing updates, addressing bug fixtures, and providing ongoing support.</p>
                        </div>
                        <div className='mt-5'>
                            <h5 className="font-extrabold text-[41px]">FAQs Related to Agile</h5>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The six stages of the Agile development lifecycle are:</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>1. <span className='ml-3'>What is Scaled Agile Framework (SAFe)?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>Scaled Agile Framework (SAFe) is a framework for enterprises for developing large-scale software using lean and Agile practices. SAFe is suitable for businesses that have a stronghold over the scrum process.</p>
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>2. <span className='ml-3'>What are the KPIs of Agile?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>The popular key performance indicators for Agile include:</p>
                            <ul className='list-disc ml-14'>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Lead Time: </strong>Measures the time between a requirement made and its completion.</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Burndown Charts:  </strong>These are the visualization charts that demonstrate the pending work in the product backlog</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Burnup Charts: </strong>These are the visualization charts that demonstrate the amount of work completed</li>
                                <li className='text-[24px] font-sans font-light mt-4'><strong className="font-bold">Velocity:</strong>Measures the amount of work completed at the end of every sprint</li>

                            </ul>
                        </div>
                        <div className="mt-5">
                            <img src={methodology} />
                        </div>
                        <div className='mt-5'>
                            <p className='font-bold text-[23px]'>3. <span className='ml-3'>What is Code Refactoring in Agile?</span></p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>Code refactoring is the process of modifying the internal structure of the code in a way that its external structure remains unaffected. The purpose of code refactoring is to clean and refine the code to make it more readable and understandable.</p>
                            <p className='text-[24px] font-sans	font-light mt-4 ml-[33px]'>Code refactoring helps minimize technical debt (i.e., the cruft in the code that leads to messy and unstructured code fragments).</p>
                        </div>
                        <div className="mt-5">
                            <h5 className="font-extrabold text-[41px]">
                                Conclusion
                            </h5>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Agile is one of the most popular software development methodologies that introduces iterative and incremental development. The best part about Agile is that it can accommodate requirement changes at any stage of the development process. There are many Agile frameworks to choose from that promote different approaches for implementing Agile. These frameworks include Scrum, Kanban, DSDM, FDD, Crystal methodology, Lean, etcetera.
                            </p>
                            <p className="text-[27px] font-sans font-light mt-5">
                                Practicing Agile for developing software products is a good decision because it provides the ability to work on new requirements as different realities emerge. The Agile Manifesto introduces the twelve principles and four values of Agile that help in developing quality software. This write-up covers everything about Agile and the related concepts.
                            </p>
                        </div>
                    </div>
                </div>
                <h5 className='font-black text-[48px] mt-5'>Featured Blogs</h5>
                <div className='flex gap-5'>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Thumbnail} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>AGILE DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className='text-[21px] hover:text-[#F13623] font-bold'>
                                    Agile Project Management 101: Everything You Need to Know to Get Started</Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={Know} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div className='flex'>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>Know all about User Stories in Agile: Examples, Templates, Best Practices</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>
                                        Omnichannel vs Multichannel Retailing: The Complete Guide </Link>
                                </div>
                            </div>
                            <div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-[31%] shadow-2xl mt-5'>
                        <img src={guide} />
                        <div className='px-4'>
                            <div className='flex mt-3'>
                                <p className='px-2 bg-[#F9D94E]'>BLOG</p>
                                <p className='px-2 bg-[#F4F9FF] ml-3'>February 23, 2023</p>
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <Link to={""} className='text-[#F13623] tracking-widest text-[12px]'>AGILE DEVELOPMENT,  PRODUCT DEVELOPMENT</Link >
                                </div>
                                <div className='mt-4'>
                                    <Link to={""} className=' text-[21px] hover:text-[#F13623] font-bold'>

                                    How to Make Distributed Agile Teams Work: A Project Manager’s Guide </Link>
                                </div>
                                <button type='button' className='w-28 px-2 py-2 mb-8 mt-11 border-[2px] border-[#F13623] text-[#F13623] hover:bg-[#F13623] hover:text-white '>Read Blog</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Agile;
